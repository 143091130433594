/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconTrain = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 81 81"
    fill="none"
    {...rest}
  >
    <g fill="currentColor">
      <path
        clipRule="evenodd"
        d="m27.6 47.1c2.1 5 7.1 8.6 12.9 8.6s10.8-3.5 12.9-8.6h-6.3c-1.6 1.9-3.9 3.1-6.6 3.1s-5-1.2-6.6-3.1z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m53.4 34.6c-2.1-5-7.1-8.6-12.9-8.6s-10.8 3.5-12.9 8.6h6.3c1.6-1.9 3.9-3.1 6.6-3.1s5 1.2 6.6 3.1z"
        fillRule="evenodd"
      />
      <path d="m56.8 37.7h-32.7v6.4h32.7z" />
    </g>
  </svg>
);

IconTrain.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconTrain.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconTrain);
