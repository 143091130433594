import logger from 'services/logger/logger';

import Client from '../Client';
import APIError from '../Entities/APIError';
import BuildingsMockNamespace from './Namespaces/Buildings';
import ContactTypesMockNamespace from './Namespaces/Buildings/ContactType';
import DepartmentsMockNamespace from './Namespaces/Buildings/Departments';
import FloorsMockNamespace from './Namespaces/Buildings/Floors';
import JobRolesMockNamespace from './Namespaces/Buildings/JobRoles';
import LocationsMockNamespace from './Namespaces/Buildings/Locations';
import ContactsMockNamespace from './Namespaces/Buildings/Occupiers/Contacts';
import OccupiersMockNamespace from './Namespaces/Buildings/Occupiers/Occupiers';
import PrioritiesMockNamespace from './Namespaces/Buildings/Priorities';
import TravelOptionsMockNamespace from './Namespaces/Buildings/TravelOptions';
import ClientsMockNamespace from './Namespaces/Clients';
import CommentsMockNamespace from './Namespaces/Comments';
import CompaniesMockNamespace from './Namespaces/Companies';
import BaysNamespace from './Namespaces/Deliveries/Bays';
import DeliveriesMockNamespace from './Namespaces/Deliveries/Deliveries';
import EmailTemplatesMockNamespace from './Namespaces/EmailTemplates';
import GeneralMockNamespace from './Namespaces/General';
import HelpdeskCategoriesMockNamespace from './Namespaces/Helpdesk/Categories';
import HelpdeskTicketMockNamespace from './Namespaces/Helpdesk/Tickets';
import MobileArticlesMockNamespace from './Namespaces/Mobile/Articles/Articles';
import MobileEventsMockNamespace from './Namespaces/Mobile/Events/Events';
import MobileMyBuildingMockNamespace from './Namespaces/Mobile/MyBuilding';
import MobileOffersMockNamespace from './Namespaces/Mobile/Offers/Offers';
import MobilePollsMockNamespace from './Namespaces/Mobile/Polls/Polls';
import NotificationMockNamespace from './Namespaces/Notifications';
import OnboardingMockNamespace from './Namespaces/Onboarding';
import PermitsMockNamespace from './Namespaces/Permits';
import UserRolesMockNamespace from './Namespaces/Users/UserRoles';
import UsersMockNamespace from './Namespaces/Users/Users';
import VisitorsMockNamespace from './Namespaces/Visitors';
import VisitorGuestsMockNamespace from './Namespaces/Visitors/Guests';
import VisitorTypesMockNamespace from './Namespaces/Visitors/VisitorTypes';

export default class MockFramework {
  mockNamespaces = {};

  mockRequests = {};

  helpers = { get: () => {}, save: () => {} };

  permissions = {};

  endpointsToError = {};

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client, helpers, permissions) {
    this.client = Client;

    if (Object.prototype.toString.call(helpers) === '[object Object]') {
      const { get: _get, save: _save } = helpers;
      if (typeof _get !== 'function') helpers.get = () => {};
      if (typeof _save !== 'function') helpers.save = () => {};

      this.helpers = helpers;
    }

    if (Object.prototype.toString.call(permissions) === '[object Object]') {
      this.permissions = permissions;
    }

    const classes = [
      GeneralMockNamespace,
      EmailTemplatesMockNamespace,
      NotificationMockNamespace,
      FloorsMockNamespace,
      LocationsMockNamespace,
      DepartmentsMockNamespace,
      PrioritiesMockNamespace,
      JobRolesMockNamespace,
      TravelOptionsMockNamespace,
      ContactTypesMockNamespace,
      BuildingsMockNamespace,
      CompaniesMockNamespace,
      ContactsMockNamespace,
      OccupiersMockNamespace,
      OnboardingMockNamespace,
      BaysNamespace,
      CommentsMockNamespace,
      DeliveriesMockNamespace,
      UserRolesMockNamespace,
      UsersMockNamespace,
      VisitorGuestsMockNamespace,
      VisitorsMockNamespace,
      VisitorTypesMockNamespace,
      HelpdeskCategoriesMockNamespace,
      HelpdeskTicketMockNamespace,
      PermitsMockNamespace,
      ClientsMockNamespace,
      MobileMyBuildingMockNamespace,
      MobileArticlesMockNamespace,
      MobileEventsMockNamespace,
      MobileOffersMockNamespace,
      MobilePollsMockNamespace
    ];

    classes.forEach((mockNamespace) => {
      const { name } = mockNamespace;
      logger.debug(`---Initializing ${name}---`);
      this.mockNamespaces[name] = new mockNamespace(Client, this);
    });

    for (const name in this.mockNamespaces) {
      const namespace = this.mockNamespaces[name];
      Object.assign(this.mockRequests, namespace.getEndpoints());
    }
  }

  request(mechanism, path, payload, headers, method, transmitType, isLoggedIn) {
    logger.debug(
      'Mock Request',
      path,
      payload,
      method,
      transmitType,
      isLoggedIn
    );

    let response = {
      code: 500,
      error: { code: 500, message: 'Unknown Error' }
    };

    if (
      this.mockRequests[path] &&
      typeof this.mockRequests[path] === 'function'
    ) {
      const endpointError = this.endpointsToError[path]
        ? this.endpointsToError[path]
        : false;
      const userPermissions = [];

      response = this.mockRequests[path](
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        endpointError
      );
    }

    logger.debug('Mock Response', path, response);

    return response;
  }

  setEndpointError(endpoint, error) {
    if (error === false) {
      if (this.endpointsToError[endpoint]) {
        delete this.endpointsToError[endpoint];
      }
    } else {
      this.endpointsToError[endpoint] = error;
    }
  }

  generateUUIDV4 = () => this.client.generateUUIDV4();

  createErrorResponse = (code) => ({ code, error: { code } });

  defaultPrismaMock = (mockData) => ({
    code: 200,
    data: mockData
  });

  defaultSearchMock(payload, dataSet, filters, permittedErrors, error) {
    if (error !== false && permittedErrors.includes(error)) {
      return this.createErrorResponse(error);
    }
    let { itemsPerPage } = this.client.config.mock;
    if (!itemsPerPage) itemsPerPage = 100;

    if (payload === null) payload = {};
    let { page, orderBy, orderDirection } = payload;

    if (!page || page === 1) page = 1;
    const [records, maxPages] = this.mockSearchCall(
      payload,
      dataSet,
      filters,
      page,
      itemsPerPage,
      orderBy,
      orderDirection
    );

    if (records.length) {
      return {
        code: 200,
        data: {
          records,
          page: parseInt(page, 10),
          pages: parseInt(maxPages, 10)
        }
      };
    }
    return this.createErrorResponse('no_records');
  }

  defaultDeleteMock(
    dataSet,
    id,
    entityIdField,
    permittedErrors,
    error,
    noRecordError,
    isLoggedIn
  ) {
    if (error !== false && permittedErrors.includes(error)) {
      return this.createErrorResponse(error);
    }
    if (!isLoggedIn) return this.createErrorResponse('not_logged_in');
    logger.debug('ids', id);
    if (Array.isArray(id)) {
      id.forEach((id) => this.deleteRecord(dataSet, entityIdField, id));
      return { code: 200, data: true };
    }
    if (this.deleteRecord(dataSet, entityIdField, id)) {
      return { code: 200, data: true };
    }

    return this.createErrorResponse(noRecordError);
  }

  mockSearchCall(
    payload,
    dataSet,
    filters,
    page,
    itemsPerPage,
    orderBy,
    orderDirection
  ) {
    let records = [];

    if (filters) {
      dataSet.forEach((record, index) => {
        let use = true;
        for (const paramKey in filters) {
          const filter = filters[paramKey];

          if (typeof payload === 'object' && payload.hasOwnProperty(paramKey)) {
            let paramValue = payload[paramKey];
            const { field, expression } = filter;

            if (paramValue && record.hasOwnProperty(field)) {
              let recordValue = record[field];

              if (typeof paramValue === 'string')
                paramValue = paramValue.toLowerCase();
              if (typeof recordValue === 'string')
                recordValue = recordValue.toLowerCase();

              if (expression === 'like' && !recordValue.includes(paramValue)) {
                use = false;
              } else if (expression === 'eq' && paramValue != recordValue) {
                /** Using != here to allow for ambiguity in the value and type * */
                use = false;
              }
            }
          }
        }
        if (use) records.push(record);
      });
    } else {
      records = dataSet;
    }

    const maxPages = Math.ceil(records.length / itemsPerPage);

    if (orderBy && records.length > 1) {
      records.sort((record1, record2) => {
        if (typeof record1[orderBy] !== 'undefined') {
          const value1 = record1[orderBy];
          const value2 = record2[orderBy];

          if (typeof value1 === 'string' && typeof value2 === 'string') {
            return orderDirection === 'desc'
              ? value2.localeCompare(value1)
              : value1.localeCompare(value2);
          }
          return orderDirection === 'desc' ? value2 - value1 : value1 - value2;
        }
        return 0;
      });
    }

    return [records.splice((page - 1) * itemsPerPage, itemsPerPage), maxPages];
  }

  updateRecord(dataSet, id, entityIdField, payload) {
    for (const x in dataSet) {
      if (dataSet.hasOwnProperty(x)) {
        const entity = dataSet[x];
        if (Number.isSafeInteger(entity[entityIdField])) id = parseInt(id, 10);

        if (entity[entityIdField] === id) {
          for (const field in entity) {
            if (
              field !== 'id' &&
              Object.prototype.hasOwnProperty.call(payload, field)
            ) {
              entity[field] = payload[field];
            }
          }
          entity.modified = new Date().toISOString();
          return entity;
        }
      }
    }
    throw new APIError(null, 'no_record');
  }

  deleteRecord(dataSet, entityIdField, id) {
    for (const x in dataSet) {
      if (dataSet.hasOwnProperty(x)) {
        const entity = dataSet[x];
        if (entity[entityIdField] == id) {
          dataSet.splice(x, 1);
          return true;
        }
      }
    }
    return false;
  }
}
