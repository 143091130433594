/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const LayerGroup = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 15 18"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M7.49132 0.000279155C7.39611 0.00556835 7.30443 0.0337774 7.22333 0.0849063L0.260983 4.44144C0.00181201 4.60188 -0.077526 4.94215 0.0829131 5.19956C0.12699 5.27185 0.188697 5.33179 0.260983 5.37763L7.2251 9.73241C7.40317 9.84348 7.62884 9.84348 7.80691 9.73241L14.771 5.37763C15.0302 5.21719 15.1095 4.87692 14.9491 4.61951C14.905 4.54723 14.8433 4.48728 14.771 4.44144L7.80515 0.0849063C7.7117 0.0267251 7.60239 -0.00324698 7.49132 0.000279155ZM1.59562 7.51271L0.260983 8.35016C0.00357508 8.51237 -0.0739999 8.85264 0.0899653 9.11004C0.134042 9.1788 0.192223 9.23875 0.260983 9.28106L7.2251 13.6411C7.40317 13.7522 7.62884 13.7522 7.80691 13.6411L14.771 9.28106C15.0284 9.11886 15.106 8.77859 14.942 8.52118C14.898 8.45242 14.8398 8.39248 14.771 8.35016L13.4364 7.51271L8.38872 10.6668C8.38696 10.6668 8.38696 10.6686 8.38519 10.6686C7.85275 10.9983 7.17397 10.9983 6.64152 10.6686C6.63976 10.6686 6.63976 10.6668 6.638 10.6668L1.59562 7.51271ZM1.59739 11.4197L0.260983 12.2554C0.00357508 12.4158 -0.0757629 12.7561 0.0846761 13.0152C0.128753 13.0858 0.188697 13.1475 0.260983 13.1915L7.2251 17.5481C7.40317 17.6592 7.62884 17.6592 7.80691 17.5481L14.771 13.1915C15.0284 13.0311 15.1078 12.6908 14.9473 12.4317C14.9033 12.3611 14.8433 12.2994 14.771 12.2554L13.4346 11.4197L8.39225 14.5773C8.39048 14.5773 8.39048 14.5791 8.38872 14.5791C7.85627 14.9088 7.17749 14.9088 6.64505 14.5791C6.64328 14.5791 6.64328 14.5773 6.64152 14.5773L1.59739 11.4197Z"
    />
  </svg>
);

LayerGroup.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

LayerGroup.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(LayerGroup);
