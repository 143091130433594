/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconSettings = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="m15 8.51v-2.02c-1.0319-.36687-1.6838-.47-2.0119-1.26188v-.00062c-.3293-.79437.0625-1.33375.5294-2.31688l-1.4281-1.42812c-.9756.46375-1.5207.85938-2.3169.52938h-.00062c-.79313-.32875-.89688-.985-1.26188-2.01188h-2.02c-.36375 1.02188-.46813 1.6825-1.26188 2.01188h-.00062c-.79437.33-1.3325-.06126-2.31688-.52938l-1.42812 1.42812c.46563.98.85938 1.52126.52938 2.31688-.32938.79438-.99.89875-2.01188 1.2625v2.02c1.02.3625 1.6825.46813 2.01188 1.26188.33125.80122-.07126 1.35372-.52938 2.31682l1.42812 1.4288c.97626-.4644 1.52126-.8594 2.31688-.5294h.00062c.79375.3288.8975.9869 1.26188 2.0119h2.02c.36375-1.0225.46875-1.6812 1.26687-2.0138h.00063c.7887-.3275 1.325.0632 2.3112.5319l1.4282-1.4287c-.465-.9769-.8594-1.5206-.53-2.31628.3293-.79437.9925-.89999 2.0131-1.26312zm-7.5 1.49c-1.38063 0-2.5-1.11938-2.5-2.5 0-1.38063 1.11937-2.5 2.5-2.5 1.38062 0 2.5 1.11937 2.5 2.5 0 1.38062-1.11938 2.5-2.5 2.5z"
    />
  </svg>
);

IconSettings.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconSettings.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconSettings);
