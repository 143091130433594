/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconUserChat = ({ size, colour, fill, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill={fill}
    {...rest}
  >
    <path
      d="M50.88,35.39h4.27l-0.48,12.97l12.25-12.97h22.02V9.63H50.88V35.39z M77.74,20.25H80c0.83,0,1.5,0.68,1.5,1.5  c0,0.83-0.67,1.5-1.5,1.5h-2.26c-0.83,0-1.5-0.67-1.5-1.5C76.24,20.93,76.91,20.25,77.74,20.25z M68.24,20.25h2.26  c0.83,0,1.5,0.68,1.5,1.5c0,0.83-0.67,1.5-1.5,1.5h-2.26c-0.82,0-1.5-0.67-1.5-1.5C66.74,20.93,67.42,20.25,68.24,20.25z   M58.75,20.25h2.26c0.82,0,1.5,0.68,1.5,1.5c0,0.83-0.68,1.5-1.5,1.5h-2.26c-0.83,0-1.5-0.67-1.5-1.5  C57.25,20.93,57.92,20.25,58.75,20.25z"
      fill={fill}
    />
    <path
      d="M36.29,38.33c-7.41,0-13.41,6-13.41,13.41c0,5.59,3.43,10.39,8.3,12.39c1.65-0.32,3.36-0.49,5.11-0.49s3.46,0.17,5.11,0.49  c4.87-2,8.3-6.8,8.3-12.39C49.7,44.33,43.7,38.33,36.29,38.33z"
      fill={fill}
    />
    <path
      d="M36.29,66.64c-12.58,0-22.9,9.84-23.68,22.23h47.36C59.19,76.48,48.87,66.64,36.29,66.64z"
      fill={fill}
    />
  </svg>
);

IconUserChat.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string,
  fill: PropTypes.string
};

IconUserChat.defaultProps = {
  size: '100px',
  colour: 'primary',
  fill: 'white'
};

export default memo(IconUserChat);
