import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const ReRegisterableInput = ({ id, unregister, required, children, value }) => {
  const [key, setKey] = useState(0);
  const [cachedValue, setCachedValue] = useState('');

  useEffect(() => {
    setCachedValue(value);
    unregister(id, { keepValue: true });
    setKey(key + 1);
  }, [id, required]);

  return <React.Fragment key={key}>{children(cachedValue)}</React.Fragment>;
};

ReRegisterableInput.propTypes = {
  id: PropTypes.string.isRequired,
  unregister: PropTypes.func.isRequired,
  required: PropTypes.bool,
  children: PropTypes.func.isRequired,
  getValue: PropTypes.node.isRequired
};

ReRegisterableInput.defaultProps = {
  required: false
};

export default ReRegisterableInput;
