/* eslint-disable import/no-cycle */
/* eslint-disable new-cap */
/* eslint-disable no-shadow */
import logger from 'services/logger/logger';

import { Client } from '../Client';
import Ticket from '../Entities/Helpdesk/Ticket';
import GenericNamespaceHandler from '../GenericNamespaceHandler';
import HelpdeskCategoriesNamespace from './Helpdesk/Categories';

export default class HelpdeskNamespace extends GenericNamespaceHandler {
  namespace = 'helpdesk';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = Ticket;

  responseKey = 'ticket';

  requiredFields = [];

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    super(Client);
    this.categories = new HelpdeskCategoriesNamespace(Client);
  }

  report = async ({ from_date, to_date, building_id, buildingFloors }) => {
    const response = await this.queryFeed({
      building_id,
      skip: 0,
      take: 1000,
      select: {
        additional_comments: true,
        archived: true,
        assigned: true,
        assignedUser: {
          select: {
            profile: {
              select: {
                name: true
              }
            }
          }
        },
        assigned_user_id: true,
        attending_date: true,
        category: {
          select: {
            name: true
          }
        },
        closed: true,
        created: true,
        description: true,
        floors: true,
        id: true,
        location: {
          select: {
            name: true
          }
        },
        priority: {
          select: {
            name: true
          }
        },
        user: {
          select: {
            profile: {
              select: {
                name: true
              }
            },
            companies: {
              select: {
                company: {
                  select: {
                    name: true
                  }
                }
              }
            }
          }
        },
        user_id: true
      },
      where: {
        created: {
          gte: `${from_date}T00:00:00.000Z`,
          lte: `${to_date}T23:59:59.999Z`
        }
      }
    });
    const records = response.data.records.map((entity) => {
      const ent = entity;
      // add user company, and floor data with expected props for Ticket
      const cmps =
        Array.isArray(entity?.user?.companies) &&
        entity.user.companies
          .filter((c) => c?.company !== null)
          .map((c) => c?.company || {});
      const flrs = entity?.floors.map(
        ({ floor_id }) => buildingFloors.find((bf) => bf.id === floor_id) || {}
      );
      ent.user.companies = cmps || [];
      ent.floors = flrs;
      return new this.responseEntity(this.client, {
        ...entity,
        assigned_user: entity.assignedUser || null
      });
    });

    if (records?.length > 999) {
      logger.warn('Max ticket length exceeded.');
    }

    return {
      ...response.data,
      records
    };
  };

  searchByBuildingId = async (buildingId, query = {}, buildingFloors) => {
    const {
      skip = 0,
      take = 10,
      orderBy = [],
      where = {},
      include = {
        user: {
          include: {
            profile: true
          }
        },
        assignedUser: {
          include: {
            profile: true
          }
        },
        building: true,
        location: true,
        floors: true,
        category: true,
        priority: true,
        department: true
      }
    } = query;

    const response = await this.queryFeed({
      building_id: buildingId,
      skip: skip || 0,
      take: take || 10,
      ...(Object.keys(include).length && { include }),
      ...(Object.keys(where).length && { where }),
      ...(orderBy.length && { orderBy })
    });

    const records = response.data.records.map((entity) => {
      const ent = entity;
      // add floor data with expected props for Ticket
      const flrs = entity?.floors.map(
        ({ floor_id }) => buildingFloors.find((bf) => bf.id === floor_id) || {}
      );
      ent.floors = flrs;
      return new this.responseEntity(this.client, {
        ...entity,
        assigned_user: entity.assignedUser || null
      });
    });

    if (records?.length > 999) {
      logger.warn('Max ticket length exceeded.');
    }

    return {
      ...response.data,
      records
    };
  };

  create(
    buildingId = null,
    floors = null,
    locationId = null,
    description = null,
    optional = { files: undefined, additional_comments: undefined }
  ) {
    let data = {
      building_id: buildingId,
      floors,
      location_id: locationId,
      description
    };
    if (Object.keys(optional).length) data = Object.assign(data, optional);

    return super.create(data);
  }

  update(
    id = null,
    optional = {
      department_id: undefined,
      floors: undefined,
      location_id: undefined,
      category_id: undefined,
      description: undefined,
      files: undefined,
      files_for_delete: undefined,
      additional_comments: undefined
    }
  ) {
    return super.update(id, optional);
  }

  assign(
    id = null,
    date = null,
    assignTo = null,
    categoryId,
    departmentId = null,
    priorityId = null
  ) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');
      if (!assignTo) throw new Error('invalid_user_id');
      if (!categoryId) throw new Error('invalid_category_id');
      if (!departmentId) throw new Error('invalid_department_id');
      if (!priorityId) throw new Error('invalid_priority_id');

      this.client.request(
        this.namespace,
        'assign',
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            const { data } = response;

            let entity = null;
            if (Object.prototype.hasOwnProperty.call(data, this.responseKey))
              entity = new Ticket(this.client, data[this.responseKey]);

            resolve(entity);
          }
        },
        {
          id,
          attending_date: date,
          assigned_user_id: assignTo,
          category_id: categoryId,
          department_id: departmentId,
          priority_id: priorityId,
          closed: false
        },
        Client.methods.PUT
      );
    });
  }

  reassign(id = null, assignTo = null) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');
      if (!assignTo) throw new Error('invalid_user_id');

      this.client.request(
        this.namespace,
        'reassign',
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            const { data } = response;

            let entity = null;
            if (Object.prototype.hasOwnProperty.call(data, this.responseKey))
              entity = new Ticket(this.client, data[this.responseKey]);

            resolve(entity);
          }
        },
        { id, assigned_user_id: assignTo },
        Client.methods.PUT
      );
    });
  }

  close(id = null, comment = null) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'close',
        (error, { data }) => {
          if (error) {
            reject(error);
          } else {
            resolve(Boolean(data));
          }
        },
        { id, comment },
        Client.methods.PUT
      );
    });
  }

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(id, unarchive) {
    return new Promise((resolve) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        unarchive ? 'unarchive' : 'archive',
        (error, { data }) => resolve(Boolean(data)),
        { id },
        Client.methods.PUT
      );
    });
  }
}
