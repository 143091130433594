import Client from '../../Client';

export default class CompaniesMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Aegon',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: true,
      domains: [{ type: 'domain', data: 'aegon.co.uk' }],
      picture_uri:
        'https://upload.wikimedia.org/wikipedia/en/thumb/1/1c/AEGON_%28logo%29.svg/1280px-AEGON_%28logo%29.svg.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 2,
      name: 'Landing 42',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: true,
      domains: [{ type: 'domain', data: 'landingfortytwo.com' }],
      picture_uri: 'https://i.imgur.com/P9W84rS.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      name: 'FM Global',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: false,
      domains: [{ type: 'domain', data: 'fmglobal.com' }],
      picture_uri: 'https://i.imgur.com/vGs3CKC.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      name: 'Fidelis',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: false,
      domains: [{ type: 'domain', data: 'fidelisinsurance.com' }],
      picture_uri: 'https://i.imgur.com/LANNgbY.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 5,
      name: 'Angel Court Bank',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: true,
      domains: [{ type: 'domain', data: 'angelcourtbank.com' }],
      picture_uri: 'https://i.imgur.com/aT6crb3.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 6,
      name: 'BBC',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: false,
      domains: [
        { type: 'domain', data: 'bbc.com' },
        { type: 'domain', data: 'bbc.co.uk' }
      ],
      picture_uri: 'https://i.imgur.com/ArliHq4.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 7,
      name: 'Yoox-net-a-Porter',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: true,
      domains: [{ type: 'domain', data: 'ynap.com' }],
      picture_uri: 'https://i.imgur.com/RwEjqPH.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 8,
      name: 'ITV Studios',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      ignore_email_validation: true,
      domains: [{ type: 'domain', data: 'itvstudios.com' }],
      picture_uri: 'https://i.imgur.com/iL4Hoyf.png',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  getEndpoints() {
    return {
      companies: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          address1: { field: 'address1', expression: 'like' },
          address2: { field: 'address2', expression: 'like' },
          address3: { field: 'address3', expression: 'like' },
          city: { field: 'city', expression: 'like' },
          county: { field: 'county', expression: 'like' },
          postcode: { field: 'postcode', expression: 'like' }
        };
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'companies/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        try {
          const entity = {
            id: this.dataSet.length + 1,
            name: payload.name,
            address1: payload.address1,
            address2: payload.address2,
            address3: payload.address3,
            city: payload.city,
            county: payload.county,
            postcode: payload.postcode,
            ignore_email_validation: payload.ignore_email_validation,
            picture_uri: payload.picture_uri,
            domains: payload.domains,
            created: new Date().toISOString(),
            modified: new Date().toISOString()
          };

          this.dataSet.push(entity);

          return {
            code: 200,
            data: { company: entity }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'companies/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'update_no_permission',
          'provide_company_id',
          'email_template_valid_name',
          'email_template_valid_subject',
          'email_template_exists'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const ret = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );
          const { delivery_terms_and_conditions } = payload;

          if (delivery_terms_and_conditions) {
            ret.terms_and_conditions.delivery.content = delivery_terms_and_conditions;
            ret.terms_and_conditions.delivery.modified = new Date().toISOString();
          }

          return { code: 200, data: { company: ret } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
