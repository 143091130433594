export default class OnboardingSession {
  /** *
   *
   * @param {Object} OnboardingSession - Session passed back by the API
   */
  constructor({ id, user_id, data }) {
    this.id = id || '';
    this.user_id = user_id || 0;
    this.data = data || [];
  }

  parseData(data) {
    data.forEach((dataItem) => {
      this.data[dataItem.data_key] = JSON.parse(dataItem.data_value);
    });
  }

  setDataForKey(dataKey = '', dataValue = '') {
    this.data[dataKey] = dataValue;
  }

  getDataByKey(dataKey) {
    if (this.data[dataKey]) return this.data[dataKey];
    return null;
  }
}
