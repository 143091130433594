/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';

import { motion } from 'framer-motion';

import Input from './Input';

const ForwardedRefInput = forwardRef((props, ref) => (
  <Input {...props} inputRef={ref} />
));

const MotionComponent = motion(ForwardedRefInput);

export default MotionComponent;
