import React from 'react';

import PropTypes from 'prop-types';

const IconPrinter = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 20 23"
    fill="none"
    {...rest}
  >
    {colour !== 'theme-primary' ? (
      <path
        d="M15.8346 7.89583H4.16797C2.78464 7.89583 1.66797 9.12417 1.66797 10.6458V16.1458H5.0013V19.8125H15.0013V16.1458H18.3346V10.6458C18.3346 9.12417 17.218 7.89583 15.8346 7.89583ZM13.3346 17.9792H6.66797V13.3958H13.3346V17.9792ZM15.8346 11.5625C15.3763 11.5625 15.0013 11.15 15.0013 10.6458C15.0013 10.1417 15.3763 9.72917 15.8346 9.72917C16.293 9.72917 16.668 10.1417 16.668 10.6458C16.668 11.15 16.293 11.5625 15.8346 11.5625ZM15.0013 3.3125H5.0013V6.97917H15.0013V3.3125Z"
        fill="white"
      />
    ) : (
      <path
        d="M15.8346 7.89583H4.16797C2.78464 7.89583 1.66797 9.12417 1.66797 10.6458V16.1458H5.0013V19.8125H15.0013V16.1458H18.3346V10.6458C18.3346 9.12417 17.218 7.89583 15.8346 7.89583ZM13.3346 17.9792H6.66797V13.3958H13.3346V17.9792ZM15.8346 11.5625C15.3763 11.5625 15.0013 11.15 15.0013 10.6458C15.0013 10.1417 15.3763 9.72917 15.8346 9.72917C16.293 9.72917 16.668 10.1417 16.668 10.6458C16.668 11.15 16.293 11.5625 15.8346 11.5625ZM15.0013 3.3125H5.0013V6.97917H15.0013V3.3125Z"
        fill="#333333"
      />
    )}
  </svg>
);

IconPrinter.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconPrinter.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default IconPrinter;
