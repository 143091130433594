/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconEmail = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      d="M24.7435 8.20081C24.7435 7.10081 23.8435 6.20081 22.7435 6.20081H6.74353C5.64353 6.20081 4.74353 7.10081 4.74353 8.20081M24.7435 8.20081V20.2008C24.7435 21.3008 23.8435 22.2008 22.7435 22.2008H6.74353C5.64353 22.2008 4.74353 21.3008 4.74353 20.2008V8.20081M24.7435 8.20081L14.7435 15.2008L4.74353 8.20081"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconEmail.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconEmail.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconEmail);
