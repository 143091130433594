export default class TravelOption {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APITravelOption - containing the server side object
   */
  constructor(Client, { id, name, icon, seq, created, modified }) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.name = name || '';
    this.icon = icon || '';
    this.seq = seq || 0;

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
