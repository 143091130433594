import React  from 'react';

import { useAuth } from 'context/auth-context';
import { ddLogsInit } from 'scripts/dd-logs';
import logger from 'services/logger/logger';

import { version } from '../../../package.json';
import LoadingScreen from '../../screens/LoadingScreen/LoadingScreen';

const AuthenticatedApp = React.lazy(() => import('routes/AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() =>
  import('routes/UnauthenticatedApp')
);

const AppEntryPoint = () => {
  ddLogsInit();

  logger.debug('Cureoscity Portal Version:', version);

  const { loggedIn, checkedLoggedIn } = useAuth();

  if (!checkedLoggedIn) return <LoadingScreen heading="Loading" />;
  return loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default AppEntryPoint;
