import logger from 'services/logger/logger';

import AuthCallbacks from './AuthCallbacks';
import APIError from './Entities/APIError';
import Company from './Entities/Company';
import Device from './Entities/Device';
import OnboardingSession from './Entities/OnboardingSession';
import Profile from './Entities/Profile';
import Session from './Entities/Session';
import User from './Entities/User';
import EventListeners from './EventListeners';
import MockFramework from './Mock/Framework';
import MockRest from './Mock/Rest';
import MockSockets from './Mock/Sockets';
import BuildingsNamespace from './Namespaces/Buildings';
import OccupiersNamespace from './Namespaces/Buildings/Occupiers';
import ClientsNamespace from './Namespaces/Clients';
import CommentsNamespace from './Namespaces/Comments';
import CompaniesNamespace from './Namespaces/Companies';
import DeliveriesNamespace from './Namespaces/Deliveries';
import EmailTemplatesNamespace from './Namespaces/EmailTemplates';
import GeneralNamespace from './Namespaces/General';
import HelpdeskNamespace from './Namespaces/Helpdesk';
import MobileNamespace from './Namespaces/Mobile';
import NotificationsNamespace from './Namespaces/Notifications';
import OnboardingNamespace from './Namespaces/Onboarding';
import PermitsNamespace from './Namespaces/Permits';
import SessionsNamespace from './Namespaces/Sessions';
import UsersNamespace from './Namespaces/Users/Users';
import VisitorsNamespace from './Namespaces/Visitors';

export default class Client {
  static methods = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE'
  };

  socketResponseHandlers = {};

  socket = null;

  mockFramework = null;

  mocked = false;

  permissionsCache = {};

  searchCache = {};

  /** *
   *
   * @param {Object} config - Object array containing configuration
   * @param socketIO - Instance of Socket IO
   * @param axios - Instance of Axios
   * @param {AuthCallbacks} authCallbacks - Should be extended version of the AuthCallbacks class
   */
  constructor(
    config = {
      rest: { host: '', api_key: '', apiGateway: '' },
      sockets: { host: '', api_key: '' }
    },
    socketIO = null,
    axios = null,
    authCallbacks = null
  ) {
    this.config = config;
    if (this.config.mock) {
      this.mocked = true;
      this.mockFramework = new MockFramework(
        this,
        config?.mock?.helpers,
        config?.mock?.permissions
      );
      this.socketIO = MockSockets(this, this.mockFramework);
      this.axios = new MockRest(this, this.mockFramework);
    } else {
      this.socketIO = socketIO;
      this.axios = axios;
    }
    this.authCallbacks = authCallbacks;
    this.namespaces = {
      general: new GeneralNamespace(this),
      notifications: new NotificationsNamespace(this),
      comments: new CommentsNamespace(this),
      users: new UsersNamespace(this),
      onboarding: new OnboardingNamespace(this),
      sessions: new SessionsNamespace(this),
      emailTemplates: new EmailTemplatesNamespace(this),
      buildings: new BuildingsNamespace(this),
      visitors: new VisitorsNamespace(this),
      deliveries: new DeliveriesNamespace(this),
      helpdesk: new HelpdeskNamespace(this),
      occupiers: new OccupiersNamespace(this),
      companies: new CompaniesNamespace(this),
      permits: new PermitsNamespace(this),
      clients: new ClientsNamespace(this),
      mobile: new MobileNamespace(this)
    };

    this.eventListeners = new EventListeners(this);
  }

  localizeErrorCode(code) {
    const {
      config: { localization }
    } = this;
    if (Object.prototype.hasOwnProperty.call(localization, code))
      return localization[code];

    return code;
  }

  generateUUIDV4 = () => {
    // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) {
        // Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  };

  /**
   * Method to upgrade connection first time we have a valid session id and device id       *
   * @param callback
   */
  upgradeConnection(callback) {
    /** do we have a socket IO instance? * */
    if (this.socketIO) {
      /** connect to the socket * */
      const socket = this.socketIO(
        `${this.config.sockets.host}?api-key=${this.config.sockets.api_key}`
      );
      socket.authenticated = false;

      /** if something goes wrong, we want the client to be able to perform the call back
       *  instead of waiting
       */
      const failureTimeout = setTimeout(() => {
        socket.disconnect();
        callback(
          new Error('Connection / Authentication with Socket server timed out')
        );
      }, 2000);

      socket.on('connect', () => {
        logger.debug('Socket connected, authenticating', socket.connected);
        try {
          this.authCallbacks.getJWT((token, deviceId) => {
            logger.debug('Getting JWT');

            this.request(
              'authenticate',
              'token',
              (error, data, status) => {
                logger.debug('Authentication Callback');

                /** clear out the failsafe timer * */
                clearTimeout(failureTimeout);
                if (status === 200) {
                  logger.debug('Socket authenticated');
                  socket.authenticated = true;
                  callback();
                } else {
                  logger.debug('Socket authentication failed');
                  socket.authenticated = false;

                  socket.disconnect();
                  callback(new Error('Authentication failed'));
                }
                this.eventListeners.handleEvent('socket/authenticate', data);
              },
              { deviceId, token },
              Client.methods.POST,
              'socket'
            );
          });
        } catch (error) {
          socket.disconnect();
          callback(error);
        }
        this.eventListeners.handleEvent('socket/connect');
      });

      socket.on('error', (error) => {
        logger.debug('Socket Error');
        this.eventListeners.handleEvent('socket/error', error);
      });

      socket.on('reconnect', (attempt) => {
        logger.debug('Socket Reconnect');
        this.eventListeners.handleEvent('socket/reconnect', attempt);
      });

      socket.on('reconnecting', (attempt) => {
        logger.debug('Socket Reconnecting');
        this.eventListeners.handleEvent('socket/reconnecting', attempt);
      });

      socket.on('reconnect_attempt', () => {
        logger.debug('Socket Reconnect Attempt');
        this.eventListeners.handleEvent('socket/reconnect_attempt');
      });

      socket.on('reconnect_error', (error) => {
        logger.debug('Socket Reconnect Error');
        this.eventListeners.handleEvent('socket/reconnect_error', error);
      });

      socket.on('reconnect_failed', (error) => {
        logger.debug('Socket Reconnect Failed');
        this.eventListeners.handleEvent('socket/reconnect_failed', error);
      });

      socket.on('disconnect', () => {
        logger.debug('Socket disconnected');
        socket.authenticated = false;
        this.eventListeners.handleEvent('socket/disconnect');
      });

      socket.on('_response', (message) => {
        const { requestId, responseData } = message;
        if (this.socketResponseHandlers[requestId]) {
          try {
            this.socketResponseHandlers[requestId](responseData);
          } catch (error) {
            logger.debug('Socket response callback failed', error);
          }
        }

        this.eventListeners.handleEvent('socket/_response', message);
      });

      socket.on('_event', (message) => {
        logger.debug('_event', message);
        const { event, data } = message;
        if (event !== null) {
          this.eventListeners.handleEvent(event, data);
        }
      });

      this.socket = socket;
    } else {
      callback(
        new Error("Cannot upgrade connection, SocketIO hasn't been provided")
      );
    }
  }

  /** *
   * This call back is used by the request method to return data to the underlying caller
   * @callback RequestCallback
   * @param {Error} error - Error returned by the API
   * @param {Object} data - Response data returned by the API
   * @param {String} status - Response status returned by the API
   */

  /** *
   * Method to run a request against the API either through REST or Socket
   * @param { String } namespace - the namespace you wish to access
   * @param { String } call - API endpoint you want to talk to
   * @param {RequestCallback} callback - Callback for when the request is finished
   * @param {Object} payload - Key/Value pair object for any data you want to pass to the API
   * @param {{building_id: *, company_id: *}} method - Defaults to Client.POST
   */
  request(
    namespace,
    call,
    callback = () => {},
    payload = {},
    method,
    transmitType = 'default',
    config = {
      baseUrl: null,
      headers: {
        'device-id': 'auto',
        'session-id': 'auto',
        'api-key': 'auto',
        'request-id': 'auto'
      }
    }
  ) {
    const requestId = [...Array(64)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join('');
    const pathParts = [];
    if (namespace) pathParts.push(namespace);
    if (call) pathParts.push(call);

    let path = pathParts.join('/');

    if (
      this?.socket?.connected &&
      (transmitType !== 'rest' || this?.socket?.authenticated)
    ) {
      logger.debug('Request, using socket');
      try {
        const packet = { requestData: payload };

        logger.debug('Request', path, packet, JSON.stringify(packet));
        if (callback) {
          logger.debug('Request Has Callback');

          packet.requestId = requestId;
          this.socketResponseHandlers[requestId] = (responseData) => {
            const { code, error } = responseData;

            if (path === 'logout' && responseData.data) {
              this.socket.disconnect();
              try {
                this.authCallbacks.handleLogout();
              } catch (e) {
                logger.debug('Failed to run handleLogout', e);
              }
            }
            callback(this.translateResponseError(error), responseData, code);
          };
        }
        this.socket.emit(`/${path}`, packet);
      } catch (error) {
        logger.debug('Socket emit failure', error);
      }
    } else {
      logger.debug('Request, using REST');
      const headers = {};
      try {
        this.authCallbacks.getSession((session) => {
          if (config.headers?.['session-id'] === 'auto') {
            if (session) {
              headers['session-id'] = session?.id;
              headers['refresh-token'] = session?.refresh_token;
              logger.debug(session);
            }
          }

          try {
            this.authCallbacks.getDevice((device) => {
              if (
                session?.id &&
                device?.id &&
                transmitType === 'default' &&
                this.socketIO &&
                !this.socket
              ) {
                this.upgradeConnection((error) => {
                  logger.log(error);
                  this.request(
                    namespace,
                    call,
                    callback,
                    payload,
                    method,
                    error ? 'rest' : 'socket'
                  );
                });
              } else {
                if (config.headers?.['device-id'] === 'auto') {
                  headers['device-id'] = device?.id;
                }
                if (config.headers?.['api-key'] === 'auto') {
                  headers['api-key'] = this?.config?.rest?.api_key;
                }
                if (config.headers?.['request-id'] === 'auto') {
                  headers['request-id'] = requestId;
                }

                Object.keys(config.headers)
                  .filter((headerName) => config.headers[headerName] !== 'auto')
                  .forEach((header) => {
                    headers[header] = config.headers[header];
                  });

                const baseURL = (() => {
                  if (config.baseUrl) {
                    return config.baseUrl;
                  }
                  if (
                    Object.prototype.hasOwnProperty.call(
                      localStorage,
                      'cureoscity_apigw_url'
                    )
                  ) {
                    return `${localStorage.getItem(
                      'cureoscity_apigw_url'
                    )}/api/mon`;
                  }

                  return this.config.rest.host;
                })();

                const instance = this.axios.create({
                  baseURL,
                  timeout: 30000,
                  headers
                });

                let command = instance.post;
                if (method === Client.methods.PUT) {
                  command = instance.put;
                } else if (method === Client.methods.DELETE) {
                  command = instance.delete;
                  payload = { data: payload };
                } else if (method === Client.methods.GET) {
                  command = instance.get;
                  if (payload && Object.keys(payload)) {
                    const queryArray = [];
                    for (const key in payload) {
                      const value = payload[key];
                      if (typeof value !== 'undefined') {
                        queryArray.push(
                          `${encodeURIComponent(key)}=${encodeURIComponent(
                            value
                          )}`
                        );
                      }
                    }

                    if (path.search(/\?/) === -1) path += '?';
                    path += queryArray.join('&');
                    payload = '';
                  }
                }

                const handleSessionInResponse = (response) => {
                  if (response) {
                    if (response?.session) {
                      this.authCallbacks.updateSession(
                        new Session(this, response?.session)
                      );
                    }
                  }
                };

                command(path, payload, { withCredentials: true })
                  .then((response) => {
                    const { status = null, data = null } = response;
                    handleSessionInResponse(data);
                    callback(null, data, status);
                  })
                  .catch((error) => {
                    logger.debug('Rest Error', error);

                    let data = null;
                    let status = 500;
                    let e = null;

                    if (error.response) {
                      data = error?.response?.data;
                      status = error?.response?.status;
                      e = data?.error || error?.response?.error || error;
                      handleSessionInResponse(data);
                    }

                    callback(
                      this.translateResponseError(e),
                      data,
                      String(status)
                    );
                  });
              }
            });
          } catch (error) {
            logger.debug('Request getDevice', error);
          }
        });
      } catch (error) {
        logger.debug('Request getSession', error);
      }
    }
  }

  translateResponseError(error) {
    const translateErrorObject = (obj) => {
      if (obj?.constructor?.name === 'APIError') {
        obj = { code: obj.code, fieldName: obj.fieldName };
      } else if (Object.prototype.toString.call(obj) === '[object Error]') {
        obj = { code: obj.message, fieldName: null };
      }

      const { code, fieldName } = obj;
      const message = this.localizeErrorCode(code) || code;

      return new APIError(fieldName, code, message);
    };

    if (error) {
      if (Array.isArray(error)) {
        return error.map(translateErrorObject);
      }
      return translateErrorObject(error);
    }
  }

  checkPermissionAgainstCache(permission, userId, companyId, buildingId) {
    const cache = this.permissionsCache;
    if (Object.hasOwnProperty.call(cache, '*')) permission = '*';
    if (!Object.hasOwnProperty.call(cache, permission)) return false;
    if (!Array.isArray(cache[permission])) return true;
    for (const index in cache[permission]) {
      const role = cache[permission][index];

      if (role.building_id === buildingId) {
        if (!role.companyId || companyId === role.company_id) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Parse a UTC string discarding timezone offsets
   * (so as if the UTC string represents local time rather than UTC)
   * The returned Date will contain the raw time from the UTC string
   * @param  {string} isoString='' - an ISO-compliant datetime string
   * @returns {Date}
   */
  parseISODateTimeAsLocalTime(isoString = '') {
    const dateTime = new Date(Date.parse(isoString));

    if (
      Object.prototype.toString.call(dateTime) === '[object Date]' &&
      !isNaN(dateTime.getTime())
    ) {
      return new Date(
        dateTime.getUTCFullYear(),
        dateTime.getUTCMonth(),
        dateTime.getUTCDate(),
        dateTime.getUTCHours(),
        dateTime.getUTCMinutes()
      );
    }

    return null;
  }

  /**
   * Parse a UTC string without intervention, so a UTC offset is applied
   * Resulting in the time correctly offset for the timezone's current rules
   * The returned Date will contain the raw time from the UTC string
   * @param  {string} isoString='' - an ISO-compliant datetime string
   * @returns {Date}
   */
  parseISODateTimeAsUTC(isoString = '') {
    const dateTime = new Date(Date.parse(isoString));

    if (
      Object.prototype.toString.call(dateTime) === '[object Date]' &&
      !isNaN(dateTime.getTime())
    ) {
      return dateTime;
    }

    return null;
  }
}

export {
  AuthCallbacks,
  Client,
  Device,
  Session,
  OnboardingSession,
  User,
  Profile,
  Company
};
