import React from 'react';

import PropTypes from 'prop-types';

import styles from './VisuallyHidden.module.scss';

const VisuallyHidden = ({ children }) => (
  <div className={styles.hidden}>{children}</div>
);

VisuallyHidden.propTypes = {
  children: PropTypes.node.isRequired
};

export default VisuallyHidden;
