/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useEffect, useState } from 'react';

import { formatDistanceToNow, format } from 'date-fns';
import logger from 'services/logger/logger';

import { apiInstance } from '../services';
import { useAuth } from './auth-context';

const APIService = apiInstance();
const NotificationsContext = createContext();

const NotificationsProvider = (props) => {
  const { loggedIn } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const {
    namespaces: { notifications: notificationsService }
  } = APIService;

  const processData = (data) =>
    data.map(({ id, created, type, entity_type, title, read, body }) => {
      let section = '';
      if (entity_type === 'ticket') {
        section = 'building-management/help-desk';
        if (type === 'ticket_assigned') section = `${section}/mine`;
        else if (type === 'ticket_closed') section = `${section}/closed`;
        else {
          section = `${section}/mine`;
        }
      } else if (entity_type === 'delivery') {
        section = 'building-management/deliveries';
        if (type === 'delivery_rejected') section = `${section}/rejected`;
        if (type === 'delivery_approved') section = `${section}/upcoming`;
      }

      return {
        id,
        status: formatDistanceToNow(created),
        time: format(created, 'hh:mmbb'),
        date: format(created, 'dd MMM yyyy'),
        title,
        body,
        read,
        section
      };
    });

  const noOfNewNotifications = notifications.filter((f) => !f.read).length;

  const markAllNotificationsAsRead = () => {
    notificationsService.read('all').then(() => {
      const items = notifications.map((item) => ({ ...item, read: true }));
      setNotifications(items);
    });
  };

  const markNotificationsAsRead = (id) => {
    const notification = notifications.find((item) => item.id === id);
    if (notification) {
      if (!notification.read) {
        notificationsService.read(id).then(() => {
          notification.read = true;
          setNotifications([...notifications]);
        });
      }
    }
  };

  const deleteNotification = (id) =>
    notificationsService.delete(id).then(() => {
      setNotifications([...notifications.filter((item) => item.id !== id)]);
    });

  const deleteAllNotifications = () =>
    notificationsService.delete('all').then(() => {
      setNotifications([]);
    });

  useEffect(() => {
    if (loggedIn) {
      notificationsService
        .mine()
        .then((retrievedNotifications) => {
          logger.debug(retrievedNotifications);
          setNotifications(processData(retrievedNotifications));
        })
        .catch((error) => {
          logger.log(error);
        });
    }
  }, [loggedIn]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        noOfNewNotifications,
        markAllNotificationsAsRead,
        markNotificationsAsRead,
        deleteNotification,
        deleteAllNotifications
      }}
      {...props}
    />
  );
};

const useNotifications = () => useContext(NotificationsContext);

export { NotificationsProvider, useNotifications };
