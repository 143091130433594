import logger from 'services/logger/logger';

import Client from '../../../Client';

export default class UsersMockNamespace {
  dataSet = [];

  termsAccepted = [
    {
      user_id: 1,
      building_id: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      user_id: 2,
      building_id: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      user_id: 3,
      building_id: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      user_id: 4,
      building_id: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      user_id: 6,
      building_id: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;

    const {
      framework: {
        mockNamespaces: {
          JobRolesMockNamespace: { dataSet: jobRoles },
          OccupiersMockNamespace: { dataSet: occupiers },
          FloorsMockNamespace: { dataSet: floors },
          UserRolesMockNamespace: { dataSet: roles }
        }
      }
    } = this;

    this.dataSet = [
      {
        id: 1,
        email: 'john@cureoscity.com',
        created: '2020-08-28T05:12:13.000Z',
        modified: '2020-08-28T05:12:13.000Z',
        last_accessed: '2020-09-27T11:11:56.000Z',
        enabled: false,
        archived: false,
        companies: [
          occupiers[0].company,
          occupiers[1].company,
          occupiers[2].company
        ],
        buildings: [
          occupiers[0].building,
          occupiers[1].building,
          occupiers[2].company
        ],
        job_roles: [jobRoles[0]],
        floors: [floors[0]],
        roles: [roles[0]],
        profile: {
          user_id: 1,
          name: 'John Smith',
          phone: '0123456789',
          date_of_birth: '1964-01-12',
          picture_uri: 'https://randomuser.me/api/portraits/men/1.jpg',
          created: '2020-08-28T05:12:13.000Z',
          modified: '2020-08-28T05:12:13.000Z'
        }
      },
      {
        id: 2,
        email: 'jane@cureoscity.com',
        created: '2020-08-28T11:53:56.000Z',
        modified: '2020-08-28T11:53:56.000Z',
        last_accessed: '2020-08-25T15:09:56.000Z',
        enabled: true,
        archived: false,
        companies: [occupiers[0].company],
        buildings: [occupiers[0].building],
        job_roles: [jobRoles[0]],
        floors: [floors[0]],
        roles: [roles[1]],
        profile: {
          user_id: 2,
          name: 'Jane Doe',
          phone: '1234567890',
          date_of_birth: '1992-12-24',
          picture_uri: 'https://randomuser.me/api/portraits/women/1.jpg',
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      },
      {
        id: 3,
        email: 'joe@cureoscity.com',
        created: '2020-08-28T11:53:56.000Z',
        modified: '2020-08-28T11:53:56.000Z',
        last_accessed: '2020-08-28T11:53:56.000Z',
        enabled: false,
        archived: true,
        companies: [occupiers[0].company],
        buildings: [occupiers[0].building],
        job_roles: [jobRoles[1]],
        floors: [floors[2]],
        roles: [roles[2]],
        profile: {
          user_id: 3,
          name: 'Joe Roberts',
          phone: '0001234567',
          date_of_birth: '1992-12-24',
          picture_uri: 'https://randomuser.me/api/portraits/men/2.jpg',
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      },
      {
        id: 4,
        email: 'july@cureoscity.com',
        created: '2020-08-28T11:53:56.000Z',
        modified: '2020-08-28T11:53:56.000Z',
        last_accessed: '2020-11-01T09:00:56.000Z',
        enabled: true,
        archived: false,
        companies: [occupiers[0].company],
        buildings: [occupiers[0].building],
        job_roles: [jobRoles[2]],
        floors: [floors[1]],
        roles: [roles[3]],
        profile: {
          user_id: 4,
          name: 'July Hammerson',
          phone: '0123456789',
          date_of_birth: '1981-12-21',
          picture_uri: 'https://randomuser.me/api/portraits/women/3.jpg',
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      },
      {
        id: 5,
        email: 'denise@cureoscity.com',
        created: '2020-08-28T11:53:56.000Z',
        modified: '2020-08-28T11:53:56.000Z',
        last_accessed: '2020-11-30T17:45:56.000Z',
        enabled: true,
        archived: false,
        companies: [occupiers[0].company],
        buildings: [occupiers[0].building],
        job_roles: [jobRoles[2]],
        floors: [floors[2]],
        roles: [roles[2]],
        profile: {
          user_id: 5,
          name: 'Denise Denderson',
          phone: '0123456789',
          date_of_birth: '1981-12-21',
          picture_uri: 'https://randomuser.me/api/portraits/women/4.jpg',
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      },
      {
        id: 6,
        email: 'aiden@cureoscity.com',
        created: '2020-08-28T11:53:56.000Z',
        modified: '2020-08-28T11:53:56.000Z',
        last_accessed: '2020-08-28T11:53:56.000Z',
        enabled: true,
        archived: true,
        companies: [occupiers[0].company],
        buildings: [occupiers[0].building],
        job_roles: [jobRoles[3]],
        floors: [floors[0]],
        roles: [roles[4]],
        profile: {
          user_id: 6,
          name: 'Aiden Harrison',
          phone: '0123456789',
          date_of_birth: '1981-12-21',
          picture_uri: 'https://randomuser.me/api/portraits/men/4.jpg',
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      },
      {
        id: 7,
        email: 'maaike@cureoscity.com',
        created: '2020-08-28T11:53:56.000Z',
        modified: '2020-08-28T11:53:56.000Z',
        last_accessed: '2020-08-28T11:53:56.000Z',
        enabled: false,
        archived: false,
        companies: [occupiers[0].company],
        buildings: [occupiers[0].building],
        job_roles: [jobRoles[3]],
        floors: [floors[0]],
        roles: [],
        profile: {
          user_id: 7,
          name: 'Maaike Smith',
          phone: '0123456789',
          date_of_birth: '1985-12-21',
          picture_uri: 'https://randomuser.me/api/portraits/women/8.jpg',
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      }
    ];
  }

  getEndpoints() {
    return {
      users: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          email: { field: 'email', expression: 'like' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'users/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'provide_id',
          'user_provide_email_or_password',
          'user_provide_name',
          'user_company_link_wrong',
          'user_valid_email',
          'user_valid_password',
          'user_valid_company_id',
          'user_duplicate_email',
          'user_email_company_invalid'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const {
          framework: {
            mockNamespaces: {
              BuildingsMockNamespace: { dataSet: buildings },
              JobRolesMockNamespace: { dataSet: jobRoles },
              CompaniesMockNamespace: { dataSet: companies },
              FloorsMockNamespace: { dataSet: floors },
              UserRolesMockNamespace: { dataSet: roles }
            }
          }
        } = this;

        const {
          role_id,
          building_id,
          company_id,
          floor_id,
          job_role_id,
          enabled
        } = payload;

        const user = {
          id: this.dataSet.length + 1,
          email: payload.email,
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          last_accessed: new Date().toISOString(),
          enabled,
          archived: false,
          buildings: [],
          companies: [],
          job_roles: [],
          floors: [],
          roles: [],
          profile: {
            user_id: this.dataSet.length + 1,
            phone: '',
            name: payload?.profile?.name,
            date_of_birth: null,
            picture_uri: 'https://i.imgur.com/t4riKtb.png',
            created: new Date().toISOString(),
            modified: new Date().toISOString()
          }
        };

        user.buildings = buildings.filter(
          (building) => building.id == building_id
        );

        if (company_id)
          user.companies = companies.filter(
            (company) => company.id == company_id
          );
        if (job_role_id)
          user.job_roles = jobRoles.filter((role) => role.id == job_role_id);
        if (floor_id)
          user.floors = floors.filter((floor) => floor.id == floor_id);
        if (role_id) user.roles = roles.filter((role) => role.id == role_id);

        this.dataSet.push(user);

        return { code: 200, data: { user } };
      },
      'users/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      },
      'users/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'users/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'users/enable': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            enabled: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'users/disable': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            enabled: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'users/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const {
            framework: {
              mockNamespaces: {
                JobRolesMockNamespace: { dataSet: jobRoles },
                CompaniesMockNamespace: { dataSet: companies },
                FloorsMockNamespace: { dataSet: floors },
                UserRolesMockNamespace: { dataSet: roles }
              }
            }
          } = this;

          const {
            profile,
            role_id,
            company_id,
            floor_id,
            job_role_id
          } = payload;
          if (profile) delete payload.profile;

          const user = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );

          if (profile) {
            for (const field in user.profile) {
              logger.debug(field, profile);
              if (
                field !== 'id' &&
                Object.prototype.hasOwnProperty.call(profile, field)
              ) {
                user.profile[field] = profile[field];
              }
            }
          }

          if (company_id) {
            user.companies = companies.filter(
              (company) => company.id == company_id
            );
          }

          if (job_role_id) {
            user.job_roles = jobRoles.filter((role) => role.id == job_role_id);
          }

          if (floor_id) {
            user.floors = floors.filter((floor) => floor.id == floor_id);
          }

          if (role_id) {
            user.roles = roles.filter((role) => role.id == role_id);
          }

          return { code: 200, data: { user } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
