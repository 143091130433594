import Client from '../../Client';

const defaultTermsAndConditions = `<div class="terms-and-conditions">
	<h1>Cureoscity Limited – Mobile Application End User Terms</h1>

	<h2>January 2021 Edition</h2>

	<h3>PLEASE READ THESE TERMS CAREFULLY</h3>
	<h4>1. Who we are and why are we asking you to agree to these terms</h4>
	<p>MITSUI FUDOSAN (U.K.) LTD, a company registered in England and Wales with registration number 11235180 (the “Owner”) have engaged Cureoscity Ltd, a company registered in England with registration number 11235180 (“Cureoscity”) to develop an application for use in relation to the building known as 5 Hanover Square (the “App”).  In these terms the Owner and Cureoscity are collectively referred to as “we”, “us” or “our”, except where expressly stated otherwise.</p>

	<p>Cureoscity require you to agree to these terms so it is able to give you access when you register to use the App. This includes where you register to use the App in order to access and navigate the building, to update content on the App, to access and use management portals relating to the App (where you have the necessary permissions) and/or to provide services to facilitate management and administration of the building.  The terms also allow you to access the services  made available through the App as well as any updates or supplements to the App.</p>

	<p><strong>If you want to get in touch, our contact details are:</strong></p>

	<table cellpadding="20" style="border: 1px solid #000; text-align: left; width: 100%">
		<tr>
			<th >Owner</th>
			<th>Cureoscity</th>
		</tr>
		<tr>
			<td>
				<p>6th Floor Cassini House<br />
				57-58 St. James's Street,<br />
				London,<br />
				SW1A 1LD</p>
			</td>
			<td>
				<p><strong>Email:</strong> support@cureoscity.com<br /></p>
				<p><strong>Address:</strong> 71-75 Shelton Street, Covent Garden, London, England, WC2H 9JQ</p>
			</td>
		</tr>
	</table>


	<h4>2. Installation of the App</h4>
	<p>When you click to accept these terms as part of the App registration process you give consent for the App to be installed on your device(s) for the purposes of accessing the functionality available through the App.</p>

	<h4>If you change your mind</h4>
	<p>You may change your mind and withdraw consent at any time by contacting support@cureoscity.com or by uninstalling the App.</p>

	<h4>3. Licence Terms</h4>
	<p>This section sets out how you can use the App. In return for your agreeing to comply with these terms you may:</p>
	<ul>
		<li>download or stream a copy of the App onto any  smartphone, tablet or any other smart handheld devices and view, use and display the App  on such devices.</li>
		<li>receive and use any free updates of the App.</li>
	</ul>

	<p>You must be 18 to accept these terms and buy the App.</p>

	<h4>3.1. Licence restrictions</h4>
	<p>You agree that you will:</p>
	<ul>
		<li>not rent, lease, sub-license, loan, provide, or otherwise make available, the App in any form, in whole or in part to any person without prior written consent from us;</li>
		<li>not copy the App, except as part of the normal use of the App or where it is necessary for the purpose of back-up or operational security;</li>
		<li>not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, nor permit the App or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use on devices as permitted in these terms;</li>
		<li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App nor attempt to do any such things;</li>
		<li>comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App.</li>
	</ul>
</div>`;

const defaultDeliveryTermsAndConditions = `<div class="delivery-terms-and-conditions">
	<h1>Cureoscity Limited – Delivery Terms</h1>

	<h2>January 2021 Edition</h2>

	<h3>PLEASE READ THESE TERMS CAREFULLY</h3>
	<h4>1. Who we are and why are we asking you to agree to these terms</h4>
	
	<ul>
		<li>Delivery Term 1</li>
		<li>Delivery Term 2</li>
		<li>Delivery Term 3</li>		
	</ul>
</div>`;

export default class BuildingsMockNamespace {
  dataSet = [
    {
      id: 1,
      client_id: 1,
      name: 'The Leadenhall Building',
      address1: '122 Leadenhall St',
      address2: 'Lime Street',
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'EC3V 4AB',
      dashboard_image_uri:
        'https://assets.riseportal.io/leadenhall/dashboard.jpg',
      logo_uri: ' https://assets.riseportal.io/leadenhall/logo.png',
      latitude: 51.5138,
      longitude: -0.0821,
      terms_and_conditions: {
        registration: {
          content: defaultTermsAndConditions,
          modified: '2021-01-31T09:00:00.000Z',
          accepted: false
        },
        delivery: {
          content: defaultDeliveryTermsAndConditions,
          modified: '2021-01-31T09:00:00.000Z'
        },
        privacy: {
          content: defaultDeliveryTermsAndConditions,
          modified: '2021-01-31T09:00:00.000Z'
        }
      },
      archived: false,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 2,
      client_id: 2,
      name: 'One Angel Court',
      address1: '1 Angel Court',
      address2: '',
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'EC2R 7HJ',
      dashboard_image_uri:
        'https://assets.riseportal.io/angelcourt/dashboard.jpg',
      logo_uri: 'https://assets.riseportal.io/angelcourt/logo.png',
      latitude: 51.53253,
      longitude: -0.10579,
      terms_and_conditions: {
        registration: {
          content: defaultTermsAndConditions,
          modified: '2021-01-31T09:00:00.000Z',
          accepted: false
        },
        delivery: {
          content: defaultDeliveryTermsAndConditions,
          modified: '2021-01-31T09:00:00.000Z'
        }
      },
      archived: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      client_id: 3,
      name: 'White City Place',
      address1: '201 Wood Ln',
      address2: "Shepherd's Bush",
      address3: '',
      city: 'London',
      county: 'London',
      postcode: 'W12 7FQ',
      latitude: 51.513436,
      longitude: -0.23231,
      dashboard_image_uri: 'https://assets.riseportal.io/wcp/dashboard.jpg',
      logo_uri: 'https://assets.riseportal.io/wcp/logo.png',
      terms_and_conditions: {
        registration: {
          content: defaultTermsAndConditions,
          modified: '2021-01-31T09:00:00.000Z',
          accepted: false
        },
        delivery: {
          content: defaultDeliveryTermsAndConditions,
          modified: '2021-01-31T09:00:00.000Z'
        }
      },
      archived: false,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  pushEntities = (building) => {
    const {
      framework: {
        mockNamespaces: {
          JobRolesMockNamespace: { dataSet: jobRoles },
          TravelOptionsMockNamespace: { dataSet: travelOptions },
          PrioritiesMockNamespace: { dataSet: priorities },
          DepartmentsMockNamespace: { dataSet: departments },
          FloorsMockNamespace: { dataSet: floors },
          LocationsMockNamespace: { dataSet: locations },
          BaysMockNamespace: { dataSet: bays },
          ContactTypesMockNamespace: { dataSet: contactTypes },
          VisitorTypesMockNamespace: { dataSet: visitorTypes },
          UsersMockNamespace: { dataSet: users },
          ClientsMockNamespace: { dataSet: clients }
        }
      }
    } = this;
    const { id, client_id } = building;

    building.job_roles = jobRoles.filter(
      ({ building_id }) => building_id === id
    );
    building.travel_options = travelOptions.filter(() => true);
    building.priorities = priorities.filter(
      ({ building_id }) => building_id === id
    );
    building.departments = departments.filter(
      ({ building_id }) => building_id === id
    );
    building.floors = floors.filter(({ building_id }) => building_id === id);
    building.locations = locations.filter(
      ({ building_id }) => building_id === id
    );
    building.bays = bays.filter(({ building_id }) => building_id === id);
    building.contact_types = contactTypes.filter(
      ({ building_id }) => building_id === id
    );
    building.visitor_types = visitorTypes.filter(
      ({ building_id }) => building_id === id
    );

    building.users = users
      .filter(
        ({ buildings }) =>
          buildings.filter((building) => building.id === id).length
      )
      .map(({ id, profile }) => ({ id, profile }));

    building.client = clients.find(({ id }) => id === parseInt(client_id, 10));

    return building;
  };

  getEndpoints() {
    return {
      buildings: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records',
          'provide_building_id'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          address1: { field: 'address1', expression: 'like' },
          address2: { field: 'address2', expression: 'like' },
          address3: { field: 'address3', expression: 'like' },
          city: { field: 'city', expression: 'like' },
          county: { field: 'county', expression: 'like' },
          postcode: { field: 'postcode', expression: 'like' }
        };

        const buildings = [...this.dataSet];
        buildings.forEach(this.pushEntities);

        return this.framework.defaultSearchMock(
          payload,
          buildings,
          filters,
          permittedErrors,
          error
        );
      },
      'buildings/accept_terms': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_building_id',
          'valid_building_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        if (!payload.id)
          return this.framework.createErrorResponse('provide_building_id');

        const id = parseInt(payload.id);

        try {
          const {
            framework: {
              mockNamespaces: {
                UsersMockNamespace: { termsAccepted }
              }
            }
          } = this;

          termsAccepted.push({
            user_id: 1,
            building_id: payload?.building_id,
            created: new Date().toISOString(),
            modified: new Date().toISOString()
          });

          return { code: 200, data: true };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'buildings/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'update_no_permission',
          'provide_building_id',
          'email_template_valid_name',
          'email_template_valid_subject',
          'email_template_exists'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const ret = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );
          const { delivery_terms_and_conditions } = payload;

          if (delivery_terms_and_conditions) {
            ret.terms_and_conditions.delivery.content = delivery_terms_and_conditions;
            ret.terms_and_conditions.delivery.modified = new Date().toISOString();
          }

          return { code: 200, data: { building: this.pushEntities(ret) } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'buildings/mine': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = ['not_logged_in', 'no_permission'];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const {
          framework: {
            mockNamespaces: {
              OccupiersMockNamespace: { dataSet: occupiers },
              UsersMockNamespace: { termsAccepted }
            }
          }
        } = this;

        const { id } = payload;

        const buildings = [...this.dataSet];
        buildings.forEach(this.pushEntities);

        if (id) {
          const building = {
            ...buildings.find((building) => building.id == id)
          };
          if (!Object.keys(building).length) {
            return this.framework.createErrorResponse('no_permission');
          } else {
            const { id, logo_uri, dashboard_image_uri } = building;

            termsAccepted.forEach(({ building_id, user_id }) => {
              if (user_id === 1 && building_id === building.id) {
                building.terms_and_conditions.registration.accepted = true;
              }
            });

            building.occupiers = occupiers;
            building.theme = {
              logo: logo_uri,
              dashboard_image: dashboard_image_uri,
              logo_background: id === 3 ? 'var(--primary)' : '#fff',
              logo_size: 'full-width'
            };
            return {
              code: 200,
              data: { building }
            };
          }
        }

        return {
          code: 200,
          data: { buildings: [...buildings] }
        };
      },
      'buildings/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const archived = false;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          return { code: 200, data: { archived } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },

      'buildings/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const archived = true;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          return { code: 200, data: { archived } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },

      'buildings/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        try {
          const entity = {
            id: this.dataSet.length + 1,
            client_id: parseInt(payload.client_id, 10),
            name: payload.name,
            address1: payload.address1,
            address2: payload.address2,
            address3: payload.address3,
            city: payload.city,
            county: payload.county,
            postcode: payload.postcode,
            logo_uri: payload.logo_uri,
            dashboard_image_uri: payload.dashboard_image_uri,
            created: new Date().toISOString(),
            modified: new Date().toISOString()
          };

          this.dataSet.push(this.pushEntities(entity));

          return {
            code: 200,
            data: { building: entity }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },

      dashboard: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = ['not_logged_in', 'no_permission'];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const ret = {
          client: {
            id: 1,
            name: 'Client',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            county: '',
            postcode: '',
            logo: '',
            created: '2020-09-13T11:47:47.000Z',
            modified: '2020-09-13T11:47:47.000Z'
          },
          building: this.dataSet[0],
          notifications: [],
          menus: [
            {
              id: 'building_management',
              label: 'Building management',
              permission: 'buildings/manage',
              order: 1,
              children: [
                {
                  id: 'permit_management',
                  label: 'Permits',
                  permission: 'permits/manage',
                  order: 1
                },
                {
                  id: 'delivery_management',
                  label: 'Deliveries',
                  permission: 'deliveries/manage',
                  order: 2
                },
                {
                  id: 'visitor_management',
                  label: 'Visitor management',
                  permission: 'visitors/manage',
                  order: 3
                },
                {
                  id: 'helpdesk_management',
                  label: 'Helpdesk',
                  permission: 'helpdesk/manage',
                  order: 4
                }
              ]
            },
            {
              id: 'mobile_management',
              label: 'Mobile App',
              permission: 'mobile/manage',
              order: 2,
              children: [
                {
                  id: 'event_management',
                  label: 'Events',
                  permission: 'events/manage',
                  order: 1
                },
                {
                  id: 'class_management',
                  label: 'Classes',
                  permission: 'classes/manage',
                  order: 2
                },
                {
                  id: 'room_management',
                  label: 'Rooms',
                  permission: 'rooms/manage',
                  order: 3
                },
                {
                  id: 'news_management',
                  label: 'News',
                  permission: 'news/manage',
                  order: 4
                },
                {
                  id: 'retailer_management',
                  label: 'Retailers',
                  permission: 'retailers/manage',
                  order: 5
                },
                {
                  id: 'offer_management',
                  label: 'Offers',
                  permission: 'offers/manage',
                  order: 6
                },
                {
                  id: 'neighbour_management',
                  label: 'Neighbours',
                  permission: 'neighbours/manage',
                  order: 7
                },
                {
                  id: 'careers_management',
                  label: 'Careers',
                  permission: 'careers/manage',
                  order: 8
                },
                {
                  id: 'building_info_management',
                  label: 'Building info',
                  permission: 'buildings/manage_info',
                  order: 9
                },
                {
                  id: 'wellbeing_management',
                  label: 'Wellbeing',
                  permission: 'wellbeing/manage',
                  order: 10
                },
                {
                  id: 'sustainability_management',
                  label: 'Sustainability',
                  permission: 'sustainability/manage',
                  order: 11
                },
                {
                  id: 'survey_management',
                  label: 'Survey and Polls',
                  permission: 'surveys/manage',
                  order: 12
                }
              ]
            },
            {
              id: 'user_management',
              label: 'Users',
              permission: 'users/manage',
              order: 3
            },
            {
              id: 'access_control',
              label: 'Access control',
              permission: 'access_control/manage',
              order: 4
            },
            {
              id: 'notification_management',
              label: 'Notifications',
              permission: 'notifications/manage',
              order: 5
            }
          ],
          widgets: {
            start: '2020-10-09T00:00:14.328Z',
            end: '2020-10-16T00:00:14.328Z',
            activity: {
              current: 0,
              previous: 0
            },
            new_user_count: {
              current: 4,
              previous: 4
            },
            average_session_duration: {
              current: 0,
              previous: 0
            },
            login_count: {
              current: 21,
              previous: 21
            }
          }
        };

        return { code: 200, data: ret };
      }
    };
  }
}
