/* eslint-disable react/forbid-prop-types */
import React from 'react';

import Input from 'components/forms/Input';
import PropTypes from 'prop-types';

const PhoneInput = ({
  errors,
  register,
  dirtyFields,
  placeholder,
  label,
  defaultValue,
  readOnly,
  name,
  isRequired,
  ...rest
}) => {
  let registerOptions = {};

  if (isRequired) {
    registerOptions = {
      required: 'This field is required',
      pattern: {
        /*
          Existing validation is too strict and doesn't work for valid numbers
          EG: +447700900502, 07700 900502, 0118 496 0402 or any international numbers
          It also validates numbers that are invalid
          EG: 01275111

          Please can we leave this commented out until we have a better solution.
         */
        /* value: /^\b(0{2})*(44|440|0){1}(1|2|7){1}([0-9]{7,10})$\b/gm, */
        message: 'Please enter a valid phone number'
      }
    };
  }

  return (
    <Input
      autoComplete="tel"
      isDirty={dirtyFields[name]}
      name={name}
      {...{
        label,
        placeholder,
        defaultValue,
        readOnly
      }}
      error={errors[name]}
      inputRef={register(registerOptions)}
      type="text"
      {...rest}
    />
  );
};

PhoneInput.propTypes = {
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  dirtyFields: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  isRequired: PropTypes.bool
};

PhoneInput.defaultProps = {
  placeholder: null,
  readOnly: false,
  label: 'Phone',
  defaultValue: '',
  name: '',
  isRequired: false
};
export default PhoneInput;
