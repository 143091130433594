import React, { useEffect } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import logger from 'services/logger/logger';

import { useBuilding } from '../../context/building-context';
import Input from '../forms/Input/Input';
import SelectInput from '../forms/SelectInput/SelectInput';

import styles from './BuildingSelect.module.scss';

const BuildingSelect = ({
  label,
  register,
  errors,
  name,
  selectClassName,
  inputClassName,
  fullWidth,
  showLabel,
  isRequired,
  onChange,
  ...props
}) => {
  const { buildingData, buildings } = useBuilding();

  useEffect(() => {
    if (onChange && buildings.length === 1) {
      try {
        onChange(buildingData?.id);
      } catch (e) {
        logger.debug(e);
      }
    }
  }, [buildingData]);

  if (buildings.length) {
    return (
      <>
        <Input
          fullWidth
          inputRef={register()}
          value={buildingData?.name}
          defaultValue={buildingData?.name}
          label={label}
          showLabel={showLabel}
          readOnly
          className={inputClassName}
          name={`${name}-name`}
        />
        <Input
          label=""
          name={name}
          type="hidden"
          inputRef={register()}
          value={buildingData?.id}
          defaultValue={buildingData?.id}
        />
      </>
    );
  }
  if (buildings.length > 1) {
    return (
      <SelectInput
        name={name}
        className={classnames(styles.select, selectClassName)}
        errors={errors}
        register={register}
        values={buildings}
        labelKey="name"
        valueKey="id"
        label={label}
        showLabel={showLabel}
        fullWidth={fullWidth}
        onChange={onChange}
        {...props}
      />
    );
  }

  return <></>;
};

BuildingSelect.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array,
  name: PropTypes.string,
  selectClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  showLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func
};

BuildingSelect.defaultProps = {
  label: '',
  register: null,
  errors: null,
  name: '',
  selectClassName: null,
  inputClassName: null,
  fullWidth: false,
  showLabel: true,
  isRequired: true,
  onChange: null
};

export default BuildingSelect;
