import Client from '../Client';
import Comment from '../Entities/Comment';
import GenericNamespaceHandler from '../GenericNamespaceHandler';

export default class CommentsNamespace extends GenericNamespaceHandler {
  namespace = 'comments';

  searchRequiresBuildingId = false;

  responseEntity = Comment;

  responseKey = 'comment';

  createCall = 'create';

  requiredFields = [];

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    super(Client);
  }

  create(type = 'ticket', subType = '', entityId, comment) {
    return super.create({
      type,
      subtype: subType,
      entity_id: entityId,
      comment
    });
  }

  getForEntity = (type = 'ticket', subType = '', entityId = null) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        '',
        (error, response) => {
          if (!Array.isArray(response?.data?.records) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const comments = response?.data?.records.map(
              (entity) => new Comment(this.client, entity)
            );
            resolve(comments);
          }
        },
        {
          type,
          subtype: subType,
          entity_id: entityId,
          orderBy: 'created',
          orderDirection: 'DESC'
        },
        Client.methods.GET
      );
    });
}
