export default class BuildingManual {
  constructor(Client, { id, building_id, title, url, created, modified }) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.id = id || 0;
    this.building_id = building_id || 0;

    this.title = title || '';
    this.url = url || '';

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
