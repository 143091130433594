import Client from '../Client';

export default class FileUpload {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIFileUpload - containing the server side object
   */
  constructor(Client, { id, uri, secure, data, created, modified }) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.secure = secure || false;
    this.uri = uri || '';
    this.data = data || {};

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
