/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import DatePickerComponent from './DatePickerComponent';

import styles from './DatePickerComponent.module.scss';

const emptyDefaultValue = '--/--/----';

const DateInput = ({
  errors,
  register,
  dirtyFields,
  placeholder,
  defaultProps,
  readOnly,
  name,
  label,
  hideLabel,
  className,
  defaultValue,
  iconOnPrefix,
  highlightDates,
  reoccurView,
  isClearable,
  mustBeInTheFuture,
  minDate,
  maxDate,
  inline,
  onSelect,
  editingMode,
  appearance,
  inputRef,
  control,
  fullWidth,
  ...rest
}) => {
  const icon = { iconName: 'Calendar' };
  const containerClasses = classnames(styles.dateInput, {
    [styles['full-width']]: fullWidth
  });

  return (
    <div className={containerClasses}>
      {label && !hideLabel && <label className={styles.label}>{label}</label>}
      <Controller
        as={<DatePickerComponent />}
        name={name}
        control={control}
        defaultValue={defaultValue}
        mustBeInTheFuture={mustBeInTheFuture}
        minDate={minDate}
        maxDate={maxDate}
        register={register}
        prefixIcon={iconOnPrefix && icon}
        suffixIcon={!iconOnPrefix && icon}
        className={className}
        isClearable={isClearable}
        readOnly={readOnly}
        editingMode={editingMode}
        reoccurView={reoccurView}
        onSelect={onSelect}
        highlightDates={highlightDates}
        hideLabel={hideLabel}
        inline={inline}
        {...rest}
      />
    </div>
  );
};

DateInput.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  dirtyFields: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  defaultProps: PropTypes.shape({}),
  highlightDates: PropTypes.shape([]),
  reoccurView: PropTypes.bool,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  hideLabel: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  inline: PropTypes.bool,
  onSelect: PropTypes.func,
  editingMode: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  readOnly: PropTypes.bool,
  iconOnPrefix: PropTypes.bool,
  mustBeInTheFuture: PropTypes.bool,
  appearance: PropTypes.oneOf(['default', 'search', 'form']),
  inputRef: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  fullWidth: PropTypes.bool
};

DateInput.defaultProps = {
  placeholder: null,
  defaultProps: null,
  label: 'Date',
  name: 'date',
  hideLabel: false,
  minDate: '',
  maxDate: '',
  inline: false,
  onSelect: () => {},
  editingMode: false,
  className: '',
  highlightDates: [],
  reoccurView: false,
  isClearable: false,
  defaultValue: emptyDefaultValue,
  readOnly: false,
  iconOnPrefix: false,
  mustBeInTheFuture: false,
  appearance: 'default',
  inputRef: null,
  fullWidth: false
};

export { DateInput as default, emptyDefaultValue };
