/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconPeople = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="m12.6667 8.70831c1.3142 0 2.3671-1.06083 2.3671-2.375 0-1.31416-1.0529-2.375-2.3671-2.375s-2.375 1.06084-2.375 2.375c0 1.31417 1.0608 2.375 2.375 2.375zm-6.33335 0c1.31417 0 2.36709-1.06083 2.36709-2.375 0-1.31416-1.05292-2.375-2.36709-2.375-1.31416 0-2.375 1.06084-2.375 2.375 0 1.31417 1.06084 2.375 2.375 2.375zm0 1.58329c-1.84458 0-5.541663.9263-5.541663 2.7709v1.9791h11.083313v-1.9791c0-1.8446-3.69706-2.7709-5.54165-2.7709zm6.33335 0c-.2296 0-.4908.0159-.7679.0396.9183.665 1.5596 1.5596 1.5596 2.7313v1.9791h4.75v-1.9791c0-1.8446-3.6971-2.7709-5.5417-2.7709z"
    />
  </svg>
);

IconPeople.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconPeople.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconPeople);
