import Client from '../../../../Client';
import Categories from './Categories.json';
import Events from './Events-mock.json';

export default class MobileEventsMockNamespace {
  dataSet = Events.data.records;

  categoriesDataSet = Categories.data.records;

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (event) => {
    const { category_id, section_id } = event;

    event.category = this.categoriesDataSet.find(
      ({ id }) => id === parseInt(category_id, 10)
    );

    return event;
  };

  getEndpoints() {
    return {
      'mobile/events': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = ['not_logged_in', 'no_permission'];
        const filters = {
          id: {
            field: 'id',
            expression: 'eq'
          },
          name: {
            field: 'name',
            expression: 'like'
          }
        };
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'mobile/events/categories': () => {
        const dashboard = {
          records: this.categoriesDataSet
        };
        return {
          code: 200,
          data: { ...dashboard }
        };
      },
      'mobile/events/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      },
      'mobile/events/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        const {
          building_id,
          name,
          start_date_time,
          end_date_time,
          price,
          description,
          location,
          attendee_limit,
          category_id,
          terms_and_conditions,
          host,
          online,
          url
        } = payload;

        const entity = {
          id: this.dataSet.length + 1,
          building_id,
          name,
          start_date_time,
          end_date_time,
          price,
          images: [
            {
              id: '573fe8a9-8b8d-4daa-b70a-0c51477e9225',
              entity_type: 'event',
              user_id: 1,
              uri: 'http://placeimg.com/640/480/architecture',
              filesize: 0,
              type: '',
              verified: false
            }
          ],
          description,
          location,
          attendee_limit,
          category_id,
          terms_and_conditions,
          host,
          online,
          url,
        };
        const translatedEntity = this.translateEntities(entity);
        this.dataSet.push(translatedEntity);
        return {
          code: 200,
          data: { event: entity }
        };
      },
      'mobile/events/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'update_no_permission',
          'provide_event_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const event = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            this.translateEntities(payload)
          );

          return {
            code: 200,
            data: { event }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'mobile/events/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const archived = false;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          return {
            code: 200,
            data: { archived }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'mobile/events/cancel': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const cancelled = true;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            cancelled
          });
          return {
            code: 200,
            data: { cancelled }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'mobile/events/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const archived = true;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          return {
            code: 200,
            data: { archived }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
