import React, { memo } from 'react';

import PropTypes from 'prop-types';

const UnderConstruction = ({ size }) => (
  <svg
    width={size}
    fill="none"
    viewBox="0 0 90 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.136 95.526H6.372a1.028 1.028 0 110-2.055h13.764a1.028 1.028 0 010 2.055zM86.68 95.526H72.918a1.028 1.028 0 010-2.055h13.764a1.028 1.028 0 110 2.055z"
      fill="#2F80ED"
    />
    <path
      d="M88.952 20.934h-11.42v-4.697a8.151 8.151 0 10-2.056 0v4.708h-61.22v-4.708a8.151 8.151 0 10-2.055 0v4.708H1.028A1.028 1.028 0 000 21.972v42.74a1.028 1.028 0 001.028 1.027h11.07v28.78a1.028 1.028 0 102.056 0V65.74h64.54v28.78a1.028 1.028 0 102.055 0V65.74h8.223A1.028 1.028 0 0090 64.711V21.962a1.027 1.027 0 00-1.048-1.028zM70.45 8.148a6.096 6.096 0 116.095 6.095 6.105 6.105 0 01-6.136-6.095h.041zm-63.275 0a6.095 6.095 0 116.095 6.095 6.104 6.104 0 01-6.137-6.095h.042zm80.79 55.504H2.055V22.99h85.869l.04 40.662z"
      fill="#2F80ED"
    />
    <path
      d="M15.449 26.3a1.028 1.028 0 00-1.336.555l-8.85 21.482a1.029 1.029 0 001.678 1.126c.096-.096.172-.21.223-.334l8.85-21.483a1.028 1.028 0 00-.565-1.346zM33.365 26.834a1.028 1.028 0 00-1.336.576L19.5 58.934a1.028 1.028 0 00.576 1.336c.123.043.25.067.38.072a1.028 1.028 0 00.956-.647l12.53-31.525a1.028 1.028 0 00-.576-1.336zM51.597 26.834a1.028 1.028 0 00-1.336.586L38.286 57.968a1.028 1.028 0 00.535 1.326 1.027 1.027 0 00.958-.105c.17-.115.302-.28.378-.47L52.183 28.17a1.028 1.028 0 00-.586-1.336zM70.303 26.31a1.027 1.027 0 00-1.356.535l-13.6 31.627a1.03 1.03 0 101.892.812l13.599-31.627a1.027 1.027 0 00-.535-1.347zM73.926 60.26a1.029 1.029 0 00.968-.089c.168-.111.3-.27.378-.455l8.778-20.404a1.028 1.028 0 10-1.88-.812l-8.79 20.404a1.029 1.029 0 00.546 1.356z"
      fill="#2F80ED"
    />
  </svg>
);

UnderConstruction.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

UnderConstruction.defaultProps = {
  size: '96px'
};

export default memo(UnderConstruction);
