import Client from '../Client';
import Company from '../Entities/Company';
import GenericNamespaceHandler from '../GenericNamespaceHandler';

export default class CompaniesNamespace extends GenericNamespaceHandler {
  namespace = 'companies';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = false;

  responseEntity = Company;

  responseKey = 'company';

  requiredFields = [];

  /** *
   * @param {Client} Client - instance of client
   */

  constructor(Client) {
    super(Client);
  }

  mine = () =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'mine',
        (error, response) => {
          if (!Array.isArray(response?.data?.companies) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const companies = response?.data?.companies.map(
              (entity) => new Company(this.client, entity)
            );
            resolve(companies);
          }
        },
        null,
        Client.methods.GET
      );
    });

  create = (
    name,
    optional = {
      address1: undefined,
      address2: undefined,
      address3: undefined,
      city: undefined,
      county: undefined,
      postcode: undefined,
      picture_uri: undefined,
      ignore_email_validation: undefined,
      domains: undefined
    }
  ) => {
    if (!name) throw new Error('provide_name');

    return super.create({ name, ...optional });
  };

  update = (
    companyId,
    optional = {
      name: undefined,
      domains: undefined,
      address1: undefined,
      address2: undefined,
      address3: undefined,
      city: undefined,
      county: undefined,
      postcode: undefined,
      ignore_email_validation: undefined,
      picture_uri: undefined
    }
  ) => {
    if (!companyId) throw new Error('provide_company_id');

    return super.update(companyId, optional);
  };

  dashboard = (companyId) =>
    new Promise((resolve, reject) => {
      if (!companyId) throw new Error('provide_company_id');

      this.client.request(
        '',
        'dashboard',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { company_id: companyId },
        Client.methods.GET
      );
    });
}
