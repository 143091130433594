/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconSolidCircleCross = ({ size, colour, fill }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="7.5" fill={`var(--${fill})`} />
    <path
      d="M10 5L5 10M5 5L10 10"
      stroke={`var(--${colour})`}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconSolidCircleCross.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
  colour: PropTypes.string
};

IconSolidCircleCross.defaultProps = {
  size: '100%',
  fill: 'white',
  colour: 'black'
};

export default memo(IconSolidCircleCross);
