/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconArrowLeft = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 28 28"
    fill="none"
    {...rest}
  >
    <path
      d="m21.4871 14.2008h-14.00004m0 0 7.00004 7m-7.00004-7 7.00004-6.99999"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconArrowLeft.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconArrowLeft.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconArrowLeft);
