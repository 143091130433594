import logger from 'services/logger/logger';

export default class BuildingEntityMockNamespace {
  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  search(
    dataSet,
    payload,
    headers,
    method,
    transmitType,
    isLoggedIn,
    userPermissions,
    error
  ) {
    const permittedErrors = ['not_logged_in', 'no_permission', 'no_records'];
    const filters = {
      id: { field: 'id', expression: 'eq' },
      name: { field: 'name', expression: 'like' },
      building_id: { field: 'building_id', expression: 'eq' }
    };
    if (!isLoggedIn) return this.framework.createErrorResponse('not_logged_in');
    return this.framework.defaultSearchMock(
      payload,
      dataSet,
      filters,
      permittedErrors,
      error
    );
  }

  create(
    dataSet,
    entityName,
    payload,
    headers,
    method,
    transmitType,
    isLoggedIn,
    userPermissions,
    error
  ) {
    const permittedErrors = [
      'not_logged_in',
      'system_error',
      'no_permission',
      'provide_building_id',
      'duplicate_name'
    ];

    if (error !== false && permittedErrors.includes(error)) {
      return this.framework.createErrorResponse(error);
    }
    if (!isLoggedIn) return this.framework.createErrorResponse('not_logged_in');

    const entity = {
      id: dataSet.length + 1,
      name: payload.name,
      building_id: payload.building_id,
      seq: payload.seq,
      enabled: payload.enabled,
      created: new Date().toISOString(),
      modified: new Date().toISOString()
    };
    dataSet.push(entity);
    return { code: 200, data: { [entityName]: entity } };
  }

  update(
    dataSet,
    entityName,
    payload,
    headers,
    method,
    transmitType,
    isLoggedIn,
    userPermissions,
    error
  ) {
    const permittedErrors = [
      'not_logged_in',
      'system_error',
      'no_permission',
      'provide_id',
      'provide_building_id',
      'duplicate_name'
    ];

    if (error !== false && permittedErrors.includes(error)) {
      return this.framework.createErrorResponse(error);
    }
    if (!isLoggedIn) return this.framework.createErrorResponse('not_logged_in');

    try {
      const ret = this.framework.updateRecord(
        dataSet,
        payload.id,
        'id',
        payload
      );
      return { code: 200, data: { [entityName]: ret } };
    } catch (e) {
      return this.framework.createErrorResponse('no_permission');
    }
  }

  updateMultiple(
    dataSet,
    entityName,
    payload,
    headers,
    method,
    transmitType,
    isLoggedIn,
    userPermissions,
    error
  ) {
    const permittedErrors = [
      'not_logged_in',
      'system_error',
      'no_permission',
      'provide_id',
      'provide_building_id',
      'duplicate_name'
    ];

    if (error !== false && permittedErrors.includes(error)) {
      return this.framework.createErrorResponse(error);
    }
    if (!isLoggedIn) return this.framework.createErrorResponse('not_logged_in');
    const ret = [];

    payload.forEach((record) => {
      try {
        const entity = this.framework.updateRecord(
          dataSet,
          record.id,
          'id',
          record
        );
        ret.push(entity);
      } catch (e) {
        logger.debug(e);
      }
    });

    if (ret.length) {
      return { code: 200, data: { records: ret } };
    }
    return this.framework.createErrorResponse('no_permission');
  }

  delete(
    dataSet,
    payload,
    headers,
    method,
    transmitType,
    isLoggedIn,
    userPermissions,
    error
  ) {
    const permittedErrors = [
      'not_logged_in',
      'no_permission',
      'provide_id',
      'no_records',
      'delete_no_record'
    ];
    return this.framework.defaultDeleteMock(
      dataSet,
      Array.isArray(payload) ? payload : payload.id,
      'id',
      permittedErrors,
      error,
      'delete_no_record',
      isLoggedIn
    );
  }
}
