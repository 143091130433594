import Client from '../../Client';

export default class OccupierHours {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIDepartment - containing the server side object
   */
  constructor(
    Client,
    {
      occupier_id,
      mon_open,
      mon_close,
      tue_open,
      tue_close,
      wed_open,
      wed_close,
      thur_open,
      thur_close,
      fri_open,
      fri_close,
      sat_open,
      sat_close,
      sun_open,
      sun_close,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.occupier_id = occupier_id || 0;
    this.mon_open = mon_open || '';
    this.mon_close = mon_close || '';
    this.tue_open = tue_open || '';
    this.tue_close = tue_close || '';
    this.wed_open = wed_open || '';
    this.wed_close = wed_close || '';
    this.thur_open = thur_open || '';
    this.thur_close = thur_close || '';
    this.fri_open = fri_open || '';
    this.fri_close = fri_close || '';
    this.sat_open = sat_open || '';
    this.sat_close = sat_close || '';
    this.sun_open = sun_open || '';
    this.sun_close = sun_close || '';

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
