/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { mergeDeepLeft } from 'ramda';
import config from 'services/api/config';
import logger from 'services/logger/logger';
import { getApiUrl } from 'utils/api';
import { refreshAuthCookies } from 'utils/cookies';

const getHeaders = (headers) =>
  mergeDeepLeft(headers, {
    'Content-Type': 'application/json; charset=utf-8',
    'api-key': config.rest.api_key,
    'device-id':
      JSON.parse(window.localStorage.getItem('cureoscity_device'))?.id || '',
    'session-id':
      JSON.parse(window.localStorage.getItem('cureoscity_session'))?.id || '',
    'refresh-token':
      JSON.parse(window.localStorage.getItem('cureoscity_session'))
        ?.refresh_token || ''
  });

export const req = async (
  {
    baseURL = getApiUrl(false),
    data = null,
    decompress,
    responseType,
    headers,
    method,
    params = {},
    url,
    withCredentials = process.env.USE_MOCKS !== 'true'
  },
  throwError
) => {
  try {
    const response = await axios({
      baseURL,
      data,
      decompress,
      responseType,
      headers: getHeaders(headers),
      method,
      params,
      url,
      withCredentials
    });

    // If the server responds with a 500 error then there is no response.
    if(!response) {
      throw new Error('A server error has occurred.');
    }
    
    // Don't add question mark to response, this breaks alot of things
    return response.data || response;
  } catch (err) {
    logger.error(err);
    if (throwError) throw err;

    return err;
  }
};

// Merged interceptors. Response can only have one 'error' handler.
axios.interceptors.response.use(null, (error) => {
  
  if (error.config && error.response && error.response.status === 401) {
    return refreshAuthCookies().then(() =>
      // replay the original request
      axios.request(error.config)
    );
  }
  
  if (
    error.config &&
    error.response &&
    error.response.status === 400 &&
    error.response.data.error.code === 'refresh_token_failed'
  ) {
    window.location.href = '/logout';
  }

  return Promise.reject(error); // If we don't do this then the function returns undefined and we won't trigger proper error handling later.
});