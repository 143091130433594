/* eslint-disable react/forbid-prop-types */
import React from 'react';

import Input from 'components/forms/Input';
import PropTypes from 'prop-types';

const NameInput = ({
  alwaysAddDefaultValue,
  appearance,
  autoComplete,
  className,
  customSubtext,
  dataTestId,
  defaultValue,
  dirtyFields,
  disabled,
  errors,
  forForm,
  fullWidth,
  hideIcon,
  hideLabel,
  hideValidation,
  hideValidationIcon,
  isRequired,
  label,
  max,
  min,
  name,
  onChange,
  onKeyDown,
  onKeyUp,
  placeholder,
  prefixIcon,
  readOnly,
  register,
  registerOptions,
  row,
  step,
  subText,
  suffixIcon,
  type,
  validated,
  validationRequired,
  value
}) => {
  const regex =
    type === 'company'
      ? /^[a-zA-Z0-9]([a-zA-Z0-9]|[-' @\\.#&!])*$/
      : /^\s*([A-Za-z]{1,}([.,] |[-']| ))+[A-Za-z]+\.?\s*$/i;
  const registerOpts = { ...registerOptions };
  if (isRequired || validationRequired) {
    if (!Object.prototype.hasOwnProperty.call(registerOptions, 'pattern')) {
      registerOpts.pattern = {
        value: regex,
        message: `Please enter a valid ${
          type === 'company' ? 'company' : ''
        } name`
      };
    }
    if (
      isRequired &&
      !Object.prototype.hasOwnProperty.call(registerOptions, 'required')
    ) {
      registerOpts.required = 'This field is required';
    }
  }
  return (
    <Input
      autoComplete={type === 'company' ? 'organisation' : 'name'}
      isDirty={dirtyFields[name]}
      suffixIcon={!hideIcon ? { iconName: 'User' } : null}
      error={errors[name]}
      inputRef={register(registerOpts)}
      type="text"
      fullWidth={fullWidth}
      hideLabel={hideLabel}
      {...{
        alwaysAddDefaultValue,
        appearance,
        autoComplete,
        className,
        customSubtext,
        dataTestId,
        defaultValue,
        disabled,
        forForm,
        hideValidation,
        hideValidationIcon,
        label:
          label && `${label}${isRequired && !label?.endsWith('*') ? '*' : ''}`,
        max,
        min,
        name,
        onChange,
        onKeyDown,
        onKeyUp,
        placeholder,
        prefixIcon,
        readOnly,
        row,
        step,
        subText,
        suffixIcon,
        validated,
        value
      }}
    />
  );
};

NameInput.propTypes = {
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  dirtyFields: PropTypes.object.isRequired,
  registerOptions: PropTypes.shape({}),
  hideIcon: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  validationRequired: PropTypes.bool,
  ...Input.propTypes
};

NameInput.defaultProps = {
  hideIcon: false,
  isRequired: false,
  registerOptions: {},
  label: 'Name',
  name: 'name',
  type: 'person',
  validationRequired: false
};
export default NameInput;
