/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconHelp = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 112 112"
    fill="none"
    {...rest}
  >
    <g fill="currentColor">
      <path d="m58.0211 73.9335c-1.258 0-2.2742 1.0162-2.2742 2.2742 0 1.2581 1.0162 2.2742 2.2742 2.2742 1.2581 0 2.2742-1.0161 2.2742-2.2742 0-1.258-1.0161-2.2742-2.2742-2.2742z" />
      <path d="m58.6138 26.1996c-7.0282 0-11.6492 3.9072-11.6492 3.9072l1.8024 2.6613s4.2218-3.2782 9.9315-3.2782c6.7984 0 11.4073 4.4516 11.4073 10.0766 0 11.3347-13.9839 12.3509-13.9839 25.1614v3.4355h3.3629v-3.4355c0-11.1775 14.1411-12.1089 14.1411-25.0888-.0121-7.6452-6.0242-13.4395-15.0121-13.4395z" />
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="m59.6201 3.03935c-7.7196-.02013-15.3348 1.78347-22.2253 5.2639l-.0057.00286c-8.2606 4.12849-15.2086 10.47529-20.0658 18.32949s-7.43175 16.9056-7.43532 26.1404l-.00001.0033c-.02012 7.7196 1.78343 15.3348 5.26393 22.2253.1803.357.2106.7712.0841 1.1507l-10.50408 31.5117 31.51218-10.5036c.3795-.1265.7937-.0962 1.1507.0841 6.8905 3.4805 14.5057 5.2845 22.2253 5.2635h.0033c9.2348-.003 18.2862-2.5777 26.1404-7.4349 7.8542-4.8573 14.201-11.8052 18.3292-20.0658l.003-.0057c3.481-6.8905 5.284-14.5057 5.264-22.2253v-.0039-2.9723c-.682-12.1801-5.828-23.6825-14.4544-32.3093-8.6268-8.62682-20.1293-13.77238-32.3094-14.45444h-2.9722zm.0058-2.9999937c-8.1905-.021043-16.2702 1.8925137-23.5812 5.5848537-8.7574 4.37759-16.1233 11.10649-21.2729 19.43349-5.14987 8.3275-7.87972 17.9242-7.88382 27.7154-.02056 7.9819 1.79619 15.8586 5.30602 23.0212l-11.256816 33.7707c-.179667.539-.039383 1.133.362366 1.535.40175.402.996.542 1.535.362l33.77055-11.257c7.1631 3.51 15.0403 5.327 23.0228 5.306 9.7907-.004 19.3868-2.734 27.7138-7.8834 8.3271-5.1497 15.0563-12.5159 19.4343-21.2735 3.692-7.3115 5.605-15.3917 5.584-23.5826v-3.01c0-.0276-.001-.0551-.002-.0826-.714-12.9327-6.172-25.1478-15.3311-34.3064-9.1587-9.15872-21.3737-14.617382-34.3064-15.3308661-.0275-.0015182-.055-.0022776-.0826-.0022776z"
      />
    </g>
  </svg>
);

IconHelp.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconHelp.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconHelp);
