import React from 'react';

import Button from 'components/Button';
import { IconTick, IconWarningTriangle } from 'components/Icons';
import TimeStamp from 'components/TimeStamp';
import { useSystemNotifications } from 'context/systemNotifications-context';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import styles from './SystemNotifications.module.scss';

const Notification = ({ message, id, type, timeStamp }) => {
  const { dismissSystemNotification } = useSystemNotifications();

  const renderIcon = () => {
    switch (type) {
      case 'error':
        return <IconWarningTriangle colour="error" />;
      case 'warning':
        return <IconWarningTriangle colour="warning" />;
      default:
        return (
          <div className={styles.icon}>
            <IconTick colour="neutral-7" />
          </div>
        );
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8, x: 300 }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      exit={{ opacity: 0, scale: 0.8, x: 300 }}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 40
      }}
      layout
      className={styles.notification}
    >
      <div className={styles.icons}>{renderIcon()}</div>
      <p className={styles.message} id={`${id}-description`}>
        {message}
        {timeStamp && <TimeStamp time={timeStamp} indicator />}
      </p>
      <Button
        className={styles.close}
        onClick={() => dismissSystemNotification(id)}
        appearance="icon-only"
        iconName="Cross"
        iconColour="neutral-1"
        width="30px"
        height="30px"
        describedBy={`${id}-description`}
        text="Dismiss notification"
        dataTestId="system_notification"
      />
    </motion.div>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'warning', 'error']),
  timeStamp: PropTypes.number.isRequired
};

Notification.defaultProps = {
  type: 'success'
};

export default Notification;
