import React, { memo } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Paragraph.module.scss';

const Paragraph = ({
  children,
  constrain,
  appearance,
  id,
  className,
  centered,
  bold
}) => {
  const classes = classnames(className, appearance, {
    [styles.constrain]: constrain,
    [styles.centered]: centered,
    [styles.bold]: bold
  });

  return (
    <p className={classes} id={id}>
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  constrain: PropTypes.bool,
  id: PropTypes.string,
  appearance: PropTypes.oneOf([
    'body-1',
    'body-2',
    'body-3',
    'body-4',
    'body-5'
  ]),
  centered: PropTypes.bool,
  bold: PropTypes.bool
};

Paragraph.defaultProps = {
  className: null,
  constrain: false,
  appearance: 'body-1',
  id: null,
  centered: false,
  bold: false
};

export default memo(Paragraph);
