/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconMaximize = ({ size, colour, ...rest }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width={size} height={size} rx="1.5" stroke={colour} />
    <path
      d="M4 3.4C3.66863 3.4 3.4 3.66863 3.4 4V9.4C3.4 9.73137 3.66863 10 4 10C4.33137 10 4.6 9.73137 4.6 9.4V4.6H9.4C9.73137 4.6 10 4.33137 10 4C10 3.66863 9.73137 3.4 9.4 3.4H4ZM12 12.6C12.3314 12.6 12.6 12.3314 12.6 12L12.6 6.6C12.6 6.26863 12.3314 6 12 6C11.6686 6 11.4 6.26863 11.4 6.6V11.4H6.6C6.26863 11.4 6 11.6686 6 12C6 12.3314 6.26863 12.6 6.6 12.6L12 12.6ZM3.57574 4.42426L11.5757 12.4243L12.4243 11.5757L4.42426 3.57574L3.57574 4.42426Z"
      fill="white"
    />
  </svg>
);

IconMaximize.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconMaximize.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconMaximize);
