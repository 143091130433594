import Client from '../../Client';

export default class OccupierDomain {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIDepartment - containing the server side object
   */
  constructor(Client, { occupier_id, type, data, created, modified }) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.occupier_id = occupier_id || 0;
    this.type = type || 'domain';
    this.data = data || '';

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
