/* eslint-disable import/no-cycle */
import { Client } from '../../Client';
import Occupier from '../../Entities/Buildings/Occupier';
import OccupierIndustry from '../../Entities/Buildings/Occupiers/OccupierIndustry';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class OccupiersNamespace extends GenericNamespaceHandler {
  namespace = 'occupiers';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = Occupier;

  responseKey = 'occupier';

  requiredFields = [
    {
      param: 'building_id',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'provide_building_id',
      validation: { type: 'int' }
    },
    {
      param: 'name',
      requiredForCreate: false,
      requiredForUpdate: false,
      error: 'invalid_name',
      validation: { type: 'string', min: 5, max: 255 }
    },
    {
      param: 'phone',
      requiredForCreate: false,
      requiredForUpdate: false,
      error: 'invalid_phone',
      validation: { type: 'string', min: 5, max: 255 }
    },
    {
      param: 'email',
      requiredForCreate: false,
      requiredForUpdate: false,
      error: 'invalid_email',
      validation: { type: 'email' }
    }
  ];

  searchByBuildingId(
    buildingId,
    criteria = {},
    page = 1,
    orderBy,
    orderDirection
  ) {
    const crit = criteria;
    if (buildingId) crit.building_id = buildingId;
    return this.search(crit, page, orderBy, orderDirection);
  }

  /**
   * Search occupiers in building using pagination endpoint
   * @param {*} query
   * @returns
   */
  searchByBuildingIdFeed = async (query = {}) => {
    const {
      buildingId,
      filter = {},
      skip = 0,
      take = 10,
      orderBy = {}
    } = query;
    const select = {
      archived: true,
      floors: {
        select: {
          floor: {
            select: {
              name: true
            }
          }
        }
      },
      company: {
        select: { name: true }
      },
      email: true,
      enabled: true,
      id: true,
      name: true,
      phone: true
    };
    const where = {
      ...filter,
      building_id: buildingId
    };

    const response = await this.queryFeed({
      skip,
      take,
      select,
      ...(Object.keys(where).length && { where }),
      ...(Object.keys(orderBy).length && { orderBy })
    });

    const records = response.data.records.map((entity) => {
      const floors = entity.floors.map((f) => f?.floor || f);
      return new Occupier(this.client, { ...entity, floors });
    });
    response.data.records = records;

    return response.data;
  };

  create(
    buildingId = null,
    companyId = null,
    floorsIds = [],
    isRetailer = false,
    isNeighbour = false,
    optional = {
      name: undefined,
      phone: undefined,
      email: undefined,
      contact_job_role: undefined,
      description: undefined,
      images: undefined,
      industry_id: undefined,
      public_phone: undefined,
      public_website: undefined,
      hours: undefined,
      contacts: undefined,
      domains: undefined
    }
  ) {
    const data = {
      building_id: buildingId,
      company_id: companyId,
      floor_ids: floorsIds,
      enabled: true,
      neighbour: isNeighbour,
      retailer: isRetailer,
      ...optional
    };
    return super.create(data);
  }

  update(
    id = null,
    optional = {
      retailer: undefined,
      occupier: undefined,
      floor_ids: undefined,
      name: undefined,
      phone: undefined,
      email: undefined,
      contact_job_role: undefined,
      description: undefined,
      images: undefined,
      images_for_delete: undefined,
      industry_id: undefined,
      public_phone: undefined,
      public_website: undefined,
      hours: undefined,
      contacts: undefined,
      domains: undefined
    }
  ) {
    return super.update(id, optional);
  }

  searchIndustriesByBuildingId(buildingId) {
    return new Promise((resolve) => {
      this.client.request(
        this.namespace,
        'industries',
        (error, { data }) => {
          const { records } = data;
          let { page, pages } = data;

          let results = [];
          if (!page) page = 1;
          if (!pages) pages = 1;

          if (Array.isArray(records))
            results = records.map(
              (entity) => new OccupierIndustry(this.client, entity)
            );
          resolve({ results, page, pages });
        },
        { building_id: buildingId },
        Client.methods.GET
      );
    });
  }

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(id, unarchive) {
    return new Promise((resolve) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        unarchive ? 'unarchive' : 'archive',
        (error, { data }) => resolve(Boolean(data)),
        { id },
        Client.methods.PUT
      );
    });
  }

  enable(id) {
    return this.toggleEnabled(id);
  }

  disable(id) {
    return this.toggleEnabled(id, true);
  }

  toggleEnabled(id, disabled) {
    return new Promise((resolve) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        disabled ? 'disable' : 'enable',
        (error, { data }) => resolve(Boolean(data)),
        { id },
        Client.methods.PUT
      );
    });
  }
}
