/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const GridView = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62903 12.3386H1.5V17.4677H6.62903V12.3386ZM0 10.8386V18.9677H8.12903V10.8386H0Z"
      fill={colour}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62903 1.5H1.5V6.62903H6.62903V1.5ZM0 0V8.12903H8.12903V0H0Z"
      fill={colour}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4677 12.3386H12.3386V17.4677H17.4677V12.3386ZM10.8386 10.8386V18.9677H18.9677V10.8386H10.8386Z"
      fill={colour}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4677 1.5H12.3386V6.62903H17.4677V1.5ZM10.8386 0V8.12903H18.9677V0H10.8386Z"
      fill={colour}
    />
  </svg>
);

GridView.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

GridView.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(GridView);
