import Client from '../Client';

export default class DeletedOccurrence {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIFileUpload - containing the server side object
   */
  constructor(Client, { id, parent_id, start }) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || '';
    this.parent_id = parent_id || '';
    this.start = parseISODateTimeAsUTC(start);
  }
}
