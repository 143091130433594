import Client from '../../Client';
import Event from '../../Entities/Mobile/Events/Event';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class EventsNamespace extends GenericNamespaceHandler {
  namespace = 'mobile/events';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  cancelCall = 'cancel';

  archiveCall = 'archive';

  unarchiveCall = 'unarchive';

  searchRequiresBuildingId = true;

  responseEntity = Event;

  responseKey = 'event';

  cacheSearchResults = false;

  requiredFields = [];

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    super(Client);
  }

  create(
    building_id,
    name,
    start_date_time,
    end_date_time,
    price,
    images,
    description,
    location = '',
    online,
    optional = {
      category_id: undefined,
      terms_and_conditions: undefined,
      host: undefined,
      attendee_limit: undefined,
      url: undefined
    }
  ) {
    const data = {
      building_id,
      name,
      start_date_time,
      end_date_time,
      price,
      images,
      description,
      location,
      online,
      ...optional,
      attendee_limit: optional?.attendee_limit
        ? Number.parseInt(optional?.attendee_limit, 10)
        : undefined
    };

    return super.create(data);
  }

  update(
    id,
    optional = {
      attendee_limit: undefined,
      category_id: undefined,
      date: undefined,
      description: undefined,
      end_date_time: undefined,
      end_time: undefined,
      host: undefined,
      images: undefined,
      images_for_delete: undefined,
      location: undefined,
      managed_externally: undefined,
      name: undefined,
      online: undefined,
      price: undefined,
      start_date_time: undefined,
      start_time: undefined,
      terms_and_conditions: undefined,
      url: undefined,
    }
  ) {
    const data = {
      attendee_limit:
        optional?.attendee_limit && optional?.attendee_limit !== '0'
          ? Number.parseInt(optional?.attendee_limit, 10)
          : null,
      category_id: optional?.category_id,
      date: optional?.date,
      description: optional?.description,
      end_date_time: optional?.end_date_time,
      end_time: optional?.end_time,
      host: optional?.host,
      images: optional?.images,
      images_for_delete: optional?.images_for_delete,
      location: optional?.location,
      managed_externally: optional?.managed_externally,
      name: optional?.name,
      online: optional?.online,
      price: optional?.price,
      start_date_time: optional?.start_date_time,
      start_time: optional?.start_time,
      terms_and_conditions: optional?.terms_and_conditions,
      url: optional?.url,
    };

    return super.update(id, data);
  }

  categories = (buildingId) =>
    new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');
      this.client.request(
        this.namespace,
        'categories',
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response?.data);
          }
        },
        { building_id: buildingId },
        Client.methods.GET
      );
    });

  cancel(eventId) {
    return new Promise((resolve, reject) => {
      if (!eventId) throw new Error('provide_event_id');
      this.client.request(
        this.namespace,
        this.cancelCall,
        (error, { cancelled }) => {
          if (error) reject(error);
          else resolve(cancelled);
        },
        { id: eventId },
        Client.methods.PUT
      );
    });
  }

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(eventId, unarchive) {
    return new Promise((resolve, reject) => {
      if (!eventId) throw new Error('provide_event_id');
      this.client.request(
        this.namespace,
        unarchive ? this.unarchiveCall : this.archiveCall,
        (error, { archived }) => {
          if (error) reject(error);
          else resolve(archived);
        },
        { id: eventId },
        Client.methods.PUT
      );
    });
  }
}
