/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const IconCircle = ({ size, colour, ...rest }) => (
  <motion.svg
    layout
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    {...rest}
  >
    <motion.circle layout r="4" cx="10" cy="10" fill="currentColor" />
  </motion.svg>
);

IconCircle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconCircle.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconCircle);
