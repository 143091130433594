import Client from '../../Client';
import Poll from '../../Entities/Mobile/Polls/Poll';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class PollsNamespace extends GenericNamespaceHandler {
  namespace = 'mobile/polls';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  archiveCall = 'archive';

  unarchiveCall = 'unarchive';

  searchRequiresBuildingId = true;

  responseEntity = Poll;

  responseKey = 'poll';

  cacheSearchResults = false;

  requiredFields = [];

  create(
    title,
    building_id,
    topic_id,
    expires_date_time,
    user_id,
    answers,
    publish_date_time,
    optional = {
      archived: false
    }
  ) {
    const data = {
      title,
      building_id,
      topic_id,
      expires_date_time,
      user_id,
      answers,
      publish_date_time,
      ...optional
    };
    return super.create(data);
  }

  update(
    id,
    optional = {
      title: undefined,
      building_id: undefined,
      topic_id: undefined,
      expires_date_time: undefined,
      user_id: undefined,
      answers: undefined,
      archived: undefined
    }
  ) {
    return super.update(id, optional);
  }

  topics = (buildingId) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'topics',
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response?.data);
          }
        },
        { building_id: buildingId },
        Client.methods.GET
      );
    });

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(pollId, unarchive) {
    return new Promise((resolve, reject) => {
      if (!pollId) throw new Error('provide_article_id');
      this.client.request(
        this.namespace,
        unarchive ? this.unarchiveCall : this.archiveCall,
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(new this.responseEntity(this.client, response?.data.poll));
          }
        },
        { id: pollId },
        Client.methods.PUT
      );
    });
  }
}
