/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconMobile = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 11 19"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="m11 1.58333c0-.873997-.704-1.58333-1.57143-1.58333h-7.85714c-.86743 0-1.57143.709333-1.57143 1.58333v15.83337c0 .874.704 1.5833 1.57143 1.5833h7.85714c.86743 0 1.57143-.7093 1.57143-1.5833zm-6.67857 0h2.35714c.21686 0 .39286.17734.39286.39584s-.176.39583-.39286.39583h-2.35714c-.21686 0-.39286-.17733-.39286-.39583s.176-.39584.39286-.39584zm1.17857 15.83337c-.4345 0-.78571-.3547-.78571-.7917s.35121-.7917.78571-.7917c.43371 0 .78493.3547.78493.7917s-.35122.7917-.78493.7917zm3.92857-2.375h-7.85714v-11.10237h7.85714z"
    />
  </svg>
);

IconMobile.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconMobile.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconMobile);
