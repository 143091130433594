import Client from '../../Client';
import Offer from '../../Entities/Mobile/Offers/Offer';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class OffersNamespace extends GenericNamespaceHandler {
  namespace = 'mobile/offers';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  archiveCall = 'archive';

  unarchiveCall = 'unarchive';

  searchRequiresBuildingId = true;

  responseEntity = Offer;

  responseKey = 'offer';

  cacheSearchResults = false;

  requiredFields = [];

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    super(Client);
  }

  create(
    building_id,
    category_id,
    title,
    start_date_time,
    end_date_time,
    retailer,
    description,
    online,
    optional = {
      images: [],
      terms_and_conditions: undefined,
      host: undefined,
      promo_code: undefined,
      nearest_location: undefined,
      featured: undefined,
      has_qr_code: undefined,
      publish_date_time: undefined
    }
  ) {
    const data = {
      building_id,
      category_id: parseInt(category_id, 10),
      title,
      start_date_time,
      end_date_time,
      retailer,
      description,
      online,
      ...optional
    };
    return super.create(data);
  }

  update(
    id,
    optional = {
      category_id: undefined,
      description: undefined,
      end_date_time: undefined,
      featured: undefined,
      host: undefined,
      images: undefined,
      images_for_delete: undefined,
      nearest_location: undefined,
      promo_code: undefined,
      retailer: undefined,
      start_date_time: undefined,
      terms_and_conditions: undefined,
      title: undefined
    }
  ) {
    return super.update(id, optional);
  }

  categories = (buildingId) =>
    new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');
      this.client.request(
        this.namespace,
        'categories',
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response?.data);
          }
        },
        { building_id: buildingId },
        Client.methods.GET
      );
    });

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(offerId, unarchive) {
    return new Promise((resolve, reject) => {
      if (!offerId) throw new Error('provide_offer_id');
      this.client.request(
        this.namespace,
        unarchive ? this.unarchiveCall : this.archiveCall,
        (error, { archived }) => {
          if (error) reject(error);
          else resolve(archived);
        },
        { id: offerId },
        Client.methods.PUT
      );
    });
  }
}
