/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import Select from '../Select/Select';

const SelectInput = ({
  className,
  dataTestId,
  defaultText,
  disableDefaultOption,
  errors,
  forForm,
  fullWidth,
  hideDisabledDefault,
  isNotRequired,
  label,
  labelKey,
  name,
  onChange,
  readOnly,
  register,
  selectedValue,
  showLabel,
  valueKey,
  values
}) => (
  <Select
    error={
      Object.prototype.hasOwnProperty.call(errors, name) ? errors[name] : null
    }
    {...{
      className,
      dataTestId,
      defaultText,
      disableDefaultOption,
      forForm,
      fullWidth,
      hideDisabledDefault,
      isRequired: !isNotRequired,
      label,
      labelKey,
      name,
      onChange,
      readOnly,
      selectedValue,
      showLabel,
      valueKey,
      values
    }}
    inputRef={
      register &&
      register(
        !isNotRequired
          ? {
              validate: (value) => {
                const valueParsedAsNumber = parseInt(value, 10);

                if (Number.isNaN(valueParsedAsNumber) && !selectedValue) {
                  return false;
                }

                return (
                  parseInt(value, 10) !== -9999 || 'This field is required'
                );
              }
            }
          : null
      )
    }
  />
);

SelectInput.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  defaultText: PropTypes.string.isRequired,
  disableDefaultOption: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  forForm: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideDisabledDefault: PropTypes.bool,
  isNotRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelKey: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  regex: PropTypes.object,
  register: PropTypes.func,
  selectedValue: PropTypes.any,
  showLabel: PropTypes.bool,
  valueKey: PropTypes.string,
  values: PropTypes.array.isRequired
};

SelectInput.defaultProps = {
  className: undefined,
  dataTestId: null,
  disableDefaultOption: false,
  forForm: false,
  fullWidth: false,
  hideDisabledDefault: false,
  isNotRequired: false,
  labelKey: '',
  readOnly: false,
  selectedValue: null,
  showLabel: false,
  valueKey: '',
  onChange: () => {},
  regex: {},
  register: () => {}
};

export default SelectInput;
