/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconBus = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 81 81"
    fill="none"
    {...rest}
  >
    <path
      d="m56.6 31.8c-.1 0-.1 0-.2 0v-4.3c0-.2 0-.4-.1-.6v-.1c0-2.3-7.1-4.1-15.9-4.1s-15.9 1.9-15.9 4.2v.1c0 .2-.1.4-.1.6v4.3c-.1 0-.1 0-.2 0-.9 0-1.6 2.2-1.6 4.8s.7 4.8 1.6 4.8h.1v9.7c0 .7.3 1.4.8 1.9s1.2.8 1.9.8h.5v.1 1.6c0 .5.2.9.5 1.3.3.3.8.5 1.3.5h1.7c.5 0 .9-.2 1.3-.5.3-.3.5-.8.5-1.3v-1.6s0 0 0-.1h14.9v.1 1.6c0 .5.2.9.5 1.3.3.3.8.5 1.3.5h1.7c.5 0 .9-.2 1.3-.5.3-.3.5-.8.5-1.3v-1.6s0 0 0-.1h.5c.4 0 .7-.1 1.1-.2.3-.1.6-.3.9-.6s.5-.6.6-.9.2-.7.2-1.1v-9.7h.2c.9 0 1.6-2.2 1.6-4.8s-.6-4.8-1.5-4.8zm-22.2-7h12.2c.3 0 .5.1.7.3s.3.4.3.7-.1.5-.3.7-.4.3-.7.3h-12.2c-.3 0-.5-.1-.7-.3s-.3-.4-.3-.7.1-.5.3-.7.4-.3.7-.3zm-4 26.3c-.4 0-.8-.1-1.1-.3s-.6-.5-.7-.9-.2-.7-.1-1.1.3-.7.5-1 .6-.5 1-.5c.4-.1.8 0 1.1.1.4.1.7.4.9.7s.3.7.3 1.1c0 .5-.2 1-.6 1.4s-.8.5-1.3.5zm20.2 0c-.4 0-.8-.1-1.1-.3s-.6-.5-.7-.9-.2-.7-.1-1.1.3-.7.5-1 .6-.5 1-.5.8 0 1.1.1c.4.1.7.4.9.7s.3.7.3 1.1c0 .3-.1.5-.1.7-.1.2-.2.5-.4.6s-.4.3-.6.4c-.3.1-.5.2-.8.2zm2.9-8.4c0 .5-.2 1.1-.6 1.5s-.9.6-1.5.6h-22c-.5 0-1.1-.2-1.5-.6s-.6-.9-.6-1.5v-12.1c0-.5.2-1.1.6-1.5s.9-.6 1.5-.6h22c.5 0 1.1.2 1.5.6s.6.9.6 1.5z"
      fill="currentColor"
    />
  </svg>
);

IconBus.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconBus.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconBus);
