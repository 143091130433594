/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import SelectInput from 'components/forms/SelectInput/SelectInput';
import PropTypes from 'prop-types';

import Input from '../forms/Input/Input';

const TimeInput = ({
  label,
  register,
  errors,
  name,
  className,
  fullWidth,
  availableTimes,
  showLabel,
  isRequired,
  onChange,
  defaultValue,
  value,
  min,
  max,
  ...props
}) => {
  if (availableTimes.length > 0) {
    return (
      <SelectInput
        name={name}
        isRequired={!!isRequired}
        showLabel
        fullWidth
        {...{ label, errors, register }}
        {...props}
        defaultValue={defaultValue}
        values={availableTimes}
      />
    );
  }

  return (
    <Input
      type="time"
      inputRef={
        typeof register === 'function'
          ? register(isRequired ? { required: 'This field is required' } : null)
          : register
      }
      {...{
        errors,
        onChange,
        name,
        label,
        fullWidth,
        className,
        showLabel,
        defaultValue,
        value,
        min,
        max
      }}
      {...props}
    />
  );
};

TimeInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.shape([]),
  fullWidth: PropTypes.bool,
  isRequired: PropTypes.bool,
  availableTimes: PropTypes.shape([]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  register: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  showLabel: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.string,
  max: PropTypes.string
};

TimeInput.defaultProps = {
  className: null,
  defaultValue: null,
  errors: null,
  fullWidth: false,
  isRequired: true,
  availableTimes: [],
  label: '',
  name: '',
  onChange: null,
  register: null,
  showLabel: true,
  value: null,
  min: '00:00',
  max: '23:59'
};

export default TimeInput;
