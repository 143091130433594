/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconPencil = ({ size }) => (
  <svg
    width={size}
    height="35px"
    viewBox="-4 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.26608 10.3418L0 11L0.659083 7.73438L3.26608 10.3418ZM1.30717 7.08583L3.91417 9.69329L11 2.60746L8.39254 0L1.30717 7.08583Z"
      fill="black"
    />
  </svg>
);

IconPencil.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

IconPencil.defaultProps = {
  size: '100%'
};

export default memo(IconPencil);
