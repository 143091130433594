import Floor from '../Buildings/Floor';
import Comment from '../Comment';
import Company from '../Company';
import User from '../User';
import Bay from './Bay';
import DeletedOccurrence from './DeletedOccurrence';

export default class Delivery {
  constructor(
    Client,
    {
      id,
      building_id,
      parent_id,
      type,
      user_id,
      bay_id,
      bay,
      description,
      start,
      end,
      company_id,
      company,
      delivery_for,
      creator_name,
      vehicle_reg_no,
      vehicle_reg_unknown,
      vehicle,
      additional_details,
      haulier_name,
      floor_id,
      floor,
      checked_in,
      checked_in_date,
      checked_in_user_id,
      checked_in_user,
      checked_out,
      checked_out_date,
      checked_out_user_id,
      checked_out_user,
      approved,
      approved_date,
      approved_user_id,
      approved_user,
      rejected,
      rejected_date,
      rejected_user_id,
      rejected_user,
      comments,
      archived,
      created,
      modified,
      recurrences,
      recurrence_frequency,
      recurrence_end_type,
      recurrence_end_after_elapsed_occurrences,
      recurrence_end_date,
      deleted_occurrences
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC, parseISODateTimeAsLocalTime } = Client;

    this.id = id || 0;
    this.parent_id = parent_id || 0;
    this.type = type || 'unscheduled';

    this.user_id = user_id || 0;

    this.building_id = building_id || 0;

    this.bay_id = bay_id || 0;
    this.bay = bay ? new Bay(Client, bay) : null;

    this.description = description || '';
    this.start = parseISODateTimeAsLocalTime(start);
    this.end = parseISODateTimeAsLocalTime(end);

    this.company_id = company_id || null;
    this.company = company ? new Company(Client, company) : null;

    this.delivery_for = delivery_for || '';
    this.creator_name = creator_name || '';

    this.vehicle_reg_no = vehicle_reg_no || '';
    this.vehicle_reg_unknown = Boolean(vehicle_reg_unknown);
    this.vehicle = vehicle || null;

    this.additional_details = additional_details || '';
    this.haulier_name = haulier_name;

    this.floor_id = floor_id || 0;
    this.floor = floor ? new Floor(Client, floor) : null;

    this.checked_in = checked_in || false;
    this.checked_in_date = parseISODateTimeAsUTC(checked_in_date);
    this.checked_in_user_id = checked_in_user_id || null;
    this.checked_in_user =
      checked_in_user && typeof checked_in_user === 'object'
        ? new User(Client, checked_in_user)
        : null;

    this.checked_out = checked_out || false;
    this.checked_out_date = parseISODateTimeAsUTC(checked_out_date);
    this.checked_out_user_id = checked_out_user_id || null;
    this.checked_out_user =
      checked_out_user && typeof checked_out_user === 'object'
        ? new User(Client, checked_out_user)
        : null;

    this.approved = Boolean(approved);
    this.approved_date = parseISODateTimeAsUTC(approved_date);
    this.approved_user_id = approved_user_id || null;
    this.approved_user =
      approved_user && typeof approved_user === 'object'
        ? new User(Client, approved_user)
        : null;

    this.rejected = Boolean(rejected);
    this.rejected_date = parseISODateTimeAsUTC(rejected_date);
    this.rejected_user_id = rejected_user_id || null;
    this.rejected_user =
      rejected_user && typeof rejected_user === 'object'
        ? new User(Client, rejected_user)
        : null;

    this.comments = Array.isArray(comments)
      ? comments.map((comment) => new Comment(Client, comment))
      : [];

    this.archived = Boolean(archived);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);

    this.recurrences = Array.isArray(recurrences)
      ? recurrences.map((recurrence) => new Delivery(Client, recurrence))
      : [];
    this.deleted_occurrences = Array.isArray(deleted_occurrences)
      ? deleted_occurrences.map(
          (deleted_occurrence) =>
            new DeletedOccurrence(Client, deleted_occurrence)
        )
      : [];

    this.recurrence_frequency = recurrence_frequency || '';
    this.recurrence_end_type = recurrence_end_type || '';
    this.recurrence_end_after_elapsed_occurrences =
      recurrence_end_after_elapsed_occurrences || 0;
    this.recurrence_end_date = parseISODateTimeAsUTC(recurrence_end_date);
  }

  getStatusLabel() {
    const { archived, rejected, approved, checked_out } = this;

    let status = 'previous';
    if (archived) status = 'archived';
    else if (rejected) status = 'rejected';
    else if (this.isToday() && approved && !checked_out) status = 'today';
    else if (this.isFuture() && approved) status = 'upcoming';
    else if (!this.isPast() && !approved) status = 'pending';

    return status;
  }

  processEntities({
    bay = false,
    company = false,
    floor = false,
    approved_user = false,
    rejected_user = false
  } = {}) {
    return new Promise((resolve) => {
      const promises = [];

      if (bay) promises.push(this.getBay());
      if (company) promises.push(this.getCompany());
      if (floor) promises.push(this.getFloor());
      if (approved_user) promises.push(this.getApprovedUser());
      if (rejected_user) promises.push(this.getRejectedUser());
      Promise.all(promises).then(() => resolve(this));
    });
  }

  getBay() {
    return new Promise((resolve) => resolve(this.bay));
  }

  getCompany() {
    return new Promise((resolve) => resolve(this.company));
  }

  getFloor() {
    return new Promise((resolve) => resolve(this.floor));
  }

  getApprovedUser() {
    return new Promise((resolve) => resolve(this.approved_user));
  }

  getRejectedUser() {
    return new Promise((resolve) => resolve(this.rejected_user));
  }

  isToday() {
    const { start } = this;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 59);

    return (
      start.getTime() >= today.getTime() &&
      start.getTime() <= endOfToday.getTime()
    );
  }

  isFuture() {
    const { start } = this;

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 59);

    return start.getTime() > endOfToday.getTime();
  }

  isPast() {
    const { start } = this;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return start.getTime() < today.getTime();
  }
}
