import Client from '../Entities/Client';
import GenericNamespaceHandler from '../GenericNamespaceHandler';

export default class ClientsNamespace extends GenericNamespaceHandler {
  namespace = 'clients';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = false;

  responseEntity = Client;

  responseKey = 'company';

  requiredFields = [];
}
