/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import ProgressNotification from 'components/FileUpload/ProgressNotification';
import { useSystemNotifications } from 'context/systemNotifications-context';
import { AnimatePresence } from 'framer-motion';

import Notification from './Notifcation';

import styles from './SystemNotifications.module.scss';

const SystemNotifications = () => {
  const { systemNotifications } = useSystemNotifications();
  return (
    <div className={styles.wrapper}>
      <AnimatePresence key="notifications-animate-presence">
        {systemNotifications.map(({ id, type, ...notification }) => {
          if (type === 'in_progress' || type === 'in_progress_completed') {
            return (
              <ProgressNotification
                key={id}
                id={id}
                type={type}
                {...notification}
              />
            );
          }
          return <Notification key={id} id={id} {...notification} />;
        })}
      </AnimatePresence>
    </div>
  );
};

export default SystemNotifications;
