/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconWarningTriangle = ({ size, colour, fill, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 18 16"
    fill="none"
    {...rest}
  >
    <path
      d="m17.896 14.8184-8.22841-14.446948c-.07155-.113854-.17023-.207572-.28691-.2724953-.11668-.064923-.24761-.0989567-.38068-.0989567s-.264.0340337-.38068.0989567c-.11668.0649233-.21536.1586413-.28691.2724953l-8.228402 14.446948c-.0679371.1194-.10380066.2548-.1040071.3927s.0352516.2734.1028301.393c.067579.1196.164911.2191.282269.2886.117357.0695.250629.1065.386495.1073h16.456805c.1359-.0008.2691-.0378.3865-.1073s.2147-.169.2823-.2886c.0675-.1196.103-.2551.1028-.393s-.0361-.2733-.104-.3927zm-9.67227-9.34246c0-.20892.08179-.40928.22737-.55701s.34302-.23072.5489-.23072.40332.08299.5489.23072.22736.34809.22736.55701v4.72636c0 .2089-.08178.4093-.22736.557-.14558.1478-.34302.2307-.5489.2307s-.40332-.0829-.5489-.2307c-.14558-.1477-.22737-.3481-.22737-.557zm.77627 8.27116c-.11679.0021-.23281-.0196-.34113-.064-.10831-.0444-.20671-.1105-.28931-.1943-.08259-.0838-.1477-.1836-.19143-.2936-.04372-.1099-.06518-.2276-.06307-.3461 0-.2382.09323-.4666.25919-.635s.39105-.263.62575-.263.45979.0946.62575.263.25919.3968.25919.635c.00211.1185-.01935.2362-.06307.3461-.04373.11-.10884.2098-.19143.2936-.0826.0838-.181.1499-.28931.1943-.10832.0444-.22434.0661-.34113.064z"
      fill={fill}
    />
  </svg>
);

IconWarningTriangle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string,
  fill: PropTypes.string
};

IconWarningTriangle.defaultProps = {
  size: '100%',
  colour: 'warning',
  fill: 'currentColor'
};

export default memo(IconWarningTriangle);
