import Building from '../Building';

export default class MyBuildingArticle {
  constructor(
    Client,
    {
      id,
      user_id,
      user,
      building_id,
      building,
      title,
      body,
      cover_image_uri,
      publish_date,
      published_user_id,
      published_user,
      archived,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.id = id || '';

    this.user_id = user_id || 0;
    if (user) this.user = new User(Client, user);

    this.building_id = building_id || 0;
    this.building = null;
    this.building = building ? new Building(Client, building) : null;

    this.title = title || '';
    this.body = body || '';
    this.cover_image_uri = cover_image_uri || '';
    this.publish_date = parseISODateTimeAsUTC(publish_date);
    this.published_user_id = published_user_id || null;

    this.published_user = published_user
      ? new User(Client, published_user)
      : null;

    this.archived = Boolean(archived);
    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  processEntities({ building = false }) {
    return new Promise((resolve) => {
      const promises = [];

      if (building) promises.push(this.getBuilding());
      Promise.all(promises).then(() => resolve(this));
    });
  }

  getBuilding() {
    return new Promise((resolve) => resolve(this.building));
  }

  getStatusLabel() {
    const { archived } = this;

    let status = 'live';
    if (archived) status = 'archived';
    return status;
  }
}
