/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import VisuallyHidden from 'components/accessibility/VisuallyHidden/VisuallyHidden';
import Button from 'components/Button';
import Input from 'components/forms/Input/Input';
import { IconBin, IconTick, IconUpload } from 'components/Icons';
import Loader from 'components/Loader/Loader';
import PropTypes from 'prop-types';
import { append, reject, find, propEq, flatten } from 'ramda';
import apiInstance from 'services/api/apiInstance/apiInstance';
import logger from 'services/logger/logger';

import styles from './FileUpload.module.scss';

const APIService = apiInstance();

const FileUpload = ({
  accept,
  acceptedFileExtensions,
  errors,
  existingFile,
  getHandler,
  isRequired,
  label,
  maxSize,
  multiUpload,
  name,
  onFileDelete,
  onFileUpload,
  downloadAllowed,
  readOnly,
  register,
  setValue,
  unregister,
  uploadHandler,
  value
}) => {
  const {
    namespaces: { general }
  } = APIService;

  const fileUploadRef = useRef(null);

  const [uploadPercentage, setUploadPercentage] = useState(null);
  // const [uploadError, setUploadError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [signedFileURI, setSignedFileURI] = useState(null);
  const [signedFileExpiry, setSignedFileExpiry] = useState(null);
  const [multiFiles, setMultiFiles] = useState([]);

  const handleFileUpload = ({ target }) => {
    const { files } = target;
    const [file] = files;
    setUploadPercentage(0);

    const uploadFunction = uploadHandler || general.upload;

    uploadFunction({
      file,
      secure: true,
      onProgress: ({ loaded }) => {
        setUploadPercentage(loaded);
      }
    })
      .then((response) => {
        const { uri } = response;
        const { size, type } = file;

        const fileObject = {
          name: file?.name || response.fileObject.fileName,
          size,
          type,
          uri,
          ...response
        };

        if (multiUpload) {
          const newFiles = append(fileObject, multiFiles);
          setMultiFiles(newFiles);
          setValue(name, newFiles, { shouldValidate: true });
        } else {
          setSelectedFile(fileObject);
          setValue(name, JSON.stringify(fileObject), { shouldValidate: true });
        }
        fileUploadRef.current.value = '';
        setUploadPercentage(null);

        if (onFileUpload) {
          try {
            onFileUpload(response, file?.name, file, name);
          } catch (e) {
            logger.debug(e);
          }
        }
      })
      .catch((error) => {
        logger.error(error);
        setUploadPercentage(null);
        // setUploadError(error);
      });
  };

  const handleFileView = (selectedFilename) => {
    const getFunction = getHandler || general.getFileLink;

    const openFile = (uri) => window.open(uri, `File-${name}`, '');

    if (signedFileURI && signedFileExpiry && signedFileExpiry >= new Date()) {
      openFile(signedFileURI);
    } else {
      setSignedFileURI(null);
      setSignedFileExpiry(null);

      const downloadFileParams = multiUpload
        ? find(propEq('originalFilename', selectedFilename))(
            flatten(Object.entries(selectedFile))
          )
        : selectedFile.uri || selectedFile;
      getFunction(downloadFileParams).then(({ expiry, uri }) => {
        if (expiry) {
          const dateTime = new Date();
          dateTime.setMinutes(dateTime.getMinutes() + expiry / 60);
          logger.debug(dateTime);
          setSignedFileExpiry(dateTime);
        }
        if (uri) {
          setSignedFileURI(uri);
          openFile(uri);
        }
      });
    }
  };

  const chooseFile = () => fileUploadRef.current.click();

  const deleteFile = (selected) => {
    const doDelete = window.confirm(
      'Are you sure you want to remove this file?'
    );
    const toDelete = multiUpload ? selected : selectedFile;
    if (doDelete) {
      if (onFileDelete) {
        try {
          onFileDelete(toDelete, name);
        } catch (e) {
          logger.debug(e);
        }
      }
      if (multiUpload) {
        const newFiles = reject(
          (file) => file.name === toDelete.name && file.uri === toDelete.uri,
          multiFiles
        );
        setMultiFiles(newFiles);
        setValue(name, newFiles, { shouldValidate: true });
      } else {
        setSelectedFile(null);
        setValue(name, '', { shouldValidate: true });
      }
    }
  };

  const formatSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  };

  const buttons = (selected) => {
    if (readOnly) return <></>;

    if (selectedFile) {
      return (
        <span>
          {selectedFile && (
            <span>
              <IconTick size={26} colour="success" />
            </span>
          )}
          <IconBin
            size={16}
            colour="neutral-grey-3"
            onClick={() => deleteFile(selected)}
          />
        </span>
      );
    }
    return (
      <span onClick={chooseFile}>
        {uploadPercentage ? (
          <div className={styles.loader}>
            <Loader isShowing className={styles.loader} animateWithCss />
          </div>
        ) : (
          <IconUpload size={32} />
        )}
      </span>
    );
  };

  const parseFileValue = (file) => {
    let parsedFile = null;
    if (typeof file === 'string' && file.length) {
      try {
        parsedFile = JSON.parse(file);
      } catch (e) {
        logger.debug(e);
      }
    } else if (typeof file === 'object') {
      parsedFile = file;
    }

    if (parsedFile) {
      const newFile = {
        ...parsedFile,
        name: parsedFile.name || parsedFile.fileName
      };
      setValue(name, JSON.stringify(newFile), { shouldValidate: true });
      setSelectedFile(newFile);
    }

    if (multiUpload) {
      const files = Object.values(parsedFile).map((f) => ({
        ...f,
        name: f.originalFilename
      }));
      setValue(`${name}-files-array`, files, { shouldValidate: true });
      setMultiFiles(files);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) =>
      handleFileUpload({ target: { files: acceptedFiles } }),
    open: chooseFile
  });

  useEffect(() => {
    if (existingFile) parseFileValue(existingFile);
  }, [existingFile]);

  useEffect(() => {
    if (value && value !== existingFile) parseFileValue(value);
  }, [value]);

  useEffect(
    () => () => {
      if (multiUpload) unregister(`${name}-files-array`);
      unregister(name);
    },
    [multiUpload, name, unregister]
  );

  return (
    <>
      {!multiUpload && (
        <div>
          <div className={styles.row}>
            <div>
              <label>{label}</label>
              {selectedFile && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div onClick={downloadAllowed && handleFileView}>
                  <p>{selectedFile?.name}</p>
                  <p>{selectedFile.size && formatSize(selectedFile?.size)}</p>
                </div>
              )}
            </div>
            {buttons()}
            <VisuallyHidden>
              <input
                ref={fileUploadRef}
                type="file"
                onChange={handleFileUpload}
              />
              <Input
                label=""
                name={name}
                type="text"
                inputRef={register(
                  isRequired ? { required: 'This field is required' } : null
                )}
                {...{ errors }}
              />
            </VisuallyHidden>
          </div>
        </div>
      )}
      {multiUpload && (
        <>
          {multiFiles.length > 0 && (
            <div className={styles.row}>
              <label>{label}</label>
            </div>
          )}

          {multiFiles.map((file, index) => (
            <div key={file?.s3Filename || index} className={styles.row}>
              <div
                className={styles.fileDetails}
                onClick={
                  downloadAllowed ? () => handleFileView(file.name) : null
                }
              >
                <p>{file.name}</p>
                <p>{file.size && formatSize(file.size)}</p>
              </div>
              {buttons(file)}
            </div>
          ))}
          <input name={name} ref={register(name)} type="hidden" />
          <input
            defaultValue={[]}
            name={`${name}-files-array`}
            ref={register(`${name}-files-array`)}
            type="hidden"
          />
        </>
      )}

      {multiUpload && !readOnly && (
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} ref={fileUploadRef} />
          <label>{label || null}</label>
          {!label && (
            <span>Upload additional documents (max. {maxSize} per each)</span>
          )}
          {uploadPercentage === null && (
            <Button
              text="Upload file"
              onClick={chooseFile}
              appearance="shaded"
            />
          )}
          {uploadPercentage !== null && (
            <div style={{ margin: '2em 0' }} className={styles.loader}>
              <Loader isShowing className={styles.loader} animateWithCss />
            </div>
          )}
          <span>
            <>
              {acceptedFileExtensions.length ? (
                <>Accepted formats: {acceptedFileExtensions.join(', ')}</>
              ) : null}
            </>
          </span>
        </div>
      )}
    </>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  acceptedFileExtensions: PropTypes.arrayOf(PropTypes.string),
  buildingId: PropTypes.number,
  className: PropTypes.string,
  downloadAllowed: PropTypes.bool,
  errors: PropTypes.shape([]),
  existingFile: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  fullWidth: PropTypes.bool,
  getHandler: PropTypes.func,
  getValues: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  maxSize: PropTypes.string,
  multiUpload: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFileDelete: PropTypes.func,
  onFileUpload: PropTypes.func,
  readOnly: PropTypes.bool,
  register: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  unregister: PropTypes.func,
  uploadHandler: PropTypes.func,
  value: PropTypes.any
};

FileUpload.defaultProps = {
  accept: [],
  acceptedFileExtensions: [],
  buildingId: null,
  className: null,
  downloadAllowed: true,
  errors: null,
  existingFile: null,
  fullWidth: false,
  getHandler: null,
  isRequired: true,
  label: '',
  maxSize: '50MB',
  multiUpload: false,
  name: '',
  onChange: null,
  onFileDelete: null,
  onFileUpload: null,
  readOnly: false,
  register: () => {},
  unregister: () => {},
  showLabel: true,
  uploadHandler: null,
  value: null
};

export default FileUpload;
