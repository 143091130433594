export default function (segments) {
  const max = 100;
  let segmentMax = 60;
  const tempResults = [];
  let remaining = max;
  let finalResults = [];

  // create a series of random numbers and push them into an array
  for (let i = 1; i <= segments; i++) {
    let r = Math.random() * segmentMax;
    if (i === segments) {
      // the final segment is just what's left after the other randoms are added up
      r = remaining;
    }
    tempResults.push(r);
    // subtract them from the total
    remaining -= r;
    // no segment can be larger than what's remaining
    segmentMax = remaining;
  }

  // randomly shuffle the array into a new array
  while (tempResults.length > 0) {
    const index = Math.floor(Math.random() * tempResults.length);
    finalResults = finalResults.concat(tempResults.splice(index, 1));
  }
  return finalResults;
}
