import logger from 'services/logger/logger';

import Client from '../../Client';

export default class OnboardingMockNamespace {
  onboardingSessions = {};

  onboardingSessionData = {};

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;

    const onboardingSessions = this.framework.helpers.get(
      'mocks_onboardingSessions'
    );
    if (
      onboardingSessions &&
      Object.prototype.toString.call(onboardingSessions)
    ) {
      this.onboardingSessions = onboardingSessions;
    }

    const onboardingSessionData = this.framework.helpers.get(
      'mocks_onboardingSessionData'
    );
    if (
      onboardingSessionData &&
      Object.prototype.toString.call(onboardingSessionData)
    ) {
      this.onboardingSessionData = onboardingSessionData;
    }
  }

  getEndpoints() {
    const { generateUUIDV4 } = this.framework;
    return {
      'onboarding/password/request': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const { token, email } = payload;
        if (!token || typeof token !== 'string' || token.length < 36) {
          return this.framework.createErrorResponse('invalid_token');
        }
        if (
          !email ||
          typeof email !== 'string' ||
          email.length <= 4 ||
          !/@/.test(email)
        ) {
          return this.framework.createErrorResponse('invalid_email');
        }
        return { code: 200 };
      },
      'onboarding/password/verify': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        let { token, pin } = payload;
        pin = `${pin}`;

        if (!token || typeof token !== 'string' || token.length < 36) {
          return this.framework.createErrorResponse('invalid_token');
        }
        if (!pin || typeof pin !== 'string' || pin.length !== 4) {
          return this.framework.createErrorResponse(`invalid_pin${pin}`);
        }
        return { code: 200, data: { reset_token: generateUUIDV4() } };
      },
      'onboarding/password/reset': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const { token1, token2, password } = payload;

        if (!token1 || !token2) {
          return this.framework.createErrorResponse('invalid_token');
        }
        if (!password) {
          return this.framework.createErrorResponse('invalid_password');
        }
        return { code: 200 };
      },

      'onboarding/generatePin': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        return { code: 200, data: true };
      },
      'onboarding/validatePin': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        return { code: 200, data: true };
      },
      'onboarding/occupiers': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const { building_id } = payload;
        const {
          framework: {
            mockNamespaces: {
              JobRolesMockNamespace: { dataSet: jobRoles },
              OccupiersMockNamespace: { dataSet: occupiers },
              FloorsMockNamespace: { dataSet: floors }
            }
          }
        } = this;

        const ret = [];

        occupiers.forEach((occupier) => {
          const {
            id,
            address1,
            address2,
            address3,
            city,
            postcode,
            latitude,
            longitude
          } = occupier.building;
          if (!building_id || building_id === id) {
            const { company, building } = occupier;

            building.job_roles = jobRoles.filter((role) => {
              if (role.building_id === building_id) return role;
            });

            building.floors = floors.filter((floor) => {
              if (floor.building_id === building_id) return floor;
            });

            ret.push({
              id: company.id,
              building_name: building.name,
              name: company.name,
              address1,
              address2,
              address3,
              city,
              postcode,
              latitude,
              longitude,
              building
            });
          }
        });
        return { code: 200, data: { occupiers: ret } };
      },
      'onboarding/jobRoles': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const {
          framework: {
            mockNamespaces: {
              JobRolesMockNamespace: { dataSet }
            }
          }
        } = this;
        return { code: 200, data: { roles: dataSet } };
      },
      'onboarding/floors': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const {
          framework: {
            mockNamespaces: {
              FloorsMockNamespace: { dataSet: floors }
            }
          }
        } = this;
        return { code: 200, data: { floors } };
      },
      'onboarding/travelOptions': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const {
          framework: {
            mockNamespaces: {
              TravelOptionsMockNamespace: { dataSet }
            }
          }
        } = this;
        return { code: 200, data: { options: dataSet } };
      },
      'onboarding/start': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const session = {
          id: generateUUIDV4(),
          user_id: 0,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        this.onboardingSessions[session.id] = session;

        this.onboardingSessionData[session.id] = [];
        this.onboardingSessionData[session.id].push({
          id: this.onboardingSessionData[session.id].length + 1,
          session_id: session.id,
          data_key: 'company_id',
          data_value: payload.company_id,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });

        this.onboardingSessionData[session.id].push({
          id: this.onboardingSessionData[session.id].length + 1,
          session_id: session.id,
          data_key: 'building_id',
          data_value: payload.building_id,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });

        this.framework.helpers.save(
          'mocks_onboardingSessions',
          this.onboardingSessions
        );
        this.framework.helpers.save(
          'mocks_onboardingSessionData',
          this.onboardingSessionData
        );

        return { code: 200, data: { session } };
      },
      'onboarding/save': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'onboarding_invalid_session_id',
          'onboarding_provide_data_key',
          'onboarding_provide_data_value'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!this.onboardingSessions[payload.session_id]) {
          return this.framework.createErrorResponse(
            'onboarding_invalid_session_id'
          );
        }
        if (!this.onboardingSessionData[payload.session_id])
          this.onboardingSessionData[payload.session_id] = [];

        if (!Array.isArray(payload.data_key)) {
          payload.data_key = [payload.data_key];
          payload.data_value = [payload.data_value];
        }

        payload.data_key.forEach((key, index) => {
          let updated = false;
          for (const index in this.onboardingSessionData[payload.session_id]) {
            if (
              this.onboardingSessionData[payload.session_id].hasOwnProperty(
                index
              )
            ) {
              const { data_key } = this.onboardingSessionData[
                payload.session_id
              ];
              if (data_key === key) {
                this.onboardingSessionData[payload.session_id].data_value =
                  payload.data_value[index];
                this.onboardingSessionData[
                  payload.session_id
                ].modified = new Date().toISOString();
                updated = true;
              }
            }
          }

          if (!updated) {
            const sessionData = {
              id: this.onboardingSessionData[payload.session_id].length + 1,
              session_id: payload.session_id,
              data_key: key,
              data_value: payload.data_value[index],
              created: new Date().toISOString(),
              modified: new Date().toISOString()
            };
            this.onboardingSessionData[payload.session_id].push(sessionData);
          }
        });

        this.framework.helpers.save(
          'mocks_onboardingSessionData',
          this.onboardingSessionData
        );

        return { code: 200, data: true };
      },
      'onboarding/get': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'onboarding_invalid_session_id',
          'onboarding_provide_data_key',
          'onboarding_no_data_found'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!this.onboardingSessions[payload.session_id]) {
          return this.framework.createErrorResponse(
            'onboarding_invalid_session_id'
          );
        }

        if (!this.onboardingSessionData[payload.session_id]) {
          return this.framework.createErrorResponse('onboarding_no_data_found');
        }

        let data = null;
        this.onboardingSessionData[payload.session_id].forEach(
          (sessionData) => {
            if (sessionData.data_key === payload.data_key) data = sessionData;
          }
        );

        if (!data)
          return this.framework.createErrorResponse('onboarding_no_data_found');

        return { code: 200, data };
      },
      'onboarding/complete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'system_error',
          'onboarding_invalid_session_id',
          'onboarding_provide_email_or_password',
          'onboarding_missing_fields',
          'onboarding_provide_email',
          'onboarding_provide_password',
          'onboarding_provide_company_id',
          'onboarding_email_duplicate',
          'onboarding_email_invalid'
        ];

        logger.log(payload);

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        const {
          framework: {
            mockNamespaces: {
              UsersMockNamespace: { dataSet: users, termsAccepted }
            }
          }
        } = this;

        const values = {};

        if (!this.onboardingSessionData[payload.session_id]) {
          return this.framework.createErrorResponse(
            'onboarding_missing_fields'
          );
        }

        this.onboardingSessionData[payload.session_id].forEach(
          (sessionData) => {
            values[sessionData.data_key] = sessionData.data_value;
          }
        );

        const requiredFields = [
          'email',
          'password',
          'name',
          'date_of_birth',
          'company_id',
          'building_id',
          'floor_id',
          'role_id'
        ];
        const missingFields = [];
        requiredFields.forEach((fieldName) => {
          if (!values[fieldName]) {
            missingFields.push(fieldName);
          }
        });

        if (missingFields.length > 0) {
          const response = this.framework.createErrorResponse(
            'onboarding_missing_fields'
          );
          response.data = missingFields;
          return response;
        }

        const user = {
          id: users.length + 1,
          email: values.email,
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          profile: {
            user_id: users.length + 1,
            name: values.name,
            date_of_birth: values.date_of_birth,
            picture_uri: '',
            created: new Date().toISOString(),
            modified: new Date().toISOString()
          }
        };
        users.push(user);

        termsAccepted.push({
          user_id: user.id,
          building_id: values.building_id,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });

        const {
          framework: {
            mockNamespaces: {
              BuildingsMockNamespace: { dataSet: buildings }
            }
          }
        } = this;

        return {
          code: 200,
          data: {
            user,
            building: buildings[values.building_id]
          }
        };
      },
      'onboarding/login': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'system_error',
          'login_no_email_or_password',
          'login_provide_device_secret',
          'login_invalid_password',
          'login_invalid_email_or_password',
          'login_invalid_email'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        const {
          framework: {
            mockNamespaces: {
              UsersMockNamespace: { dataSet: users }
            }
          }
        } = this;

        return {
          code: 200,
          data: {
            user: users[0],
            device: {
              id: '6d0657eb-be8a-4912-bacc-2ad691207279',
              name: "James's Test Device",
              platform: 'Android',
              created: '2020-08-27T16:14:22.778Z',
              modified: '2020-08-27T16:14:22.778Z'
            },
            session: {
              id: '27399081-0c81-40b2-99a1-36fbd6085734',
              user_id: 1,
              refresh_token: '4589397c-60e6-41c3-8022-8a6ec4c8e41a',
              device_id: '6d0657eb-be8a-4912-bacc-2ad691207279',
              verified: false,
              created: '2020-08-27T16:14:22.782Z',
              modified: '2020-08-27T16:14:22.782Z',
              expiry: '2026-08-27T17:14:22.782Z'
            }
          }
        };
      }
    };
  }
}
