/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconBike = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 81 81"
    fill="none"
    {...rest}
  >
    <g fill="currentColor">
      <path d="m38.9 27.6c-.3-.3-.8-.5-1.2-.5-.6 0-1.2.3-1.5.8l-5.4 9.4c-.2.3-.2.6-.2.8 0 .7.5 1.4 1.2 1.6l8.9 2.4-2.4 8.9c-.2.9.3 1.8 1.2 2s1.8-.3 2-1.2l2.8-10.4c0-.2.1-.3.1-.5 0-.7-.5-1.4-1.2-1.6l-8.4-2.2 3.3-5.7 6.2 6.2c.3.3.8.5 1.2.5h6.3c.9 0 1.7-.7 1.7-1.7 0-.9-.7-1.7-1.7-1.7h-5.6z" />
      <path d="m42.3 24c0-1.5-1.2-2.8-2.8-2.8-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8c1.5 0 2.8-1.2 2.8-2.8z" />
      <g clipRule="evenodd" fillRule="evenodd">
        <path d="m27.6 55.4c3 0 5.4-2.4 5.4-5.4s-2.4-5.4-5.4-5.4-5.4 2.4-5.4 5.4c.1 3 2.5 5.4 5.4 5.4zm0 3c4.6 0 8.4-3.7 8.4-8.4s-3.7-8.4-8.4-8.4-8.4 3.7-8.4 8.4 3.8 8.4 8.4 8.4z" />
        <path d="m53.3 55.4c3 0 5.4-2.4 5.4-5.4s-2.4-5.4-5.4-5.4-5.3 2.5-5.3 5.4c0 3 2.4 5.4 5.3 5.4zm0 3c4.6 0 8.4-3.7 8.4-8.4s-3.7-8.4-8.4-8.4-8.3 3.8-8.3 8.4 3.7 8.4 8.3 8.4z" />
      </g>
    </g>
  </svg>
);

IconBike.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconBike.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconBike);
