/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconEmailReset = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 113 115"
    fill="none"
    {...rest}
  >
    <clipPath id="a">
      <path d="m0 0h113v115h-113z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path
        d="m9.45953 115h94.10347c4.718-.022 8.699-3.492 9.325-8.141.09-.178.134-.356.134-.556v-61.1924c0-3.648-1.744-7.0513-4.673-9.2312l-9.6166-7.0957v-4.2263c0-4.9604-4.0477-8.9865-9.057-9.0087h-8.9004l-18.5613-13.6799c-3.3768-2.513542-8.0059-2.513542-11.3603 0l-18.5613 13.6799h-9.4148c-4.9869 0-9.0346 4.0261-9.057 9.0087v4.6044l-9.12408 6.7176c-2.95191 2.1577-4.69622 5.5832-4.69622 9.2312v61.1924c0 .2.0447259.378.134178.556.6038 4.649 4.584402 8.119 9.325352 8.141zm89.25057-82.902 8.0279 5.9168c2.259 1.6683 3.578 4.2931 3.601 7.0958v1.3568l-11.6066 9.3202v-23.6896zm-46.2913-28.07156c2.4152-1.80174 5.7472-1.80174 8.1848 0l15.6541 11.52226h-39.493zm-35.9373 20.53096c0-3.4923 2.8625-6.3172 6.3734-6.3395h66.8205c3.511 0 6.3511 2.8472 6.3735 6.3395v33.3656l-33.1643 26.5589c-3.7346 3.0029-9.057 3.0029-12.7916 0l-33.6115-26.9593zm-13.79795 20.5532c0-2.8027 1.34178-5.4275 3.57808-7.0958l7.51397-5.5387v22.8888l-11.11441-8.8975zm0 60.4804v-55.698l11.60635 9.2978v.0223l34.1483 27.3598c4.7185 3.7814 11.4274 3.7814 16.146 0l33.6115-26.9594 12.1203-9.6983v56.3878h-.067c-.357 3.426-3.242 6.028-6.686 6.028h-94.10347c-3.4439 0-6.35108-2.625-6.68652-6.028h-.08946z"
        fill="currentColor"
      />
    </g>
    <path
      d="m74.6829 36.398c-.2199-.253-.4989-.3795-.8033-.3964-.3045-.0169-.592.1012-.7949.3121l-23.4744 23.4138-9.6823-9.6658c-.2029-.2024-.4904-.3205-.8033-.3205-.3045 0-.6004.1181-.8034.3205-.2029.2024-.3213.4892-.3213.8012 0 .3037.1184.5989.3213.8013l11.289 11.3358 25.0726-25.0079c.4228-.4217.4228-1.1639 0-1.5941z"
      fill="var(--secondary-1)"
    />
  </svg>
);

IconEmailReset.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconEmailReset.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconEmailReset);
