/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useBuilding } from '../../context/building-context';
import SelectInput from '../forms/SelectInput/SelectInput';
import MultiSelect from '../MultiSelect/MultiSelect';

import styles from './FloorSelect.module.scss';

const FloorSelect = ({
  label,
  register,
  errors,
  name,
  className,
  fullWidth,
  showLabel,
  dataTestId,
  isRequired,
  onChange,
  buildingId,
  selectedValue,
  multi,
  readOnly,
  ...props
}) => {
  const { buildingData, buildings } = useBuilding();
  const [floors, setFloors] = useState([]);

  useEffect(() => {
    if (buildingId !== null && +buildingId !== buildingData?.id) {
      const selectedBuilding = buildings
        .filter(({ id }) => id === parseInt(buildingId, 10))
        .pop();
      setFloors(selectedBuilding?.floors);
    } else {
      setFloors(buildingData?.floors);
    }
  }, [buildingId, buildingData, selectedValue]);

  if (multi) {
    return (
      <MultiSelect
        options={floors}
        selectedValues={selectedValue}
        inputTestId={`${dataTestId}-input`}
        listTestId={`${dataTestId}-list`}
        {...{
          readOnly,
          label,
          showLabel,
          errors,
          register,
          name,
          fullWidth,
          onChange,
          isRequired
        }}
        {...props}
      />
    );
  }

  return (
    <SelectInput
      name={name}
      className={classnames(styles.select, className)}
      values={floors}
      valueKey="id"
      labelKey="name"
      dataTestId={`${dataTestId}-input`}
      {...{
        readOnly,
        label,
        showLabel,
        errors,
        register,
        name,
        fullWidth,
        onChange,
        selectedValue
      }}
      {...props}
    />
  );
};

FloorSelect.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array,
  name: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  showLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  buildingId: PropTypes.number,
  dataTestId: PropTypes.string,
  selectedValue: PropTypes.string,
  multi: PropTypes.bool,
  readOnly: PropTypes.bool
};

FloorSelect.defaultProps = {
  label: '',
  register: null,
  errors: null,
  name: '',
  className: null,
  fullWidth: false,
  showLabel: true,
  isRequired: true,
  onChange: null,
  buildingId: null,
  dataTestId: null,
  multi: null,
  readOnly: null,
  selectedValue: ''
};

export default FloorSelect;
