import { version } from '../../package.json';

const env = process.env.NODE_ENV;

const ddConfig = () => {
  if (process.env.CLIENT_NAME === 'savills') {
    return {
      service: 'riseportal.io',
      clientToken: 'pub8c4ed0c9f7c2040ca62cbf79c61fcfe1',
      applicationId: '643649af-8a49-4dd4-a18f-4fdda6385498',
      sampleRate: 1,
      replaySampleRate: 1,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    };
  }
  if (process.env.CLIENT_NAME === 'cureoscity')
    return {
      service: 'cureoscityportal.com',
      clientToken: 'pub332cdc96b07aaa874980ac744f6efbd5',
      applicationId: 'c56bd6ae-ba1a-478f-b64c-e0175d392748',
      sampleRate: 1,
      replaySampleRate: 1,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',

    };
  return '';
};

const {
  applicationId,
  clientToken,
  defaultPrivacyLevel,
  replaySampleRate,
  sampleRate,
  service,
  trackInteractions,
} = ddConfig();

const ddRumInit = () => applicationId ? `
  (function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
  })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
  DD_RUM.onReady(function() {
    DD_RUM.init({
      clientToken: '${clientToken}',
      applicationId: '${applicationId}',
      site: 'datadoghq.eu',
      service: '${service}',
      version: '${version}',
      sampleRate: ${sampleRate},
      replaySampleRate: ${replaySampleRate},
      trackInteractions: ${trackInteractions},
      defaultPrivacyLevel: '${defaultPrivacyLevel}',
      env: '${env}',
    });
    DD_RUM.startSessionReplayRecording();
  })
  ` : '';

export { ddRumInit };
