/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconSearch = ({ size, colour, ...rest }) => (
  <svg
    width={size}
    height={size}
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m23.5989 23.2008-4.35-4.35m2.35-5.65c0 4.4183-3.5817 8-8 8s-8.00002-3.5817-8.00002-8c0-4.41827 3.58172-7.99999 8.00002-7.99999s8 3.58172 8 7.99999z"
    />
  </svg>
);

IconSearch.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconSearch.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconSearch);
