import Client from '../Client';

export default class SessionsNamespace {
  /** *
   * @param {Client} client - instance of client
   */
  constructor(Client) {
    this.client = Client;
  }

  hasPermission = (
    permission = '',
    userId = 0,
    companyId = 0,
    buildingId = 0
  ) => {
    const { client } = this;

    const { permissionsCache } = client;

    return new Promise((resolve, reject) => {
      if (!permission) resolve(true);

      if (Object.values(permissionsCache).length) {
        const ret = {};
        if (Array.isArray(permission)) {
          permission.forEach((permission) => {
            ret[permission] = client.checkPermissionAgainstCache(
              permission,
              userId,
              companyId,
              buildingId
            );
          });
        } else {
          ret[permission] = client.checkPermissionAgainstCache(
            permission,
            userId,
            companyId,
            buildingId
          );
        }
        resolve(ret);
      } else {
        if (typeof permission !== 'string')
          permission = JSON.stringify(permission);
        client.request(
          '',
          'hasPermission',
          (error, response) => {
            if (error) reject(error);
            else {
              resolve(response?.data);
            }
          },
          {
            permission,
            user_id: userId,
            company_id: companyId,
            building_id: buildingId
          },
          Client.methods.GET
        );
      }
    });
  };

  isLoggedIn = () => {
    const { client } = this;
    return new Promise((resolve, reject) => {
      client.request(
        '',
        'isLoggedIn',
        (error, response) => {
          if (error) reject(error);
          else {
            client.permissionsCache = response?.data?.permissions || {};
            resolve(response?.data);
          }
        },
        null,
        Client.methods.GET,
        'rest'
      );
    });
  };

  logout = () => {
    const { client } = this;
    return new Promise((resolve, reject) => {
      client.request(
        '',
        'logout',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(Boolean(response?.data));
          }
        },
        null,
        Client.methods.GET
      );
    });
  };
}
