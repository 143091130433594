/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IPhoneHeader = ({ size, colour, height, width, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={width}
    height={height}
    viewBox="0 0 212 52"
    fill="none"
    {...rest}
  >
    <rect width="212" height="52" fill="#222540" />
    <path
      d="M22.2276 19.452C23.6303 19.452 24.4636 18.355 24.4636 16.4974C24.4636 14.6742 23.5768 13.6652 22.2582 13.6652C21.058 13.6652 20.2095 14.4716 20.2095 15.603C20.2095 16.6618 20.9739 17.4339 22.025 17.4339C22.671 17.4339 23.1946 17.1357 23.4545 16.6121H23.5195C23.508 17.8887 23.0455 18.6264 22.2391 18.6264C21.7651 18.6264 21.3867 18.3703 21.2682 17.9575H20.2859C20.4464 18.8595 21.2185 19.452 22.2276 19.452ZM22.262 16.6541C21.6199 16.6541 21.1765 16.2107 21.1765 15.5724C21.1765 14.9571 21.6428 14.4869 22.2658 14.4869C22.8888 14.4869 23.3551 14.9647 23.3551 15.5916C23.3551 16.2069 22.9003 16.6541 22.262 16.6541Z"
      fill="white"
    />
    <path
      d="M26.195 18.5576C26.5582 18.5576 26.8066 18.2977 26.8066 17.9537C26.8066 17.6059 26.5582 17.3498 26.195 17.3498C25.8358 17.3498 25.5835 17.6059 25.5835 17.9537C25.5835 18.2977 25.8358 18.5576 26.195 18.5576ZM26.195 15.7674C26.5582 15.7674 26.8066 15.5113 26.8066 15.1673C26.8066 14.8195 26.5582 14.5634 26.195 14.5634C25.8358 14.5634 25.5835 14.8195 25.5835 15.1673C25.5835 15.5113 25.8358 15.7674 26.195 15.7674Z"
      fill="white"
    />
    <path
      d="M30.6173 19.3182H31.5614V18.2671H32.3067V17.4339H31.5614V13.8028H30.1701C29.421 14.9418 28.6374 16.1993 27.9227 17.4453V18.2671H30.6173V19.3182ZM28.8362 17.4033C29.3751 16.4745 30.0134 15.4616 30.5791 14.6054H30.6326V17.4568H28.8362V17.4033Z"
      fill="white"
    />
    <path
      d="M34.5657 19.3182H35.5518V13.8028H34.5695L33.1285 14.8118V15.7597L34.5007 14.7965H34.5657V19.3182Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.489 13.1395H199.223C199.875 13.1395 200.111 13.2073 200.349 13.3347C200.587 13.462 200.774 13.6489 200.901 13.887C201.029 14.1252 201.097 14.3614 201.097 15.0127V17.2677C201.097 17.9191 201.029 18.1553 200.901 18.3934C200.774 18.6315 200.587 18.8184 200.349 18.9457C200.111 19.0731 199.875 19.1409 199.223 19.1409H191.489C190.838 19.1409 190.601 19.0731 190.363 18.9457C190.125 18.8184 189.938 18.6315 189.811 18.3934C189.684 18.1553 189.616 17.9191 189.616 17.2677V15.0127C189.616 14.3614 189.684 14.1252 189.811 13.887C189.938 13.6489 190.125 13.462 190.363 13.3347C190.601 13.2073 190.838 13.1395 191.489 13.1395ZM191.49 13.6614C190.975 13.6614 190.795 13.6961 190.61 13.7949C190.463 13.8736 190.35 13.986 190.272 14.1332C190.173 14.3178 190.138 14.4977 190.138 15.0127V17.2677C190.138 17.7828 190.173 17.9626 190.272 18.1473C190.35 18.2945 190.463 18.4068 190.61 18.4856C190.795 18.5843 190.975 18.6191 191.49 18.6191H199.224C199.739 18.6191 199.919 18.5843 200.104 18.4856C200.251 18.4068 200.363 18.2945 200.442 18.1473C200.541 17.9626 200.575 17.7828 200.575 17.2677V15.0127C200.575 14.4977 200.541 14.3178 200.442 14.1332C200.363 13.986 200.251 13.8736 200.104 13.7949C199.919 13.6961 199.739 13.6614 199.224 13.6614H191.49ZM201.619 17.1526V15.0651C201.619 15.0651 202.402 15.4635 202.402 16.1088C202.402 16.7542 201.619 17.1526 201.619 17.1526Z"
      fill="white"
    />
    <rect
      x="190.661"
      y="14.1398"
      width="9.39345"
      height="4.00091"
      rx="1.6"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.578 13.3689C175.146 13.3689 174.796 13.7194 174.796 14.1517V18.17C174.796 18.6023 175.146 18.9528 175.578 18.9528C176.011 18.9528 176.361 18.6023 176.361 18.17V14.1517C176.361 13.7194 176.011 13.3689 175.578 13.3689ZM172.343 15.4041C172.343 14.9717 172.693 14.6213 173.126 14.6213C173.558 14.6213 173.908 14.9717 173.908 15.4041V18.1699C173.908 18.6022 173.558 18.9527 173.126 18.9527C172.693 18.9527 172.343 18.6022 172.343 18.1699V15.4041ZM170.725 15.8215C170.292 15.8215 169.942 16.172 169.942 16.6043V18.1698C169.942 18.6022 170.292 18.9526 170.725 18.9526C171.157 18.9526 171.507 18.6022 171.507 18.1698V16.6043C171.507 16.172 171.157 15.8215 170.725 15.8215ZM168.22 16.8652C167.787 16.8652 167.437 17.2157 167.437 17.648V18.1699C167.437 18.6022 167.787 18.9527 168.22 18.9527C168.652 18.9527 169.003 18.6022 169.003 18.1699V17.648C169.003 17.2157 168.652 16.8652 168.22 16.8652Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.989 14.463C184.155 14.4631 185.277 14.9111 186.122 15.7145C186.185 15.7766 186.287 15.7758 186.35 15.7128L186.958 15.0989C186.99 15.0669 187.008 15.0236 187.007 14.9786C187.007 14.9336 186.989 14.8905 186.957 14.8589C184.739 12.7332 181.239 12.7332 179.021 14.8589C178.989 14.8905 178.971 14.9335 178.971 14.9786C178.97 15.0236 178.988 15.0669 179.02 15.0989L179.628 15.7128C179.691 15.7759 179.793 15.7767 179.856 15.7145C180.701 14.9111 181.823 14.463 182.989 14.463V14.463ZM182.99 16.4602C183.631 16.4601 184.248 16.6983 184.723 17.1283C184.787 17.1893 184.889 17.188 184.951 17.1253L185.559 16.5114C185.591 16.4792 185.609 16.4355 185.608 16.3901C185.608 16.3447 185.589 16.3014 185.556 16.2698C184.11 14.9246 181.871 14.9246 180.425 16.2698C180.392 16.3014 180.373 16.3447 180.373 16.3901C180.372 16.4355 180.39 16.4792 180.422 16.5114L181.03 17.1253C181.092 17.188 181.194 17.1893 181.258 17.1283C181.732 16.6985 182.35 16.4604 182.99 16.4602V16.4602ZM184.158 17.9255C184.19 17.8936 184.208 17.8497 184.207 17.8042C184.206 17.7587 184.187 17.7156 184.153 17.685C183.482 17.1173 182.499 17.1173 181.827 17.685C181.793 17.7155 181.774 17.7586 181.773 17.8042C181.772 17.8497 181.79 17.8936 181.822 17.9255L182.873 18.9862C182.904 19.0174 182.946 19.0349 182.99 19.0349C183.034 19.0349 183.076 19.0174 183.107 18.9862L184.158 17.9255Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1217 38.8055C9.95942 38.6366 9.95942 38.3638 10.1217 38.1945L13.9738 34.1832C14.2081 33.9389 14.5893 33.9389 14.824 34.1832C15.0583 34.4275 15.0583 34.824 14.824 35.0683L11.5288 38.5002L14.824 41.9313C15.0583 42.176 15.0583 42.5725 14.824 42.8168C14.5893 43.0611 14.2081 43.0611 13.9738 42.8168L10.1217 38.8055Z"
      fill="white"
    />
  </svg>
);

IPhoneHeader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IPhoneHeader.defaultProps = {
  size: '100%',
  colour: 'theme-primary',
  height: 52,
  width: 212
};

export default memo(IPhoneHeader);
