export default class Device {
  /** *
   * @param {Object} params - name you want to desginate to this device, eg: Mike's iPhone
   * @param {Boolean} generateSecret - Generate a secret on creation, false by default
   */
  constructor(
    { id = '', name = '', platform = '', secret = '' },
    generateSecret = false
  ) {
    this.id = id || '';
    this.secret = secret || '';
    this.name = name || '';
    this.platform = platform || '';

    if (generateSecret) this.generateSecret();
  }

  /** *
   * Generates a unique secret for use when logging in
   */
  generateSecret() {
    // eslint-disable-next-line no-bitwise
    this.secret = [...Array(64)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join('');
  }
}
