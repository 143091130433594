import axios from 'axios';
// import io from 'socket.io-client';
import { Client, Device, Session } from 'middleware/Client';
import config from 'services/api/config';
import credentials from 'services/api/credentials/credentials';
import logger from 'services/logger/logger';

let cachedApiInstance;
const io = () => {};

const apiInstance = (newInstance = false) => {
  if (!cachedApiInstance || newInstance) {
    const isValidDevice = (device) => {
      const { secret } = device;
      return typeof secret === 'string' && secret.length > 0;
    };

    const isValidSession = (session) => {
      const { id } = session;
      return typeof id === 'string' && id.length > 0;
    };

    const client = new Client(config, null, axios, credentials);

    const deviceStorageKey = 'cureoscity_device';
    const sessionStorageKey = 'cureoscity_session';
    let currentDevice = null;
    let currentSession = null;

    const getDevice = () =>
      Object.prototype.hasOwnProperty.call(localStorage, deviceStorageKey)
        ? JSON.parse(localStorage.getItem(deviceStorageKey))
        : currentDevice;
    const getSession = () =>
      Object.prototype.hasOwnProperty.call(localStorage, sessionStorageKey)
        ? JSON.parse(localStorage.getItem(sessionStorageKey))
        : currentSession;

    const updateDevice = (device) => {
      currentDevice = device;
      if (device === null) {
        localStorage.removeItem(deviceStorageKey);
      } else {
        localStorage.setItem(deviceStorageKey, JSON.stringify(device));
      }
    };

    const updateSession = (session) => {
      currentSession = session;
      if (session === null) {
        localStorage.removeItem(sessionStorageKey);
      } else {
        localStorage.setItem(sessionStorageKey, JSON.stringify(session));
      }
    };

    try {
      const storedDevice = JSON.parse(localStorage.getItem(deviceStorageKey));
      logger.log('Stored Device', storedDevice);
      if (!isValidDevice(storedDevice)) {
        throw new Error('invalid_device');
      }
      currentDevice = new Device(storedDevice);
    } catch (e) {
      currentDevice = new Device('Some Device', 'Some Platform', true);
      updateDevice(currentDevice);
    }

    try {
      const storedSession = JSON.parse(localStorage.getItem(sessionStorageKey));
      logger.log('session', storedSession);
      if (!isValidSession(storedSession)) {
        throw new Error('invalid_session');
      }
      currentSession = new Session(client, storedSession);
    } catch (e) {
      currentSession = null;
      updateSession(currentSession);
    }

    cachedApiInstance = {
      ...client,
      cacheId: Date.now(),
      getDevice,
      getSession,
      updateDevice,
      updateSession,
      resetSession: () => {
        updateSession(null);
      },
      // eslint-disable-next-line consistent-return
      isLoggedIn: () =>
        new Promise((resolve, reject) => {
          if (getDevice() && getSession()) {
            const {
              namespaces: {
                sessions: { isLoggedIn }
              }
            } = client;
            resolve(isLoggedIn());
          }
          reject(new Error('not_logged_in'));
        })
    };
  }
  return cachedApiInstance;
};

export default apiInstance;
