/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconBin = ({ size, colour, ...rest }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    {...rest}
    fill="none"
    viewBox="0 0 11 13"
  >
    <path
      d="M0.55 3.25V13H10.45V3.25H0.55ZM3.3 10.8333C3.3 11.1323 3.0536 11.375 2.75 11.375C2.4464 11.375 2.2 11.1323 2.2 10.8333V5.41667C2.2 5.11767 2.4464 4.875 2.75 4.875C3.0536 4.875 3.3 5.11767 3.3 5.41667V10.8333ZM6.05 10.8333C6.05 11.1323 5.8036 11.375 5.5 11.375C5.1964 11.375 4.95 11.1323 4.95 10.8333V5.41667C4.95 5.11767 5.1964 4.875 5.5 4.875C5.8036 4.875 6.05 5.11767 6.05 5.41667V10.8333ZM8.8 10.8333C8.8 11.1323 8.5536 11.375 8.25 11.375C7.9464 11.375 7.7 11.1323 7.7 10.8333V5.41667C7.7 5.11767 7.9464 4.875 8.25 4.875C8.5536 4.875 8.8 5.11767 8.8 5.41667V10.8333ZM11 1.08333V2.16667H0V1.08333H3.14105C3.63605 1.08333 4.0381 0.488042 4.0381 0H6.96135C6.96135 0.488042 7.36285 1.08333 7.8584 1.08333H11Z"
      fill={`var(--${colour})`}
    />
  </svg>
);

IconBin.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconBin.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconBin);
