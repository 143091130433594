import logger from 'services/logger/logger';

import Client from '../../Client';
import MockFramework from '../Framework';

export default class NotificationMockNamespace {
  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;

    const { generateUUIDV4 } = this.framework;

    this.dataSet = [
      {
        id: generateUUIDV4(),
        title: 'Some Title',
        description: 'Some description',
        schedule_date: '2020-08-28T05:12:13.000Z',
        user_id: 0,
        building_id: 1,
        company_id: 1,
        created: '2020-08-28T05:12:13.000Z',
        modified: '2020-08-28T05:12:13.000Z'
      },
      {
        id: generateUUIDV4(),
        title: 'Another Title',
        description: 'Another description',
        schedule_date: null,
        user_id: 1,
        building_id: 1,
        company_id: 0,
        created: '2020-08-28T05:12:13.000Z',
        modified: '2020-08-28T05:12:13.000Z'
      }
    ];
  }

  getEndpoints() {
    return {
      'notifications/mine': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'title', expression: 'like' },
          email: { field: 'description', expression: 'like' },
          user_id: { field: 'user_id', expression: 'eq' },
          building_id: { field: 'building_id', expression: 'eq' },
          company_id: { field: 'company_id', expression: 'eq' }
        };

        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const ret = this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );

        return { code: 200, data: { notifications: ret?.data?.records } };
      },
      'notifications/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'notification_valid_title',
          'notification_valid_description',
          'notification_valid_recipient',
          'notification_valid_schedule_date',
          'notification_no_send_permission'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const {
          title,
          description,
          user_id,
          company_id,
          building_id,
          schedule_date
        } = payload;

        const notification = {
          id: this.dataSet.length + 1,
          title,
          description,
          schedule_date,
          user_id,
          company_id,
          building_id,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        if (this.client.socket !== null) {
          try {
            this.client.socket.trigger('_event', {
              event: 'notification',
              data: notification
            });
          } catch (e) {
            logger.debug('Mock socket trigger failed', e);
          }
        }
        this.dataSet.push(notification);
        return { code: 200, data: { notification } };
      },
      'notifications/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      }
    };
  }
}
