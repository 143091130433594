/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCircleExclamation = ({ size }) => (
  <svg
    width={size}
    height="107"
    viewBox="0 0 107 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.5 0C24.0047 0 0 24.0047 0 53.5C0 82.9953 24.0047 107 53.5 107C82.9953 107 107 82.9953 107 53.5C107 24.0047 82.9953 0 53.5 0ZM53.5 104.082C25.6043 104.082 2.91818 81.3957 2.91818 53.5C2.91818 25.6043 25.6152 2.91818 53.5 2.91818C81.3848 2.91818 104.082 25.6043 104.082 53.5C104.082 81.3957 81.3957 104.082 53.5 104.082Z"
      fill="#FE5676"
    />
    <path
      d="M53.6191 70.3066H53.7488C54.6675 70.3066 55.424 69.5609 55.424 68.6314V27.2905C55.424 26.3718 54.6783 25.6152 53.7488 25.6152H53.6191C52.7004 25.6152 51.9438 26.361 51.9438 27.2905V68.6314C51.9438 69.5609 52.7004 70.3066 53.6191 70.3066Z"
      fill="#FE5676"
    />
    <path
      d="M53.6838 80.2289C54.806 80.2289 55.7157 79.3191 55.7157 78.197C55.7157 77.0748 54.806 76.165 53.6838 76.165C52.5616 76.165 51.6519 77.0748 51.6519 78.197C51.6519 79.3191 52.5616 80.2289 53.6838 80.2289Z"
      fill="#FE5676"
    />
  </svg>
);

IconCircleExclamation.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

IconCircleExclamation.defaultProps = {
  size: '100%'
};

export default memo(IconCircleExclamation);
