/* eslint-disable eqeqeq */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import IconSolidCircleCross from 'components/Icons/SolidCircleCross';
import PropTypes from 'prop-types';
import logger from 'services/logger/logger';

import { keyGenerator } from '../../utils';
import Input from '../forms/Input/Input';

import styles from './MultiSelect.module.scss';

const MultiSelect = ({
  errors,
  inputTestId,
  label,
  labelTestId,
  listTestId,
  name,
  onChange,
  options,
  placeholder,
  readOnly,
  register,
  isRequired,
  selectedValues,
  setValue
}) => {
  const [selected, setSelected] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [text, setText] = useState('');
  const [isValid, setIsValid] = useState();
  const [touched, setTouched] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const deleteItem = (idToDelete) => {
    const newItems = selected.filter((item) => item.value !== idToDelete);
    setSelected(newItems);
  };

  const clearSelected = () => {
    setSelected([]);
  };

  const addItem = (item) => {
    if (item.id === null) return;
    if (selected.find(({ value }) => value === item?.id)) return;
    const itemsToAdd = [
      ...selected,
      { key: `${name}-${selected.length}`, value: item.id }
    ];
    setSelected(itemsToAdd);
    setOpenMenu(false);
    setText('');
  };

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 8 && text === '')
      setSelected(selected.slice(0, selected.length - 1));
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    setText(value);
    setOpenMenu(value.length > 0 && true);
  };

  const handleInputClick = () => {
    setTouched(true);
    if (!selected.length) setIsValid(false);
  };

  useEffect(() => {
    setIsValid(selected.length > 0);
    if (selected) {
      setValue(name, JSON.stringify(selected?.map(({ value }) => value)), {
        shouldValidate: true,
        shouldDirty: true
      });
    }

    if (onChange) onChange(selected);
  }, [name, selected, setValue]);

  useEffect(() => {
    let defaultValueArray = [];
    setSelected([]);

    const existsInOptions = (value) =>
      options.filter(({ id }) => id == value).length;
    const existsInDefaults = (value) =>
      defaultValueArray.filter((id) => id == value).length;

    if (selectedValues) {
      if (!Array.isArray(selectedValues)) {
        if (typeof selectedValues === 'string') {
          try {
            defaultValueArray = JSON.parse(selectedValues);
          } catch (e) {
            logger.debug(e);
          }
        } else if (existsInOptions(selectedValues)) {
          defaultValueArray.push(selectedValues);
        }
      } else {
        defaultValueArray = selectedValues;
      }
    }

    if (
      Array.isArray(defaultValueArray) &&
      defaultValueArray.length &&
      Array.isArray(options) &&
      options.length
    ) {
      const newSelected = options
        .filter(({ id }) => existsInDefaults(id))
        .map(({ id }, index) => {
          const key = `${name}-${index}`;
          return { key, value: id };
        });
      setSelected(newSelected);
    }
    // Loop option.  compare Id, where there is match setSElectedItems
  }, [selectedValues, options, name]);

  const handleOptions = (allOptions) => {
    const selectedIds = selected?.map(({ value }) => value);

    const availableOptions = allOptions?.filter(
      (option) => selectedIds.includes(option?.id) === false
    );

    const newOptions2 = availableOptions?.filter((option) =>
      option?.name.toLowerCase().includes(text?.toLowerCase())
    );

    setFilteredOptions(newOptions2);
  };

  const showOnPage = (selectedName) => {
    const selectMenu = document.querySelector(
      `input[name="${selectedName}-TextInput"]`
    );

    if (openMenu && selectMenu) {
      selectMenu?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  useEffect(() => {
    handleOptions(options);
    showOnPage(name);
  }, [text, options, openMenu, selected, name]);

  const listClasses = classnames(styles.list, {
    [styles.open]: openMenu
  });

  useEffect(() => {
    setOpenMenu(false);
  }, [readOnly]);

  const inputContainerClasses = classnames(styles['input-container'], {
    [styles.valid]: touched && isValid,
    [styles.invalid]: touched && !isValid,
    [styles.disabled]: readOnly
  });

  return (
    <div className={styles.container}>
      <Input
        label=""
        type="hidden"
        name={name}
        isRequired={isRequired}
        inputRef={register(
          isRequired
            ? {
                validate: (value) =>
                  (Array.isArray(value) && value.length) ||
                  (value.length && !!JSON.parse(value).length)
              }
            : {}
        )}
        value={selected}
        defaultValue={
          selected && JSON.stringify(selected?.map(({ value }) => value))
        }
      />
      <div
        className={inputContainerClasses}
        role="button"
        onClick={() => {}}
        onKeyDown={handleInputClick}
        tabIndex={0}
      >
        <label className={styles.label} data-testId={labelTestId}>
          {label}
        </label>{' '}
        {selected &&
          selected.map(({ key, value }) => (
            <span className={styles.selected} key={key}>
              <span>{options.find((option) => option.id === value)?.name}</span>
              {!readOnly && (
                <button
                  className={styles.delete}
                  onClick={() => deleteItem(value)}
                  type="button"
                >
                  <IconSolidCircleCross
                    size="15px"
                    fill="neutral-3"
                    colour="white"
                  />
                </button>
              )}
            </span>
          ))}
        {!readOnly && options.length !== selected.length && (
          <>
            <input
              className={styles.transparent}
              type="text"
              name={`${name}-TextInput`}
              placeholder={!selected.length ? placeholder : null}
              onClick={toggleMenu}
              value={text}
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              data-testid={inputTestId}
            />
            {errors?.[name]?.message && <span>{errors?.[name]?.message}</span>}
          </>
        )}
        {!readOnly && selected.length > 0 ? (
          <button
            type="button"
            onClick={clearSelected}
            className={styles['clear-selected-button']}
          >
            <IconSolidCircleCross size="15px" fill="neutral-3" colour="white" />
          </button>
        ) : null}
      </div>
      <div className={listClasses}>
        <ul className={styles.options} data-testid={listTestId}>
          {filteredOptions.map((option, index) => (
            <li className={styles.item} key={keyGenerator(option?.name, index)}>
              <button
                type="button"
                disabled={option?.disabled || false}
                className={styles['list-item-button']}
                onClick={() => addItem(option)}
                data-testid={`${listTestId}-item_${index}`}
              >
                {option?.name}
              </button>
            </li>
          ))}
          {filteredOptions.length === 0 && (
            <li className={styles['no-results']}>No results found</li>
          )}
        </ul>
      </div>
    </div>
  );
};

MultiSelect.propTypes = {
  errors: PropTypes.shape({}),
  inputTestId: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  selectedValues: PropTypes.array,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  listTestId: PropTypes.string,
  readOnly: PropTypes.bool,
  labelTestId: PropTypes.string
};

MultiSelect.defaultProps = {
  errors: {},
  inputTestId: null,
  label: false,
  name: 'name',
  onChange: () => {},
  options: [],
  placeholder: 'Please select',
  selectedValues: [],
  setValue: () => {},
  isRequired: true,
  listTestId: PropTypes.string,
  readOnly: false,
  labelTestId: 'label'
};

export default MultiSelect;
