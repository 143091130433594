/* eslint-disable import/no-cycle */
import { Client } from '../Client';
import Building from '../Entities/Building';
import GenericNamespaceHandler from '../GenericNamespaceHandler';
import ContactTypesNamespace from './Buildings/ContactType';
import DepartmentsNamespace from './Buildings/Departments';
import FloorsNamespace from './Buildings/Floors';
import JobRolesNamespace from './Buildings/JobRoles';
import LocationsNamespace from './Buildings/Locations';
import PrioritiesNamespace from './Buildings/Priorities';
import TravelOptionsNamespace from './Buildings/TravelOptions';

export default class BuildingsNamespace extends GenericNamespaceHandler {
  namespace = 'buildings';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  archiveCall = 'archive';

  unarchiveCall = 'unarchive';

  searchRequiresBuildingId = false;

  responseEntity = Building;

  responseKey = 'building';

  requiredFields = [];

  /** *
   * @param {Client} c - instance of Client
   */
  constructor(c) {
    super(c);
    this.jobRoles = new JobRolesNamespace(c);
    this.floors = new FloorsNamespace(c);
    this.travelOptions = new TravelOptionsNamespace(c);
    this.locations = new LocationsNamespace(c);
    this.priorities = new PrioritiesNamespace(c);
    this.departments = new DepartmentsNamespace(c);
    this.contactTypes = new ContactTypesNamespace(c);
  }

  acceptTermsAndConditions = (buildingId, type) =>
    new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');

      this.client.request(
        this.namespace,
        'accept_terms',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(Boolean(response));
          }
        },
        { id: buildingId, type },
        Client.methods.PUT
      );
    });

  saveTermsAndConditions = (buildingId, type, content) =>
    new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');
      if (!type) throw new Error('building_terms_provide_type');

      this.client.request(
        `${this.namespace}/terms`,
        'save',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { building_id: buildingId, type, content },
        Client.methods.POST
      );
    });

  getRegistrationTerms = (buildingId) =>
    new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');

      this.client.request(
        this.namespace,
        'registration_terms',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { id: buildingId },
        Client.methods.GET
      );
    });

  mine = (id) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'mine',
        (error, response) => {
          const { building, buildings } = response?.data;
          let e = error;

          if (building) resolve(new Building(this.client, building));
          else {
            if (!Array.isArray(buildings) && !e) e = new Error('unknown_error');

            if (e) reject(e);
            else {
              const parsedBuildings = buildings
                .map((entity) => new Building(this.client, entity))
                .sort((a, b) => a.name.localeCompare(b.name));
              resolve(parsedBuildings);
            }
          }
        },
        { id },
        Client.methods.GET
      );
    });

  create = (
    name,
    client_id,
    optional = {
      address1: undefined,
      address2: undefined,
      address3: undefined,
      city: undefined,
      county: undefined,
      postcode: undefined,
      logo_uri: undefined,
      dashboard_image_uri: undefined
    }
  ) => {
    if (!name) throw new Error('provide_name');
    if (!client_id) throw new Error('provide_client_id');

    return super.create({ name, client_id, ...optional });
  };

  update = (
    buildingId,
    optional = {
      name: undefined,
      client_id: undefined,
      address1: undefined,
      address2: undefined,
      address3: undefined,
      city: undefined,
      county: undefined,
      postcode: undefined,
      logo_uri: undefined,
      dashboard_image_uri: undefined
    }
  ) => {
    if (!buildingId) throw new Error('provide_building_id');

    return super.update(buildingId, optional);
  };

  archive = (id) => this.toggleArchived(id);

  unarchive = (id) => this.toggleArchived(id, true);

  toggleArchived = (buildingId, unarchive) =>
    new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');

      this.client.request(
        this.namespace,
        unarchive ? this.unarchiveCall : this.archiveCall,
        (error, { archived }) => {
          if (error) reject(error);
          else resolve(archived);
        },
        { id: buildingId },
        Client.methods.PUT
      );
    });

  dashboard = (buildingId) =>
    new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');

      this.client.request(
        '',
        'dashboard',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { building_id: buildingId },
        Client.methods.GET
      );
    });
}
