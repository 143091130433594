import { sub, differenceInMilliseconds } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { refreshToken } from 'services/api/auth/auth';

export const getCookie = (name) => {
  const cookiestring = RegExp(`${name}=[^;]+`).exec(document.cookie);
  return decodeURIComponent(
    cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : ''
  );
};

export const refreshAuthCookies = () =>
  new Promise((resolve, reject) => {
    let refreshTokenAt = 0;
    const authJwt = getCookie('cureoscity_auth');

    const exp = authJwt ? jwt_decode(authJwt) : 0;
    refreshTokenAt = exp
      ? differenceInMilliseconds(
          sub(new Date(exp * 1000), { seconds: 30 }),
          new Date()
        )
      : 0;

    setTimeout(() => {
      (async () => {
        try {
          const { code, session } = await refreshToken();
          if (code && code === 200) {
            if (session) {
              localStorage.setItem(
                'cureoscity_session',
                JSON.stringify(session)
              );
            }
            return resolve();
          }
          return reject(code);
        } catch (err) {
          return reject(err);
        }
      })();
    }, refreshTokenAt);
  });
