import Client from '../Client';
import Notification from '../Entities/Notification';

export default class NotificationsNamespace {
  namespace = 'notifications/mine';

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    this.client = Client;
  }

  mine() {
    return new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        '',
        (error, { data }) => {
          if (!Array.isArray(data?.notifications) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const notifications = data?.notifications.map(
              (entity) => new Notification(this.client, entity)
            );
            resolve(notifications);
          }
        },
        null,
        Client.methods.GET
      );
    });
  }

  read(id) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('notification_provide_id');

      this.client.request(
        this.namespace,
        'read',
        (error, { data }) => {
          if (error) reject(error);
          else resolve(Boolean(data));
        },
        { id },
        Client.methods.PUT
      );
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('notification_provide_id');

      this.client.request(
        this.namespace,
        'delete',
        (error, { data }) => {
          if (error) reject(error);
          else resolve(data);
        },
        { id },
        Client.methods.DELETE
      );
    });
  }
}
