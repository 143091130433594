import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useBuilding } from '../../context/building-context';
import SelectInput from '../forms/SelectInput/SelectInput';

import styles from './LocationSelect.module.scss';

const LocationSelect = ({
  label,
  register,
  errors,
  name,
  className,
  fullWidth,
  showLabel,
  isRequired,
  onChange,
  buildingId,
  ...props
}) => {
  const { buildingData, buildings } = useBuilding();

  const [locations, setLocations] = useState(buildingData?.locations);

  useEffect(() => {
    if (buildingId && +buildingId !== buildingData?.id) {
      const selectedBuilding = buildings.find(
        ({ id }) => id === parseInt(buildingId, 10)
      );
      setLocations(selectedBuilding?.locations);
    } else {
      setLocations(buildingData?.locations);
    }
  }, [buildingId]);

  return (
    <SelectInput
      name={name}
      className={classnames(styles.select, className)}
      errors={errors}
      register={register}
      values={locations}
      valueKey="id"
      labelKey="name"
      {...{
        label,
        showLabel,
        errors,
        register,
        name,
        fullWidth,
        onChange
      }}
      {...props}
    />
  );
};

LocationSelect.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array,
  name: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  showLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  buildingId: PropTypes.number
};

LocationSelect.defaultProps = {
  label: '',
  register: null,
  errors: null,
  name: '',
  className: null,
  fullWidth: false,
  showLabel: true,
  isRequired: true,
  onChange: null,
  buildingId: null
};

export default LocationSelect;
