import PollTopic from './Topic';

export default class Poll {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIPoll - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      title,
      topic,
      topic_id,
      expires_date_time,
      user_id,
      archived,
      created,
      modified,
      publish_date_time,
      answers,
      participants,
      participant_count,
      user,
      canEdit
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');

    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    this.ISODateTimeToUTCDate = parseISODateTimeAsUTC;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || null;
    this.archived = archived || false;
    this.topic_id = topic_id || null;
    this.topic =
      typeof topic === 'object' ? new PollTopic(Client, topic) : null;
    this.expires_date_time = parseISODateTimeAsUTC(expires_date_time) || null;
    this.publish_date_time = parseISODateTimeAsUTC(publish_date_time) || null;
    this.user_id = user_id || null;
    this.title = title || '';
    this.answers = answers || [];
    this.participants = participants || [];
    this.participant_count = participant_count || 0;
    this.user = user || null;
    this.canEdit = canEdit || false;

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  getStatusLabel() {
    const { archived, publish_date_time, expires_date_time } = this;

    const now = new Date();

    let status = 'live';
    if (publish_date_time > now) status = 'scheduled';
    if (expires_date_time < now) status = 'offline';
    if (archived) status = 'archived';
    return status;
  }
}
