/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconSort = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 20"
    fill="none"
    {...rest}
  >
    <path
      d="M6.47577 9.71429L6.47577 3.2381M6.47577 3.2381L3.23768 6.47619M6.47577 3.2381L9.71387 6.47619"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.52423 9.33332L9.52423 15.8095M9.52423 15.8095L12.7623 12.5714M9.52423 15.8095L6.28613 12.5714"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconSort.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconSort.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconSort);
