import CryptoJS from 'crypto-js';
import { AuthCallbacks } from 'middleware/Client';
import logger from 'services/logger/logger';

import apiInstance from '../apiInstance/apiInstance';

class Credentials extends AuthCallbacks {
  getSession = (callback) => {
    callback(apiInstance().getSession());
  };

  getDevice = (callback) => {
    callback(apiInstance().getDevice());
  };

  updateSession = (session) => {
    apiInstance().updateSession(session);
  };

  handleLogout = () => {
    apiInstance().updateSession(null);
  };

  getJWT = (callback) => {
    const { getSession, getDevice } = apiInstance();
    const [session, device] = [getSession(), getDevice()];

    if (session && device) {
      try {
        // Generate a JWT using session id, refresh token and device secret
        const { id: sessionId, refreshToken } = session;

        const generateJWT = (data, secret) => {
          function base64url(source) {
            // Encode in classical base64
            let encodedSource = CryptoJS.enc.Base64.stringify(source);
            encodedSource = encodedSource
              .replace(/=+$/, '')
              .replace(/\+/g, '-')
              .replace(/\//g, '_');
            return encodedSource;
          }

          const encodedHeader = base64url(
            CryptoJS.enc.Utf8.parse(
              JSON.stringify({ alg: 'HS256', typ: 'JWT' })
            )
          );
          const encodedData = base64url(
            CryptoJS.enc.Utf8.parse(JSON.stringify(data))
          );
          return `${encodedHeader}.${encodedData}.${base64url(
            CryptoJS.HmacSHA256(`${encodedHeader}.${encodedData}`, secret)
          )}`;
        };

        const token = generateJWT({ sessionId, refreshToken }, device.secret);

        callback(token, device.id);
      } catch (e) {
        logger.error(e);
        callback();
      }
    } else {
      callback();
    }
  };
}

export default new Credentials();
