const tomorrow = (() => {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(new Date().getDate() + 1);
  tomorrowDate.setHours(12);
  return tomorrowDate;
})();

const yesterday = (() => {
  const yesterdayDate = new Date();
  yesterdayDate.setDate(new Date().getDate() - 1);
  yesterdayDate.setHours(12);
  return yesterdayDate;
})();

export { tomorrow, yesterday };
