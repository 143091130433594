import Client from './Client';
import EventListener from './Entities/EventListener';

export default class EventListeners {
  events = {};

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    this.client = Client;
  }

  /**
   * Add's an event listener for the specified eventName
   *
   * @param {String} eventName - name of the event to listen to
   * @param {function} callback - callback to run when an event is received, passes Event Name and Data
   * @returns {EventListener}
   */
  add(eventName = '', callback) {
    if (typeof callback !== 'function')
      throw new Error('Listener should be a function');

    if (!this.events[eventName]) this.events[eventName] = [];

    const listener = new EventListener(eventName, callback);
    this.events[eventName].push(listener);

    return listener;
  }

  /**
   * Alias of remove, will remove all listeners for the specified event
   * @param eventName
   */
  removeAll(eventName) {
    this.remove(eventName, null);
  }

  /**
   * Remove a or all listeners
   * @param {String} eventName - Name of the event eg: 'socket/connect' you want to listen for
   * @param {EventListener|null} listener - listen object you want to remove, if this is null all listeners for the event will be removed
   */
  remove(eventName, listener) {
    if (this.events[eventName]) {
      if (listener === null) {
        delete this.events[eventName];
      } else if (typeof listener === 'object') {
        if (listener.constructor.name === 'EventListener') {
          const listeners = this.events[eventName];
          for (const index in listeners) {
            const existingListener = listeners[index];
            if (existingListener === listener) {
              delete this.events[eventName][index];
              break;
            }
          }
        }
      }
    }
  }

  /**
   * Handles an event trigger
   * @param {String} eventName - name of event you
   * @param data - Mixed, will supply event data to the listener
   */
  handleEvent(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((listener) => {
        if (
          typeof listener === 'object' &&
          listener.constructor.name === 'EventListener'
        ) {
          listener.trigger(eventName, data);
        }
      });
    }
  }
}
