/* eslint-disable import/no-cycle */
import Building from '../Building';
import Company from '../Company';
import Floor from './Floor';
import Contact from './Occupiers/Contact';
import OccupierDomain from './Occupiers/OccupierDomain';
import OccupierHours from './Occupiers/OccupierHours';
import OccupierIndustry from './Occupiers/OccupierIndustry';

export default class Occupier {
  constructor(
    Client,
    {
      id,
      name,
      phone,
      email,
      company_id,
      company,
      retailer,
      neighbour,
      contact_job_role,
      description,
      public_phone,
      public_website,
      industry_id,
      industry,
      images,
      building_id,
      building,
      floor_ids,
      floors,
      domains,
      contacts,
      hours,
      archived,
      enabled,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.id = id || 0;
    this.name = name || '';
    this.email = email || '';
    this.phone = phone || '';

    this.company_id = company_id || null;
    this.company = company ? new Company(Client, company) : null;

    this.building_id = building_id || null;
    this.building = building ? new Building(Client, building) : null;

    this.industry_id = industry_id || null;
    this.industry = industry ? new OccupierIndustry(Client, industry) : null;

    this.images = images || [];

    this.retailer = Boolean(retailer);
    this.neighbour = Boolean(neighbour);

    this.contact_job_role = contact_job_role || '';
    this.description = description || '';

    this.public_phone = public_phone || '';
    this.public_website = public_website || '';

    this.floor_ids = Array.isArray(floor_ids) ? floor_ids : [];
    this.floors = Array.isArray(floors)
      ? floors.map((floor) => new Floor(Client, floor))
      : [];
    this.contacts = Array.isArray(contacts)
      ? contacts.map((contact) => new Contact(Client, contact))
      : [];
    this.domains = Array.isArray(domains)
      ? domains.map((domain) => new OccupierDomain(Client, domain))
      : [];
    this.hours = hours ? new OccupierHours(Client, hours) : null;

    this.archived = Boolean(archived);
    this.enabled = Boolean(enabled);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  processEntities({
    floors = false,
    building = false,
    company = false,
    contacts = false,
    domains = false
  }) {
    return new Promise((resolve) => {
      const promises = [];

      if (company) promises.push(this.getCompany());
      if (building) promises.push(this.getBuilding());
      if (floors) promises.push(this.getFloors());
      if (contacts) promises.push(this.getContacts());
      if (domains) promises.push(this.getDomains());

      Promise.all(promises).then(() => resolve(this));
    });
  }

  getCompany() {
    return new Promise((resolve) => resolve(this.company));
  }

  getBuilding() {
    return new Promise((resolve) => resolve(this.building));
  }

  getFloors() {
    return new Promise((resolve) => resolve(this.floors));
  }

  getDomains() {
    return new Promise((resolve) => resolve(this.domains));
  }

  getContacts() {
    return new Promise((resolve) => resolve(this.contacts));
  }

  getStatusLabel() {
    const { archived, enabled } = this;

    let status = 'live';
    if (archived) status = 'archived';
    else if (!enabled) status = 'disabled';
    return status;
  }
}
