import config from 'services/api/config';

const API_GW_KEY = 'cureoscity_apigw_url';

export const getApiUrl = ({
  monolith = false,
  lambda = false,
  urlModifier = ''
} = {}) => {
  const url = (() => {
    if (Object.prototype.hasOwnProperty.call(localStorage, API_GW_KEY)) {
      return `${localStorage.getItem(API_GW_KEY)}`;
    }

    if (config.rest.apiGateway) return config.rest.apiGateway;
  })();

  if (urlModifier) return `${url}/${urlModifier}`;
  if (lambda) return `${url}/api/resource`;
  if (monolith) return `${url}/api/mon`;

  return url;
};
