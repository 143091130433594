/* eslint-disable no-console */

import { datadogLogs } from '@datadog/browser-logs';

const LogLevel = {
  debug: 1,
  log: 2,
  info: 3,
  warn: 4,
  error: 5,
  silent: 6
};
const logLevel =
  LogLevel[(process?.env?.LOG_LEVEL ?? 'error').toLowerCase()] ||
  LogLevel.error;

const logToDataDog = (level, args) => {
  const errToJsonObj = (o) =>
    o instanceof Error
      ? {
          name: o.name,
          message: o.message,
          stack: o.stack,
          ...o
        }
      : o;

  if (args?.length < 1) return;
  try {
    const msg = (() => {
      const str = String(args[0]);
      return str === '[object Object]'
        ? JSON.parse(JSON.stringify(args[0]))
        : str;
    })();
    const context = { context: args.map(errToJsonObj) };
    datadogLogs.logger[level](msg, context);
  } catch (e) {
    datadogLogs.logger[level](`DataDog logging error - ${e.toString()}`);
  }
};

const log = (level, args) => {
  if (LogLevel[level] >= logLevel) {
    console[level](args);
    logToDataDog(level, args);
  }
};

const logger = {
  debug: (...args) => {
    log('debug', args);
  },
  error: (...args) => {
    log('error', args);
  },
  info: (...args) => {
    log('info', args);
  },
  log: (...args) => {
    log('log', args);
  },
  warn: (...args) => {
    log('warn', args);
  }
};

export default logger;
