export default class Theme {
  constructor({
    homepage_image,
    homepage_logo,
    homepage_variant,
    homepage_position,
    logo,
    logo_background,
    favicon_location,
    colours
  }) {
    this.homepage_image = homepage_image || '';
    this.homepage_logo = homepage_logo || '';
    this.homepage_variant = homepage_variant || '';
    this.homepage_position = homepage_position || '';
    this.logo = logo || '';
    this.logo_background = logo_background || '';
    this.favicon_location = favicon_location || '/';

    if (typeof colours === 'string') {
      colours = JSON.parse(colours);
    }
    this.colours = colours;
  }
}
