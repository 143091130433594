/* eslint-disable import/prefer-default-export */
import theme from 'constants/default-theme.json';

import * as Icons from 'components/Icons';

const iconPropValidator = (props, propName, componentName = 'ANONYMOUS') => {
  const { [propName]: iconName } = props;
  const validIcon = Object.keys(Icons).includes(`Icon${iconName}`);

  if (iconName === null || typeof iconName === 'object') return null;

  if (!validIcon) {
    return new Error(
      `Icon "${iconName}" in component: ${componentName} does not exist. Please check icon is in src/components/icon.`
    );
  }

  return null;
};

const themeColourPropValidator = (
  props,
  propName,
  componentName = 'ANONYMOUS'
) => {
  const { [propName]: colourName } = props;
  const validColour = Object.keys(theme.colours).includes(colourName);

  if (colourName === null || colourName === undefined) return null;

  if (!validColour) {
    return new Error(
      `The colour "${colourName}" passed to component: "${componentName}" does not exist in the theme. Please supply a valid theme colour.`
    );
  }

  return null;
};

export { iconPropValidator, themeColourPropValidator };
