import React, { createContext, useContext, useState } from 'react';

const TrackingContext = createContext(null);

const getGaIds = () => {
  if (process.env.CLIENT_NAME?.toLowerCase() === 'savills')
    return ['G-ME6LT58F2T', 'UA-226885284-3'];
  if (process.env.CLIENT_NAME?.toLowerCase() === 'cureoscity')
    return ['G-T6E4BP29EC', 'UA-226885284-2'];
  return [];
};

const TrackingProvider = (props) => {
  const [gaTrackingIds] = useState(getGaIds());

  return (
    <TrackingContext.Provider
      value={{
        gaTrackingIds
      }}
      {...props}
    />
  );
};

const useTracking = () => useContext(TrackingContext);

export { TrackingProvider, useTracking, getGaIds };
