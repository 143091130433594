/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCircleInfo = ({ size, colour, strokeWidth, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1" fill="white">
      <path d="M6.4999 11.7143C9.85305 11.7143 12.5713 9.15591 12.5713 5.99999C12.5713 2.84408 9.85305 0.285706 6.4999 0.285706C3.14674 0.285706 0.428467 2.84408 0.428467 5.99999C0.428467 9.15591 3.14674 11.7143 6.4999 11.7143Z" />
    </mask>
    <path
      d="M5.9999 8.28571C5.9999 8.56185 6.22375 8.78571 6.4999 8.78571C6.77604 8.78571 6.9999 8.56185 6.9999 8.28571H5.9999ZM6.9999 5.0281C6.9999 4.75196 6.77604 4.5281 6.4999 4.5281C6.22375 4.5281 5.9999 4.75196 5.9999 5.0281H6.9999ZM6.9999 8.28571V7.14285H5.9999V8.28571H6.9999ZM6.9999 7.14285V5.0281H5.9999V7.14285H6.9999ZM11.5713 5.99999C11.5713 8.54737 9.35875 10.7143 6.4999 10.7143V12.7143C10.3474 12.7143 13.5713 9.76444 13.5713 5.99999H11.5713ZM6.4999 10.7143C3.64104 10.7143 1.42847 8.54737 1.42847 5.99999H-0.571533C-0.571533 9.76444 2.65243 12.7143 6.4999 12.7143V10.7143ZM1.42847 5.99999C1.42847 3.45261 3.64104 1.28571 6.4999 1.28571V-0.714294C2.65243 -0.714294 -0.571533 2.23555 -0.571533 5.99999H1.42847ZM6.4999 1.28571C9.35875 1.28571 11.5713 3.45261 11.5713 5.99999H13.5713C13.5713 2.23555 10.3474 -0.714294 6.4999 -0.714294V1.28571Z"
      fill={colour}
      mask="url(#path-1-inside-1)"
    />
    <mask id="path-3-inside-2" fill="white">
      <ellipse cx="6.4999" cy="3.40307" rx="0.626116" ry="0.589286" />
    </mask>
    <ellipse
      cx="6.4999"
      cy="3.40307"
      rx="0.626116"
      ry="0.589286"
      fill={colour}
    />
    <path
      d="M6.12601 3.40307C6.12601 3.11999 6.35138 2.99235 6.4999 2.99235V4.99235C7.34 4.99235 8.12601 4.33705 8.12601 3.40307H6.12601ZM6.4999 2.99235C6.64841 2.99235 6.87378 3.11999 6.87378 3.40307H4.87378C4.87378 4.33705 5.6598 4.99235 6.4999 4.99235V2.99235ZM6.87378 3.40307C6.87378 3.68615 6.64841 3.81378 6.4999 3.81378V1.81378C5.6598 1.81378 4.87378 2.46908 4.87378 3.40307H6.87378ZM6.4999 3.81378C6.35138 3.81378 6.12601 3.68615 6.12601 3.40307H8.12601C8.12601 2.46908 7.34 1.81378 6.4999 1.81378V3.81378Z"
      fill={colour}
      mask="url(#path-3-inside-2)"
    />
  </svg>
);

IconCircleInfo.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string,
  strokeWidth: PropTypes.number
};

IconCircleInfo.defaultProps = {
  size: '22px',
  colour: '#fff',
  strokeWidth: 1.5
};

export default memo(IconCircleInfo);
