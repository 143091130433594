/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconVehicle = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 81 81"
    fill="none"
    {...rest}
  >
    <path
      fill="currentColor"
      d="m28.8 37.1c.7-1.5 1.4-3.1 2.3-4.9.5-.8 1-1.6 2-1.7h14.9c1 .1 1.5.8 2 1.7.9 1.8 1.5 3.4 2.3 4.9zm4.2-9c-1.6 0-2.6.6-3.9 2.4l-2.2 5h-3.4c-.5 0-1.5 2.7 2 2.8l-1.2 2.9v10.9c0 .8.6 1.4 1.4 1.4h4.6c.8 0 1.3-.6 1.3-1.4v-3h17.4v3c0 .8.6 1.4 1.4 1.4h4.6c.8 0 1.3-.6 1.3-1.4 0-3.6 0-7.3 0-10.9l-1.2-2.9c3.6-.1 2.6-2.8 2.1-2.8h-3.4l-2.2-5c-1.3-1.9-2.3-2.4-3.9-2.4zm18.5 13.5c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2c0-1.3-.9-2.2-2.2-2.2zm-22 0c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2-2.2-1-2.2-2.2c-.1-1.3.9-2.2 2.2-2.2z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
);

IconVehicle.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconVehicle.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconVehicle);
