import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import VisuallyHidden from 'components/accessibility/VisuallyHidden';
import * as Icons from 'components/Icons';
import PropTypes from 'prop-types';
import {
  iconPropValidator,
  themeColourPropValidator
} from 'utils/propValidators';

import styles from './Input.module.scss';

/* eslint-disable react/prop-types */
const renderIcon = ({
  iconName,
  clickHandler,
  toolTip,
  colour,
  dataTestId
}) => {
  const { [`Icon${iconName}`]: Icon } = Icons;

  if (!Icon) return null;

  if (clickHandler) {
    return (
      <button
        type="button"
        className={styles.button}
        onClick={clickHandler}
        title={toolTip}
        data-testid={dataTestId}
      >
        <Icon colour={colour || 'neutral-4'} aria-hidden="true" />
        <VisuallyHidden>{toolTip}</VisuallyHidden>
      </button>
    );
  }
  return (
    <span className={styles.icon} aria-hidden="true" key={`icon-${iconName}`}>
      <Icon colour={colour || 'neutral-4'} />
    </span>
  );
};
/* eslint-enable */

/* eslint-disable react/jsx-props-no-spreading */
const Input = (props) => {
  const {
    alwaysAddDefaultValue,
    appearance,
    autoComplete,
    className,
    customSubtext,
    dataTestId,
    defaultValue,
    disabled,
    error,
    forForm,
    fullWidth,
    hideLabel,
    hideValidation,
    hideValidationIcon,
    inputRef,
    isDirty,
    isRequired,
    label,
    max,
    min,
    name,
    onChange,
    onKeyDown,
    onKeyUp,
    placeholder,
    prefixIcon,
    readOnly,
    row,
    step,
    subText,
    suffixIcon,
    type,
    validated,
    value,
    onFocusChange
  } = props;

  const [hadFocus, setHadFocus] = useState(false);
  const [, setHasFocus] = useState(false);
  const [wasSlow, setWasSlow] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [suffixCount, setSuffixCount] = useState('');

  const inputId = `${name}-id`;
  const labelId = `${name}-label`;
  const isTextarea = row !== 1;

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const inputClasses = classnames(styles.input, {
    [styles.validated]: !hideValidation && ((!error && isDirty) || validated),
    [styles.invalidated]: error && isDirty,
    [styles['prefix-icon-padding']]: prefixIcon,
    [styles['suffix-icon-padding-1']]: suffixIcon,
    [styles['suffix-icon-padding-2']]: error || isDirty || validated,
    [styles.textarea]: isTextarea,
    [styles.inputWithMax]: max
  });

  const renderLabel = () => {
    if (hideLabel) {
      return (
        <VisuallyHidden>
          <label id={labelId} className={styles.label} htmlFor={inputId}>
            {label}
            {isRequired && !label?.endsWith('*') ? '*' : ''}
          </label>
        </VisuallyHidden>
      );
    }
    return (
      <label id={labelId} className={styles.label} htmlFor={inputId}>
        {label}
        {isRequired && !label?.endsWith('*') ? '*' : ''}
      </label>
    );
  };

  const onBlur = () => {
    setHadFocus(true);
    if (onFocusChange) {
      onFocusChange(false);
    }
  };
  const onFocus = () => {
    setHasFocus(true);
    if (onFocusChange) {
      onFocusChange(true);
    }
  };

  const hasValidValue = value && value.length >= min;
  const hasValue = value;
  const showMinChars =
    (min && type === 'text' && hadFocus && !hasValidValue) ||
    (hasValue && !hasValidValue && wasSlow);

  const renderSubtext = () => (
    <span id="subtext" className={styles.subtext} aria-live="assertive">
      {error && <span className={styles.error}>{error.message}</span>}
      {subText && <span className={styles.message}>{subText}</span>}
      {showMinChars && (
        <span className={styles.message}>
          Please enter at least {min} characters
        </span>
      )}
    </span>
  );

  const wrapperClasses = classnames(styles[appearance], {
    [styles['for-form']]: forForm,
    [className]: className,
    [styles['read-only']]: readOnly,
    [styles['full-width']]: fullWidth,
    [styles.hidden]: type === 'hidden'
  });

  const getTextWidth = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = getComputedStyle(document.body).font;

    return context.measureText(text).width;
  };

  const padRight = () =>
    getTextWidth(value?.length) + getTextWidth('/') + getTextWidth(max);

  useEffect(() => {
    let timer;
    if (value && type === 'text') {
      timer = window.setInterval(() => {
        setWasSlow(true);
        window.clearInterval(timer);
      }, 750);
    }
    return () => {
      window.clearInterval(timer);
    };
  }, [value]);

  useEffect(() => {
    if (inputValue && inputValue.length) {
      setSuffixCount(`${inputValue.length} / ${max}`);
    } else if (value && value.length) {
      setSuffixCount(`${value.length} / ${max}`);
    } else if (defaultValue && defaultValue.length) {
      setSuffixCount(`${defaultValue.length} / ${max}`);
    } else {
      setSuffixCount(`0 / ${max}`);
    }
  }, [max, inputValue, value]);

  useEffect(() => {
    if (!readOnly) {
      if (value && value.length) {
        setSuffixCount(`${value.length} / ${max}`);
      } else {
        setSuffixCount(`0 / ${max}`);
      }
    }
  }, [readOnly]);

  return (
    <div className={wrapperClasses}>
      {renderLabel()}
      <div className={styles['input-wrapper']}>
        <span className={styles.prefix}>
          {prefixIcon && renderIcon(prefixIcon)}
        </span>
        {!isTextarea && (
          <input
            data-testid={dataTestId || inputId}
            aria-labelledby={labelId}
            aria-invalid="true"
            aria-describedby="subtext"
            className={inputClasses}
            id={inputId}
            onChange={handleChange}
            ref={inputRef}
            {...{
              autoComplete,
              name,
              type,
              placeholder
            }}
            {...((alwaysAddDefaultValue || (!onChange && !onKeyUp)) && {
              defaultValue
            })}
            {...((onChange || onKeyUp) && { value })}
            {...(onChange && { onChange })}
            {...(onKeyUp && { onKeyUp })}
            onBlur={onBlur}
            onFocus={onFocus}
            {...(readOnly && { readOnly: 'readonly' })}
            onKeyDown={onKeyDown}
            {...(min && min)}
            disabled={disabled}
            {...(max && type !== 'time' && { maxLength: max })}
            {...(max &&
              type !== 'time' && { style: { paddingRight: padRight() } })}
            {...(max && type === 'time' && { max })}
            {...(step && { step })}
          />
        )}
        {isTextarea && (
          <textarea
            data-testid={inputId}
            aria-labelledby={labelId}
            aria-invalid={error && 'true'}
            aria-describedby="subtext"
            className={inputClasses}
            id={inputId}
            ref={inputRef}
            onChange={handleChange}
            {...{
              autoComplete,
              name,
              type,
              placeholder
            }}
            {...((alwaysAddDefaultValue || (!onChange && !onKeyUp)) && {
              defaultValue
            })}
            {...((onChange || onKeyUp) && { value })}
            {...(onChange && { onChange })}
            {...(onKeyUp && { onKeyUp })}
            {...(readOnly && { readOnly: 'readonly' })}
            row={row}
            {...(max && { maxLength: max })}
            onKeyDown={onKeyDown}
            style={{
              minHeight: row * 18,
              ...(max && { paddingRight: padRight() })
            }}
          />
        )}
        <span className={styles.suffix}>
          {max !== 0 && type !== 'time' && (
            <span
              className={classnames(styles.suffixCount, {
                [styles.textArea]: isTextarea
              })}
              data-testid="suffix-count"
            >
              {suffixCount}
            </span>
          )}
          {((suffixIcon && !error && !isDirty) || suffixIcon?.clickHandler) &&
            renderIcon(suffixIcon)}
          {!hideValidation &&
            !hideValidationIcon &&
            !error &&
            isDirty &&
            renderIcon({
              iconName: 'Tick',
              colour: 'success'
            })}
          {!hideValidation &&
            !hideValidationIcon &&
            error &&
            renderIcon({
              iconName: 'Cross',
              colour: 'error'
            })}
        </span>
      </div>
      {customSubtext || ((error || subText || showMinChars) && renderSubtext())}
    </div>
  );
};
Input.propTypes = {
  appearance: PropTypes.oneOf([
    'default',
    'search',
    'form',
    'comment',
    'hidden'
  ]),
  /** HTML autocomplete attribute */
  autoComplete: PropTypes.oneOf([
    'off',
    'on',
    'name',
    'honorific-prefix',
    'given-name',
    'additional-name',
    'family-name',
    'honorific-suffix',
    'nickname',
    'email',
    'username',
    'new-password',
    'current-password',
    'one-time-code',
    'organization-title',
    'organization',
    'street-address',
    'address-line1',
    'address-line2',
    'address-line3',
    'address-level1',
    'address-level2',
    'address-level3',
    'address-level4',
    'country',
    'country-name',
    'postal-code',
    'cc-name',
    'cc-given-name',
    'cc-additional-name',
    'cc-family-name',
    'cc-number',
    'cc-exp',
    'cc-exp-month',
    'cc-exp-year',
    'cc-csc',
    'cc-type',
    'transaction-currency',
    'transaction-amount',
    'language',
    'bday',
    'bday-day',
    'bday-month',
    'bday-year',
    'sex',
    'tel',
    'tel-country-code',
    'tel-area-code',
    'tel-local',
    'tel-extension',
    'impp',
    'url',
    'photo'
  ]),
  /** Optionaly provide default value */
  defaultValue: PropTypes.string,
  /** Error object */
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  /** Optional reference to DOM element */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  /** Label displayed above input */
  label: PropTypes.string,
  /** Name of field */
  name: PropTypes.string.isRequired,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Oprional supporting text that appears beneath input */
  subText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  isDirty: PropTypes.bool,
  /** HTML type attribute */
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'comment',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week'
  ]),
  hideValidationIcon: PropTypes.bool,
  hideValidation: PropTypes.bool,
  alwaysAddDefaultValue: PropTypes.bool,
  /** Alternative way to set validated state */
  validated: PropTypes.bool,
  /** Prefix icon configuration object */
  suffixIcon: PropTypes.shape({
    /** Name of icon. Must match one of the exisiting icons exported in `/components/icons` */
    iconName: iconPropValidator,
    /** Function to call when prefix icon is clicked.
     * Causes rendered icon to be wrapped in a button. */
    clickHandler: PropTypes.func,
    /** If clickHandler supplied then a tooltip is required */
    toolTip: PropTypes.string,
    colour: themeColourPropValidator,
    dataTestId: PropTypes.string
  }),
  /** Prefix icon configuration object */
  prefixIcon: PropTypes.shape({
    /** Name of icon. Must match one of the exisiting icons exported in `/components/icons` */
    iconName: iconPropValidator,
    /** Function to call when prefix icon is clicked.
     * Causes rendered icon to be wrapped in a button. */
    clickHandler: PropTypes.func,
    /** If clickHandler supplied then a tooltip is required */
    toolTip: PropTypes.string
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  customSubtext: PropTypes.node,
  onFocusChange: PropTypes.func,
  hideLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  row: PropTypes.number,
  forForm: PropTypes.bool,
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataTestId: PropTypes.string,
  step: PropTypes.string
};

Input.defaultProps = {
  appearance: 'default',
  alwaysAddDefaultValue: false,
  autoComplete: 'on',
  defaultValue: null,
  error: null,
  inputRef: null,
  subText: '',
  type: 'text',
  hideValidationIcon: false,
  onFocusChange: () => null,
  onKeyDown: () => null,
  disabled: false,
  hideValidation: false,
  placeholder: null,
  prefixIcon: null,
  suffixIcon: null,
  validated: null,
  value: undefined,
  onChange: null,
  onKeyUp: null,
  isDirty: false,
  customSubtext: null,
  hideLabel: false,
  readOnly: false,
  fullWidth: false,
  className: '',
  row: 1,
  forForm: false,
  label: '',
  min: false,
  max: 0,
  step: null,
  dataTestId: null,
  isRequired: false
};

export default Input;
