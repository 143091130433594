/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconNoCode = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 113 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <clipPath id="a">
      <path d="m0 0h113v115h-113z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path
        d="m9.45953 115h94.10347c4.718-.022 8.699-3.492 9.325-8.141.09-.178.134-.356.134-.556v-61.1927c0-3.6479-1.744-7.0512-4.673-9.2311l-9.6166-7.0958v-4.2263c0-4.9603-4.0477-8.9864-9.057-9.0087h-8.9004l-18.5613-13.67985c-3.3768-2.513537-8.0059-2.513537-11.3603 0l-18.5613 13.67985h-9.4148c-4.9869 0-9.0346 4.0261-9.057 9.0087v4.6045l-9.12408 6.7176c-2.95191 2.1576-4.69622 5.5832-4.69622 9.2311v61.1927c0 .2.0447259.378.134178.556.6038 4.649 4.584402 8.119 9.325352 8.141zm89.25057-82.9022 8.0279 5.9168c2.259 1.6683 3.578 4.293 3.601 7.0957v1.3569l-11.6066 9.3201v-23.6895zm-46.2913-28.07161c2.4152-1.80174 5.7472-1.80174 8.1848 0l15.6541 11.52221h-39.493zm-35.9373 20.53091c0-3.4922 2.8625-6.3172 6.3734-6.3394h66.8205c3.511 0 6.3511 2.8472 6.3735 6.3394v33.3656l-33.1643 26.559c-3.7346 3.0029-9.057 3.0029-12.7916 0l-33.6115-26.9594zm-13.79795 20.5532c0-2.8027 1.34178-5.4274 3.57808-7.0957l7.51397-5.5387v22.8888l-11.11441-8.8975zm0 60.4807v-55.6983l11.60635 9.2979v.0222l34.1483 27.3598c4.7185 3.7814 11.4274 3.7814 16.146 0l33.6115-26.9594 12.1203-9.6982v56.388h-.067c-.357 3.425-3.242 6.028-6.686 6.028h-94.10347c-3.4439 0-6.35108-2.625-6.68652-6.028h-.08946z"
        fill="currentColor"
      />
      <g fill="var(--secondary-1)">
        <path d="m24.1797 44.8555c0-.7594.6156-1.375 1.375-1.375h10.195c.7594 0 1.375.6156 1.375 1.375s-.6156 1.375-1.375 1.375h-10.195c-.7594 0-1.375-.6156-1.375-1.375z" />
        <path d="m56.8828 44.8555c0-.7594.6156-1.375 1.375-1.375h10.1951c.7594 0 1.375.6156 1.375 1.375s-.6156 1.375-1.375 1.375h-10.1951c-.7594 0-1.375-.6156-1.375-1.375z" />
        <path d="m40.5312 44.8555c0-.7594.6157-1.375 1.375-1.375h10.1951c.7594 0 1.375.6156 1.375 1.375s-.6156 1.375-1.375 1.375h-10.1951c-.7593 0-1.375-.6156-1.375-1.375z" />
        <path d="m73.2344 44.8555c0-.7594.6156-1.375 1.375-1.375h10.195c.7594 0 1.375.6156 1.375 1.375s-.6156 1.375-1.375 1.375h-10.195c-.7594 0-1.375-.6156-1.375-1.375z" />
      </g>
    </g>
  </svg>
);

IconNoCode.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconNoCode.defaultProps = {
  size: '100%',
  colour: 'primary'
};

export default memo(IconNoCode);
