/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

const IconMagnifyingGlassLess = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.51559 3.60888C6.25367 3.60888 3.60937 6.25319 3.60937 9.51511C3.60937 12.777 6.25367 15.4213 9.51559 15.4213C12.7775 15.4213 15.4218 12.777 15.4218 9.51511C15.4218 6.25319 12.7775 3.60888 9.51559 3.60888ZM1.64062 9.51511C1.64062 5.16588 5.16637 1.64014 9.51559 1.64014C13.8648 1.64014 17.3906 5.16588 17.3906 9.51511C17.3906 13.8643 13.8648 17.3901 9.51559 17.3901C5.16637 17.3901 1.64062 13.8643 1.64062 9.51511Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4062 9.51535C16.4062 13.3209 13.3212 16.406 9.5156 16.406C5.71003 16.406 2.625 13.3209 2.625 9.51535C2.625 5.70978 5.71003 2.62476 9.5156 2.62476C13.3212 2.62476 16.4062 5.70978 16.4062 9.51535ZM6.8906 8.53131C6.34695 8.53131 5.90623 8.97203 5.90623 9.51568C5.90623 10.0593 6.34695 10.5001 6.8906 10.5001H12.1406C12.6842 10.5001 13.125 10.0593 13.125 9.51568C13.125 8.97203 12.6842 8.53131 12.1406 8.53131H6.8906Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6916 13.6919C14.0761 13.3075 14.6993 13.3075 15.0838 13.6919L19.0705 17.6787C19.4549 18.0631 19.4549 18.6864 19.0704 19.0708C18.686 19.4552 18.0627 19.4552 17.6783 19.0708L13.6916 15.084C13.3072 14.6996 13.3072 14.0763 13.6916 13.6919Z"
      fill="white"
    />
  </svg>
);

IconMagnifyingGlassLess.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconMagnifyingGlassLess.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default IconMagnifyingGlassLess;
