import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import SystemNotifications from 'components/SystemNotifications';
import AppProviders from 'context';
import { getGaIds } from 'context/tracking-context';
import AppEntryPoint from 'routes/AppEntryPoint';
import LoadingScreen from 'screens/LoadingScreen';
import hotjar from 'scripts/hotjar';

import './styles/index.scss';
import { ddRumInit } from './scripts/dd-rum';
import { gaScript, gaInit } from './scripts/ga';

const Head = () => {
  const gaTrackingIds = getGaIds();
  return (
    <>
      {gaTrackingIds.map((gaId) => (
        <Helmet>
          <script async src={gaScript(gaId)} />
          <script>{`${gaInit(gaId)}`}</script>
        </Helmet>
      ))}
      <Helmet>
        <script>{`${hotjar()}`}</script>
        <script>{`${ddRumInit()}`}</script>
      </Helmet>
    </>
  );
};

const App = () => (
  <>
    <React.StrictMode>
      <Head />
      <AppProviders>
        <SystemNotifications />
        <Suspense fallback={<LoadingScreen heading="Loading..." />}>
          <AppEntryPoint />
        </Suspense>
      </AppProviders>
    </React.StrictMode>
  </>
);

ReactDOM.render(<App />, document.getElementById('root'));
