import Client from '../../Client';

export default class OccupierIndustry {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIDepartment - containing the server side object
   */
  constructor(Client, { id, name, seq, enabled, created, modified }) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.name = name || '';
    this.seq = seq || 0;
    this.enabled = Boolean(enabled);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
