import logger from 'services/logger/logger';

export default class EventListener {
  constructor(name = null, callback) {
    this.id = [...Array(64)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join('');
    this.name = name;
    this.callback = callback;
  }

  trigger(event, data) {
    try {
      if (typeof this.callback === 'function') this.callback(event, data);
    } catch (e) {
      logger.debug('Event Listener Failed', event, e);
    }
  }
}
