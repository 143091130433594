import logger from 'services/logger/logger';

import Client from './Client';
import APIError from './Entities/APIError';

export default class GenericNamespaceHandler {
  namespace = '';

  searchCall = '';

  createCall = '';

  updateCall = '';

  updateMultipleCall = '';

  deleteCall = '';

  searchRequiresBuildingId = false;

  responseEntity = () => {};

  responseKey = 'type';

  cacheSearchResults = false;

  requiredFields = [];

  /** *
   * @param {Client} client - instance of client
   */
  constructor(Client) {
    this.client = Client;
  }

  isValidString(val, minLength, maxLength) {
    if (!minLength) minLength = 0;
    if (!maxLength) maxLength = Number.MAX_SAFE_INTEGER;
    return (
      typeof val === 'string' &&
      val.length >= minLength &&
      val.length <= maxLength
    );
  }

  isValidInteger(val) {
    if (typeof val === 'string') val = parseInt(val, 10);
    return Number.isSafeInteger(val) && val;
  }

  isValidBool(val) {
    return this.isTrue(val) !== this.isFalse(val);
  }

  isTrue(val) {
    return val === '1' || val === 1 || val === true || val === 'true';
  }

  isFalse(val) {
    return val === '0' || val === 0 || val === false || val === 'false';
  }

  queryFeed(prismaQuery, method = Client.methods.POST, path = 'feed') {
    return new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        path,
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            resolve(response);
          }
        },
        { ...prismaQuery },
        method
      );
    });
  }

  search(criteria = {}, page = 1, orderBy, orderDirection, method) {
    return new Promise((resolve, reject) => {
      if (
        this.searchRequiresBuildingId &&
        !criteria.hasOwnProperty('building_id') &&
        !criteria.hasOwnProperty('id')
      ) {
        throw this.client.translateResponseError(
          new Error('provide_building_id')
        );
      }

      if (typeof criteria !== 'object') criteria = {};

      const { searchCache } = this.client;
      const requestKey = [this.namespace, this.searchCall].join('/');
      const searchCriteriaKey = JSON.stringify({
        criteria,
        page,
        orderBy,
        orderDirection
      });

      if (this.cacheSearchResults) {
        if (Object.hasOwnProperty.call(searchCache, requestKey)) {
          if (
            Object.hasOwnProperty.call(
              searchCache[requestKey],
              searchCriteriaKey
            )
          ) {
            const cacheData = searchCache[requestKey][searchCriteriaKey];
            if (cacheData) {
              const { expiry, ret } = cacheData;
              logger.debug(cacheData);
              if (expiry.getTime() >= new Date().getTime()) {
                return resolve(ret);
              }
                delete searchCache[requestKey][searchCriteriaKey];

            }
          }
        }
      }

      this.client.request(
        this.namespace,
        this.searchCall,
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            const { data } = response;
            if (data.hasOwnProperty(this.responseKey)) {
              resolve(
                new this.responseEntity(this.client, data[this.responseKey])
              );
            } else {
              let { page, pages, records } = data;
              if (!records) {
                resolve(
                  new this.responseEntity(
                    this.client,
                    Object.values(data).pop()
                  )
                );
              } else {
                let results = [];
                if (!page) page = 1;
                if (!pages) pages = 1;

                if (Array.isArray(records))
                  results = records.map(
                    (entity) => new this.responseEntity(this.client, entity)
                  );
                if (criteria?.id) resolve(results.pop());
                else {
                  const ret = { results, page, pages };
                  if (this.cacheSearchResults) {
                    if (!Object.hasOwnProperty.call(searchCache, requestKey))
                      searchCache[requestKey] = {};
                    const expiry = new Date();
                    expiry.setTime(expiry.getTime() + 5 * 1000 * 60);
                    searchCache[requestKey][searchCriteriaKey] = {
                      ret,
                      expiry
                    };
                  }
                  resolve(ret);
                }
              }
            }
          }
        },
        { page, orderBy, orderDirection, ...criteria },
        method || Client.methods.GET
      );
    });
  }

  /**
   * Get item, you must be logged in to perform this action
   * @param {int} id - id of the type you want to retrieve
   * @return {Promise} provides the entity requested
   */
  get(id = 0) {
    return new Promise((resolve, reject) => {
      if (!id)
        throw this.client.translateResponseError(new Error('provide_id'));

      this.search({ id })
        .then((entity) => {
          resolve(entity);
        })
        .catch((error) => reject(error));
    });
  }

  create(data) {
    return this.save(this.createCall, null, data);
  }

  update(id, data) {
    return this.save(this.updateCall, id, data);
  }

  updateMultiple(data) {
    return this.save(this.updateMultipleCall, null, data);
  }

  validatePayload(data, isUpdate) {
    const errors = {};

    this.requiredFields.forEach((field) => {
      const {
        param,
        validation,
        requiredForCreate,
        requiredForUpdate,
        error
      } = field;
      const value = data[param];

      if (!data.hasOwnProperty(param) || !data[param]) {
        if (
          (isUpdate && requiredForUpdate) ||
          (!isUpdate && requiredForCreate)
        ) {
          errors[param] = this.client.translateResponseError(
            new APIError(param, error)
          );
        }
      }

      if (validation && !errors.hasOwnProperty(param)) {
        if (!validation.error) validation.error = error;
        let valid = false;

        switch (validation.type) {
          case 'bool':
            if (this.isValidBool(value)) valid = true;
            break;
          case 'int':
            if (this.isValidInteger(value)) valid = true;
            break;
          case 'string':
            const { min, max } = validation;
            if (this.isValidString(value, min, max)) valid = true;
            break;
          case 'password':
            if (this.isValidString(value, 5)) valid = true;
            break;
          case 'email':
            if (this.isValidString(value, 2) && /[@]/.test(value)) valid = true;
            break;
          case 'object':
            if (value !== null && typeof value === 'object') {
              const instanceName = validation?.instanceOf?.name;
              if (!instanceName || instanceName === value.constructor.name)
                valid = true;
            }
            break;
          default:
            valid = true;
        }

        if (!valid) {
          if (
            (isUpdate && requiredForUpdate) ||
            (!isUpdate && requiredForCreate)
          ) {
            errors[param] = this.client.translateResponseError(
              new APIError(param, validation.error)
            );
          }
        }
      }
    });

    if (Object.values(errors).length) throw Object.values(errors);
  }

  save(type = 'update', id, data = {}, useCustomType = false) {
    if (!data || typeof data !== 'object') data = {};

    return new Promise((resolve, reject) => {
      if (!['create', 'update', 'updateMultiple'].includes(type))
        type = 'update';

      const isUpdate =
        type === 'update' ||
        type === 'updateMultiple' ||
        (id !== null && id !== undefined);
      const errors = [];

      let requestData = null;

      if (type === 'updateMultiple') {
        requestData = [];
        for (const x in data) {
          const entity = data[x];
          try {
            if (!entity.id)
              throw this.client.translateResponseError(new Error('invalid_id'));
            this.validatePayload(entity, isUpdate);
            requestData.push(entity);
          } catch (e) {
            logger.debug(e);
            errors.push(e);
          }
        }
      } else {
        if (isUpdate && !id)
          throw this.client.translateResponseError(new Error('invalid_id'));

        this.validatePayload(data, isUpdate);
        requestData = data;

        if (isUpdate) requestData.id = id;
      }

      this.client.request(
        this.namespace,
        type,
        (error, response) => {
          if (error) {
            reject(error);
          } else {
            const { data } = response;

            let entity = null;
            if (type === 'updateMultiple') {
              if (data.hasOwnProperty('records')) {
                entity = data.records.map(
                  (entity) => new this.responseEntity(this.client, entity)
                );
              }
            } else if (data.hasOwnProperty(this.responseKey))
              entity = new this.responseEntity(
                this.client,
                data[this.responseKey]
              );

            logger.log(entity);

            resolve(entity);
          }
        },
        requestData,
        isUpdate ? Client.methods.PUT : Client.methods.POST
      );
    });
  }

  /**
   * Method to delete a type
   *
   * Example: delete(1, (error) => logger.log(error));
   *
   * @param {int} id - VisitorTypeId of the type to delete
   * @param {function(Error)} callback - callback for the response
   *
   * @throws Error
   */
  delete(id, callback) {
    return new Promise((resolve, reject) => {
      if ((Array.isArray(id) && !id.length) || (!Array.isArray(id) && !id))
        throw this.client.translateResponseError(new Error('provide_id'));

      this.client.request(
        this.namespace,
        this.deleteCall,
        (error) => {
          if (error) reject(error);
          else {
            resolve(true);
          }
        },
        { id },
        Client.methods.DELETE
      );
    });
  }
}
