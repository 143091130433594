import React from 'react';

import { motion } from 'framer-motion';

const containerStyle = {
  position: 'relative',
  width: '0.8rem',
  height: '3rem',
  boxSizing: 'border-box'
};

const IconUploadCircle = ({
  percents = 100,
  counter = true,
  stroke = '#e9e9e9',
  emptyStroke = '#fff',
  emptyStrokeOpacity = 0.25,
  duration = 0.5,
  delay = 0,
  size = 17,
  strokeWidth = 6,
  caption
}) => {
  const radius = 7;
  const circumference = Math.ceil(2 * Math.PI * radius);
  const fillPercents = Math.abs(
    Math.ceil((circumference / 100) * (percents - 100))
  );

  const transition = {
    duration,
    delay,
    ease: 'easeIn'
  };

  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition
    },
    show: {
      strokeDashoffset: fillPercents,
      transition
    }
  };

  return (
    <div style={containerStyle}>
      <svg
        viewBox="0 0 17 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
      >
        <circle
          cx="8.5"
          cy="8.5"
          r={2}
          className="circle"
          strokeWidth={10}
          stroke={emptyStroke}
          strokeOpacity={emptyStrokeOpacity}
          fill="transparent"
        />
      </svg>
      <svg
        viewBox="0 0 17 17"
        width={size}
        height={size}
        style={{
          position: 'absolute',
          top: 0,
          transform: 'rotate(-90deg)',
          overflow: 'visible'
        }}
      >
        <motion.circle
          cx="8.5"
          cy="8.5"
          r={radius}
          strokeWidth={strokeWidth}
          stroke={stroke}
          fill="transparent"
          strokeDashoffset={fillPercents}
          strokeDasharray={circumference}
          variants={variants}
          initial="hidden"
          animate="show"
        />
      </svg>
    </div>
  );
};

export default IconUploadCircle;
