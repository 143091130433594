import React, { useState } from 'react';

import PropTypes from 'prop-types';

import styles from './HideableGroup.module.scss';

const HideableGroup = ({ children, label }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className={styles.label} onClick={() => setIsOpen(!isOpen)}>
        <label className={styles.icon}>{isOpen ? '^' : '˅'}</label>
        <label>{label}</label>
      </div>
      <div
        className={
          isOpen
            ? styles['children-container-open']
            : styles['children-container']
        }
      >
        {children}
      </div>
    </div>
  );
};

HideableGroup.propTypes = {
  children: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired
};

export default HideableGroup;
