import Client from '../Client';
import Delivery from '../Entities/Deliveries/Delivery';
import GenericNamespaceHandler from '../GenericNamespaceHandler';
import Bays from './Deliveries/Bays';

export default class DeliveriesNamespace extends GenericNamespaceHandler {
  namespace = 'deliveries';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = Delivery;

  responseKey = 'delivery';

  requiredFields = [];

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    super(Client);
    this.bays = new Bays(Client);
  }

  searchByBuildingId = async (buildingId, query = {}) => {
    const {
      skip = 0,
      take = 10,
      orderBy = [],
      where = {},
      include = {
        company: true
      }
    } = query;

    const response = await this.queryFeed({
      building_id: buildingId,
      skip: skip || 0,
      take: take || 10,
      ...(Object.keys(include).length && { include }),
      ...(Object.keys(where).length && { where }),
      ...(orderBy.length && { orderBy })
    });
    const records = response.data.records.map(
      (entity) => new this.responseEntity(this.client, entity)
    );
    return {
      ...response.data,
      records
    };
  };

  create(
    type = 'scheduled',
    buildingId = null,
    floorId = null,
    companyId = null,
    deliveryFor = '',
    bayId = null,
    description = '',
    date = null,
    time = '',
    duration = 0,
    creatorName = '',
    haulierName = '',
    vehicleReg = '',
    vehicleRegUnknown = false,
    additionalDetails = '',
    recurrence_frequency = '',
    recurrence_end_type = '',
    recurrence_end_after_elapsed_occurrences = null,
    recurrence_end_date = null,
    excluded_dates = [],
    deleted_occurrences = []
  ) {
    const data = {
      type,
      building_id: buildingId,
      floor_id: floorId,
      company_id: companyId,
      bay_id: bayId,
      description,
      date,
      time,
      duration,
      delivery_for: deliveryFor,
      creator_name: creatorName,
      haulier_name: haulierName,
      vehicle_reg_no: vehicleReg,
      vehicle_reg_unknown: vehicleRegUnknown,
      additional_details: additionalDetails,
      recurrence_end_type,
      recurrence_frequency,
      recurrence_end_after_elapsed_occurrences,
      recurrence_end_date
    };

    return super.create(data);
  }

  update(
    id = null,
    optional = {
      department_id: undefined,
      floor_id: undefined,
      location_id: undefined,
      category_id: undefined,
      description: undefined,
      files: undefined,
      files_for_delete: undefined,
      additional_details: undefined,
      excluded_dates: undefined,
      recurrence_end_after_elapsed_occurrences: undefined,
      deleted_occurrences: undefined
    }
  ) {
    return super.update(id, optional);
  }

  checkout(id, comments) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'checkout',
        (error, { data }) => {
          if (error) reject(error);
          resolve(new this.responseEntity(this.client, data?.delivery));
        },
        { id, comments },
        Client.methods.PUT
      );
    });
  }

  checkin(id, comments) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'checkin',
        (error, { data }) => {
          if (error) reject(error);
          resolve(new this.responseEntity(this.client, data?.delivery));
        },
        { id, comments },
        Client.methods.PUT
      );
    });
  }

  approve(id, comment) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'approve',
        (error, { data }) => {
          if (error) reject(error);
          resolve(new this.responseEntity(this.client, data?.delivery));
        },
        { id, comment },
        Client.methods.PUT
      );
    });
  }

  reject(id, comment) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'reject',
        (error, { data }) => {
          if (error) reject(error);
          resolve(new this.responseEntity(this.client, data?.delivery));
        },
        { id, comment },
        Client.methods.PUT
      );
    });
  }

  verifyVehicle = (reg) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'verify_vehicle',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data?.vehicle);
          }
        },
        { reg },
        Client.methods.GET
      );
    });

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(id, unarchive) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        unarchive ? 'unarchive' : 'archive',
        (error, { data }) => resolve(Boolean(data)),
        { id },
        Client.methods.PUT
      );
    });
  }
}
