export default class GeneralMockNamespace {
  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  getEndpoints() {
    const {
      framework: {
        mockNamespaces: {
          UsersMockNamespace: { dataSet: users }
        }
      }
    } = this;

    return {
      isLoggedIn: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => ({ code: 200, data: users[0] }),
      hasPermission: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const {
          framework: { permissions }
        } = this;
        let { permission } = payload;
        let response = {};
        let wasArray = false;

        permission = JSON.parse(permission);

        if (!Array.isArray(permission)) permission = [permission];
        else {
          wasArray = true;
        }

        permission.forEach((permission) => {
          let hasPermission = false;
          if (
            isLoggedIn &&
            Object.hasOwnProperty.call(permissions, permission)
          ) {
            hasPermission = Boolean(permissions[permission]);
          }
          response[permission] = hasPermission;
        });

        if (permission.length === 1 && !wasArray)
          response = Object.values(response)[0];

        return { code: 200, data: response };
      },
      'authenticate/token': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => ({ code: 200, data: true }),
      profile: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'system_error',
          'not_logged_in',
          'no_permission'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        const {
          framework: {
            mockNamespaces: {
              UsersMockNamespace: { dataSet: users },
              JobRolesMockNamespace: { dataSet: jobRoles },
              OccupiersMockNamespace: { dataSet: occupiers }
            }
          }
        } = this;

        const user = users[0];
        const { profile, email } = user;
        const {
          id: occupierId,
          building: { id: buildingId, name: buildingName },
          company: { name: companyName }
        } = occupiers[0];

        profile.email = email;
        if (!profile.job_role) profile.job_role = jobRoles[0];

        profile.company = { id: occupierId, name: companyName };
        profile.building = { id: buildingId, name: buildingName };

        return {
          code: 200,
          data: { profile }
        };
      },
      'profile/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'system_error',
          'not_logged_in',
          'no_permission'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (payload !== null) {
          const { name, job_role, profile_picture_uri } = payload;

          const {
            framework: {
              mockNamespaces: {
                UsersMockNamespace: { dataSet: users },
                JobRolesMockNamespace: { dataSet: jobRoles }
              }
            }
          } = this;

          if (name) users[0].profile.name = name;
          if (profile_picture_uri)
            users[0].profile.picture_uri = profile_picture_uri;
          if (job_role) {
            jobRoles.forEach((role) => {
              if (role.id === parseInt(job_role, 10)) {
                users[0].profile.job_role = role;
              }
            });
          }
        }

        return {
          code: 200,
          data: true
        };
      },
      'profile/update/password': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const { password } = payload;

        if (!password) {
          return this.framework.createErrorResponse('invalid_password');
        }
        return { code: 200 };
      },
      platformSetup: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const { theme_key } = payload;

        const theme = {
          homepage_image:
            'https://assets.cureoscityportal.com/generic-portal.jpg',
          homepage_logo: '/logo.svg',
          logo: '/logo.svg',
          logo_background: '#009BA4',
          favicon_location: '/',
          colours: {
            accent: '#ffe850',
            black: '#000',
            green: '#00c48c',
            'interactive-blue2': '#3c80e9',
            'interactive-blue3': '#034fc9',
            'neutral-1': '#1b1b1b',
            'neutral-10': '#222540',
            'neutral-11': '#e4e4e4',
            'neutral-12': '#c7c7c7',
            'neutral-13': '#8e8d8d',
            'neutral-2': '#333',
            'neutral-3': '#666',
            'neutral-4': '#999',
            'neutral-5': '#e0e0e0',
            'neutral-6': '#f7f7f7',
            'neutral-7': '#fafafa',
            'neutral-8': '#f0f2f5',
            'neutral-9': '#f0f2f5',
            'neutral-grey-3': '#666666',
            'neutral-grey-4': '#c7c7cc',
            'neutral-grey-5': '#e4e4e4',
            orange: '#fda61b',
            primary: '#222540',
            'primary-dark': '#141525',
            'primary-light': '#383b52',
            'purple-1': '#9b51e0',
            red: '#fd0e3b',
            red2: '#e3022c',
            'secondary-1': '#0dceb3',
            'secondary-1-dark': '#0dc3a9',
            'secondary-2': '#0ee1c4',
            'secondary-3': '#0bad96',
            success: '#00c48c',
            'tertiary-1': '#fda61b',
            'tertiary-2': '#fe5676',
            'tertiary-3': '#2f80ed',
            'tertiary-4': '#ff5ff9',
            'tertiary-5': '#9b51e0',
            'tertiary-6': '#56ccf2',
            warning: '#fda61b',
            white: '#fff'
          }
        };

        // staging.localhost
        if (theme_key === '1c0a0c23e79abad754ad36d028c54952') {
          theme.homepage_image =
            'https://assets.cureoscityportal.com/test/fakesky.jpg';
          theme.homepage_logo =
            'https://assets.cureoscityportal.com/test/fakelogo.jpg';
          theme.logo = 'https://assets.cureoscityportal.com/test/fakelogo.jpg';
          theme.favicon_location =
            'https://assets.cureoscityportal.com/test/favicon/';
        }

        return { code: 200, data: { theme, features: [] } };
      }
    };
  }
}
