import Client from '../../../Client';
import OfferCategory from './Category';

export default class Offer {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APICategory - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      archived,
      cancelled,
      cancelled_reason,
      category,
      category_id,
      description,
      end_date_time,
      featured,
      has_qr_code,
      images,
      nearest_location,
      online,
      promo_code,
      publish_date_time,
      retailer,
      start_date_time,
      terms_and_conditions,
      title,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');

    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    this.ISODateTimeToUTCDate = parseISODateTimeAsUTC;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || null;
    this.title = title || '';

    this.cancelled_reason = cancelled_reason || '';
    this.category = category || '';
    this.description = description || '';
    this.nearest_location = nearest_location || '';
    this.promo_code = promo_code || '';
    this.retailer = retailer || '';
    this.terms_and_conditions = terms_and_conditions || '';

    this.category =
      typeof category === 'object' ? new OfferCategory(Client, category) : null;
    this.category_id = category_id;

    this.images = images || [];

    this.archived = archived || false;
    this.archived = archived || false;
    this.cancelled = cancelled || false;
    this.featured = featured || false;
    this.online = online || false;
    this.has_qr_code = has_qr_code || false;

    this.start_date_time = parseISODateTimeAsUTC(start_date_time);
    this.end_date_time = parseISODateTimeAsUTC(end_date_time);
    this.publish_date_time = parseISODateTimeAsUTC(publish_date_time);
    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  getStatusLabel() {
    const { start_date_time, cancelled, archived } = this;

    const now = new Date();

    let status = 'live';
    if (start_date_time > now) status = 'scheduled';
    if (cancelled) status = 'cancelled';
    if (archived) status = 'archived';
    return status;
  }
}
