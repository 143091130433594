/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IPhone = ({ size, colour, height, width, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size || width}
    height={size || height}
    viewBox="0 0 238 479"
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M201.213 0H36.7869C17.3935 0 1.67213 15.7214 1.67213 35.1148V64.0984H1.58315C0.7088 64.0984 0 64.8072 0 65.6815V80.3513C0 81.2256 0.7088 81.9344 1.58315 81.9344H1.67213V98.6557H1.58315C0.7088 98.6557 0 99.3645 0 100.239V131.63C0 132.504 0.7088 133.213 1.58315 133.213H1.67213V142.131H1.58315C0.7088 142.131 0 142.84 0 143.714V175.105C0 175.98 0.7088 176.689 1.58315 176.689H1.67213V443.115C1.67213 462.508 17.3935 478.229 36.7869 478.229H201.213C220.606 478.229 236.328 462.508 236.328 443.115V165.541H236.417C237.291 165.541 238 164.832 238 163.958V111.386C238 110.512 237.291 109.803 236.417 109.803H236.328V35.1148C236.328 15.7214 220.606 0 201.213 0ZM4.45902 35.1148C4.45902 17.2606 18.9327 2.78689 36.7869 2.78689H201.213C219.067 2.78689 233.541 17.2606 233.541 35.1148V443.115C233.541 460.969 219.067 475.443 201.213 475.443H36.7869C18.9327 475.443 4.45902 460.969 4.45902 443.115V35.1148Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M223.508 35.1147C223.508 22.7967 213.531 12.8197 201.213 12.8197H181.426C178.964 12.8197 176.967 14.816 176.967 17.2787V17.8361C176.967 24.6361 171.505 30.0983 164.705 30.0983H73.2951C66.4951 30.0983 61.0328 24.6361 61.0328 17.8361V17.2787C61.0328 14.816 59.0364 12.8197 56.5738 12.8197H36.7869C24.4689 12.8197 14.4918 22.7967 14.4918 35.1147V443.115C14.4918 455.433 24.4689 465.41 36.7869 465.41H201.213C213.531 465.41 223.508 455.433 223.508 443.115V35.1147ZM36.7869 2.78689L201.213 2.37472C219.049 2.37472 234.042 17.2787 234.042 35.1147L233.541 443.115C234.834 460.96 219.266 477.847 201.213 475.443H36.7869C19.2615 476.264 3.43017 461.224 4.45902 443.115V35.1148C3.69411 17.2787 18.7339 1.58316 36.7869 2.78689Z"
      fill="#333333"
    />
    <path
      d="M147.984 17.8362C147.984 19.991 146.238 21.7378 144.083 21.7378C141.928 21.7378 140.181 19.991 140.181 17.8362C140.181 15.6814 141.928 13.9346 144.083 13.9346C146.238 13.9346 147.984 15.6814 147.984 17.8362Z"
      fill="#4F4F4F"
    />
    <path
      d="M106.46 16.1641C105.536 16.1641 104.788 16.9127 104.788 17.8362C104.788 18.7597 105.536 19.5083 106.46 19.5083H130.984C131.908 19.5083 132.656 18.7597 132.656 17.8362C132.656 16.9127 131.908 16.1641 130.984 16.1641H106.46Z"
      fill="#4F4F4F"
    />
  </svg>
);

IPhone.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IPhone.defaultProps = {
  size: '100%',
  colour: 'theme-primary',
  height: 479,
  width: 238
};

export default memo(IPhone);
