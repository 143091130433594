import logger from 'services/logger/logger';

export default class MobileMyBuildingMockNamespace {
  dataSet = [
    {
      id: 1,
      user_id: 1,
      building_id: 1,
      title: 'My Building 1',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      seq: 1,
      cover_image_uri: 'https://picsum.photos/400/400',
      publish_date: null,
      published_user_id: null,
      archived: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      user_id: 1,
      building_id: 1,
      title: 'My Building 2',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      seq: 2,
      cover_image_uri: 'https://picsum.photos/400/400',
      publish_date: new Date().toISOString(),
      published_user_id: 1,
      archived: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 3,
      user_id: 1,
      building_id: 1,
      title: 'My Building 3',
      body:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      seq: 2,
      cover_image_uri: 'https://picsum.photos/400/400',
      publish_date: '2025-08-28T05:12:13.000Z',
      published_user_id: 1,
      archived: true,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (article) => {
    const { building_id } = article;
    const {
      framework: {
        mockNamespaces: {
          BuildingsMockNamespace: { dataSet: buildings }
        }
      }
    } = this;

    if (building_id)
      article.building = buildings.filter(({ id }) => id == building_id).pop();

    return article;
  };

  refreshDataSet() {
    this.dataSet.forEach(this.translateEntities);
  }

  getEndpoints() {
    this.refreshDataSet();
    const { generateUUIDV4 } = this.framework;

    return {
      'mobile/my-building': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'mobile/my-building/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const {
          title,
          body,
          cover_image_uri,
          publish_date,
          building_id
        } = payload;

        const article = {
          id: this.dataSet.length + 1,
          user_id: 1,
          building_id,
          title,
          body,
          cover_image_uri,
          publish_date,
          publish_user_id: publish_date ? 1 : null,
          archived: false,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        this.dataSet.push(this.translateEntities(article));
        return { code: 200, data: { article } };
      },
      'mobile/my-building/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const { title, body, cover_image, publish_date } = payload;

          const ret = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );

          return {
            code: 200,
            data: { article: this.translateEntities(ret) }
          };
        } catch (e) {
          logger.debug(e);
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'mobile/my-building/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      },
      'mobile/my-building/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'mobile/my-building/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
