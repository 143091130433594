/**
 * Creates a representation of a datetime string in ISO8601 format
 * But assumes that the time is in Zulu Time/UTC with no local timezone offset
 * @param  {Date} date - a JavaScript date object
 */
const dateToISOStringDiscountingOffset = (date) => {
  if (
    Object.prototype.toString.call(date) === '[object Date]' &&
    date.getTime &&
    !isNaN(date.getTime())
  ) {
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
  }

  return null;
};

export default dateToISOStringDiscountingOffset;
