/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState, useEffect } from 'react';

import { useBuilding } from 'context/building-context';

import { useAuth } from './auth-context';

const NavContext = createContext();

const NavProvider = (props) => {
  const [navigationConfig, setNavigationConfig] = useState({});
  const [noNavigation, setNoNavigation] = useState(false);
  const [subMenuTitle, setSubMenuTitle] = useState();
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [backLink, setBackLink] = useState([]);
  const [backLinkTitle, setBackLinkTitle] = useState([]);

  const { hasPermission } = useAuth();
  const { buildingData } = useBuilding();

  useEffect(() => {
    // Get navigation data from API
  }, []);

  const validateMenuPermissions = (items) =>
    // eslint-disable-next-line no-unused-vars
    new Promise((resolve, reject) => {
      const permissions = items
        .filter(({ permission }) => !!permission)
        .map(({ permission }) => permission);
      if (permissions.length) {
        hasPermission(permissions, buildingData?.id).then(
          (permissionStates) => {
            const allowedItems = items.filter(
              ({ permission }) =>
                !permission ||
                (Object.hasOwnProperty.call(permissionStates, permission) &&
                  permissionStates[permission] === true)
            );
            resolve(allowedItems);
          }
        );
      } else resolve(items);
    });

  const subMenuItemsChild = (items) =>
    validateMenuPermissions(items).then((allowedItems) =>
      setSubMenuItems(allowedItems)
    );

  const navCfg = (config) => {
    if (config.navigation && Array.isArray(config.navigation)) {
      validateMenuPermissions(config.navigation).then((allowedItems) => {
        config.navigation = allowedItems;
        setNavigationConfig(config);
      });
    } else {
      setNavigationConfig(config);
    }
  };

  return (
    <NavContext.Provider
      value={{
        navigationConfig,
        subMenuItems,
        noNavigation,
        setNoNavigation,
        subMenuTitle,
        backLink,
        backLinkTitle,
        setSubMenuTitle,
        setBackLink,
        setBackLinkTitle,
        setSubMenuItems: (items) => subMenuItemsChild(items),
        setNavigationConfig: (config) => navCfg(config)
      }}
      {...props}
    />
  );
};

const useNav = () => useContext(NavContext);

export { NavProvider, useNav };
