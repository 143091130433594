/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconPDF = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 35 36"
    fill="none"
    {...rest}
  >
    <rect y="0.344727" width="35" height="35" rx="3" fill="currentColor" />
    <path
      d="M7 22.3447H9.11785V19.872H10.5943C12.2765 19.872 13.4141 18.7084 13.4141 17.0972C13.4141 15.486 12.2765 14.3447 10.5943 14.3447H7V22.3447ZM9.11785 18.1937V16.023H10.1949C10.8968 16.023 11.2599 16.4594 11.2599 17.0972C11.2599 17.7349 10.8968 18.1937 10.1586 18.1937H9.11785Z"
      fill="white"
    />
    <path
      d="M14.6701 22.3447H17.744C20.467 22.3447 22.2096 20.8454 22.2096 18.3279C22.2096 15.8105 20.467 14.3447 17.744 14.3447H14.6701V22.3447ZM16.788 20.6664V16.023H17.6593C19.0873 16.023 20.0192 16.8063 20.0192 18.3279C20.0192 19.8608 19.1236 20.6664 17.6593 20.6664H16.788Z"
      fill="white"
    />
    <path
      d="M23.6993 22.3447H25.8172V19.3797H28.4554V17.7014H25.8172V16.023H29V14.3447H23.6993V22.3447Z"
      fill="white"
    />
  </svg>
);

IconPDF.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconPDF.defaultProps = {
  size: '100%',
  colour: 'tertiary-3'
};

export default memo(IconPDF);
