export default class Department {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIDepartment - containing the server side object
   */
  constructor(
    Client,
    { id, name, building_id, seq, enabled, created, modified }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.name = name || '';
    this.building_id = building_id || null;
    this.seq = seq || 0;
    this.enabled = Boolean(enabled);
    this.disabled = Boolean(!enabled);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
