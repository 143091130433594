/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

const IconMagnifyingGlassPlus = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6916 13.04C14.0761 12.6738 14.6993 12.6739 15.0838 13.04L19.0705 16.8369C19.4549 17.203 19.4549 17.7966 19.0704 18.1627C18.686 18.5288 18.0627 18.5288 17.6783 18.1627L13.6916 14.3658C13.3072 13.9997 13.3072 13.4061 13.6916 13.04Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.51559 3.43725C6.25367 3.43725 3.60937 5.95564 3.60937 9.06223C3.60937 12.1688 6.25367 14.6872 9.51559 14.6872C12.7775 14.6872 15.4218 12.1688 15.4218 9.06223C15.4218 5.95564 12.7775 3.43725 9.51559 3.43725ZM1.64062 9.06223C1.64062 4.92011 5.16637 1.56226 9.51559 1.56226C13.8648 1.56226 17.3906 4.92011 17.3906 9.06223C17.3906 13.2043 13.8648 16.5622 9.51559 16.5622C5.16637 16.5622 1.64062 13.2043 1.64062 9.06223Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5156 15.6247C13.3212 15.6247 16.4062 12.6866 16.4062 9.06223C16.4062 5.43788 13.3212 2.49976 9.5156 2.49976C5.71003 2.49976 2.625 5.43788 2.625 9.06223C2.625 12.6866 5.71003 15.6247 9.5156 15.6247ZM5.90623 9.06249C5.90623 8.54473 6.34695 8.125 6.8906 8.125H8.53122V6.56251C8.53122 6.04474 8.97193 5.62501 9.51559 5.62501C10.0592 5.62501 10.5 6.04474 10.5 6.56251V8.125H12.1406C12.6842 8.125 13.125 8.54473 13.125 9.06249C13.125 9.58026 12.6842 9.99999 12.1406 9.99999H10.5V11.5625C10.5 12.0803 10.0592 12.5 9.51559 12.5C8.97193 12.5 8.53122 12.0803 8.53122 11.5625V9.99999H6.8906C6.34695 9.99999 5.90623 9.58026 5.90623 9.06249Z"
      fill="white"
    />
  </svg>
);

IconMagnifyingGlassPlus.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconMagnifyingGlassPlus.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default IconMagnifyingGlassPlus;
