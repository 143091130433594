import Client from '../../Client';

export default class EmailTemplatesMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'BOOKASPACE',
      subject: 'Form submit from book a space',
      content: '<html></html>',
      building_id: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      name: 'REGISTER',
      subject: 'Register for an account',
      content: '<html></html>',
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  getEndpoints() {
    return {
      email_templates: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records',
          'provide_building_id'
        ];

        if (!payload.building_id) {
          return this.framework.createErrorResponse('provide_building_id');
        }

        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          subject: { field: 'subject', expression: 'like' },
          building_id: { field: 'building_id', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'email_templates/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_building_id',
          'email_template_valid_name',
          'email_template_valid_subject',
          'email_template_exists'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const template = {
          id: this.dataSet.length + 1,
          name: payload.name,
          subject: payload.subject,
          content: payload.content,
          building_id: payload.building_id,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        return { code: 200, data: { template } };
      },
      'email_templates/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'update_no_permission',
          'provide_building_id',
          'email_template_valid_name',
          'email_template_valid_subject',
          'email_template_exists'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const ret = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );
          return { code: 200, data: { template: ret } };
        } catch (e) {
          return this.framework.createErrorResponse(
            'email_template_no_permission'
          );
        }
      },
      'email_templates/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      }
    };
  }
}
