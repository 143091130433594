/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconPadlock = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12 6.66667v-2.66667c0-2.20867-1.7913-4-4-4-2.20867 0-4 1.79133-4 4v2.66667h-2v9.33333h12v-9.33333zm-3.33333 5.14863v1.518h-1.33334v-1.518c-.39666-.2313-.66666-.656-.66666-1.1486 0-.73603.59733-1.33337 1.33333-1.33337s1.33333.59734 1.33333 1.33337c0 .492-.26933.9173-.66666 1.1486zm-3.33334-5.14863v-2.66667c0-1.47067 1.196-2.66667 2.66667-2.66667 1.47 0 2.6667 1.196 2.6667 2.66667v2.66667z"
      fill="currentColor"
    />
  </svg>
);

IconPadlock.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconPadlock.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconPadlock);
