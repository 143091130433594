/* eslint-disable import/no-cycle */
import Building from '../Building';
import Department from '../Buildings/Department';
import Floor from '../Buildings/Floor';
import Location from '../Buildings/Location';
import Priority from '../Buildings/Priority';
import Comment from '../Comment';
import User from '../User';
import Category from './Category';

export default class Ticket {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIFloor - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      user_id,
      user,
      assigned_user_id,
      assigned_user,
      category_id,
      category,
      priority_id,
      priority,
      building_id,
      building,
      department_id,
      department,
      floors,
      location_id,
      location,
      comments,
      description,
      additional_comments,
      files,
      archived,
      managed_by,
      managed_can_archive,
      managed_can_assign,
      managed_can_close,
      managed_can_edit,
      managed_can_reassign,
      managed_can_view,
      assigned,
      closed,
      attending_date,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || null;
    this.user_id = user_id || null;
    this.assigned_user_id = assigned_user_id || null;
    this.category_id = category_id || null;
    this.priority_id = priority_id || null;
    this.building_id = building_id || null;
    this.department_id = department_id || null;
    this.location_id = location_id || null;

    this.description = description || '';
    this.additional_comments = additional_comments || '';

    this.files = Array.isArray(files) ? files : [];

    this.user = user ? new User(Client, user) : null;
    this.assigned_user = assigned_user ? new User(Client, assigned_user) : null;
    this.category = category ? new Category(Client, category) : null;
    this.priority = priority ? new Priority(Client, priority) : null;
    this.building = building ? new Building(Client, building) : null;
    this.department = department ? new Department(Client, department) : null;
    this.floors = floors
      ? floors.map((floor) => new Floor(Client, floor))
      : null;
    this.location = location ? new Location(Client, location) : null;

    this.comments = Array.isArray(comments)
      ? comments.map((comment) => new Comment(Client, comment))
      : [];

    this.archived = archived || false;
    this.managed_by = managed_by || '';
    this.managed_can_archive = managed_can_archive || false;
    this.managed_can_assign = managed_can_assign || false;
    this.managed_can_close = managed_can_close || false;
    this.managed_can_edit = managed_can_edit || false;
    this.managed_can_reassign = managed_can_reassign || false;
    this.managed_can_view = managed_can_view || false;

    this.assigned = parseISODateTimeAsUTC(assigned);
    this.closed = parseISODateTimeAsUTC(closed);
    this.attending_date = parseISODateTimeAsUTC(attending_date);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  getStatusLabel() {
    const { archived, assigned, closed } = this;

    let status = 'new';
    if (archived) status = 'archived';
    else if (closed) status = 'closed';
    else if (assigned) status = 'assigned';

    return status;
  }

  processEntities({
    user = false,
    assigned_user = false,
    category = false,
    priority = false,
    building = false,
    department = false,
    floors = false
  }) {
    return new Promise((resolve) => {
      const promises = [];

      if (user) promises.push(this.getUser());
      if (assigned_user) promises.push(this.getAssignedUser());
      if (category) promises.push(this.getCategory());
      if (priority) promises.push(this.getPriority());
      if (building) promises.push(this.getBuilding());
      if (department) promises.push(this.getDepartment());
      if (floors) promises.push(this.getFloors());

      Promise.all(promises).then(() => resolve(this));
    });
  }

  getUser() {
    return new Promise((resolve) => resolve(this.user));
  }

  getAssignedUser() {
    return new Promise((resolve) => resolve(this.assigned_user));
  }

  getCategory() {
    return new Promise((resolve) => resolve(this.category));
  }

  getPriority() {
    return new Promise((resolve) => resolve(this.priority));
  }

  getBuilding() {
    return new Promise((resolve) => resolve(this.building));
  }

  getDepartment() {
    return new Promise((resolve) => resolve(this.department));
  }

  getFloors() {
    return new Promise((resolve) => resolve(this.floors));
  }

  getLocation() {
    return new Promise((resolve) => resolve(this.location));
  }
}
