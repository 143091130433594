import { datadogLogs } from '@datadog/browser-logs';

import { name, version } from '../../package.json';

const isLocal =
  process.env.NODE_ENV === 'development' &&
  (window.location.hostname.includes('localhost') ||
    window.location.hostname === 'dev.cureoscity.com');
const isDevelop =
  process.env.NODE_ENV === 'production' &&
  (window.location.hostname.includes('fe-dev') ||
    window.location.hostname.includes('develop'));
const isStaging =
  process.env.NODE_ENV === 'production' &&
  window.location.hostname.includes('staging');
const isProd =
  process.env.NODE_ENV === 'production' && !isDevelop && !isStaging;
const env = (() => {
  if (isProd) return 'production';
  if (isStaging) return 'staging';
  if (isDevelop) return 'development';
  return 'local';
})();

const ddLogsInit = () => {
  datadogLogs.init({
    clientToken: 'pub819fe99a41f5f931bed6919b02b494c4',
    site: 'datadoghq.eu',
    service: `${name}:${window.location.hostname}`,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version,
    env,
    silentMultipleInit: true
  });
};

if (isLocal) datadogLogs.logger.setHandler('console');

export { ddLogsInit };
