/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconVisitor = ({ size, colour, fill, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill={fill}
    {...rest}
  >
    <g>
      <path
        d="M52.95,82.86c18.12,0,32.87-14.74,32.87-32.86S71.07,17.14,52.95,17.14S20.09,31.88,20.09,50S34.83,82.86,52.95,82.86z    M52.95,39.2c-4.24,0-7.68-3.35-7.68-7.46c0-4.11,3.44-7.46,7.68-7.46s7.689,3.35,7.689,7.46C60.64,35.85,57.19,39.2,52.95,39.2z    M52.95,44.73c11.439,0,20.79,9.09,21.21,20.42H31.74C32.16,53.82,41.52,44.73,52.95,44.73z"
        fill={fill}
      />
      <path
        d="M52.952,7.821c-23.149,0-42.001,18.748-42.175,41.857l-4.536-4.535c-0.313-0.314-0.822-0.314-1.136,0   c-0.314,0.313-0.314,0.822,0,1.137l5.902,5.902c0.157,0.157,0.362,0.235,0.568,0.235c0.205,0,0.41-0.078,0.568-0.235l5.903-5.902   c0.313-0.314,0.313-0.823,0-1.137c-0.313-0.314-0.823-0.314-1.137,0l-4.527,4.526C12.563,27.451,30.691,9.429,52.951,9.429   c22.371,0,40.57,18.2,40.57,40.571s-18.199,40.571-40.57,40.571c-13.775,0-26.493-6.9-34.021-18.459   c-0.242-0.371-0.74-0.479-1.111-0.234c-0.373,0.242-0.478,0.74-0.235,1.113c7.826,12.015,21.048,19.188,35.369,19.188   c23.258,0,42.179-18.921,42.179-42.179S76.209,7.821,52.952,7.821z"
        fill={fill}
      />
    </g>
  </svg>
);

IconVisitor.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string,
  fill: PropTypes.string
};

IconVisitor.defaultProps = {
  size: '80px',
  colour: 'primary',
  fill: 'white'
};

export default memo(IconVisitor);
