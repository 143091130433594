export default class PermitSessionData {
  /** *
   *
   * @param {Object} PermitSessionData - Session passed back by the API
   */
  constructor({
    id,
    session_id,
    section_id,
    data_key,
    data_value,
    created,
    modified
  }) {
    this.id = id || '';
    this.session_id = session_id || '';
    this.section_id = section_id || '';

    this.data_key = data_key || null;
    this.data_value = data_value || null;
    this.created = created || null;
    this.modified = modified || null;
  }
}
