import logger from 'services/logger/logger';

import Client from '../../Client';
import UserRole from '../../Entities/UserRole';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class UserRolesNamespace extends GenericNamespaceHandler {
  namespace = 'users/roles';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = UserRole;

  responseKey = 'role';

  cacheSearchResults = false;

  requiredFields = [
    {
      param: 'name',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_name',
      validation: { type: 'string' }
    }
  ];

  searchByBuildingId(
    buildingId,
    criteria = {},
    page = 1,
    orderBy,
    orderDirection
  ) {
    if (buildingId) criteria.building_id = buildingId;
    return this.search(criteria, page, orderBy, orderDirection);
  }

  dashboard(buildingId) {
    return new Promise((resolve, reject) => {
      if (!buildingId) throw new Error('provide_building_id');

      this.client.request(
        this.namespace,
        'dashboard',
        (error, { data }) => {
          if (error) reject(error);
          else {
            resolve(data);
          }
        },
        { building_id: buildingId },
        Client.methods.GET
      );
    });
  }

  getForEdit(id) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'getForEdit',
        (error, { data }) => {
          if (error) reject(error);
          else {
            resolve(new UserRole(this.client, data.role));
          }
        },
        { id },
        Client.methods.GET
      );
    });
  }

  /**
   * @callback SaveCallback
   * Callback to handle a UserRole save request
   *
   * @param {Error} error - Contains an error that might be returned by the request
   * @param {UserRole} UserRole - contains a user object on success
   */

  /**
   * Method to create a userRole
   *
   * Example: create(
   *    'James Role',
   *    'Some Description',
   *    1,
   *    2,
   *    ['permission_1','permission_2],
   *    error, user => logger.log(error, user)
   * );
   *
   * @param {int} buildingId - BuildingId to associate this userRole with
   * @param {String} name - must be a valid name
   * @param optional
   */
  create(
    buildingId = null,
    name = '',
    optional = {
      description: undefined,
      company_id: undefined,
      permissions: undefined
    }
  ) {
    let data = { building_id: buildingId, name };
    if (Object.keys(optional).length) data = Object.assign(data, optional);
    return super.create(data);
  }

  /**
   * Method to update a userRole
   * Please provide at-least one field to update!
   *
   * Example: update(
   *    1,
   *    {name: 'New Role'}
   * );
   *
   * @param {int} id - ID of the userRole to update
   * @param optional
   */
  update(
    id = null,
    optional = {
      name: undefined,
      description: undefined,
      companyId: undefined,
      permissions: undefined
    }
  ) {
    return super.update(id, optional);
  }

  /** Method to update Permissions for a UserRole
   *
   * @param {int} id - UserRole id you want to update
   * @param permissions - Array of permissions to set
   */
  updatePermissions(id = null, permissions = null) {
    if (!Array.isArray(permissions))
      throw new Error('Please provide an array of permissions');
    return super.save(
      'update/permissions',
      id,
      { justPermissions: true, permissions },
      true
    );
  }
}
