import logger from 'services/logger/logger';

import Client from '../../Client';
import OccupiersMockNamespace from './Buildings/Occupiers/Occupiers';
import permitsPrismaMock from './permits-prisma-mock.json';

export default class PermitsMockNamespace {
  permitSessions = {};

  permitSessionData = {};

  dataSet = [
    {
      id: 1,
      contact_name: 'Bob Vance',
      contact_number: '123456',
      company_name: 'Vance refrigeration',
      draft: false,
      start: '2021-06-28T11:53:56.000Z',
      finish: '2021-07-05T11:53:56.000Z',
      user_id: 1,
      company_id: 1,
      building_id: 1,
      floor_id: [1],
      location_id: 1,
      description: 'Some description about the permit',
      safety_measures: 'Some safety measures about the permit',
      other_details: 'Some other details about the permit',
      hazard_sheet: true,
      asbestos_register: true,
      activated: false,
      activated_date: null,
      activated_user_id: null,
      closed: false,
      closed_date: null,
      closed_user_id: null,
      approved: false,
      approved_date: null,
      approved_user_id: null,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 2,
      contact_name: 'Jane Doe',
      contact_number: '123456',
      company_name: 'Dunder Mifflin Paper Company',
      draft: false,
      start: '2021-02-26T11:53:56.000Z',
      finish: '2021-02-27T11:53:56.000Z',
      user_id: 1,
      company_id: 2,
      building_id: 1,
      floor_id: [1],
      location_id: 1,
      description: 'This work and that work is going to happen',
      safety_measures: 'Be careful of the employees',
      other_details: '',
      hazard_sheet: true,
      asbestos_register: true,
      activated: false,
      activated_date: null,
      activated_user_id: null,
      closed: false,
      closed_date: null,
      closed_user_id: null,
      approved: false,
      approved_date: null,
      approved_user_id: null,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 3,
      contact_name: 'Jane Doe',
      contact_number: '123456',
      company_name: 'Coke cola',
      draft: false,
      start: '2021-03-01T11:53:56.000Z',
      finish: '2021-03-01T18:00:56.000Z',
      user_id: 1,
      company_id: 2,
      building_id: 1,
      floor_id: [1],
      location_id: 1,
      description: 'A polar bear is stuck in the dry wall again',
      safety_measures: "It's a polar bear, goes without saying....",
      other_details: '',
      hazard_sheet: true,
      asbestos_register: true,
      activated: false,
      activated_date: null,
      activated_user_id: null,
      closed: false,
      closed_date: null,
      closed_user_id: null,
      approved: true,
      approved_date: '2021-02-01T11:53:56.000Z',
      approved_user_id: 1,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    }
  ];

  permitData = [
    {
      id: '8556a07b-95ff-4390-beb6-d9280f3c002e',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'company_id',
      data_value: '1',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'a4190896-611d-4b30-91f5-4cd8198d19dd',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'company_name',
      data_value: 'Vance refrigeration',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'a11878a9-d098-4729-811f-c62be49dd0d5',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'contact_name',
      data_value: 'Bob Vance',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'ad5e9c21-6218-416d-b5b9-6c1ea35917e8',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'contact_number',
      data_value: '123456',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '41be0293-2c5d-45b7-8c68-29231d793b2d',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'building_id',
      data_value: '1',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'de55b614-6bd2-45cc-9879-a7b9d05821e9',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'floor_id',
      data_value: '1',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '19828065-5e7c-4aa2-b012-e31fc052956a',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'location_id',
      data_value: '1',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'f9f90448-91c0-411e-8d28-4b2f8cd082fa',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'description',
      data_value: 'Some description about the permit',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'd9ed3d11-b557-4c10-940c-4ce91fecc417',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'other_details',
      data_value: 'Some other details about the permit',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '91b300a3-3fcb-4f23-9932-87392a7f0ff3',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'safety_measures',
      data_value: 'Some safety measures about the permit',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'ecd71518-393c-4117-a3ce-def5bb201de9',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'hazard_sheet',
      data_value: true,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '539a99eb-08fc-4840-ac2e-e5b3dc949384',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'asbestos_register',
      data_value: true,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '5b825c55-325f-4906-ace0-90cd9acca9fc',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'start_date',
      data_value: '2021-02-28',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '0d6d8501-829d-4cc8-bc94-5795a0152dbd',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'start_time',
      data_value: '19:00',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '03bb8bdd-6239-4d87-99f2-9e644e69f845',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'end_date',
      data_value: '2021-02-28',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '6148f5c7-45e4-494b-8abc-f017f14af850',
      permit_id: 1,
      section_id: 'job_details',
      data_structure: {},
      data_key: 'end_time',
      data_value: '20:00',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '380e1287-2d80-4a75-9c6e-a3ec97462a6e',
      permit_id: 1,
      section_id: 'contractor_details',
      data_structure: {},
      data_key: 'company_name',
      data_value: 'Contractors Company',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'cae9b473-5f65-41ea-a335-d306390428fd',
      permit_id: 1,
      section_id: 'contractor_details',
      data_structure: {},
      data_key: 'company_number',
      data_value: 'Contractor number',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'e2b7b43c-1f4f-4967-922a-aaf04ff6fac6',
      permit_id: 1,
      section_id: 'contractor_details',
      data_structure: {},
      data_key: 'supervisor_name',
      data_value: 'Supervisor name',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '4092d801-9935-482f-998f-43a235afafb4',
      permit_id: 1,
      section_id: 'contractor_details',
      data_structure: {},
      data_key: 'supervisor_number',
      data_value: 'Supervisor number',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '72ce6156-bc0c-4b48-9870-f45bd647d20d',
      permit_id: 1,
      section_id: 'contractor_details',
      data_structure: {},
      data_key: 'crew_members',
      data_value: '',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'fe429299-46e1-42cd-aec7-16baf9e47dc5',
      permit_id: 1,
      section_id: 'contractor_details',
      data_structure: {},
      data_key: 'crew_member_names_unknown',
      data_value: true,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '17535cdc-64b5-11eb-aa1b-fb8ed92ff8f5',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Checkbox',
        required: true,
        label: 'Work at height',
        preview_label: 'Work at height'
      },
      data_key: 'scaffold_tower',
      data_value: true,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'd148b943-e84f-42b2-a908-4f9762fed1bd',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'OccupierSelect',
        required: true,
        label: 'Occupier',
        preview_label: 'Occupier',
        props: {
          showLabel: true
        }
      },
      data_key: 'company_id',
      data_value: 1,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '3d62380a-d7bc-4a32-8c64-28c90fb399bd',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'LocationSelect',
        required: true,
        label: 'Location',
        preview_label: 'Location',
        props: {
          showLabel: true
        }
      },
      data_key: 'location_id',
      data_value: 1,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '20ca9ad5-eeee-4bd5-8971-fb6a9aa38e40',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Date',
        required: true,
        label: 'Start date *',
        preview_label: 'Start date ',
        props: {
          hideIcon: true,
          placeholder: 'dd/mm/yyyy'
        }
      },
      data_key: 'start_date',
      data_value: '2021-02-02',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'f056f801-589f-4cec-9898-9dccc9d1507c',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Time',
        required: true,
        label: 'Start time *',
        preview_label: 'Start time ',
        props: {
          hideIcon: true
        }
      },
      data_key: 'start_time',
      data_value: '09:00',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '280b4fb0-7727-4444-98de-fd4c98726551',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Text',
        required: true,
        label: 'Company name *',
        preview_label: 'Company name ',
        props: {
          type: 'text',
          hideIcon: true,
          placeholder: 'Enter the company name'
        }
      },
      data_key: 'company_name',
      data_value: 'Some company',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '554c9ae8-7501-46a0-9664-2e699063e4b7',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Name',
        required: true,
        label: 'Operative name *',
        preview_label: 'Operative name ',
        props: {
          hideIcon: true,
          placeholder: 'Enter an operative name'
        }
      },
      data_key: 'operative_name',
      data_value: 'Some operative',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'fd3b7eea-de26-4be9-8747-550309be0cae',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'BuildingSelect',
        required: true,
        label: 'Building *',
        preview_label: 'Building ',
        props: {
          showLabel: true
        }
      },
      data_key: 'building_id',
      data_value: 1,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '5c9f1e1d-8496-4308-8467-f49d27f1e12f',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'FloorSelect',
        required: true,
        label: 'Floor *',
        preview_label: 'Floor ',
        props: {
          showLabel: true
        }
      },
      data_key: 'floor_id',
      data_value: 1,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '1c592b1a-4905-42e4-9b45-85a40fee06bb',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Text',
        required: true,
        label: 'Please provide a summary of the project *',
        preview_label: 'Please provide a summary of the project ',
        props: {
          hideIcon: true,
          placeholder: 'Tell us a bit more about the project',
          row: 8
        }
      },
      data_key: 'project_summary',
      data_value: 'Some summary',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '055fbb75-b53d-414a-b597-0a56c5faf828',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Text',
        required: true,
        label: 'Principle designer *',
        preview_label: 'Principle designer ',
        props: {
          hideIcon: true,
          placeholder: 'Who designed it?',
          row: 8
        }
      },
      data_key: 'principle_designer',
      data_value: 'A designer',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '2b071630-48e1-47e0-9d4c-f65d1640f4ea',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Text',
        required: true,
        label: 'Principle contractor *',
        preview_label: 'Principle contractor ',
        props: {
          hideIcon: true,
          placeholder: 'Who will be doing the work?',
          row: 8
        }
      },
      data_key: 'principle_contractor',
      data_value: 'A contractor',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'e12cb781-3a36-4c5e-8fe3-c54cb286ea81',
      permit_id: 1,
      section_id: 'cdm_works_access',
      data_structure: {
        component: 'Text',
        required: true,
        label: 'Site-based responsible person *',
        preview_label: 'Site-based responsible person ',
        props: {
          hideIcon: true,
          placeholder: 'Who is responsible for this work on-site?',
          row: 8
        }
      },
      data_key: 'sitebased_person',
      data_value: 'A person',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '148ddd91-4085-4e73-b687-8e376ee86aca',
      permit_id: 1,
      section_id: 'vehicle_details',
      data_structure: {},
      data_key: 'vehicle_access',
      data_value: 'Yes',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: 'dcaba251-22b5-4a33-aa3c-9d55f3c23b4e',
      permit_id: 1,
      section_id: 'vehicle_details',
      data_structure: {},
      data_key: 'vehicle_reg_no',
      data_value: 'KM12 AKK',
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    },
    {
      id: '966c4afd-01d0-4470-a272-e5116ddc1532',
      permit_id: 1,
      section_id: 'vehicle_details',
      data_structure: {},
      data_key: 'vehicle_reg_unknown',
      data_value: false,
      created: '2021-01-28T11:53:56.000Z',
      modified: '2021-01-28T11:53:56.000Z'
    }
  ];

  permitSections = [
    {
      id: 'work_at_height',
      title: 'Work at Height',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please confirm where working from height will be required*'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please tick the type of work involved*'
          }
        },
        {
          id: 'work_scaffold_towers',
          component: 'Group',
          required: true,
          label: 'Work at height',
          preview_label: 'Work at height',
          children: [
            {
              id: 'type_of_work_scaffold',
              component: 'Checkbox',
              label: 'Scaffold / Towers',
              preview_label: 'Scaffold / Towers',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_cradles',
              component: 'Checkbox',
              label: 'Cradles',
              preview_label: 'Cradles',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_mewps',
              component: 'Checkbox',
              label: "MEWP's/Scissor Lifts",
              preview_label: "MEWP's/Scissor Lifts",
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_unguarded_roofs',
              component: 'Checkbox',
              label: 'Unguarded roofs',
              preview_label: 'Unguarded roofs',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_atrias',
              component: 'Checkbox',
              label:
                "Atria's, canopies, high level glass or fragile structures",
              preview_label:
                "Atria's, canopies, high level glass or fragile structures",
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_water_systems',
              component: 'Checkbox',
              label: 'Work on water systems (containing Legionella)',
              preview_label: 'Work on water systems (containing Legionella)',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_demolition',
              component: 'Checkbox',
              label: 'Demolition',
              preview_label: 'Demolition',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_asbestos',
              component: 'Checkbox',
              label: 'Asbestos',
              preview_label: 'Asbestos',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_maintenance',
              component: 'Checkbox',
              label:
                'Maintenance work (that can only be carried out if normal controls are removed)',
              preview_label:
                'Maintenance work (that can only be carried out if normal controls are removed)',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_serious_hazard',
              component: 'Checkbox',
              label:
                'Straight forward works that may interacts with others and cause a serious hazard',
              preview_label:
                'Straight forward works that may interacts with others and cause a serious hazard',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_others',
              component: 'Checkbox',
              label: 'Others',
              preview_label: 'Others',
              props: {
                showLabel: true
              }
            },
            {
              id: 'type_of_work_others',
              component: 'Name',
              label: '',
              preview_label: '',
              props: {
                hideIcon: true,
                placeholder: ''
              }
            }
          ]
        },
        {
          id: 'safety_precautions',
          component: 'Text',
          required: true,
          label: 'Please explain the safety precautions to be applied: *',
          preview_label: 'Please explain the safety precautions to be applied',
          props: {
            hideIcon: true,
            placeholder: 'What precautions need to be applied?',
            row: 8
          }
        },
        {
          id: 'ppe_required',
          component: 'Text',
          required: true,
          label: 'Please detail the PPE required: *',
          preview_label: 'Please detail the PPE required',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          component: 'Label',
          props: {
            text:
              'Please note to provide in the document file section, all relevant documentation applicable and required for working at height, including valid operative certificates (e.g. PASMA), insurance or maintenance records of platform, etc.'
          }
        }
      ]
    },
    {
      id: 'hot_works',
      title: 'Hot Works',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          component: 'Label',
          props: {
            text:
              'Please confirm the exact location the Hot Works will take place'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          id: 'work_details',
          component: 'Text',
          required: true,
          label:
            'Please outline the nature of the Hot Works by detailing the tools used *',
          preview_label:
            'Please outline the nature of the Hot Works by detailing the tools used ',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'date',
          component: 'Date',
          required: true,
          label: 'Date*',
          preview_label: 'Date',
          props: {}
        },
        {
          id: 'time',
          component: 'Time',
          required: true,
          label: 'Time*',
          preview_label: 'Time',
          props: {}
        },
        {
          id: 'safety_precautions',
          component: 'Text',
          required: true,
          label: 'Please explain the safety precautions to be applied: *',
          preview_label: 'Please explain the safety precautions to be applied',
          props: {
            hideIcon: true,
            placeholder: 'What precautions need to be applied?',
            row: 8
          }
        },
        {
          id: 'ppe_required',
          component: 'Text',
          required: true,
          label: 'Please detail the PPE required: *',
          preview_label: 'Please detail the PPE required',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        }
      ]
    },
    {
      id: 'confined_spaces',
      title: 'Confined Spaces',
      fields: [
        {
          component: 'HideableGroup',
          label:
            '1.  Incoming services (gas; electric; water; steam) have been isolated',
          preview_label:
            '1.  Incoming services (gas; electric; water; steam) have been isolated',
          required: true,
          children: [
            {
              id: 'services_been_isolated',
              component: 'TwoOptionRadio',
              required: true,
              props: {
                answer1: 'Yes',
                answer2: 'No'
              }
            },
            {
              id: 'services_been_isolated_applicant_comments',
              component: 'Text',
              required: false,
              label: 'Applicant comments',
              preview_label: 'Applicant comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            },
            {
              id: 'services_been_isolated_property_management_comments',
              component: 'Text',
              required: false,
              label: 'Property management comments',
              preview_label: 'Property management comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            }
          ]
        },
        {
          component: 'HideableGroup',
          label:
            '2. Gas monitoring equipment requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of this equipment.',
          preview_label:
            '2. Gas monitoring equipment requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of this equipment.',
          required: true,
          children: [
            {
              id: 'has_gas_monitoring_requirement',
              component: 'TwoOptionRadio',
              required: true,
              props: {
                answer1: 'Yes',
                answer2: 'No'
              }
            },
            {
              id: 'has_gas_monitoring_requirement_applicant_comments',
              component: 'Text',
              required: false,
              label: 'Applicant comments',
              preview_label: 'Applicant comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            },
            {
              id: 'has_gas_monitoring_requirement_property_management_comments',
              component: 'Text',
              required: false,
              label: 'Property management comments',
              preview_label: 'Property management comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            }
          ]
        },
        {
          component: 'HideableGroup',
          label:
            '3. Respiratory Protection Equipment requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of RPE equipment.',
          preview_label:
            '3. Respiratory Protection Equipment requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of RPE equipment.',
          required: true,
          children: [
            {
              id: 'respiratory_protection_required',
              component: 'TwoOptionRadio',
              required: true,
              props: {
                answer1: 'Yes',
                answer2: 'No'
              }
            },
            {
              id: 'respiratory_protection_required_applicant_comments',
              component: 'Text',
              required: false,
              label: 'Applicant comments',
              preview_label: 'Applicant comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            },
            {
              id:
                'respiratory_protection_required_property_management_comments',
              component: 'Text',
              required: false,
              label: 'Property management comments',
              preview_label: 'Property management comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            }
          ]
        },
        {
          component: 'HideableGroup',
          label:
            '4. Additional fire protection requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of this equipment.',
          preview_label:
            '4. Additional fire protection requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of this equipment.',
          required: true,
          children: [
            {
              id: 'additional_fire_protection_required',
              component: 'TwoOptionRadio',
              required: true,
              props: {
                answer1: 'Yes',
                answer2: 'No'
              }
            },
            {
              id: 'additional_fire_protection_required_applicant_comments',
              component: 'Text',
              required: false,
              label: 'Applicant comments',
              preview_label: 'Applicant comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            },
            {
              id:
                'additional_fire_protection_required_property_management_comments',
              component: 'Text',
              required: false,
              label: 'Property management comments',
              preview_label: 'Property management comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            }
          ]
        },
        {
          component: 'HideableGroup',
          label: '5. Powered equipment/valves are isolated/shut off.',
          preview_label: '5. Powered equipment/valves are isolated/shut off.',
          required: true,
          children: [
            {
              id: 'power_equipment_isolated',
              component: 'TwoOptionRadio',
              required: true,
              props: {
                answer1: 'Yes',
                answer2: 'No'
              }
            },
            {
              id: 'power_equipment_isolated_applicant_comments',
              component: 'Text',
              required: false,
              label: 'Applicant comments',
              preview_label: 'Applicant comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            },
            {
              id: 'power_equipment_isolated_property_management_comments',
              component: 'Text',
              required: false,
              label: 'Property management comments',
              preview_label: 'Property management comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            }
          ]
        },
        {
          component: 'HideableGroup',
          label:
            '6. Certified safety belt and life line requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of this equipment.',
          preview_label:
            '6. Certified safety belt and life line requirements have been identified and equipment made available before commencement of work. Personnel are trained in the use of this equipment.',
          required: true,
          children: [
            {
              id: 'certified_safety_belt_requirement',
              component: 'TwoOptionRadio',
              required: true,
              props: {
                answer1: 'Yes',
                answer2: 'No'
              }
            },
            {
              id: 'certified_safety_belt_requirement_applicant_comments',
              component: 'Text',
              required: false,
              label: 'Applicant comments',
              preview_label: 'Applicant comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            },
            {
              id:
                'certified_safety_belt_requirement_property_management_comments',
              component: 'Text',
              required: false,
              label: 'Property management comments',
              preview_label: 'Property management comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            }
          ]
        },
        {
          component: 'HideableGroup',
          label:
            '7. Emergency and rescue procedures are in place and operatives trained.',
          preview_label:
            '7. Emergency and rescue procedures are in place and operatives trained.',
          required: true,
          children: [
            {
              id: 'emergency_procedures_in_place',
              component: 'TwoOptionRadio',
              required: true,
              props: {
                answer1: 'Yes',
                answer2: 'No'
              }
            },
            {
              id: 'emergency_procedures_in_place_applicant_comments',
              component: 'Text',
              required: false,
              label: 'Applicant comments',
              preview_label: 'Applicant comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            },
            {
              id: 'emergency_procedures_in_place_property_management_comments',
              component: 'Text',
              required: false,
              label: 'Property management comments',
              preview_label: 'Property management comments',
              props: {
                hideIcon: true,
                placeholder: '',
                row: 8
              }
            }
          ]
        }
      ]
    },
    {
      id: 'electrical_works',
      title: 'Electrical Works',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please confirm where electrical works will be required'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          id: 'work_details',
          component: 'Text',
          required: true,
          label:
            'Please explain the details of electrical works and process involved:',
          preview_label:
            'Please explain the details of electrical works and process involved',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'electrical_isolation_required',
          component: 'TwoOptionRadio',
          required: true,
          label: 'Is there any electrical isolation required? *',
          preview_label: 'Is there any electrical isolation required? ',
          props: {
            answer1: 'Yes',
            answer2: 'No'
          }
        },
        {
          id: 'impact_details',
          component: 'Text',
          required: true,
          label:
            'Please provide the corresponding details (impact identified, technical requirements, duration)*',
          preview_label:
            'Please provide the corresponding details (impact identified, technical requirements, duration)',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'date',
          component: 'Date',
          required: true,
          label: 'Date*',
          preview_label: 'Date',
          props: {}
        },
        {
          id: 'time',
          component: 'Time',
          required: true,
          label: 'Time*',
          preview_label: 'Time',
          props: {}
        },
        {
          id: 'safety_precautions',
          component: 'Text',
          required: true,
          label: 'Please explain the safety precautions to be applied: *',
          preview_label: 'Please explain the safety precautions to be applied',
          props: {
            hideIcon: true,
            placeholder: 'What precautions need to be applied?',
            row: 8
          }
        },
        {
          id: 'ppe_required',
          component: 'Text',
          required: true,
          label: 'Please detail the PPE required: *',
          preview_label: 'Please detail the PPE required',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          component: 'Label',
          props: {
            text:
              'Please note  that we would issue only an access permit for any HV electrical works, and any works outside of normal working hours when there is no Savills responsible person onsite, so in that case the contractor has to operate under their own permit to work system.'
          }
        }
      ]
    },
    {
      id: 'fire_alarm_sprinkler_isolation',
      title: 'Fire Alarm/Sprinkler Isolation',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please confirm the location of the concerned areas'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          id: 'work_details',
          component: 'Text',
          required: true,
          label: 'Please explain the works which require isolation*',
          preview_label: 'Please explain the works which require isolation',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'systems_require_isolation',
          component: 'Text',
          required: true,
          label: 'Which areas or systems require isolation*',
          preview_label: 'Which areas or systems require isolation',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'impact_details',
          component: 'Text',
          required: true,
          label:
            'Please provide the corresponding details (impact identified, technical requirements, duration)*',
          preview_label:
            'Please provide the corresponding details (impact identified, technical requirements, duration)',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'date',
          component: 'Date',
          required: true,
          label: 'Date*',
          preview_label: 'Date',
          props: {}
        },
        {
          id: 'time',
          component: 'Time',
          required: true,
          label: 'Time*',
          preview_label: 'Time',
          props: {}
        },
        {
          id: 'safety_precautions',
          component: 'Text',
          required: true,
          label: 'Please explain the safety precautions to be applied: *',
          preview_label: 'Please explain the safety precautions to be applied',
          props: {
            hideIcon: true,
            placeholder: 'What precautions need to be applied?',
            row: 8
          }
        },
        {
          id: 'ppe_required',
          component: 'Text',
          required: true,
          label: 'Please detail the PPE required: *',
          preview_label: 'Please detail the PPE required',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        }
      ]
    },
    {
      id: 'mechanical_isolation',
      title: 'Mechanical Isolation',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please confirm the location of the concerned areas'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          id: 'work_details',
          component: 'Text',
          required: true,
          label:
            'Please explain the details of mechanical works which require isolation*',
          preview_label:
            'Please explain the details of mechanical works which require isolation',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'valves_plants_require_isolation',
          component: 'Text',
          required: true,
          label: 'Which valves or plants require isolation*',
          preview_label: 'Which valves or plants require isolation',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'impact_details',
          component: 'Text',
          required: true,
          label:
            'Please provide the corresponding details (impact identified, technical requirements, duration)*',
          preview_label:
            'Please provide the corresponding details (impact identified, technical requirements, duration)',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'date',
          component: 'Date',
          required: true,
          label: 'Date*',
          preview_label: 'Date',
          props: {}
        },
        {
          id: 'time',
          component: 'Time',
          required: true,
          label: 'Time*',
          preview_label: 'Time',
          props: {}
        },
        {
          id: 'safety_precautions',
          component: 'Text',
          required: true,
          label: 'Please explain the safety precautions to be applied: *',
          preview_label: 'Please explain the safety precautions to be applied',
          props: {
            hideIcon: true,
            placeholder: 'What precautions need to be applied?',
            row: 8
          }
        },
        {
          id: 'ppe_required',
          component: 'Text',
          required: true,
          label: 'Please detail the PPE required: *',
          preview_label: 'Please detail the PPE required',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        }
      ]
    },
    {
      id: 'lone_working',
      title: 'Lone Working',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please confirm the location of working area:'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          id: 'work_details',
          component: 'Text',
          required: true,
          label: 'Please explain the details of the works *',
          preview_label: 'Please explain the details of the works ',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'operative_has_medical_conditions',
          component: 'TwoOptionRadio',
          required: true,
          label:
            'Does the operative suffer from any medical condition(s) that may increase the risk of injury? If yes, give more information below. *',
          preview_label:
            'Does the operative suffer from any medical condition(s) that may increase the risk of injury? If yes, give more information below. ',
          props: {
            answer1: 'Yes',
            answer2: 'No'
          }
        },
        {
          id: 'operative_medical_conditions',
          component: 'Text',
          required: true,
          label: '',
          preview_label: '',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'identify_hazards',
          component: 'Text',
          required: true,
          label:
            'Identify the hazard which the operative may be exposed to whilst undertaking their lone working activity:*',
          preview_label:
            'Identify the hazard which the operative may be exposed to whilst undertaking their lone working activity:',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'operative_has_received_training',
          component: 'TwoOptionRadio',
          required: true,
          label:
            'Has the operative received training in what to do in the event of an emergency and is the person sufficiently experienced to carry out the lone working activity? If No, give more information below. *',
          preview_label:
            'Has the operative received training in what to do in the event of an emergency and is the person sufficiently experienced to carry out the lone working activity? If No, give more information below. ',
          props: {
            answer1: 'Yes',
            answer2: 'No'
          }
        },
        {
          id: 'operative_training',
          component: 'Text',
          required: true,
          label: '',
          preview_label: '',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'communication_type',
          component: 'Group',
          required: true,
          label:
            'Determine which form of communication is most suitable for the lone working activity being assessed.*',
          preview_label:
            'Determine which form of communication is most suitable for the lone working activity being assessed.',
          children: [
            {
              id: 'communication_type_buddy_system',
              component: 'Checkbox',
              label: 'Buddy system',
              preview_label: 'Buddy system',
              props: {
                showLabel: true
              }
            },
            {
              id: 'communication_type_physical_checks',
              component: 'Checkbox',
              label: 'Physical checks by another person',
              preview_label: 'Physical checks by another person',
              props: {
                showLabel: true
              }
            },
            {
              id: 'communication_type_periodic_telephone',
              component: 'Checkbox',
              label: 'Periodic telephone contact',
              preview_label: 'Periodic telephone contact',
              props: {
                showLabel: true
              }
            },
            {
              id: 'communication_type_surveillance',
              component: 'Checkbox',
              label: 'Constant or intermittent surveillance',
              preview_label: 'Constant or intermittent surveillance',
              props: {
                showLabel: true
              }
            },
            {
              id: 'communication_type_physical_protection',
              component: 'Checkbox',
              label: 'Physical protection from attack',
              preview_label: 'Physical protection from attack',
              props: {
                showLabel: true
              }
            }
          ]
        },
        {
          id: 'safety_precautions',
          component: 'Text',
          required: true,
          label: 'Please explain the safety precautions to be applied: *',
          preview_label: 'Please explain the safety precautions to be applied',
          props: {
            hideIcon: true,
            placeholder: 'What precautions need to be applied?',
            row: 8
          }
        },
        {
          id: 'ppe_required',
          component: 'Text',
          required: true,
          label: 'Please detail the PPE required: *',
          preview_label: 'Please detail the PPE required',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        }
      ]
    },
    {
      id: 'cdm_works_access',
      title: 'CDM Works/Access Permit',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          id: 'project_summary',
          component: 'Text',
          required: true,
          label: 'Please provide a summary of the project *',
          preview_label: 'Please provide a summary of the project ',
          props: {
            hideIcon: true,
            placeholder: 'Tell us a bit more about the project',
            row: 8
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please explain who is the:'
          }
        },
        {
          id: 'principle_designer',
          component: 'Text',
          required: true,
          label: 'Principle designer *',
          preview_label: 'Principle designer ',
          props: {
            hideIcon: true,
            placeholder: 'Who designed it?',
            row: 8
          }
        },
        {
          id: 'principle_contractor',
          component: 'Text',
          required: true,
          label: 'Principle contractor *',
          preview_label: 'Principle contractor ',
          props: {
            hideIcon: true,
            placeholder: 'Who will be doing the work?',
            row: 8
          }
        },
        {
          id: 'sitebased_person',
          component: 'Text',
          required: true,
          label: 'Site-based responsible person *',
          preview_label: 'Site-based responsible person ',
          props: {
            hideIcon: true,
            placeholder: 'Who is responsible for this work on-site?',
            row: 8
          }
        },
        {
          component: 'Label',
          props: {
            text:
              'Please note that the principle contractor is responsible for all Health and Safety associated with the construction project.'
          }
        }
      ]
    },
    {
      id: 'event',
      title: 'Event Permit',
      fields: [
        {
          id: 'company_name',
          component: 'Text',
          required: true,
          label: 'Company name *',
          preview_label: 'Company name ',
          props: {
            type: 'text',
            hideIcon: true,
            placeholder: 'Enter the company name'
          }
        },
        {
          id: 'operative_name',
          component: 'Name',
          required: true,
          label: 'Operative name *',
          preview_label: 'Operative name ',
          props: {
            hideIcon: true,
            placeholder: 'Enter an operative name'
          }
        },
        {
          component: 'Label',
          props: {
            text: 'Please confirm where the event(s) will take place'
          }
        },
        {
          id: 'building_id',
          component: 'BuildingSelect',
          required: true,
          label: 'Building *',
          preview_label: 'Building ',
          props: {
            showLabel: true
          }
        },
        {
          id: 'floor_id',
          component: 'FloorSelect',
          required: true,
          label: 'Floor *',
          preview_label: 'Floor ',
          props: {
            multi: true,
            showLabel: true
          }
        },
        {
          id: 'event_summary',
          component: 'Text',
          required: true,
          label: 'Please provide a summary of the event(s)*',
          preview_label: 'Please provide a summary of the event(s)',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'responsible_person_name',
          component: 'Text',
          required: true,
          label: 'Name of the event manage/responsible person*',
          preview_label: 'Name of the event manage/responsible person',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'safety_plan',
          component: 'Text',
          required: true,
          label: 'Safety plan*',
          preview_label: 'Safety plan',
          props: {
            hideIcon: true,
            placeholder: '',
            row: 8
          }
        },
        {
          id: 'safety_plan_document',
          component: 'File',
          required: true,
          label: 'Safety plan document*',
          preview_label: 'Safety plan document',
          props: {}
        },
        {
          id: 'public_liability_insurance_certificate',
          component: 'File',
          required: true,
          label: 'Public liability insurance certificate',
          preview_label: 'Public liability insurance certificate',
          props: {}
        },
        {
          component: 'Label',
          props: {
            text:
              'Please ensure that you have all adequate, required event(s) and alcohol licenses. Provide any additional and further required documentation in the document file section.'
          }
        }
      ]
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;

    const permitSessions = this.framework.helpers.get('mocks_permitSessions');
    if (permitSessions && Object.prototype.toString.call(permitSessions)) {
      this.permitSessions = permitSessions;
    }

    const permitSessionData = this.framework.helpers.get(
      'mocks_permitSessionData'
    );
    if (
      permitSessionData &&
      Object.prototype.toString.call(permitSessionData)
    ) {
      this.permitSessionData = permitSessionData;
    }
  }

  translateEntities = (permit) => {
    const {
      user_id,
      approved_user_id,
      rejected_user_id,
      closed_user_id,
      activated_user_id,
      building_id,
      floor_id,
      location_id,
      company_id
    } = permit;

    const {
      framework: {
        mockNamespaces: {
          UsersMockNamespace: { dataSet: users },
          BuildingsMockNamespace: { dataSet: buildings },
          FloorsMockNamespace: { dataSet: floors },
          LocationsMockNamespace: { dataSet: locations },
          CompaniesMockNamespace: { dataSet: companies },
          CommentsMockNamespace: { dataSet: comments }
        }
      }
    } = this;

    users.forEach((user) => {
      const { id } = user;
      if (id == user_id) permit.user = user;
      if (id == approved_user_id) permit.approved_user = user;
      if (id == rejected_user_id) permit.rejected_user = user;
      if (id == closed_user_id) permit.closed_user = user;
      if (id == activated_user_id) permit.activated_user = user;
    });

    if (building_id)
      permit.building = buildings.filter(({ id }) => id == building_id).pop();
    if (floor_id)
      permit.floors = floors.filter(({ id }) => id == floor_id).pop();
    if (location_id)
      permit.location = locations.filter(({ id }) => id == location_id).pop();
    if (company_id)
      permit.company = companies.filter(({ id }) => id == company_id).pop();

    permit.comments = comments
      .filter(
        ({ type, entity_id }) => type === 'permit' && entity_id == permit.id
      )
      .sort((a, b) => {
        const aDate = new Date(a.created);
        const bDate = new Date(b.created);
        return bDate.getTime() - aDate.getTime();
      });
    return permit;
  };

  refreshDataSet() {
    this.dataSet.forEach(this.translateEntities);
  }

  getPermitSection = (sectionId) =>
    this.permitSections.filter((section) => section.id === sectionId).pop();

  generateFieldMap = (section) => {
    const fieldMap = {};

    const mapField = (field) => {
      if (field.hasOwnProperty('id')) {
        fieldMap[field.id] = field;
      }
    };

    if (section && Array.isArray(section.fields)) {
      section.fields.forEach((field) => {
        if (field.hasOwnProperty('component')) {
          if (field.hasOwnProperty('children')) {
            field.children.forEach((field) => mapField(field));
          } else {
            mapField(field);
          }
        }
      });
    }
    return fieldMap;
  };

  getEndpoints() {
    const { generateUUIDV4 } = this.framework;

    const translatePermitForGet = (permit, translateValues) => {
      permit.data = {};
      const sections = [];

      this.permitData.forEach((data) => {
        const { permit_id, section_id } = data;
        if (permit_id === permit?.id) {
          if (!permit.data.hasOwnProperty(section_id)) {
            permit.data[section_id] = [];
            sections.push(section_id);
          }
          permit.data[section_id].push(data);
        }
      });

      const {
        framework: {
          mockNamespaces: {
            BuildingsMockNamespace: { dataSet: buildings },
            FloorsMockNamespace: { dataSet: floors },
            LocationsMockNamespace: { dataSet: locations },
            CompaniesMockNamespace: { dataSet: companies }
          }
        }
      } = this;

      permit.sections = this.permitSections.filter((section) => {
        const { id, fields } = section;
        if (sections.includes(id)) {
          const mappedFields = {};
          fields.forEach((field) => (mappedFields[field.id] = field));

          if (translateValues && permit.data.hasOwnProperty(id)) {
            permit.data[id].forEach((fieldData) => {
              const { data_key: fieldId, data_value: fieldValue } = fieldData;

              if (mappedFields.hasOwnProperty(fieldId)) {
                const field = mappedFields[fieldId];
                const { component } = field;

                if (component === 'BuildingSelect') {
                  const building = buildings
                    .filter(({ id }) => id == fieldValue)
                    .pop();
                  if (building) fieldData.data_value = building;
                }

                if (component === 'FloorSelect') {
                  const floor = floors
                    .filter(({ id }) => id == fieldValue)
                    .pop();
                  if (floor) fieldData.data_value = floor;
                }

                if (component === 'LocationSelect') {
                  const location = locations
                    .filter(({ id }) => id == fieldValue)
                    .pop();
                  if (location) fieldData.data_value = location;
                }

                if (component === 'CompanySelect') {
                  const company = companies
                    .filter(({ id }) => id == fieldValue)
                    .pop();
                  if (company) fieldData.data_value = company;
                }
              }
            });
          }
          return true;
        }
        return false;
      });
      return permit;
    };

    this.refreshDataSet();
    return {
      'permits/sections': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return { code: 200, data: { sections: this.permitSections } };
      },
      permits: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          building_id: { field: 'building_id', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const ret = this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
        if (payload?.id) {
          if (Array.isArray(ret?.data?.records)) {
            const permit = ret?.data?.records?.pop();

            return {
              code: 200,
              data: {
                permit: translatePermitForGet(
                  permit,
                  Boolean(payload?.translate_values)
                )
              }
            };
          }
        }
        return ret;
      },
      'permits/feed': () => this.framework.defaultPrismaMock({
          records: [...this.dataSet, ...permitsPrismaMock.records]
        }),
      'permits/session/start': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (error !== false) {
          return this.framework.createErrorResponse('unknown_error');
        }
        const { building_id, access_only, sections } = payload;

        const session = {
          id: generateUUIDV4(),
          building_id,
          user_id: 1,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        this.permitSessions[session.id] = session;

        const stepId = 'disclaimer';

        this.permitSessionData[session.id] = { [stepId]: [] };

        this.permitSessionData[session.id][stepId].push({
          id: generateUUIDV4(),
          session_id: session.id,
          section_id: stepId,
          data_key: 'sections',
          data_value: sections,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });

        this.framework.helpers.save(
          'mocks_permitSessions',
          this.permitSessions
        );
        this.framework.helpers.save(
          'mocks_permitSessionData',
          this.permitSessionData
        );

        return { code: 200, data: { session } };
      },
      'permits/session/save': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'permit_invalid_session_id',
          'permit_provide_data_key',
          'permit_provide_data_value'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!this.permitSessions[payload.session_id]) {
          return this.framework.createErrorResponse(
            'permit_invalid_session_id'
          );
        }
        const currentStepId = payload.section_id;

        if (!this.permitSessionData[payload.session_id])
          this.permitSessionData[payload.session_id] = {};
        if (!this.permitSessionData[payload.session_id][currentStepId])
          this.permitSessionData[payload.session_id][currentStepId] = [];

        if (!Array.isArray(payload.data_key)) {
          payload.data_key = [payload.data_key];
          payload.data_value = [payload.data_value];
        }

        payload.data_key.forEach((key, index) => {
          let updated = false;

          for (const index in this.permitSessionData[payload.session_id]) {
            if (
              this.permitSessionData[payload.session_id].hasOwnProperty(
                currentStepId
              ) &&
              this.permitSessionData[payload.session_id][
                currentStepId
              ].hasOwnProperty(key)
            ) {
              const { data_key } = this.permitSessionData[payload.session_id][
                currentStepId
              ];
              if (data_key === key) {
                this.permitSessionData[payload.session_id][
                  currentStepId
                ].data_value = payload.data_value[index];
                this.permitSessionData[payload.session_id][
                  currentStepId
                ].modified = new Date().toISOString();
                updated = true;
              }
            }
          }

          if (!updated) {
            const sessionData = {
              id: generateUUIDV4(),
              section_id: payload.section_id,
              session_id: payload.session_id,
              data_key: key,
              data_value: payload.data_value[index],
              created: new Date().toISOString(),
              modified: new Date().toISOString()
            };
            this.permitSessionData[payload.session_id][currentStepId].push(
              sessionData
            );
          }
        });

        this.framework.helpers.save(
          'mocks_permitSessionData',
          this.permitSessionData
        );

        return { code: 200, data: true };
      },
      'permits/session/get': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'permit_invalid_session_id',
          'permit_provide_data_key',
          'permit_no_data_found'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!this.permitSessions[payload.session_id]) {
          return this.framework.createErrorResponse(
            'permit_invalid_session_id'
          );
        }

        if (!this.permitSessionData[payload.session_id]) {
          return this.framework.createErrorResponse('permit_no_data_found');
        }

        let data = null;
        this.permitSessionData[payload.session_id].forEach((sessionData) => {
          sessionData.forEach((stepData) => {
            if (stepData.data_key === payload.data_key) data = stepData;
          });
        });

        if (!data)
          return this.framework.createErrorResponse('permit_no_data_found');

        return { code: 200, data };
      },
      'permits/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const { session_id } = payload;

        if (!this.permitSessionData.hasOwnProperty(session_id)) {
          return this.framework.createErrorResponse(
            'permits_invalid_session_id'
          );
        }

        const sessionData = this.permitSessionData[session_id];
        const { job_details, contractor_details } = sessionData;

        if (!job_details) {
          return this.framework.createErrorResponse(
            'permits_provide_job_details'
          );
        }

        if (!contractor_details) {
          return this.framework.createErrorResponse(
            'permits_provide_contractor_details'
          );
        }

        const processValue = (val) => {
          if (val === 'true' || val === 'false') val = Boolean(val);
          return val;
        };

        const permit = {
          id: this.dataSet.length + 1,
          contact_name: '',
          contact_number: '',
          company_name: '',
          draft: false,
          start: '',
          finish: '',
          user_id: 1,
          company_id: null,
          building_id: null,
          floor_id: null,
          location_id: null,
          description: '',
          safety_measures: '',
          other_details: '',
          hazard_sheet: false,
          asbestos_register: false,
          activated: false,
          activated_date: null,
          activated_user_id: null,
          closed: false,
          closed_date: null,
          closed_user_id: null,
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null,
          archived: false,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        logger.debug(sessionData);

        for (const sectionId in sessionData) {
          const section = sessionData[sectionId];

          if (sectionId === 'job_details') {
            let start_date;
            let start_time;
            let end_date;
            let end_time = '';

            section.forEach(({ data_key, data_value }) => {
              const val = processValue(data_value);
              if (permit.hasOwnProperty(data_key)) permit[data_key] = val;

              if (data_key === 'start_date') start_date = val;
              if (data_key === 'start_time') start_time = val;
              if (data_key === 'end_date') end_date = val;
              if (data_key === 'end_time') end_time = val;
            });

            if (start_date && start_time) {
              permit.start = new Date(
                `${start_date} ${start_time}`
              ).toISOString();
            }

            if (end_date && end_time) {
              permit.finish = new Date(`${end_date} ${end_time}`).toISOString();
            }
          } else {
            const permitSection = this.getPermitSection(sectionId);
            const fieldMap = this.generateFieldMap(permitSection);

            section.forEach(({ data_key, data_value }) => {
              let val = processValue(data_value);
              const dataStructure = fieldMap.hasOwnProperty(data_key)
                ? fieldMap[data_key]
                : null;

              if (dataStructure) {
                const { component } = dataStructure;
                if (component === 'Checkbox') val = Boolean(val);
              }

              this.permitData.push({
                id: generateUUIDV4(),
                permit_id: permit.id,
                section_id: sectionId,
                data_structure: dataStructure,
                data_key,
                data_value: val,
                created: new Date().toISOString(),
                modified: new Date().toISOString()
              });
            });
          }
        }

        this.dataSet.push(permit);
        return {
          code: 200,
          data: {
            permit: this.translateEntities(translatePermitForGet(permit))
          }
        };
      },
      'permits/save': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const { id, section_id, data } = payload;
          let permit = null;
          let permitIndex = null;

          this.dataSet.forEach((existingPermit, index) => {
            if (existingPermit.id === parseInt(id, 10)) {
              permit = existingPermit;
              permitIndex = index;
            }
          });

          if (permit) {
            if (section_id === 'job_details') {
              const { start_date, start_time, end_date, end_time } = data;
              for (const key in data) {
                let val = data[key];
                if (val === 'true' || val === 'false') val = Boolean(val);
                if (permit.hasOwnProperty(key)) {
                  permit[key] = val;
                }
              }

              if (start_date && start_time) {
                permit.start = new Date(
                  `${start_date} ${start_time}`
                ).toISOString();
              }

              if (end_date && end_time) {
                permit.finish = new Date(
                  `${end_date} ${end_time}`
                ).toISOString();
              }
              this.dataSet[permitIndex] = permit;
            } else {
              this.permitData = this.permitData.filter(
                (permitData) =>
                  permitData.permit_id != permit.id ||
                  permitData.section_id !== section_id
              );

              const permitSection = this.getPermitSection(section_id);
              const fieldMap = this.generateFieldMap(permitSection);

              for (const key in data) {
                let val = data[key];
                const dataStructure = fieldMap.hasOwnProperty(key)
                  ? fieldMap[key]
                  : null;

                if (dataStructure) {
                  const { component } = dataStructure;
                  if (component === 'Checkbox') val = Boolean(val);
                }

                this.permitData.push({
                  id: generateUUIDV4(),
                  permit_id: permit.id,
                  section_id,
                  data_structure: dataStructure,
                  data_key: key,
                  data_value: val,
                  created: new Date().toISOString(),
                  modified: new Date().toISOString()
                });
              }
            }

            permit = this.translateEntities(translatePermitForGet(permit));
            return { code: 200, data: { permit } };
          }
          return this.framework.createErrorResponse('no_record');
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/resubmit': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null
        };

        try {
          const permit = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { permit } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/approve': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          approved: true,
          approved_date: new Date(),
          approved_user_id: 1,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null
        };

        try {
          const permit = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { permit } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/reject': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: true,
          rejected_date: new Date(),
          rejected_user_id: 1
        };

        try {
          const permit = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );

          if (payload.comment) {
            const {
              framework: {
                mockNamespaces: { CommentsMockNamespace }
              }
            } = this;
            CommentsMockNamespace.create(
              permit.id,
              'permit',
              '',
              payload.comment
            );
          }

          return { code: 200, data: { permit } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/activate': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          activated: true,
          activated_date: new Date(),
          activated_user_id: 1
        };

        try {
          const permit = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { permit } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/deactivate': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          activated: false,
          activated_date: null,
          activated_user_id: null
        };

        try {
          const permit = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { permit } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/close': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const realPayload = {
            closed: true,
            closed_date: new Date(),
            closed_user_id: 1
          };

          const permit = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );

          if (payload.comment) {
            const {
              framework: {
                mockNamespaces: { CommentsMockNamespace }
              }
            } = this;
            CommentsMockNamespace.create(
              permit.id,
              'permit',
              '',
              payload.comment
            );
          }

          return { code: 200, data: { permit } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'permits/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
