import logger from 'services/logger/logger';

import Client from '../../Client';
import visitorsPrismaMock from './visitors-prisma-mock.json';

export default class VisitorsMockNamespace {
  dataSet = [
    {
      id: 1,
      parent_id: 0,
      user_id: 1,
      type: 'prebooked',
      building_id: 1,
      company_id: 1,
      host_name: 'Joe Bloggs',
      host_email: 'test@test.com',
      pass_no: '12345',
      visitor_type_id: 1,
      visitor_name: 'John Smith',
      visitor_email: 'test@test.com',
      visitor_company: 'Vodafone',
      checked_in: true,
      checked_in_date: '2020-12-25T16:00:13.000Z',
      checked_in_user_id: 1,
      checked_out: true,
      checked_out_date: '2020-12-25T16:47:13.000Z',
      checked_out_user_id: 2,
      arrival: '2020-12-25T09:00:13.000Z',
      departure: '2020-12-25T17:00:00.000Z',
      arrival_instructions: 'Some arrival instructions',
      special_instructions: 'May require wheelchair access',
      comments: 'Some comments',
      archived: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      parent_id: 0,
      user_id: 1,
      type: 'walkin',
      building_id: 1,
      company_id: 1,
      host_name: 'Lia Powell',
      host_email: 'test@test.com',
      pass_no: '',
      visitor_type_id: 1,
      visitor_name: 'Cristian Brooks',
      visitor_email: 'chrissy@cureoscity.com',
      visitor_company: 'Lucky Lawyers',
      checked_in: true,
      checked_in_date: '2020-12-25T16:00:13.000Z',
      checked_in_user_id: 3,
      checked_out: false,
      checked_out_date: null,
      checked_out_user_id: null,
      arrival: '2020-12-25T10:00:13.000Z',
      departure: null,
      arrival_instructions: '',
      special_instructions: '',
      comments:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      archived: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 3,
      parent_id: 0,
      user_id: 1,
      type: 'prebooked',
      building_id: 1,
      company_id: 3,
      host_name: 'Judy Smith',
      host_email: '',
      pass_no: '',
      visitor_type_id: 1,
      visitor_name: 'Luke Rodgers',
      visitor_email: '',
      visitor_company: 'Wheels-for-meals',
      checked_in: false,
      checked_in_date: null,
      checked_in_user_id: null,
      checked_out: false,
      checked_out_date: null,
      checked_out_user_id: null,
      arrival: '2020-01-25T09:00:00.000Z',
      departure: '2020-01-25T10:00:00.000Z',
      arrival_instructions:
        'They may try and feed people wheels, please be prepared',
      special_instructions: '',
      comments: '',
      archived: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 4,
      parent_id: 0,
      user_id: 1,
      type: 'prebooked',
      building_id: 1,
      company_id: 3,
      host_name: 'Eric Walker',
      host_email: 'eric@walker.com',
      pass_no: '',
      visitor_type_id: 2,
      visitor_name: 'David Brent',
      visitor_email: 'brent@wernhamhogg.co.uk',
      visitor_company: 'Wernham-Hogg paper merchants',
      checked_in: false,
      checked_in_date: null,
      checked_in_user_id: null,
      checked_out: false,
      checked_out_date: null,
      checked_out_user_id: null,
      arrival: '2020-01-25T09:00:00.000Z',
      departure: '2020-01-25T10:00:00.000Z',
      arrival_instructions: '',
      special_instructions: 'has a habit of making inappropriate jokes',
      comments: '',
      archived: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 5,
      parent_id: 0,
      user_id: 1,
      type: 'prebooked',
      building_id: 1,
      company_id: 1,
      host_name: 'Larry Longfellow',
      host_email: 'test@test.com',
      pass_no: '',
      visitor_type_id: 1,
      visitor_name: 'Kimberly jisoo',
      visitor_email: 'kimbers@cureoscity.com',
      visitor_company: 'Blackpink Studios',
      checked_in: false,
      checked_in_date: null,
      checked_in_user_id: null,
      checked_out: false,
      checked_out_date: null,
      checked_out_user_id: null,
      arrival: '2020-12-25T10:00:13.000Z',
      departure: null,
      arrival_instructions: '',
      special_instructions: '',
      comments:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      archived: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    }
  ];

  passDataSet = [
    {
      id: 1,
      url: 'http://localhost:3000/test-static/pdf-sample.pdf',
      visitor_id: 1,
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 2,
      url: 'http://localhost:3000/test-static/pdf-sample.pdf',

      visitor_id: 1,
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      url: 'http://localhost:3000/test-static/pdf-sample.pdf',

      visitor_id: 2,
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      url: 'http://localhost:3000/test-static/pdf-sample.pdf',

      visitor_id: 3,
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 5,
      url: 'http://localhost:3000/test-static/pdf-sample.pdf',
      visitor_id: 5,
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 6,
      url: 'http://localhost:3000/test-static/pdf-sample.pdf',
      visitor_id: 6,
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (visitor, ignoreContacts) => {
    const {
      id,
      user_id,
      building_id,
      company_id,
      checked_in_user_id,
      checked_out_user_id
    } = visitor;

    const {
      framework: {
        mockNamespaces: {
          UsersMockNamespace: { dataSet: users },
          BuildingsMockNamespace: { dataSet: buildings },
          CompaniesMockNamespace: { dataSet: companies },
          VisitorGuestsMockNamespace: { dataSet: guests }
        }
      }
    } = this;

    users.forEach((user) => {
      const {
        id,
        profile: { name, picture_uri }
      } = user;
      const newUser = { id, profile: { name, picture_uri } };

      if (id == user_id) visitor.user = newUser;
      if (id == checked_in_user_id) visitor.checked_in_user = newUser;
      if (id == checked_out_user_id) visitor.checked_out_user = newUser;
    });

    if (company_id)
      visitor.company = companies.filter(({ id }) => id == company_id).pop();
    if (building_id)
      visitor.building = buildings.filter(({ id }) => id == building_id).pop();

    visitor.guests = guests.filter(({ visitor_id }) => visitor_id === id);

    return visitor;
  };

  getEndpoints() {
    this.dataSet.forEach(this.translateEntities);
    const { generateUUIDV4 } = this.framework;
    return {
      visitors: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          building_id: { field: 'building_id', expression: 'eq' },
          checked_in: { field: 'checked_in', expression: 'eq' },
          checked_out: { field: 'checked_out', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'visitors/feed': () => this.framework.defaultPrismaMock({
          records: [...this.dataSet, ...visitorsPrismaMock.records],
          total: 50,
          taken: 10,
          matches: 100
        }),
      'visitors/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const {
          type,
          building_id,
          company_id,
          host_name,
          host_email,
          pass_no,
          visitor_type_id,
          visitor_name,
          visitor_email,
          visitor_company,
          arrival,
          departure,
          arrival_instructions,
          special_instructions,
          comments,
          guests
        } = payload;

        const user_id = 1;
        let checked_in = false;
        let checked_in_user_id = null;
        let checked_in_date = null;
        let approved = false;
        let approved_date = null;
        let approved_user_id = null;

        const {
          framework: {
            mockNamespaces: {
              VisitorGuestsMockNamespace: { dataSet: guestsDataSet }
            }
          }
        } = this;

        if (type === 'walkin') {
          const {
            framework: {
              mockNamespaces: {
                UsersMockNamespace: { dataSet: users }
              }
            }
          } = this;

          users.forEach(({ id }) => {
            if (id === user_id) {
              checked_in = true;
              checked_in_date = new Date();
              checked_in_user_id = user_id;
              approved = true;
              approved_date = new Date();
              approved_user_id = user_id;
            }
          });
        }

        if (Array.isArray(guests)) {
          guests.forEach(({ name, email }) => {
            if (name && email) {
              guestsDataSet.push({
                id: generateUUIDV4(),
                name,
                email,
                visitor_id: this.dataSet.length + 1,
                created: new Date(),
                modified: new Date()
              });
            }
          });
        }

        const visitor = this.translateEntities({
          id: this.dataSet.length + 1,
          parent_id: 0,
          user_id,
          type: type !== 'walkin' ? 'prebooked' : 'walkin',
          building_id,
          company_id,
          host_name,
          host_email,
          pass_no,
          visitor_type_id,
          visitor_name,
          visitor_email,
          visitor_company,
          checked_in,
          checked_in_user_id,
          checked_in_date,
          checked_out: false,
          checked_out_user_id: null,
          checked_out_date: null,
          arrival,
          departure,
          arrival_instructions,
          special_instructions,
          comments,
          archived: false,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });

        this.dataSet.push(visitor);
        return { code: 200, data: { visitor } };
      },
      'visitors/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const { guests } = payload;

        try {
          const visitor = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );

          let {
            framework: {
              mockNamespaces: {
                VisitorGuestsMockNamespace: { dataSet: guestsDataSet }
              }
            }
          } = this;

          if (Array.isArray(guests)) {
            guestsDataSet = guestsDataSet.filter(
              (visitor_id) => visitor_id != payload.id
            );

            guests.forEach(({ id, name, email }) => {
              if (name && email) {
                guestsDataSet.push({
                  id,
                  name,
                  email,
                  visitor_id: payload.id,
                  created: new Date(),
                  modified: new Date()
                });
              }
            });
          }

          return {
            code: 200,
            data: { visitor: this.translateEntities(visitor, true) }
          };
        } catch (e) {
          logger.debug(e);
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'visitors/checkin': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          checked_in: true,
          checked_in_date: new Date(),
          checked_in_user_id: 1
        };

        if (payload?.pass_no) realPayload.pass_no = payload?.pass_no;

        try {
          const visitor = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { visitor } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'visitors/checkout': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          checked_out: true,
          checked_out_date: new Date(),
          checked_out_user_id: 1
        };

        try {
          const visitor = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { visitor } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'visitors/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'visitors/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'visitors/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
      },
      'visitor-pass': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          visitor_id: { field: 'visitor_id', expression: 'eq' },
          building_id: { field: 'building_id', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const ret = this.framework.defaultSearchMock(
          payload,
          this.passDataSet,
          filters,
          permittedErrors,
          error
        );
        if (payload?.id) {
          if (Array.isArray(ret?.data?.records)) {
            const permit = ret?.data?.records?.pop();

            return {
              code: 200,
              data: {
                permit: translatePermitForGet(
                  permit,
                  Boolean(payload?.translate_values)
                )
              }
            };
          }
        }
        return { ...ret?.data.records[0] };
      }
    };
  }
}
