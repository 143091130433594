/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCursor = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="a">
      <path d="m.875 0h20.125v20.125h-20.125z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path
        d="m2.66061 1.00494 12.25889 6.33154-4.5043 1.51452 3.8498 5.2651-2.456 1.7005-3.8758-5.3167-3.11439 3.6267z"
        fill="currentColor"
      />
    </g>
  </svg>
);

IconCursor.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconCursor.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconCursor);
