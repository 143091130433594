export default class Profile {
  constructor({
    user_id,
    name,
    phone,
    date_of_birth,
    picture_uri,
    created,
    modified
  }) {
    this.user_id = user_id || 0;
    this.name = name || '';
    this.date_of_birth = date_of_birth || null;
    this.phone = phone || null;
    this.picture_uri = picture_uri || '';

    this.created = new Date(Date.parse(created)) || null;
    this.modified = new Date(Date.parse(modified)) || null;
  }
}
