/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState } from 'react';

const LayoutTemplateContext = createContext();

const LayoutTemplateProvider = (props) => {
  const [useLayoutTemplate, setUseLayoutTemplate] = useState(true);

  return (
    <LayoutTemplateContext.Provider
      value={{
        useLayoutTemplate,
        setUseLayoutTemplate
      }}
      {...props}
    />
  );
};

const useLayoutTemplateProvider = () => useContext(LayoutTemplateContext);

export { LayoutTemplateProvider, useLayoutTemplateProvider };
