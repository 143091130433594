/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconUser = ({ size, colour, fill, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      stroke="currentColor"
      d="m22.4871 23.4147v-2c0-1.0608-.4215-2.0782-1.1716-2.8284-.7502-.7501-1.7676-1.1716-2.8284-1.1716h-8c-1.06091 0-2.07832.4215-2.82847 1.1716-.75014.7502-1.17157 1.7676-1.17157 2.8284v2m12.00004-13.99997c0 2.20917-1.7909 3.99997-4 3.99997-2.2092 0-4-1.7908-4-3.99997 0-2.20914 1.7908-4 4-4 2.2091 0 4 1.79086 4 4z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
      fill={fill}
    />
  </svg>
);

IconUser.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string,
  fill: PropTypes.string
};

IconUser.defaultProps = {
  size: '100%',
  colour: 'theme-primary',
  fill: 'currentColor'
};

export default memo(IconUser);
