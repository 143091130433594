/* eslint-disable arrow-body-style */
import React from 'react';

import { AuthProvider } from 'context/auth-context';
import { BuildingProvider } from 'context/building-context';
import { NavProvider } from 'context/nav-context';
import { NotificationsProvider } from 'context/notifications-context';
import { SystemNotificationsProvider } from 'context/systemNotifications-context';
import { ThemeProvider } from 'context/theme-context';
import { UIProvider } from 'context/ui-context';
import PropTypes from 'prop-types';

import { PermitProvider } from './form-builder-context';
import { LayoutTemplateProvider } from './layout-template-context';
import { PaginationProvider } from './pagination-context';
import { TrackingProvider } from './tracking-context';

const AppProviders = ({ children }) => {
  return (
    <TrackingProvider>
      <SystemNotificationsProvider>
        <LayoutTemplateProvider>
          <AuthProvider>
            <BuildingProvider>
              <ThemeProvider>
                <NavProvider>
                  <UIProvider>
                  <PermitProvider>
                    <NotificationsProvider>
                      <PaginationProvider>{children}</PaginationProvider>
                    </NotificationsProvider>
                    </PermitProvider>
                  </UIProvider>
                </NavProvider>
              </ThemeProvider>
            </BuildingProvider>
          </AuthProvider>
        </LayoutTemplateProvider>
      </SystemNotificationsProvider>
    </TrackingProvider>
  );
};

AppProviders.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProviders;
