/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useRef, useEffect } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './TwoOptionRadio.module.scss';

const TwoOptionRadio = ({
  afterChange,
  answer1,
  answer2,
  answer1Label,
  answer2Label,
  appearance,
  className,
  dataTestIdOptionOne,
  dataTestIdOptionTwo,
  label,
  name,
  onBlur,
  onChange,
  optionDefaultValue,
  readOnly,
  value
}) => {
  const [activeValue, setActiveValue] = useState(optionDefaultValue);

  const button1 = useRef(null);
  const button2 = useRef(null);

  const handleChange = (val, fromForm) => {
    if (readOnly && !fromForm) return;

    setActiveValue(() => {
      onBlur(val);
      onChange(val);
      afterChange(val);
      return val;
    });

    if (val === answer1) {
      button1.current.focus();
    } else {
      button2.current.focus();
    }
  };

  const isAnswer1 = activeValue === answer1;
  const isAnswer2 = activeValue === answer2;

  useEffect(() => {
    handleChange(value, true);
  }, [value]);

  const handleKeypress = (e, answer) => {
    if (readOnly) e.preventDefault();
    else {
      const { key } = e;
      if (key !== 'Tab') {
        e.preventDefault();
      }

      if (
        activeValue === 'mixed' &&
        answer === answer1 &&
        (key === 'ArrowRight' || key === 'ArrowDown')
      ) {
        handleChange(answer2);
      }

      if (key === ' ' && activeValue === 'mixed') {
        handleChange(answer);
      }

      if (isAnswer1 && (key === 'ArrowRight' || key === 'ArrowDown')) {
        handleChange(answer2);
      }

      if (isAnswer2 && (key === 'ArrowLeft' || key === 'ArrowUp')) {
        handleChange(answer1);
      }
    }
  };

  return (
    <div
      className={classnames(styles.wrapper, className)}
      role="radiogroup"
      aria-labelledby={`${name}-label`}
    >
      {label && (
        <label id={`${name}-label`} className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={styles.optionsWrapper}>
        <div
          className={classnames(styles.option, {
            [styles.blue]: isAnswer1 && appearance === 'blue',
            [styles.active]: isAnswer1,
            [styles['read-only']]: readOnly
          })}
          role="radio"
          ref={button1}
          aria-checked={isAnswer1}
          onClick={() => handleChange(answer1)}
          onKeyDown={(e) => handleKeypress(e, answer1)}
          data-testid={dataTestIdOptionOne || answer1}
        >
          {answer1Label}
        </div>
        <div
          className={classnames(styles.option, {
            [styles.blue]: isAnswer2 && appearance === 'blue',
            [styles.active]: isAnswer2,
            [styles['read-only']]: readOnly
          })}
          role="radio"
          ref={button2}
          aria-checked={isAnswer2}
          onClick={() => handleChange(answer2)}
          onKeyDown={(e) => handleKeypress(e, answer2)}
          data-testid={dataTestIdOptionTwo || answer2}
        >
          {answer2Label}
        </div>
      </div>
    </div>
  );
};

TwoOptionRadio.propTypes = {
  afterChange: PropTypes.func,
  answer1: PropTypes.string,
  answer2: PropTypes.string,
  answer1Label: PropTypes.string,
  answer2Label: PropTypes.string,
  appearance: PropTypes.oneOf(['blue', 'default']),
  className: PropTypes.string,
  dataTestIdOptionOne: PropTypes.string,
  dataTestIdOptionTwo: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  optionDefaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  readOnly: PropTypes.bool,
  value: PropTypes.any
};

TwoOptionRadio.defaultProps = {
  afterChange: () => {},
  answer1: 'Yes',
  answer2: 'No',
  answer1Label: 'Yes',
  answer2Label: 'No',
  appearance: 'default',
  className: '',
  dataTestIdOptionOne: '',
  dataTestIdOptionTwo: '',
  onBlur: () => {},
  optionDefaultValue: 'mixed',
  readOnly: false,
  value: null
};

export default TwoOptionRadio;
