import Client from '../Client';

export default class Comment {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIFileUpload - containing the server side object
   */
  constructor(
    Client,
    { id, type, entity_id, comment, user, created, modified }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || '';
    this.type = type || '';
    this.entity_id = entity_id || 0;
    this.comment = comment || '';
    this.user = user || null;

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
