/* eslint-disable class-methods-use-this */
import Session from './Entities/Session';

export default class AuthCallbacks {
  /** *
   * Retrieves the existing session and passes it back to the callback
   * @param {function(Session)} callback - callback function that provides the current session
   */
  getSession(callback = () => {}) {
    throw new Error('Please implement getSession');
    callback(new Session());
  }

  /** *
   * Returns a Device object for use by the API
   * @param {function(Device)} callback - callback function that provides the current device
   */
  getDevice(callback = () => {}) {
    throw new Error('Please implement getDevice');
    callback(new Device());
  }

  handleLogout(session) {
    throw new Error('Please implement handleLogout');
  }

  /** *
   * Returns a JWT string for use by the API
   * @param {function(String, String)} callback - callback function that provides the generated JWT
   */

  getJWT(callback = () => {}) {
    throw new Error('Please implement getJWT');
    callback('', '');
  }

  /** *
   * Triggered when reauth is required by the underlying system
   * @param sessionData
   */
  requireReauth(sessionData = {}) {
    throw new Error('Please implement requireReauth');
  }
}
