/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const Heart = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 22 20"
    fill="none"
    {...rest}
  >
    <path
      d="M19.2013 2.56539C18.7127 2.06089 18.1324 1.66069 17.4938 1.38764C16.8552 1.1146 16.1707 0.97406 15.4794 0.97406C14.7881 0.97406 14.1036 1.1146 13.465 1.38764C12.8264 1.66069 12.2462 2.06089 11.7575 2.56539L10.7433 3.61191L9.72907 2.56539C8.74195 1.54682 7.40313 0.974588 6.00714 0.974588C4.61114 0.974588 3.27232 1.54682 2.28521 2.56539C1.29809 3.58396 0.74353 4.96545 0.74353 6.40593C0.74353 7.84641 1.29809 9.22789 2.28521 10.2465L3.29941 11.293L10.7433 18.9741L18.1871 11.293L19.2013 10.2465C19.6903 9.7422 20.0781 9.14348 20.3427 8.48451C20.6073 7.82554 20.7435 7.11923 20.7435 6.40593C20.7435 5.69263 20.6073 4.98631 20.3427 4.32734C20.0781 3.66837 19.6903 3.06965 19.2013 2.56539V2.56539Z"
      stroke="#999999"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Heart.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

Heart.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(Heart);
