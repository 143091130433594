/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCross = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      stroke="currentColor"
      d="m20.5989 8.20081-12.00002 11.99999m0-11.99999 12.00002 11.99999"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconCross.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconCross.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconCross);
