/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

import TwoOptionRadio from '../../TwoOptionRadio/TwoOptionRadio';

const TwoOptionRadioInput = ({
  afterChange,
  answer1,
  answer2,
  answer1Label,
  answer2Label,
  appearance,
  className,
  control,
  dataTestIdOptionOne,
  dataTestIdOptionTwo,
  defaultValue,
  label,
  name,
  readOnly
}) => (
  <Controller
    as={TwoOptionRadio}
    className={className}
    name={name}
    label={label}
    control={control}
    defaultValue={defaultValue}
    answer1={answer1}
    answer2={answer2}
    answer1Label={answer1Label}
    answer2Label={answer2Label}
    rules={{
      required: true
    }}
    appearance={appearance}
    optionDefaultValue={defaultValue}
    readOnly={readOnly}
    afterChange={afterChange}
    dataTestIdOptionOne={dataTestIdOptionOne}
    dataTestIdOptionTwo={dataTestIdOptionTwo}
  />
);

TwoOptionRadioInput.propTypes = {
  afterChange: PropTypes.func,
  answer1: PropTypes.string,
  answer2: PropTypes.string,
  answer1Label: PropTypes.string,
  answer2Label: PropTypes.string,
  appearance: PropTypes.oneOf(['blue', 'default']),
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  dataTestIdOptionOne: PropTypes.string,
  dataTestIdOptionTwo: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

TwoOptionRadioInput.defaultProps = {
  afterChange: () => {},
  answer1: 'Yes',
  answer2: 'No',
  answer1Label: 'Yes',
  answer2Label: 'No',
  appearance: 'default',
  className: '',
  dataTestIdOptionOne: '',
  dataTestIdOptionTwo: '',
  defaultValue: '',
  readOnly: false
};

export default TwoOptionRadioInput;
