import Client from '../../../Client';
import NewsCategory from './Category';
import NewsSection from './Section';

export default class Article {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APICategory - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      archived,
      body,
      building_id,
      button_text,
      category_id,
      category,
      created,
      featured,
      images,
      modified,
      publish_date,
      section,
      section_id,
      title,
      website_url
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');

    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    this.ISODateTimeToUTCDate = parseISODateTimeAsUTC;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || null;
    this.archived = archived || false;
    this.body = body || '';
    this.building_id = building_id || null;
    this.button_text = button_text || '';
    this.category_id = category_id || null;
    this.category =
      typeof category === 'object' ? new NewsCategory(Client, category) : null;
    this.featured = featured || false;
    this.images = images || [];
    this.publish_date = parseISODateTimeAsUTC(publish_date) || null;
    this.section =
      typeof section === 'object' ? new NewsSection(Client, section) : null;
    this.section_id = section_id || null;
    this.title = title || '';
    this.website_url = website_url || '';

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  getStatusLabel() {
    const { archived, publish_date } = this;

    const now = new Date();

    let status = 'live';
    if (publish_date > now) status = 'scheduled';
    if (archived) status = 'archived';
    return status;
  }
}
