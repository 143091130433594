import React from 'react';

import PropTypes from 'prop-types';

const IconRecurrence = ({ size, colour, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 1.5L11.5 3.5L9.5 5.5"
      stroke="#2F80ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 6.5V5.5C2.5 4.96957 2.71071 4.46086 3.08579 4.08579C3.46086 3.71071 3.96957 3.5 4.5 3.5H11.5"
      stroke="#2F80ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 12.5L2.5 10.5L4.5 8.5"
      stroke="#2F80ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 7.5V8.5C11.5 9.03043 11.2893 9.53914 10.9142 9.91421C10.5391 10.2893 10.0304 10.5 9.5 10.5H2.5"
      stroke="#2F80ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconRecurrence.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

IconRecurrence.defaultProps = {
  size: '100%'
};

export default IconRecurrence;
