/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import VisuallyHidden from 'components/accessibility/VisuallyHidden';
import { IconArrowDropDown } from 'components/Icons';
import PropTypes from 'prop-types';
import { prop, sortBy } from 'ramda';
import { renderToStaticMarkup } from 'react-dom/server';
import { keyGenerator } from 'utils';

import styles from './Select.module.scss';

const Select = ({
  className,
  dataTestId,
  defaultText,
  disableDefaultOption,
  error,
  forForm,
  fullWidth,
  hideDisabledDefault,
  inputRef,
  isRequired,
  label,
  labelKey,
  name,
  onChange,
  readOnly,
  selectedValue,
  sortKey,
  showLabel,
  valueKey,
  values
}) => {
  const iconAsString = encodeURIComponent(
    renderToStaticMarkup(<IconArrowDropDown />)
  );
  const keyIsValidAndExists = (value, param) =>
    typeof param === 'string' &&
    param.length &&
    Object.prototype.hasOwnProperty.call(value, param);

  const [options, setOptions] = useState('');

  const setSelected = () => {
    let selectedSet = false;
    const sortedValues =
      values && values.length ? sortBy(prop(sortKey), values) : [];
    const mapped = !Array.isArray(sortedValues)
      ? []
      : sortedValues.map((value, index) => {
          let optionValue = '';
          let optionLabel = '';
          let disabled = false;

          if (value?.disabled) disabled = value?.disabled;
          else if (value?.enabled) disabled = !value.enabled;

          if (typeof value === 'object') {
            if (keyIsValidAndExists(value, valueKey))
              optionValue = value[valueKey];
            if (keyIsValidAndExists(value, labelKey))
              optionLabel = value[labelKey];
          } else {
            optionValue = value;
            optionLabel = value;
          }

          if (String(selectedValue) === String(optionValue)) selectedSet = true;

          return (
            <option
              selected={String(selectedValue) === String(optionValue)}
              key={keyGenerator(value, index)}
              value={optionValue}
              disabled={disabled}
            >
              {optionLabel}
            </option>
          );
        });

    if (!hideDisabledDefault) {
      mapped.unshift(
        <option
          selected={!selectedSet}
          disabled={disableDefaultOption}
          value="-9999"
          key={keyGenerator(-9999, 0)}
        >
          {defaultText}
        </option>
      );
    }

    setOptions(mapped);
  };

  useEffect(() => {
    setSelected();
  }, [values, selectedValue]);

  useEffect(() => {
    setSelected();
  }, []);

  const renderLabel = showLabel ? (
    <label className={styles.visualLabel} htmlFor={`select-${name}`}>
      {label}
      {isRequired && !label?.endsWith('*') ? '*' : ''}
    </label>
  ) : (
    <VisuallyHidden>
      <label htmlFor={`select-${name}`}>
        {label}
        {isRequired && label && !label?.endsWith('*') ? '*' : ''}
      </label>
    </VisuallyHidden>
  );

  const wrapperClasses = classnames(styles.wrapper, {
    [styles['for-form']]: forForm,
    [className]: className,
    [styles['has-label']]: showLabel,
    [styles['read-only']]: readOnly,
    [styles['full-width']]: fullWidth
  });

  return (
    <div className={wrapperClasses}>
      {renderLabel}
      <select
        title={label}
        ref={inputRef}
        defaultValue={selectedValue}
        disabled={readOnly}
        onChange={onChange}
        className={styles.select}
        name={name}
        id={`select-${name}`}
        style={{
          backgroundImage: `url("data:image/svg+xml;utf8,${iconAsString}")`
        }}
        aria-describedby={`${name}-subtext`}
        data-testid={dataTestId}
      >
        {options}
      </select>
      <span
        id={`${name}-subtext`}
        className={styles.subtext}
        aria-live="assertive"
      >
        {error && <span>{error.message}</span>}
      </span>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  defaultText: PropTypes.string,
  disableDefaultOption: PropTypes.bool,
  error: PropTypes.object,
  forForm: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hideDisabledDefault: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  isRequired: PropTypes.bool,
  label: PropTypes.string,

  labelKey: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  selectedValue: PropTypes.any,
  sortKey: PropTypes.string,
  showLabel: PropTypes.bool,
  valueKey: PropTypes.string,
  values: PropTypes.array.isRequired
};

Select.defaultProps = {
  className: null,
  dataTestId: null,
  defaultText: 'Please select',
  disableDefaultOption: false,
  error: null,
  forForm: false,
  fullWidth: false,
  hideDisabledDefault: false,
  inputRef: null,
  isRequired: false,
  label: null,
  labelKey: '',
  onChange: () => {},
  readOnly: false,
  selectedValue: null,
  sortKey: '',
  showLabel: false,
  valueKey: ''
};

export default Select;
