import logger from 'services/logger/logger';

import { getApiUrl } from '../../../utils/api';
import Client from '../Client';
import Visitor from '../Entities/Visitor';
import GenericNamespaceHandler from '../GenericNamespaceHandler';
import dateToISOStringDiscountingOffset from './_utils/dateToISOStringDiscountingOffset';
import VisitorTypes from './Visitors/VisitorTypes';

export default class VisitorsNamespace extends GenericNamespaceHandler {
  types = null;

  namespace = 'visitors';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = Visitor;

  responseKey = 'visitor';

  requiredFields = [];

  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    super(Client);
    this.types = new VisitorTypes(Client);
  }

  /**
   * Search types, you must be logged in to perform this action
   *
   * @param {int} buildingId - required
   * @param {Object} [criteria] - key value pair for search criteria eg: {name: 'john'}
   * @param {int} [page] - defaults to 1, this is the page number you want results for
   * @param {String} [orderBy] - field to order the data by, default will use natural sorting
   * @param {String} [orderDirection] - direction (asc / desc) you want to order the results by
   *
   * @return {Promise} - Promise object including results, page and pages
   */
  searchByBuildingId = async (buildingId, query = {}) => {
    const {
      skip = 0,
      take = 10,
      orderBy = [],
      where = {},
      include = {
        building: true,
        company: true,
        user: true,
        guests: true
      }
    } = query;

    const response = await this.queryFeed({
      building_id: buildingId,
      skip: skip || 0,
      take: take || 10,
      ...(Object.keys(include).length && { include }),
      ...(Object.keys(where).length && { where }),
      ...(orderBy.length && { orderBy })
    });
    const records = response.data.records.map(
      (entity) => new this.responseEntity(this.client, entity)
    );
    return {
      ...response.data,
      records
    };
  };

  create(
    type = 'prebooked',
    buildingId = null,
    arrival = null,
    departure = null,
    visitorName = '',
    visitorCompany = '',
    companyId = null,
    hostName = '',
    hostEmail = '',
    optional = {
      pass_no: '',
      visitor_type_id: '',
      visitor_email: '',
      arrival_instructions: '',
      special_instructions: '',
      comments: '',
      guests: []
    }
  ) {
    let data = {
      type,
      building_id: buildingId,
      arrival: dateToISOStringDiscountingOffset(arrival),
      departure: dateToISOStringDiscountingOffset(departure),
      visitor_name: visitorName,
      visitor_company: visitorCompany,
      company_id: companyId,
      host_name: hostName,
      host_email: hostEmail
    };

    if (Object.keys(optional).length) data = Object.assign(data, optional);

    return super.create(data);
  }

  update(
    id = null,
    optional = {
      arrival: undefined,
      departure: undefined,
      visitor_name: undefined,
      visitor_company: undefined,
      host_name: undefined,
      host_email: undefined,
      pass_no: undefined,
      visitor_type_id: undefined,
      visitor_email: undefined,
      arrival_instructions: undefined,
      special_instructions: undefined,
      comments: undefined,
      guests: undefined
    }
  ) {
    const dataWithNormalisedDates = {
      ...optional,
      arrival: dateToISOStringDiscountingOffset(optional.arrival),
      departure: dateToISOStringDiscountingOffset(optional.departure)
    };

    return super.update(id, dataWithNormalisedDates);
  }

  checkin(id, passNo) {
    logger.debug(passNo);
    return this.checkinCheckout('checkin', id, passNo);
  }

  checkout(id, passNo) {
    return this.checkinCheckout('checkout', id, passNo);
  }

  checkinCheckout(mode = 'checkin', id, passNo) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      const data = { id };
      if (mode !== 'checkin') mode = 'checkout';
      else if (passNo) data.pass_no = passNo;

      this.client.request(
        this.namespace,
        mode,
        (error, { data }) => {
          if (error) reject(error);
          resolve(new this.responseEntity(this.client, data?.visitor));
        },
        data,
        Client.methods.PUT
      );
    });
  }

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(id, unarchive) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        unarchive ? 'unarchive' : 'archive',
        (error, { data }) => resolve(Boolean(data)),
        { id },
        Client.methods.PUT
      );
    });
  }

  getPass(visitor_id, building_id) {
    return new Promise((resolve, reject) => {
      if (
        isNaN(visitor_id) ||
        isNaN(building_id) ||
        visitor_id <= 0 ||
        building_id <= 0
      )
        throw this.client.translateResponseError(new Error('provide_id'));

      this.client.request(
        '',
        'visitor-pass',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response);
          }
        },
        { building_id, visitor_id },
        Client.methods.POST,
        undefined,
        {
          baseUrl: getApiUrl(),
          headers: {
            'device-id': 'auto',
            'session-id': 'auto',
            'api-key': 'auto',
            'request-id': 'auto'
          }
        }
      );
    });
  }

  getBulkTemplate(building_id) {
    return new Promise((resolve, reject) => {
      if (isNaN(building_id) || building_id <= 0)
        throw this.client.translateResponseError(new Error('provide_id'));

      this.client.request(
        this.namespace,
        'batch/template',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response);
          }
        },
        { building_id },
        Client.methods.GET,
        undefined,
        {
          baseUrl: getApiUrl(),
          headers: {
            'device-id': 'auto',
            'session-id': 'auto',
            'api-key': 'auto',
            'request-id': 'auto'
          }
        }
      );
    });
  }

  getBulkSignedUploadUrl(fileName, fileType, userId) {
    return new Promise((resolve, reject) => {
      if (!fileName || fileName.indexOf('.') === -1 || !fileType)
        throw this.client.translateResponseError(
          new Error('provide_valid_file')
        );

      const fileNameParts = fileName.split('.');
      const hashedFileName = [
        `${fileNameParts[0]}-${userId}-${Date.now()}`,
        ...fileNameParts.slice(1)
      ].join('.');

      this.client.request(
        '',
        'resource/s3/presignedlink',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve({
              ...response,
              fileName: hashedFileName
            });
          }
        },
        {
          fileName: hashedFileName,
          fileType,
          bucketName: this.client.config.visitorBatchUploadBucket
        },
        Client.methods.POST,
        undefined,
        {
          baseUrl: getApiUrl(),
          headers: {
            'device-id': 'auto',
            'session-id': 'auto',
            'api-key': 'auto',
            'request-id': 'auto'
          }
        }
      );
    });
  }

  bulkImport(fileName, buildingId) {
    return new Promise((resolve, reject) => {
      if (!fileName || isNaN(buildingId) || buildingId <= 0)
        throw this.client.translateResponseError(
          new Error('provide_valid_file')
        );

      this.client.request(
        this.namespace,
        'batch',
        (error, response, status) => {
          const importError = new Error(error);
          if (error) {
            importError.status =
              response?.error?.indexOf('Visitor:') !== -1 ? '400' : status;
            importError.message = response?.error;
            reject(importError);
          } else {
            resolve(response);
          }
        },
        { fileName, buildingId },
        Client.methods.POST,
        undefined,
        {
          baseUrl: getApiUrl(),
          headers: {
            'device-id': 'auto',
            'session-id': 'auto',
            'api-key': 'auto',
            'request-id': 'auto'
          }
        }
      );
    });
  }
}
