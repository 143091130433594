import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';

import classnames from 'classnames';
import * as Icons from 'components/Icons';
import './DatePicker.scss';
import moment from 'moment';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import styles from './DatePickerComponent.module.scss';

const renderIcon = ({ iconName, colour }) => {
  const { [`Icon${iconName}`]: Icon } = Icons;

  if (!Icon) return null;

  return (
    <span className={styles.icon} aria-hidden="true" key={`icon-${iconName}`}>
      <Icon colour={colour || 'neutral-4'} />
    </span>
  );
};

const DatePickerComponent = ({
  name,
  onChange,
  defaultValue,
  required,
  mustBeInTheFuture,
  className,
  minDate,
  maxDate,
  editingMode,
  highlightDates,
  readOnly,
  isClearable,
  register,
  prefixIcon,
  suffixIcon,
  value,
  dataTestId,
  inline,
  reoccurView
}) => {
  const defaultValueAsDate = useMemo(() => new Date(defaultValue), [
    defaultValue
  ]);
  const [date, setDate] = useState(
    defaultValue !== '--/--/----' ? defaultValueAsDate : null
  );
  const [reoccurDates, setReoccurDates] = useState(highlightDates);

  const changeDate = useCallback(
    (selectedDate, triggerOnChange = false) => {
      if (selectedDate === '--/--/----' || selectedDate === null) {
        setDate(null);
        return;
      }

      if (selectedDate && selectedDate !== '--/--/----') {
        let reconstitutedDate = selectedDate;
        let wrongFormat = false;

        if (typeof selectedDate === 'string' && selectedDate.includes('/')) {
          const [day, month, year] = selectedDate.split('/');
          wrongFormat = true;

          reconstitutedDate = `${year}-${month}-${day}`;
        }

        const nDate = moment(reconstitutedDate);

        if (nDate.isValid()) {
          const formattedDate = nDate.format('YYYY-MM-DD');
          setDate(nDate.toDate());

          if (triggerOnChange || wrongFormat) onChange(formattedDate);
        }
      }
    },
    [onChange]
  );

  const handleClick = (selectedDate) => {
    let santisedSelectedDate = null;

    if (selectedDate instanceof Date) {
      santisedSelectedDate = selectedDate;
    } else if (typeof selectedDate === 'string') {
      santisedSelectedDate = selectedDate?.trim();
    }
    onChange(santisedSelectedDate, name);
    if (!readOnly) changeDate(santisedSelectedDate, true);
  };

  useEffect(() => {
    if (Object.prototype.toString.call(defaultValue) === '[object Date]') {
      return changeDate(defaultValue, true);
    }

    return changeDate(defaultValue);
  }, [changeDate, defaultValue]);

  useEffect(() => {
    const newHighlightDates =
      highlightDates &&
      highlightDates.map(
        (dateObject) => dateObject.status !== 'deleted' && dateObject.date
      );
    setReoccurDates(newHighlightDates);
  }, [highlightDates]);

  useEffect(() => {
    changeDate(value);
  }, [changeDate, value]);

  const containerClasses = classnames(styles.datepickerContainer, className, {
    [styles['read-only']]: readOnly,
    [styles.noClose]: inline
  });

  const iconClasses = classnames(styles.suffix, {
    [styles['icon-read-only']]: readOnly
  });

  const inputClasses = classnames(styles.datepicker, {
    [styles['prefix-icon-margin']]: prefixIcon,
    [styles['suffix-icon-padding-1']]: suffixIcon,
    [styles['read-only']]: readOnly,
    [styles.reoccurView]: reoccurView
  });

  const getDayClassName = (dateToCheck) => {
    if (!editingMode && readOnly && highlightDates) {
      const formattedDate = moment(dateToCheck).format('YYYY-MM-DD');
      const formattedHighlightDates = highlightDates?.map((dateObject) =>
        moment(dateObject.date).format('YYYY-MM-DD')
      );
      if (readOnly && formattedHighlightDates.includes(formattedDate)) {
        return ['noneHighlighted', 'disabledHighlight'];
      }
    }

    return 'noneHighlighted';
  };

  return (
    <div
      key={shortid.generate()}
      className={containerClasses}
      data-testid={dataTestId}
    >
      {prefixIcon && (
        <span className={styles.prefix}>{renderIcon(prefixIcon)}</span>
      )}
      <DatePicker
        className={inputClasses}
        selected={date}
        placeholderText="--/--/----"
        dateFormat="dd/MM/yyyy"
        register={register}
        onSelect={(selectedDate) => handleClick(selectedDate)}
        onBlur={(e) => handleClick(e.target.value)}
        name={name}
        id={`date_picker-${name}`}
        minDate={minDate || (mustBeInTheFuture && new Date())}
        maxDate={maxDate}
        mustBeInTheFuture={mustBeInTheFuture}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        defaultValue={defaultValue}
        isClearable={isClearable}
        onChange={(selectedDate) => handleClick(selectedDate)}
        dayClassName={reoccurView && getDayClassName}
        highlightDates={
          highlightDates && reoccurDates.length > 0 && reoccurDates
        }
        required={required}
        readOnly={readOnly}
        inline={inline}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      {!isClearable && suffixIcon && (
        <span className={iconClasses}>{renderIcon(suffixIcon)}</span>
      )}
    </div>
  );
};

DatePickerComponent.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  isClearable: PropTypes.bool,
  mustBeInTheFuture: PropTypes.bool,
  readOnly: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.shape([]),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  editingMode: PropTypes.bool,
  highlightDates: PropTypes.shape([]),
  prefixIcon: PropTypes.bool,
  suffixIcon: PropTypes.bool,
  value: PropTypes.instanceOf(Date).isRequired,
  inline: PropTypes.bool,
  reoccurView: PropTypes.bool
};

DatePickerComponent.defaultProps = {
  name: '',
  onChange: () => {},
  defaultValue: null,
  required: false,
  isClearable: false,
  mustBeInTheFuture: false,
  readOnly: false,
  dataTestId: 'date_picker',
  className: [],
  minDate: null,
  maxDate: null,
  editingMode: false,
  highlightDates: [],
  prefixIcon: false,
  suffixIcon: false,
  inline: false,
  reoccurView: false
};

export default DatePickerComponent;
