import logger from 'services/logger/logger';

import Client from '../../../Client';
import DepartmentsMockNamespace from '../Buildings/Departments';
import helpdeskPrismaMock from './helpdesk-prisma-mock.json';

export default class HelpdeskTicketMockNamespace {
  dataSet = [
    {
      id: 1,
      user_id: 1,
      assigned_user_id: null,
      category_id: 1,
      priority_id: null,
      building_id: 1,
      department_id: 1,
      floors: [
        {
          id: 1,
          name: 'Floor 1',
          building_id: 1,
          seq: 1,
          enabled: true,
          created: '2020-08-28T05:12:13.000Z',
          modified: '2020-08-28T05:12:13.000Z'
        },
        {
          id: 2,
          name: 'Floor 2',
          building_id: 1,
          seq: 2,
          enabled: true,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      ],
      location_id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      additional_comments:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      archived: false,
      assigned: null,
      closed: null,
      attending_date: null,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      user_id: 2,
      assigned_user_id: 1,
      category_id: 2,
      priority_id: 3,
      building_id: 1,
      department_id: 1,
      floors: [
        {
          id: 1,
          name: 'Floor 1',
          building_id: 1,
          seq: 1,
          enabled: true,
          created: '2020-08-28T05:12:13.000Z',
          modified: '2020-08-28T05:12:13.000Z'
        }
      ],
      location_id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      additional_comments:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      files: [],
      archived: false,
      assigned: '2020-08-28T11:53:56.000Z',
      closed: null,
      attending_date: '2021-03-21T11:53:56.000Z',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      user_id: 3,
      assigned_user_id: 4,
      category_id: 2,
      priority_id: 3,
      building_id: 1,
      department_id: 1,
      floors: [
        {
          id: 2,
          name: 'Floor 2',
          building_id: 1,
          seq: 2,
          enabled: true,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      ],
      location_id: 1,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      additional_comments:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam rhoncus ut dolor quis aliquam. Cras et leo sed arcu ornare porttitor at a nibh.',
      files: [
        {
          id: '7f75e9a0-33d2-410a-818d-64240b51ad2f',
          uri: 'https://i.imgur.com/flmr83K.png',
          type: 'image'
        }
      ],
      archived: false,
      assigned: '2020-08-28T11:53:56.000Z',
      closed: '2020-08-29T11:53:56.000Z',
      attending_date: '2021-03-21T11:53:56.000Z',
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-29T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (ticket) => {
    const {
      user_id,
      assigned_user_id,
      category_id,
      priority_id,
      building_id,
      department_id,
      floors,
      location_id
    } = ticket;
    const {
      framework: {
        mockNamespaces: {
          UsersMockNamespace: { dataSet: users },
          HelpdeskCategoriesMockNamespace: { dataSet: categories },
          PrioritiesMockNamespace: { dataSet: priorities },
          BuildingsMockNamespace: { dataSet: buildings },
          DepartmentsMockNamespace: { dataSet: departments },
          FloorsMockNamespace: { dataSet: floorsMockNamespace },
          LocationsMockNamespace: { dataSet: locations },
          CommentsMockNamespace: { dataSet: comments }
        }
      }
    } = this;

    if (user_id) ticket.user = users.filter(({ id }) => id == user_id).pop();
    if (assigned_user_id)
      ticket.assigned_user = users
        .filter(({ id }) => id == assigned_user_id)
        .pop();
    if (category_id)
      ticket.category = categories.filter(({ id }) => id == category_id).pop();
    if (priority_id)
      ticket.priority = priorities.filter(({ id }) => id == priority_id).pop();
    if (building_id)
      ticket.building = buildings.filter(({ id }) => id == building_id).pop();
    if (department_id)
      ticket.department = departments
        .filter(({ id }) => id == department_id)
        .pop();
    if (floors)
      ticket.floors = floors.filter(({ id }) =>
        floorsMockNamespace.filter(({ id: mockFloorId }) => id === mockFloorId)
      );
    if (location_id)
      ticket.location = locations.filter(({ id }) => id == location_id).pop();

    ticket.comments = comments
      .filter(
        ({ type, entity_id }) => type === 'ticket' && entity_id == ticket.id
      )
      .sort((a, b) => {
        const aDate = new Date(a.created);
        const bDate = new Date(b.created);
        return bDate.getTime() - aDate.getTime();
      });
    return ticket;
  };

  refreshDataSet() {
    this.dataSet.forEach(this.translateEntities);
  }

  getEndpoints() {
    const { generateUUIDV4 } = this.framework;
    this.refreshDataSet();

    return {
      helpdesk: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'helpdesk/feed': () => this.framework.defaultPrismaMock({
          records: [...this.dataSet, ...helpdeskPrismaMock.records]
        }),
      'helpdesk/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        const {
          framework: {
            mockNamespaces: {
              FloorsMockNamespace: { dataSet: floorsMockNamespace }
            }
          }
        } = this;

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const {
          priority_id,
          building_id,
          floors,
          location_id,
          description,
          additional_comments,
          files
        } = payload;

        const entity = {
          id: this.dataSet.length + 1,
          user_id: 1,
          assigned_user_id: null,
          priority_id,
          building_id,
          floors: floors.map((id) =>
            floorsMockNamespace.find(({ id: floorId }) => id === floorId)
          ),
          location_id,
          description,
          additional_comments,
          files: [],
          archived: false,
          assigned: null,
          closed: null,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        if (Array.isArray(files)) {
          files.forEach((image) => {
            if (image) {
              const { id, uri } = image;
              entity.files.push({ id: generateUUIDV4(), uri, type: 'image' });
            }
          });
        }

        this.dataSet.push(this.translateEntities(entity));
        return { code: 200, data: { ticket: entity } };
      },
      'helpdesk/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id',
          'provide_building_id',
          'duplicate_name'
        ];
        const {
          framework: {
            mockNamespaces: {
              FloorsMockNamespace: { dataSet: floorsMockNamespace }
            }
          }
        } = this;

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const { files, files_for_delete } = payload;
          if (Object.hasOwnProperty.call(payload, 'files'))
            delete payload.files;
          if (Object.hasOwnProperty.call(payload, 'files_for_delete'))
            delete payload.files_for_delete;

          const ret = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );

          if (Array.isArray(files)) {
            files.forEach((image) => {
              if (image) {
                const { id, uri } = image;
                let existingIndex = null;
                files.forEach((image, index) => {
                  if (id === image.id) existingIndex = index;
                });

                if (existingIndex) {
                  ret.files[existingIndex].uri = uri;
                } else {
                  ret.files.push({
                    id: generateUUIDV4(),
                    uri,
                    type: 'image'
                  });
                }
              }
            });
          }

          if (Array.isArray(files_for_delete)) {
            ret.files = ret.files.filter(
              ({ id }) => !files_for_delete.includes(id)
            );
          }

          ret.floors = ret.floors.map((id) =>
            floorsMockNamespace.find(({ id: floorId }) => id === floorId)
          );

          return { code: 200, data: { ticket: this.translateEntities(ret) } };
        } catch (e) {
          logger.debug(e);
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'helpdesk/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      },
      'helpdesk/assign': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const {
            attending_date,
            assigned_user_id,
            category_id,
            department_id,
            priority_id
          } = payload;

          const ret = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            {
              assigned: new Date().toISOString(),
              attending_date,
              assigned_user_id,
              category_id,
              department_id,
              priority_id
            }
          );
          return { code: 200, data: { ticket: this.translateEntities(ret) } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'helpdesk/reassign': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const { assigned_user_id } = payload;

          const ret = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            { assigned: new Date().toISOString(), assigned_user_id }
          );
          return { code: 200, data: { ticket: this.translateEntities(ret) } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'helpdesk/close': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const { assigned_user_id, department_id, priority_id } = payload;

          const ticket = this.translateEntities(
            this.framework.updateRecord(this.dataSet, payload.id, 'id', {
              closed: new Date().toISOString(),
              assigned_user_id,
              department_id,
              priority_id
            })
          );

          if (payload.comment) {
            const {
              framework: {
                mockNamespaces: { CommentsMockNamespace }
              }
            } = this;
            CommentsMockNamespace.create(
              ticket.id,
              'ticket',
              '',
              payload.comment
            );
          }

          return { code: 200, data: { ticket } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'helpdesk/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'helpdesk/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
