/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconWalk = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 81 81"
    fill="none"
    {...rest}
  >
    <g fill="currentColor">
      <path d="m43.3 53.2c-1.1 2.7-1.9 6.5 1.2 7.3 4.8 1.3 5.5-5.9 5.5-5.9z" />
      <path d="m54.1 43.9c.7-3.7.7-10.1-3.8-11.7-1.1-.4-5.3-1.4-6.9 5.9-1.3 5.4.6 11.8.6 11.8l6.8 1.4s2.8-5 3.3-7.4z" />
      <path d="m36.3 42.8c.8 2.8 1.2 6.7-2 7.2-4.9.8-4.8-6.5-4.8-6.5z" />
      <path d="m26.6 32.4c-.3-3.8.4-10.1 5-11.2 1.1-.3 5.4-.8 6.3 6.6.7 5.4-1.9 11.6-1.9 11.6l-6.9.7c0 .1-2.3-5.2-2.5-7.7z" />
    </g>
  </svg>
);

IconWalk.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconWalk.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconWalk);
