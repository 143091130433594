/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import VisuallyHidden from 'components/accessibility/VisuallyHidden';
import { useTheme } from 'context/theme-context';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import styles from './Checkbox.module.scss';

const Checkbox = ({
  additionalClasses = {},
  afterChange,
  dataTestId,
  defaultChecked,
  deleteSelector,
  hideLabel,
  inline,
  label,
  inputRef,
  onChange,
  onClick,
  readOnly,
  required,
  value
}) => {
  const isControlled = value === true || value === false;
  const [isFocused, setFocused] = useState(false);
  const [isChecked, setChecked] = useState(defaultChecked);
  const { getCurrentTheme } = useTheme();

  const {
    colours: {
      success = '#00c48c',
      deleteSelect = '#FD0E3B',
      'neutral-4': neutral4 = '#999999',
      'interactive-blue': interactiveBlue = '#126bfb'
    }
  } = getCurrentTheme();

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const getStrokeColour = () => {
    if (readOnly) {
      return neutral4;
    }

    if (isFocused) {
      return interactiveBlue;
    }
    if (isChecked && deleteSelector) {
      return deleteSelect;
    }
    if (isChecked) {
      return success;
    }
    return neutral4;
  };

  const handleClick = (e) => {
    if (e) e.stopPropagation();
    !readOnly && onClick && onClick();
    if (!readOnly && typeof onChange === 'function') {
      onChange(!isChecked);
      afterChange();
      if (!isControlled) setChecked((checked) => !checked);
    }
  };

  const handleKeypress = ({ charCode }) => {
    if (!readOnly && charCode === 32) {
      handleClick();
    }
  };

  const checkboxId = shortid.generate();

  const wrapperClasses = classnames(
    'body-1',
    styles.wrapper,
    {
      [styles.inline]: inline,
      [styles.readOnly]: readOnly
    },
    additionalClasses
  );

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const renderLabel = () => {
    if (hideLabel) {
      return (
        <VisuallyHidden>
          <label
            className={classnames(styles.label, {
              [styles.readOnly]: readOnly
            })}
            htmlFor={checkboxId}
            ref={inputRef}
          >
            {label}
            {required && <span className={styles.required}>required</span>}
          </label>
        </VisuallyHidden>
      );
    }
    return (
      <label
        className={classnames(styles.label, { [styles.readOnly]: readOnly })}
        htmlFor={checkboxId}
      >
        {label}
        {required && <span className={styles.required}>required</span>}
      </label>
    );
  };

  const fill = classnames({
    '#00c48c': isChecked && !deleteSelector && !readOnly,
    '#FFFFFF': !isChecked,
    '#999': isChecked && readOnly,
    '#FD0E3B': isChecked && deleteSelector && !readOnly
  });

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <div
      className={wrapperClasses}
      onClick={handleClick}
      onKeyPress={handleKeypress}
      data-testid={dataTestId}
    >
      <input
        className={styles['checkbox-invisible']}
        type="checkbox"
        ref={inputRef}
      />
      <svg
        id={checkboxId}
        className={styles.checkbox}
        width="20"
        height="18"
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid={`${dataTestId}_checkbox`}
        tabIndex="0"
        role="checkbox"
        aria-checked={isChecked}
      >
        <motion.rect
          animate={{
            fill,
            stroke: getStrokeColour()
          }}
          x="2"
          y="2"
          width="14"
          height="14"
          rx="2px"
          fill={fill}
          stroke={neutral4}
          strokeWidth="2px"
        />
        <motion.path
          animate={{
            strokeDashoffset: isChecked ? 0 : 20
          }}
          transition={{
            delay: 0.1,
            duration: isChecked ? 0.3 : 0
          }}
          d="M5 9L8 12L14 6"
          stroke="white"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeDasharray="20"
          strokeLinejoin="round"
          strokeDashoffset="20"
        />
      </svg>
      {renderLabel()}
    </div>
  );
};

Checkbox.propTypes = {
  afterChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  hideLabel: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.bool
};

Checkbox.defaultProps = {
  afterChange: () => {},
  defaultChecked: false,
  hideLabel: false,
  inline: false,
  readOnly: false,
  inputRef: null,
  required: false,
  value: null
};

export default Checkbox;
