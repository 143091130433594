export default class Session {
  /** *
   *
   * @param {Object} APISession - Session passed back by the API
   */
  constructor(
    Client,
    { id, device_id, user_id, refresh_token, expiry, verified }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    this.Client = Client;

    const { parseISODateTimeAsLocalTime } = Client;

    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || '';
    this.device_id = device_id || '';
    this.user_id = user_id || 0;
    this.refresh_token = refresh_token || '';
    this.expiry = parseISODateTimeAsLocalTime(expiry);
    this.verified = verified || false;
  }

  /** Alias * */
  hasPermission(permission, userId, companyId, buildingId, callback) {
    this.Client.sessions.hasPermission(
      permission,
      userId,
      companyId,
      buildingId,
      callback
    );
  }

  willExpire() {
    const refreshWindow = 2 * 60 * 1000;
    const { expiry } = this;
    return (
      !this.hasExpired() &&
      expiry.getTime() - refreshWindow <= new Date().getTime()
    );
  }

  hasExpired() {
    const { expiry } = this;
    return expiry.getTime() <= new Date().getTime();
  }
}
