/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable brace-style */
import React, { createContext, useContext, useState, useEffect } from 'react';

import defaultTheme from 'constants/default-theme';

import CryptoJS from 'crypto-js';
import moment from 'moment/moment';
import logger from 'services/logger/logger';

import LoadingScreen from '../screens/LoadingScreen/LoadingScreen';
import apiInstance from '../services/api/apiInstance/apiInstance';

const ThemeContext = createContext();
const APIService = apiInstance();

const ThemeProvider = ({ children, ...props }) => {
  const {
    namespaces: { general }
  } = APIService;

  const [theme, setTheme] = useState(null);

  const {
    location: { hostname }
  } = window;
  const themeKey = CryptoJS.MD5(hostname).toString();

  const applyTheme = () => {
    if (theme?.colours) {
      Object.entries(theme?.colours).forEach((themeItem) => {
        const [themeName, themeColour] = themeItem;
        document.documentElement.style.setProperty(
          `--${themeName}`,
          themeColour
        );
      });
    }

    if (theme?.favicon_location) {
      const links = document.querySelectorAll(
        "link[rel~='icon'], link[rel~='apple-touch-icon']"
      );
      links.forEach((link) => {
        const filename = link.getAttribute('filename') || 'favicon.ico';
        // eslint-disable-next-line no-param-reassign
        if (filename) link.href = `${theme?.favicon_location}${filename}`;
      });
    }
  };

  const getCurrentTheme = () => {
    const currentThemeColours = {};

    if (theme) {
      Object.keys(theme?.colours).forEach((themeColour) => {
        currentThemeColours[themeColour] = window
          .getComputedStyle(document.documentElement)
          .getPropertyValue(`--${themeColour}`)
          .trim();
      });
    }

    const images = {
      homepage_image:
        theme?.homepage_image || process?.env?.CLIENT_HOMEPAGE_IMAGE,
      homepage_logo: theme?.homepage_logo || process?.env?.CLIENT_DEFAULT_LOGO,
      logo: theme?.logo || process?.env?.CLIENT_DEFAULT_LOGO,
      logo_background:
        theme?.logo_background || process?.env?.CLIENT_PRIMARY_COLOUR
    };
    return {
      ...images,
      colours: currentThemeColours,
      homepage_variant: theme?.homepage_variant,
      homepage_position: theme?.homepage_position
    };
  };

  const getTheme = async (force) => {
    const themeStorageKey = `${themeKey}_theme`;
    let themeToUse = defaultTheme;

    try {
      if (!force) {
        const localStorageTheme = JSON.parse(
          localStorage.getItem(themeStorageKey)
        );
        if (
          localStorageTheme &&
          moment().isBefore(localStorageTheme?.expiry) &&
          localStorageTheme?.theme
        ) {
          themeToUse = localStorageTheme?.theme;
          setTheme(themeToUse);
        } else {
          throw new Error('theme_storage_expired');
        }
      } else {
        throw new Error('theme_forced');
      }
    } catch (e) {
      try {
        const platform = await general.platformSetup(themeKey);
        const { theme: foundTheme, apigw_url } = platform;
        if (foundTheme) themeToUse = foundTheme;
        if (apigw_url) {
          localStorage.setItem('cureoscity_apigw_url', apigw_url);
        }
      } catch (error) {
        logger.debug('error', error);
      } finally {
        const expiry = new Date();
        expiry.setDate(expiry.getDate() + 1);
        localStorage.setItem(
          themeStorageKey,
          JSON.stringify({ expiry, theme: themeToUse })
        );

        setTheme(themeToUse);
      }
    }
  };

  const resetTheme = () => {
    getTheme(true);
  };

  useEffect(() => {
    const shouldRefetchTheme = !Object.prototype.hasOwnProperty.call(
      localStorage,
      'cureoscity_apigw_url'
    );
    getTheme(shouldRefetchTheme);
  }, []);

  useEffect(() => {
    applyTheme();
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        getCurrentTheme,
        setTheme,
        resetTheme
      }}
      {...props}
    >
      {theme ? (
        children
      ) : (
        <LoadingScreen
          heading="Loading..."
          description="We're just setting up your portal!"
        />
      )}
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export { ThemeProvider, useTheme };
