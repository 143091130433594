import React, { createContext, useContext, useState } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';

const PaginationContext = createContext(null);

const PaginationProvider = (props) => {
  const [storageRecordsPerPage] = useLocalStorage(
    'cureoscity_records_per_page'
  );
  const defaultRecordsPerPage = storageRecordsPerPage || 10;
  const [recordsPerPage, setRecordsPerPage] = useState(defaultRecordsPerPage);

  return (
    <PaginationContext.Provider
      value={{
        recordsPerPage,
        setRecordsPerPage
      }}
      {...props}
    />
  );
};

const usePagination = () => useContext(PaginationContext);

export { PaginationProvider, usePagination };
