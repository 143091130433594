import Client from '../Client';

export default class Notification {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APINotification - containing the server side object
   */
  constructor(
    Client,
    { id, type, title, body, read, entity_type, entity_id, created, modified }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.type = type || '';
    this.title = title || '';
    this.body = body || '';
    this.entity_type = entity_type || '';
    this.entity_id = entity_id || '';

    this.read = Boolean(read);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
