/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconTrain = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 81 81"
    fill="none"
    {...rest}
  >
    <g fill="currentColor">
      <path d="m48.4 21.8h-15.8c-4 0-7.2 3.2-7.2 7.2v18.2c0 4 3.2 7.2 7.2 7.2h15.8c4 0 7.2-3.2 7.2-7.2v-18.2c0-4-3.2-7.2-7.2-7.2zm-17.1 27.4c-1.2 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1 2.1.9 2.1 2.1-1 2.1-2.1 2.1zm7.9-12.8c0 1-.8 1.8-1.8 1.8h-7.1c-1 0-1.8-.8-1.8-1.8v-4.5c0-1 .8-1.8 1.8-1.8h7.1c1 0 1.8.8 1.8 1.8zm10.5 12.8c-1.2 0-2.1-.9-2.1-2.1s.9-2.1 2.1-2.1 2.1.9 2.1 2.1-.9 2.1-2.1 2.1zm2.8-12.8c0 1-.8 1.8-1.8 1.8h-7.1c-1 0-1.8-.8-1.8-1.8v-4.5c0-1 .8-1.8 1.8-1.8h7.1c1 0 1.8.8 1.8 1.8z" />
      <path d="m52.5 57h-24v2.9h24z" />
    </g>
  </svg>
);

IconTrain.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconTrain.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconTrain);
