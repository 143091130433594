/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconFilter = ({ size }) => (
  <svg
    width={size}
    height="35px"
    viewBox="-5 0 18 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.457031 0L4.58203 6.91808V9.625L6.41536 11V6.91808L10.5404 0H0.457031ZM8.92657 0.916667L7.56028 3.20833H3.43711L2.07082 0.916667H8.92657Z"
      fill="black"
    />
  </svg>
);

IconFilter.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

IconFilter.defaultProps = {
  size: '100%'
};

export default memo(IconFilter);
