import Client from '../Client';
import Building from '../Entities/Building';
import OnboardingSession from '../Entities/OnboardingSession';
import OnboardingSessionData from '../Entities/OnboardingSessionData';
import Session from '../Entities/Session';
import User from '../Entities/User';

export default class OnboardingNamespace {
  /** *
   * @param {Client} Client - instance of client
   */
  constructor(Client) {
    this.client = Client;
    this.namespace = 'onboarding';
  }

  /**
   * Callback triggered when a response is received from the API for a save request
   *
   * @callback StartOnboardingCallback
   * @param {Error} error - any error that's been passed back
   * @param {OnboardingSession} onboardingSession - The onboarding session if successful
   */

  /** *
   * Starts the onboarding process
   * @param {StartOnboardingCallback} callback - Callback once a response is received.
   */
  start = (companyId, buildingId) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'start',
        (error, response) => {
          if (!response?.data?.session && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            resolve(new OnboardingSession(response?.data?.session));
          }
        },
        { company_id: companyId, building_id: buildingId }
      );
    });

  /**
   * Callback triggered when a response is received from the API for a save request
   *
   * @callback SaveOnboardingCallback
   * @param {Error} error - any error that's been passed back
   * @param {OnboardingSession} onboardingSession - The onboarding session if successful
   * @param {Object} onboardingSessionData - data returned if successful
   */

  /** *
   * Saves data to your onboarding session the onboarding process
   * @param {OnboardingSession} onboardingSession - Session object to save data against
   * @param {SaveOnboardingCallback} callback - Callback once a response is received.
   */
  save = (session, dataKey = '', dataValue = {}) => {
    if (
      !Array.isArray(dataKey) &&
      !['string', 'number', 'bigint', 'symbol', 'function'].includes(
        typeof dataValue
      )
    ) {
      dataValue = JSON.stringify(dataValue);
    }

    return new Promise((resolve, reject) => {
      if (
        !session ||
        typeof session !== 'object' ||
        session.constructor.name !== 'OnboardingSession'
      )
        throw new Error('Please provide an onboardingSession');

      if (dataValue === undefined || dataValue === null)
        throw new Error('Please provide a valid value');
      if (
        dataKey === 'email' &&
        (typeof dataValue !== 'string' || !dataValue.length)
      )
        throw new Error('Please provide a valid e-mail');
      if (
        (dataKey === 'password' && typeof dataValue !== 'string') ||
        !dataValue.length
      )
        throw new Error('Please provide a valid password');

      session.setDataForKey(dataKey, dataValue);
      this.client.request(
        this.namespace,
        'save',
        (error, response) => {
          if (!response?.data && !error) error = new Error('unknown_error');

          if (error) reject(error);
          else {
            resolve({ data: response?.data, session });
          }
        },
        { session_id: session.id, data_key: dataKey, data_value: dataValue }
      );
    });
  };

  uploadProfilePicture = (session, file, progressCallback) =>
    new Promise((resolve, reject) => {
      const {
        client: {
          namespaces: { general }
        }
      } = this;
      if (
        !session ||
        typeof session !== 'object' ||
        session.constructor.name !== 'OnboardingSession'
      )
        throw new Error('Please provide an onboardingSession');

      resolve(
        general.upload(
          file,
          null,
          false,
          progressCallback,
          null,
          'onboarding',
          'generateProfileUploadLink'
        )
      );
    });

  generatePin = (session) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'generatePin',
        (error, response) => {
          if (!response?.data && !error) error = new Error('unknown_error');

          if (error) reject(error);
          else {
            resolve({ data: response?.data, session });
          }
        },
        { session_id: session.id }
      );
    });

  validatePin = (session, pin) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'validatePin',
        (error, response) => {
          if (!response?.data && !error) error = new Error('unknown_error');

          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { session_id: session.id, pin }
      );
    });

  isResetTokenValid(token) {
    return token && typeof token && 'string' && token.length === 36;
  }

  requestPassword = (token, email) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'password/request',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { token, email }
      );
    });

  verifyPasswordReset = (token, pin) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'password/verify',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data?.reset_token);
          }
        },
        { token, pin }
      );
    });

  resetPassword = (token1, token2, password) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'password/reset',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve();
          }
        },
        { token1, token2, password }
      );
    });

  /** *
   *
   * @param {OnboardingSession} session - Session object to save data against
   * @param {String} dataKey - key to retrieve data against
   */
  get = (session, dataKey = '') =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session.constructor.name !== 'OnboardingSession'
      )
        throw new Error('Please provide an onboardingSession');

      this.client.request(
        this.namespace,
        'get',
        (error, response) => {
          if (!response?.data && !error) error = new Error('unknown_error');

          if (error) reject(error);
          else {
            resolve(new OnboardingSessionData(response?.data));
          }
        },
        { session_id: session.id, data_key: dataKey },
        Client.methods.GET
      );
    });

  /** *
   *
   * @param {OnboardingSession} session - Session object to save data against
   */
  getAll = (session) =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session?.constructor?.name !== 'OnboardingSession'
      )
        throw new Error('Please provide an onboardingSession');

      this.client.request(
        this.namespace,
        'getAll',
        (error, response) => {
          if (!Array.isArray(response?.data) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const data = response?.data.map(
              (sessionData) => new OnboardingSessionData(sessionData)
            );
            resolve(data);
          }
        },
        { session_id: session.id },
        Client.methods.GET
      );
    });

  getOccupiers = (buildingId) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'occupiers',
        (error, response) => {
          if (!Array.isArray(response?.data?.occupiers) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            resolve(response?.data?.occupiers);
          }
        },
        { building_id: buildingId },
        Client.methods.GET
      );
    });

  getJobRoles = (session) =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session?.constructor?.name !== 'OnboardingSession'
      )
        throw new Error('Please provide an onboardingSession');
      this.client.request(
        this.namespace,
        'jobRoles',
        (error, response) => {
          if (!Array.isArray(response?.data?.roles) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            resolve(response?.data?.roles);
          }
        },
        { session_id: session.id },
        Client.methods.GET
      );
    });

  getTravelOptions = (session) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'travelOptions',
        (error, response) => {
          if (!Array.isArray(response?.data?.options) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            resolve(response?.data?.options);
          }
        },
        { session_id: session.id },
        Client.methods.GET
      );
    });

  getFloors = (session) =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session?.constructor?.name !== 'OnboardingSession'
      )
        throw new Error('Please provide an onboardingSession');
      this.client.request(
        this.namespace,
        'floors',
        (error, response) => {
          if (!Array.isArray(response?.data?.floors) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            resolve(response?.data?.floors);
          }
        },
        { session_id: session.id },

        Client.methods.GET
      );
    });

  /** *
   * Triggers a registration call
   * @param {OnboardingSession} session - Onboarding session to complete
   */
  complete = (session) =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session?.constructor?.name !== 'OnboardingSession'
      )
        throw new Error('Please provide an onboardingSession');

      this.client.request(
        this.namespace,
        'complete',
        (error, response) => {
          if (!response?.data?.user?.id && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const user = response?.data?.user
              ? new User(this.client, response?.data?.user)
              : null;
            const building = response?.data?.building
              ? new Building(this.client, response?.data?.building)
              : null;
            resolve({ user, building });
          }
        },
        {
          session_id: session.id
        }
      );
    });

  /** *
   * Triggers a login call
   * @param {String} email - E-mail address
   * @param {String} password - Password
   * @param {Device} device - Device object to be passed to the login call
   * @param {LoginCallback} callback - Callback once a response is received.
   */
  login = (email, password, device) => {
    const { client, namespace } = this;

    return new Promise((resolve, reject) => {
      client.request(
        namespace,
        'login',
        (error, response) => {
          if (!response?.data?.session && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const { user, session, device: liveDevice } = response?.data;
            client.permissionsCache = user?.permissions || {};
            device.id = liveDevice.id;
            resolve({
              user: new User(client, user),
              session: new Session(client, session),
              device
            });
          }
        },
        {
          email,
          password,
          device
        },
        Client.methods.POST,
        'rest'
      );
    });
  };
}
