export default class PermitSession {
  /** *
   *
   * @param {Object} PermitSession - Session passed back by the API
   */
  constructor({ id, building_id, user_id, data }) {
    this.id = id || '';
    this.user_id = user_id || 0;
    this.building_id = building_id || 0;
    this.data = data || [];
  }

  parseData(data) {
    data.forEach((dataItem) => {
      this.data[dataItem.data_key] = JSON.parse(dataItem.data_value);
    });
  }

  setDataForKey(sectionId, dataKey = '', dataValue = '') {
    if (!this.data[sectionId]) this.data[sectionId] = {};
    this.data[dataKey] = dataValue;
  }

  getDataByStep(sectionId) {
    return this.data[sectionId] || {};
  }

  getDataByKey(sectionId, dataKey) {
    if (this.data[sectionId] && this.data[sectionId][dataKey])
      return this.data[sectionId][dataKey];
    return null;
  }
}
