/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const IconEyeAnimated = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    >
      <motion.path
        d="m3.7312 14.6967s4-8.00004 11-8.00004 11 8.00004 11 8.00004-4 8-11 8-11-8-11-8z"
        initial={{ pathLength: 0, pathOffset: 1 }}
        animate={{ pathLength: 1, pathOffset: 0 }}
        transition={{ duration: 0.5 }}
      />
      <motion.path
        d="m14.7312 17.6967c1.6569 0 3-1.3432 3-3 0-1.6569-1.3431-3-3-3s-3 1.3431-3 3c0 1.6568 1.3431 3 3 3z"
        initial={{ pathLength: 0, pathOffset: 1 }}
        animate={{ pathLength: 1, pathOffset: 0 }}
        transition={{ duration: 0.5 }}
      />
    </g>
  </svg>
);

IconEyeAnimated.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconEyeAnimated.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconEyeAnimated);
