import PermitField from './PermitField';

export default class PermitSection {
  /** *
   *
   * @param {Object} PermitSection - Session passed back by the API
   */
  constructor({
    id,
    title,
    preview_title,
    preview_cover,
    code,
    colour,
    fields,
    show_in_preview,
    required,
    enabled
  }) {
    this.id = id || '';
    this.title = title;
    this.preview_title = preview_title || title;
    this.preview_cover = Boolean(preview_cover);
    this.code = code || '';
    this.colour = colour || '#ffffff';

    this.show_in_preview = Boolean(show_in_preview);
    this.required = Boolean(required);
    this.enabled = Boolean(enabled);
    this.fields = [];

    if (Array.isArray(fields)) {
      this.fields = fields.map((field) => new PermitField(field));
    }
  }
}
