/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

export const OpenFolder = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 18 12"
    fill="none"
    {...rest}
  >
    <path
      d="M17.0222 5.13524L15.2946 12.3912C15.2686 12.5002 15.1713 12.5771 15.0593 12.5771H0.921004C0.787425 12.5771 0.679137 12.4688 0.679137 12.3352C0.679137 12.3139 0.681954 12.2927 0.687514 12.2721L2.6002 5.19517C2.65722 4.98419 2.84862 4.83765 3.06717 4.83765L16.7869 4.83735C16.9205 4.83735 17.0288 4.94563 17.0288 5.07921C17.0288 5.09808 17.0266 5.11688 17.0222 5.13524ZM0.242289 0H3.10302C3.1596 0 3.21439 0.0198371 3.25786 0.0560597L4.30543 0.929037C4.52277 1.11015 4.79672 1.20934 5.07963 1.20934H14.7543C14.8879 1.20934 14.9962 1.31762 14.9962 1.4512V3.38614C14.9962 3.51972 14.8879 3.62801 14.7543 3.62801H2.7403C2.19392 3.62801 1.71541 3.99436 1.57285 4.52182L0.190143 9.63785C0.176202 9.68943 0.123086 9.71995 0.0715047 9.70601C0.0293068 9.6946 -1.91066e-06 9.65632 0 9.61261L0.000411041 0.241867H0.000421651C0.000427504 0.108292 0.108713 1.06105e-05 0.242289 1.06105e-05V0Z"
      stroke="currentColor"
      fill="currentColor"
    />
  </svg>
);

OpenFolder.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

OpenFolder.defaultProps = {
  size: '30px',
  colour: 'theme-primary'
};

export default memo(OpenFolder);
