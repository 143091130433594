/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconSolidCircleTick = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 17 17"
    fill="none"
    {...rest}
  >
    <circle cx="8.5" cy="8.5" r="8.5" fill="#00C48C" />
    <path
      d="M12 6L6.5 12L4 9.27273"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconSolidCircleTick.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconSolidCircleTick.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconSolidCircleTick);
