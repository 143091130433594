import Category from '../../Entities/Helpdesk/Category';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class HelpdeskCategoriesNamespace extends GenericNamespaceHandler {
  namespace = 'helpdesk/categories';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  updateMultipleCall = 'updateMultiple';

  deleteCall = 'delete';

  searchRequiresBuildingId = false;

  responseEntity = Category;

  responseKey = 'category';

  cacheSearchResults = true;

  requiredFields = [
    {
      param: 'name',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_category_name',
      validation: { type: 'string', min: 5, max: 255 }
    },
    {
      param: 'enabled',
      requiredForCreate: false,
      requiredForUpdate: false,
      validation: { type: 'bool' }
    },
    {
      param: 'seq',
      requiredForCreate: false,
      requiredForUpdate: false,
      validation: { type: 'int', error: 'invalid_seq' }
    }
  ];

  create(name = '', seq = undefined) {
    const data = { name, seq, enabled: true };
    return super.create(data);
  }

  update(id = null, optional = { name: undefined, seq: undefined }) {
    return super.update(id, optional);
  }
}
