/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconUsers = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 10"
    fill="none"
    {...rest}
  >
    <path
      d="M10.668 4.33301C11.7746 4.33301 12.6613 3.43967 12.6613 2.33301C12.6613 1.22634 11.7746 0.333008 10.668 0.333008C9.5613 0.333008 8.66797 1.22634 8.66797 2.33301C8.66797 3.43967 9.5613 4.33301 10.668 4.33301ZM5.33464 4.33301C6.4413 4.33301 7.32797 3.43967 7.32797 2.33301C7.32797 1.22634 6.4413 0.333008 5.33464 0.333008C4.22797 0.333008 3.33464 1.22634 3.33464 2.33301C3.33464 3.43967 4.22797 4.33301 5.33464 4.33301ZM5.33464 5.66634C3.7813 5.66634 0.667969 6.44634 0.667969 7.99967V9.66634H10.0013V7.99967C10.0013 6.44634 6.88797 5.66634 5.33464 5.66634ZM10.668 5.66634C10.4746 5.66634 10.2546 5.67967 10.0213 5.69967C10.7946 6.25967 11.3346 7.01301 11.3346 7.99967V9.66634H15.3346V7.99967C15.3346 6.44634 12.2213 5.66634 10.668 5.66634Z"
      fill="#C7C7CC"
    />
  </svg>
);

IconUsers.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconUsers.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconUsers);
