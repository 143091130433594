/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconTick = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      stroke="currentColor"
      d="m22.5989 8.20081-11 10.99999-5.00002-5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

IconTick.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconTick.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconTick);
