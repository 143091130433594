import BuildingManual from './Buildings/BuildingManual';
import Department from './Buildings/Department';
import Floor from './Buildings/Floor';
import JobRole from './Buildings/JobRole';
import Location from './Buildings/Location';
import Occupier from './Buildings/Occupier';
import ContactType from './Buildings/Occupiers/ContactType';
import OccupierIndustry from './Buildings/Occupiers/OccupierIndustry';
import Priority from './Buildings/Priority';
import TravelOption from './Buildings/TravelOption';
import ClientEntity from './Client';
import Bay from './Deliveries/Bay';
import VisitorType from './Visitors/VisitorType';

export default class Building {
  constructor(
    Client,
    {
      id,
      name,
      client_id,
      client_name,
      client,
      address1,
      address2,
      address3,
      city,
      county,
      postcode,
      latitude,
      longitude,
      archived,
      logo_uri,
      logo_background,
      dashboard_image_uri,
      floors,
      bays,
      departments,
      job_roles,
      locations,
      priorities,
      travel_options,
      contact_types,
      visitor_types,
      users,
      occupiers,
      theme,
      terms_and_conditions,
      manuals,
      industries,
      created,
      modified,
      visitors_v2,
      timezone
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.id = id || 0;
    this.name = name || 0;
    this.client_id = client_id || 0;
    this.client_name = client_name || '';
    this.address1 = address1 || '';
    this.address2 = address2 || '';
    this.address3 = address3 || '';
    this.city = city || '';
    this.county = county || '';
    this.postcode = postcode || '';
    this.latitude = latitude || '';
    this.longitude = longitude || '';
    this.archived = archived || false;
    this.logo_uri = logo_uri || '';
    this.dashboard_image_uri = dashboard_image_uri || '';
    this.logo_background = logo_background || '';

    this.floors = Array.isArray(floors)
      ? floors.map((floor) => new Floor(Client, floor))
      : [];
    this.bays = Array.isArray(bays)
      ? bays.map((bay) => new Bay(Client, bay))
      : [];
    this.departments = Array.isArray(departments)
      ? departments.map((department) => new Department(Client, department))
      : [];
    this.job_roles = Array.isArray(job_roles)
      ? job_roles.map((job_role) => new JobRole(Client, job_role))
      : [];
    this.locations = Array.isArray(locations)
      ? locations.map((location) => new Location(Client, location))
      : [];
    this.priorities = Array.isArray(priorities)
      ? priorities.map((priority) => new Priority(Client, priority))
      : [];
    this.travel_options = Array.isArray(travel_options)
      ? travel_options.map((option) => new TravelOption(Client, option))
      : [];
    this.contact_types = Array.isArray(contact_types)
      ? contact_types.map((option) => new ContactType(Client, option))
      : [];
    this.visitor_types = Array.isArray(visitor_types)
      ? visitor_types.map((option) => new VisitorType(Client, option))
      : [];
    this.occupiers = Array.isArray(occupiers)
      ? occupiers.map((option) => new Occupier(Client, option))
      : [];
    this.industries = Array.isArray(industries)
      ? industries.map((option) => new OccupierIndustry(Client, option))
      : [];

    this.client =
      client !== null && typeof client === 'object'
        ? new ClientEntity(Client, client)
        : {};

    this.users = Array.isArray(users) ? users : [];

    this.theme = theme !== null && typeof theme === 'object' ? theme : {};

    this.terms_and_conditions = {};
    if (
      terms_and_conditions !== null &&
      typeof terms_and_conditions === 'object'
    ) {
      for (const key in terms_and_conditions) {
        const val = terms_and_conditions[key];
        val.modified = parseISODateTimeAsUTC(val);
      }
      this.terms_and_conditions = terms_and_conditions;
    }

    this.manuals = Array.isArray(manuals)
      ? manuals.map((manual) => new BuildingManual(Client, manual))
      : [];

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
    this.visitors_v2 = visitors_v2;
    this.timezone = timezone;
  }

  getURIPath() {
    return `${this.name.split(' ').join('-').toLowerCase()}-${this.id}`;
  }

  processEntities({
    floors = false,
    departments = false,
    job_roles = false,
    locations = false,
    priorities = false,
    travel_options = false,
    contact_types = true,
    users = false
  }) {
    return new Promise((resolve) => {
      const promises = [];

      if (floors) promises.push(this.getFloors());
      if (departments) promises.push(this.getDepartments());
      if (job_roles) promises.push(this.getJobRoles());
      if (locations) promises.push(this.getLocations());
      if (priorities) promises.push(this.getPriorities());
      if (travel_options) promises.push(this.getTravelOptions());
      if (contact_types) promises.push(this.getContactTypes());
      if (users) promises.push(this.getUsers());

      Promise.all(promises).then(() => resolve(this));
    });
  }

  getFloors() {
    return new Promise((resolve) => resolve(this.floors));
  }

  getDepartments() {
    return new Promise((resolve) => resolve(this.departments));
  }

  getJobRoles() {
    return new Promise((resolve) => resolve(this.job_roles));
  }

  getLocations() {
    return new Promise((resolve) => resolve(this.locations));
  }

  getPriorities() {
    return new Promise((resolve) => resolve(this.priorities));
  }

  getTravelOptions() {
    return new Promise((resolve) => resolve(this.travel_options));
  }

  getContactTypes() {
    return new Promise((resolve) => resolve(this.contact_types));
  }

  getUsers() {
    return new Promise((resolve) => resolve(this.users));
  }

  getStatusLabel() {
    const { archived } = this;

    let status = 'live';
    if (archived) status = 'archived';
    return status;
  }
}
