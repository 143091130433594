import { mergeDeepRight } from 'ramda';
import logger from 'services/logger/logger';

import Client from '../../../Client';
import dateToISOStringDiscountingOffset from '../../../Namespaces/_utils/dateToISOStringDiscountingOffset';
import deliveriesPrismaMock from './deliveries-prisma-mock.json';

export default class DeliveriesMockNamespace {
  dataSet = [
    {
      id: 33,
      building_id: 1,
      parent_id: 0,
      type: 'scheduled',
      user_id: 1,
      bay_id: 1,
      description: 'My new delivery',
      start: (() => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 2);
        return tomorrow;
      })().toISOString(),
      end: (() => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 3);
        return tomorrow;
      })().toISOString(),
      company_id: 1,
      delivery_for: 'John Smith',
      creator_name: 'John Smith',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Morning Supplies',
      haulier_name: 'Test Hauliers',
      floor_id: 1,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: true,
      approved_date: '2020-08-28T05:12:13.000Z',
      approved_user_id: 1,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 1,
      building_id: 1,
      parent_id: 0,
      type: 'unscheduled',
      user_id: 1,
      bay_id: 1,
      description: 'Delivery Morning',
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      company_id: 1,
      delivery_for: 'John Smith',
      creator_name: 'John Smith',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Morning Supplies',
      haulier_name: 'Test Hauliers',
      floor_id: 1,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: true,
      approved_date: '2020-08-28T05:12:13.000Z',
      approved_user_id: 1,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      building_id: 1,
      parent_id: 0,
      type: 'scheduled',
      user_id: 1,
      bay_id: 1,
      description: 'Another Delivery',
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      company_id: 1,
      delivery_for: 'Judy Smith',
      creator_name: 'Judy Smith',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Morning Supplies',
      haulier_name: 'Test Hauliers',
      floor_id: 1,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: true,
      approved_date: '2020-08-28T05:12:13.000Z',
      approved_user_id: 1,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 3,
      building_id: 1,
      parent_id: 0,
      type: 'unscheduled',
      user_id: 1,
      bay_id: 2,
      description: 'Another Delivery Morning',
      start: '2020-12-20T08:00:00.000Z',
      end: '2020-12-20T09:00:00.000Z',
      company_id: 1,
      creator_name: 'John Smith',
      delivery_for: 'Judy Smith',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Morning Supplies',
      haulier_name: 'Test Hauliers',
      floor_id: 1,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: true,
      approved_date: '2020-08-28T05:12:13.000Z',
      approved_user_id: 1,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 4,
      building_id: 1,
      parent_id: 0,
      type: 'scheduled',
      user_id: 1,
      bay_id: 3,
      description: 'Delivery Evening',
      start: '2021-01-20T12:00:00.000Z',
      end: '2021-01-20T13:00:00.000Z',
      company_id: 2,
      creator_name: 'Dolores Wilder',
      delivery_for: 'Dolores Wilder',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Evening Supplies',
      haulier_name: 'Super Hauliers',
      floor_id: 2,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: true,
      approved_date: null,
      approved_user_id: null,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 5,
      building_id: 1,
      parent_id: 0,
      type: 'scheduled',
      user_id: 1,
      bay_id: 1,
      description: 'Another Delivery Evening',
      start: '2021-01-20T12:00:00.000Z',
      end: '2021-01-20T13:00:00.000Z',
      company_id: 3,
      creator_name: 'Lorelai Farmer',
      delivery_for: 'Lorelai Farmer',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Evening Supplies',
      haulier_name: 'Super Hauliers',
      floor_id: 2,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: false,
      approved_date: null,
      approved_user_id: null,
      rejected: true,
      rejected_date: '2020-08-28T05:12:13.000Z',
      rejected_user_id: 1,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 6,
      building_id: 1,
      parent_id: 0,
      type: 'scheduled',
      user_id: 1,
      bay_id: 1,
      description: 'Third Delivery Evening',
      start: '2021-01-20T12:00:00.000Z',
      end: '2021-01-20T13:00:00.000Z',
      company_id: 3,
      creator_name: 'Lorelai Farmer',
      delivery_for: 'Lorelai Farmer',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Evening Supplies',
      haulier_name: 'Super Hauliers',
      floor_id: 2,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: false,
      approved_date: null,
      approved_user_id: null,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 7,
      building_id: 1,
      parent_id: 0,
      type: 'scheduled',
      user_id: 1,
      bay_id: 1,
      description: 'Recurring Delivery',
      start: '2021-09-28T12:00:00.000Z',
      end: '2021-09-28T13:00:00.000Z',
      company_id: 3,
      creator_name: 'Jane Rhodes',
      delivery_for: 'Jane Rhodes',
      vehicle_reg_no: 'AB12CBE',
      vehicle_reg_unknown: false,
      goods_description: 'Stationary Supplies',
      haulier_name: 'GG Hauliers',
      floor_id: 2,
      checked_in: false,
      checked_in_user_id: null,
      checked_in_date: null,
      checked_out: false,
      checked_out_user_id: null,
      checked_out_date: null,
      approved: false,
      approved_date: null,
      approved_user_id: null,
      rejected: false,
      rejected_date: null,
      rejected_user_id: null,
      archived: false,
      deleted: false,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z',
      recurrence_frequency: 'Daily',
      recurrence_end_type: 'date',
      recurrence_end_after_elapsed_occurrences: null,
      recurrence_end_date: '2021-10-20T12:00:00.000Z',
      excluded_dates: ['2021-10-10', '2021-09-25', '2021-09-27'],
      recurrences: [
        {
          id: 1129,
          building_id: 1,
          parent_id: 7,
          type: 'scheduled',
          user_id: 38,
          bay_id: 1,
          description: 'Newly created recurring delivery',
          start: '2021-09-29T13:00:00.000Z',
          end: '2021-09-29T13:10:00.000Z',
          company_id: 8,
          delivery_for: 'Some guy',
          creator_name: 'TLB Super Admin',
          vehicle_reg_no: '',
          vehicle_reg_unknown: false,
          additional_details: '',
          haulier_name: '',
          floor_id: 32,
          checked_in: false,
          checked_in_user_id: 0,
          checked_in_date: null,
          checked_out: false,
          checked_out_user_id: 0,
          checked_out_date: null,
          approved: false,
          approved_date: null,
          approved_user_id: 0,
          rejected: false,
          rejected_date: '2021-09-22T08:26:27.000Z',
          rejected_user_id: 0,
          archived: false,
          created: '2021-09-22T08:26:27.000Z',
          modified: '2021-09-22T08:26:27.000Z'
        },
        {
          id: 1125,
          building_id: 1,
          parent_id: 531,
          type: 'scheduled',
          user_id: 38,
          bay_id: 1,
          description: 'Newly created recurring delivery2',
          start: '2021-10-01T13:00:00.000Z',
          end: '2021-10-01T13:10:00.000Z',
          company_id: 8,
          delivery_for: 'Some guy',
          creator_name: 'TLB Super Admin',
          vehicle_reg_no: '',
          vehicle_reg_unknown: false,
          additional_details: null,
          haulier_name: '',
          floor_id: 32,
          checked_in: false,
          checked_in_user_id: null,
          checked_in_date: null,
          checked_out: false,
          checked_out_user_id: null,
          checked_out_date: null,
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null,
          archived: false,
          created: '2021-09-22T08:26:27.000Z',
          modified: '2021-09-22T08:26:27.000Z'
        },
        {
          id: 1126,
          building_id: 1,
          parent_id: 7,
          type: 'scheduled',
          user_id: 38,
          bay_id: 1,
          description: 'Newly created recurring delivery 3',
          start: '2021-10-02T13:00:00.000Z',
          end: '2021-09-02T13:10:00.000Z',
          company_id: 8,
          delivery_for: 'Some guy',
          creator_name: 'TLB Super Admin',
          vehicle_reg_no: '',
          vehicle_reg_unknown: false,
          additional_details: null,
          haulier_name: '',
          floor_id: 32,
          checked_in: false,
          checked_in_user_id: null,
          checked_in_date: null,
          checked_out: false,
          checked_out_user_id: null,
          checked_out_date: null,
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null,
          archived: false,
          created: '2021-09-22T08:26:27.000Z',
          modified: '2021-09-22T08:26:27.000Z'
        },
        {
          id: 1127,
          building_id: 1,
          parent_id: 7,
          type: 'scheduled',
          user_id: 38,
          bay_id: 1,
          description: 'Newly created recurring delivery 4',
          start: '2021-10-03T13:00:00.000Z',
          end: '2021-10-03T13:10:00.000Z',
          company_id: 8,
          delivery_for: 'Some guy',
          creator_name: 'TLB Super Admin',
          vehicle_reg_no: '',
          vehicle_reg_unknown: false,
          additional_details: null,
          haulier_name: '',
          floor_id: 32,
          checked_in: false,
          checked_in_user_id: null,
          checked_in_date: null,
          checked_out: false,
          checked_out_user_id: null,
          checked_out_date: null,
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null,
          archived: false,
          created: '2021-09-22T08:26:27.000Z',
          modified: '2021-09-22T08:26:27.000Z'
        },
        {
          id: 1128,
          building_id: 1,
          parent_id: 7,
          type: 'scheduled',
          user_id: 38,
          bay_id: 1,
          description: 'Newly created recurring delivery 5',
          start: '2021-10-04T13:00:00.000Z',
          end: '2021-10-04T13:10:00.000Z',
          company_id: 8,
          delivery_for: 'Some guy',
          creator_name: 'TLB Super Admin',
          vehicle_reg_no: '',
          vehicle_reg_unknown: false,
          additional_details: null,
          haulier_name: '',
          floor_id: 32,
          checked_in: false,
          checked_in_user_id: null,
          checked_in_date: null,
          checked_out: false,
          checked_out_user_id: null,
          checked_out_date: null,
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null,
          archived: false,
          created: '2021-09-22T08:26:27.000Z',
          modified: '2021-09-22T08:26:27.000Z'
        }
      ]
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (delivery, ignoreContacts) => {
    const {
      user_id,
      bay_id,
      company_id,
      floor_id,
      approved_user_id,
      rejected_user_id
    } = delivery;

    const {
      framework: {
        mockNamespaces: {
          UsersMockNamespace: { dataSet: users },
          BaysMockNamespace: { dataSet: bays },
          CompaniesMockNamespace: { dataSet: companies },
          FloorsMockNamespace: { dataSet: floors },
          CommentsMockNamespace: { dataSet: comments }
        }
      }
    } = this;

    users.forEach((user) => {
      const {
        id,
        profile: { name, picture_uri }
      } = user;
      const newUser = { id, profile: { name, picture_uri } };

      if (id == user_id) delivery.user = newUser;
      if (id == approved_user_id) delivery.approved_user = newUser;
      if (id == rejected_user_id) delivery.rejected_user = newUser;
    });

    if (bay_id) delivery.bay = bays.filter(({ id }) => id == bay_id).pop();
    if (company_id)
      delivery.company = companies.filter(({ id }) => id == company_id).pop();
    if (floor_id)
      delivery.floor = floors.filter(({ id }) => id == floor_id).pop();

    delivery.comments = comments
      .filter(
        ({ type, entity_id }) => type === 'delivery' && entity_id == delivery.id
      )
      .sort((a, b) => {
        const aDate = new Date(a.created);
        const bDate = new Date(b.created);
        return bDate.getTime() - aDate.getTime();
      });

    return delivery;
  };

  refreshDataSet = () => {
    this.dataSet.forEach(this.translateEntities);
  };

  getEndpoints() {
    this.refreshDataSet();
    const { generateUUIDV4 } = this.framework;
    return {
      deliveries: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          building_id: { field: 'building_id', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },

      'deliveries/feed': () => this.framework.defaultPrismaMock({
          records: [...this.dataSet, ...deliveriesPrismaMock.records]
        }),

      'deliveries/verify_vehicle': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const { reg } = payload;
        if (reg.toLowerCase().replace(/[^a-z0-9]+/g, '') === 'km12akk') {
          return {
            code: 200,
            data: {
              vehicle: {
                height: 1740,
                length: 4854,
                weight: 2540,
                make: 'Volkswagen',
                model: 'Sharan',
                origin: 'Portugal',
                first_register: '2012-06-15T00:00:00'
              }
            }
          };
        }
        return this.framework.createErrorResponse(new Error('invalid_reg'));
      },
      'deliveries/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const {
          type,
          building_id,
          company_id,
          delivery_for,
          floor_id,
          bay_id,
          description,
          date,
          time,
          duration,
          vehicle_reg_no,
          vehicle_reg_unknown,
          goods_description,
          haulier_name,
          recurrence_frequency,
          recurrence_end_type,
          recurrence_end_after_elapsed_occurrences,
          recurrence_end_date,
          excluded_dates
        } = payload;
        let { creator_name } = payload;

        const [day, month, year] = date.split('/');

        const baseStartTime = new Date(`${year}-${month}-${day} ${time}`);
        const start = dateToISOStringDiscountingOffset(baseStartTime);
        const end = dateToISOStringDiscountingOffset(
          new Date(baseStartTime.getTime() + parseInt(duration, 10) * 60 * 1000)
        );

        const recurrEnd = new Date(recurrence_end_date);
        const recurrences = [];

        const dateDiffInDays = (a, b) => {
          const _MS_PER_DAY = 1000 * 60 * 60 * 24;

          const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
          const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

          return Math.floor((utc2 - utc1) / _MS_PER_DAY);
        };

        let daySpan = null;
        if (recurrence_end_type === 'date') {
          daySpan = dateDiffInDays(baseStartTime, recurrEnd);
        } else {
          daySpan = recurrence_end_after_elapsed_occurrences;
        }

        const addDays = (date, days) => {
          const result = new Date(date);
          result.setDate(result.getDate() + days);
          return result;
        };

        switch (recurrence_frequency) {
          case 'daily':
            for (let i = 1; i < daySpan + 1; i++) {
              const start_date = addDays(start, i).toISOString();

              const newRecurringDate = {
                id: recurrences.length + 1,
                building_id: 1,
                parent_id: this.dataSet.length + 1,
                type: 'scheduled',
                user_id: 38,
                bay_id: 1,
                description: 'Newly created recurring delivery',
                start: start_date,
                end: start_date,
                company_id: 8,
                delivery_for: 'Some guy',
                creator_name: 'TLB Super Admin',
                vehicle_reg_no: '',
                vehicle_reg_unknown: false,
                additional_details: null,
                haulier_name: '',
                floor_id: 32,
                checked_in: false,
                checked_in_user_id: null,
                checked_in_date: null,
                checked_out: false,
                checked_out_user_id: null,
                checked_out_date: null,
                approved: false,
                approved_date: null,
                approved_user_id: null,
                rejected: false,
                rejected_date: null,
                rejected_user_id: null,
                archived: false,
                created: start,
                modified: start
              };
              recurrences.push(newRecurringDate);
            }
            break;

          case 'weekdays':
            for (let i = 1; i < daySpan + 1; i++) {
              const test = addDays(start, i).toDateString().substring(0, 4);
              if (
                addDays(start, i).toDateString().substring(0, 3) === 'Sat' ||
                addDays(start, i).toDateString().substring(0, 3) === 'Sun'
              ) {
                daySpan += 1;
              } else {
                const start_date = addDays(start, i).toISOString();

                const newRecurringDate = {
                  id: recurrences.length + 1,
                  building_id: 1,
                  parent_id: this.dataSet.length + 1,
                  type: 'scheduled',
                  user_id: 38,
                  bay_id: 1,
                  description: 'Newly created recurring delivery',
                  start: start_date,
                  end: start_date,
                  company_id: 8,
                  delivery_for: 'Some guy',
                  creator_name: 'TLB Super Admin',
                  vehicle_reg_no: '',
                  vehicle_reg_unknown: false,
                  additional_details: null,
                  haulier_name: '',
                  floor_id: 32,
                  checked_in: false,
                  checked_in_user_id: null,
                  checked_in_date: null,
                  checked_out: false,
                  checked_out_user_id: null,
                  checked_out_date: null,
                  approved: false,
                  approved_date: null,
                  approved_user_id: null,
                  rejected: false,
                  rejected_date: null,
                  rejected_user_id: null,
                  archived: false,
                  created: start,
                  modified: start
                };
                recurrences.push(newRecurringDate);
              }
            }
            break;

          case 'weekly':
            for (let i = 1; i < daySpan + 1; i += 7) {
              const start_date = addDays(start, i).toISOString();

              const newRecurringDate = {
                id: recurrences.length + 1,
                building_id: 1,
                parent_id: this.dataSet.length + 1,
                type: 'scheduled',
                user_id: 38,
                bay_id: 1,
                description: 'Newly created recurring delivery',
                start: start_date,
                end: start_date,
                // .setDate(new Date(start.getDate() + i)).toISOString(),
                company_id: 8,
                delivery_for: 'Some guy',
                creator_name: 'TLB Super Admin',
                vehicle_reg_no: '',
                vehicle_reg_unknown: false,
                additional_details: null,
                haulier_name: '',
                floor_id: 32,
                checked_in: false,
                checked_in_user_id: null,
                checked_in_date: null,
                checked_out: false,
                checked_out_user_id: null,
                checked_out_date: null,
                approved: false,
                approved_date: null,
                approved_user_id: null,
                rejected: false,
                rejected_date: null,
                rejected_user_id: null,
                archived: false,
                created: start,
                modified: start
              };
              recurrences.push(newRecurringDate);
            }
            break;

          case 'fortnightly':
            for (let i = 1; i < daySpan + 1; i += 14) {
              const start_date = addDays(start, i).toISOString();

              const newRecurringDate = {
                id: recurrences.length + 1,
                building_id: 1,
                parent_id: this.dataSet.length + 1,
                type: 'scheduled',
                user_id: 38,
                bay_id: 1,
                description: 'Newly created recurring delivery',
                start: start_date,
                end: start_date,
                company_id: 8,
                delivery_for: 'Some guy',
                creator_name: 'TLB Super Admin',
                vehicle_reg_no: '',
                vehicle_reg_unknown: false,
                additional_details: null,
                haulier_name: '',
                floor_id: 32,
                checked_in: false,
                checked_in_user_id: null,
                checked_in_date: null,
                checked_out: false,
                checked_out_user_id: null,
                checked_out_date: null,
                approved: false,
                approved_date: null,
                approved_user_id: null,
                rejected: false,
                rejected_date: null,
                rejected_user_id: null,
                archived: false,
                created: start,
                modified: start
              };
              recurrences.push(newRecurringDate);
            }
            break;
          default:
            for (let i = 1; i < daySpan + 1; i++) {
              const start_date = addDays(start, i);

              const newRecurringDate = {
                id: recurrences.length + 1,
                building_id: 1,
                parent_id: this.dataSet.length + 1,
                type: 'scheduled',
                user_id: 38,
                bay_id: 1,
                description: 'Newly created recurring delivery',
                start: start_date,
                end: start_date,
                // .setDate(new Date(start.getDate() + i)).toISOString(),
                company_id: 8,
                delivery_for: 'Some guy',
                creator_name: 'TLB Super Admin',
                vehicle_reg_no: '',
                vehicle_reg_unknown: false,
                additional_details: null,
                haulier_name: '',
                floor_id: 32,
                checked_in: false,
                checked_in_user_id: null,
                checked_in_date: null,
                checked_out: false,
                checked_out_user_id: null,
                checked_out_date: null,
                approved: false,
                approved_date: null,
                approved_user_id: null,
                rejected: false,
                rejected_date: null,
                rejected_user_id: null,
                archived: false,
                created: start,
                modified: start
              };
              recurrences.push(newRecurringDate);
            }
        }

        const user_id = 1;
        let checked_in = false;
        let checked_in_user_id = null;
        let checked_in_date = null;
        let approved = false;
        let approved_date = null;
        let approved_user_id = null;

        if (type === 'unscheduled') {
          const {
            framework: {
              mockNamespaces: {
                UsersMockNamespace: { dataSet: users }
              }
            }
          } = this;

          users.forEach((user) => {
            const {
              id,
              profile: { name, picture_uri }
            } = user;
            if (id === user_id) {
              creator_name = name;
              checked_in = true;
              checked_in_date = new Date();
              checked_in_user_id = user_id;
              approved = true;
              approved_date = new Date();
              approved_user_id = user_id;
            }
          });
        }

        const delivery = this.translateEntities({
          id: this.dataSet.length + 1,
          building_id,
          parent_id: 0,
          type: type !== 'unscheduled' ? 'scheduled' : 'unscheduled',
          user_id,
          bay_id,
          description,
          date,
          time,
          duration,
          start,
          end,
          company_id,
          delivery_for,
          creator_name,
          vehicle_reg_no,
          vehicle_reg_unknown: Boolean(vehicle_reg_unknown),
          goods_description,
          haulier_name,
          floor_id,
          checked_in,
          checked_in_user_id,
          checked_in_date,
          checked_out: false,
          checked_out_user_id: null,
          checked_out_date: null,
          approved,
          approved_date,
          approved_user_id,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null,
          archived: false,
          deleted: false,
          created: new Date().toISOString(),
          modified: new Date().toISOString(),
          recurrence_frequency,
          recurrences,
          deleted_occurrences: [],
          recurrence_end_type,
          recurrence_end_after_elapsed_occurrences,
          recurrence_end_date
          // excluded_dates
        });

        this.dataSet.push(delivery);
        return { code: 200, data: { delivery } };
      },
      'deliveries/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const [day, month, year] = payload.date.split('/');

        const baseStartTime = new Date(
          `${year}-${month}-${day} ${payload.time}`
        );
        const start = dateToISOStringDiscountingOffset(baseStartTime);
        const end = dateToISOStringDiscountingOffset(
          new Date(
            baseStartTime.getTime() + parseInt(payload.duration, 10) * 60 * 1000
          )
        );

        let newRecurrences = [];
        if (payload.deleted_occurrences) {
          const recurrencesToCheck = this.dataSet.find(
            (delivery) =>
              delivery.id === payload.deleted_occurrences[0].parent_id
          );
          newRecurrences = recurrencesToCheck.recurrences.filter(
            ({ id: id1 }) =>
              !payload.deleted_occurrences.some(({ id: id2 }) => id2 === id1)
          );
        }

        payload = {
          ...payload,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null,
          recurrences: newRecurrences,
          recurrence_end_after_elapsed_occurrences: newRecurrences.length,
          start,
          end
        };

        try {
          const delivery = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              payload
            ),
            true
          );
          return { code: 200, data: { delivery } };
        } catch (e) {
          logger.debug(e);
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'deliveries/checkin': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          checked_in: true,
          checked_in_date: new Date(),
          checked_in_user_id: 1
        };

        try {
          const delivery = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { delivery } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'deliveries/checkout': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          checked_out: true,
          checked_out_date: new Date(),
          checked_out_user_id: 1
        };

        try {
          const delivery = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { delivery } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'deliveries/approve': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          approved: true,
          approved_date: new Date(),
          approved_user_id: 1,
          rejected: false,
          rejected_date: null,
          rejected_user_id: null
        };

        try {
          const delivery = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );
          return { code: 200, data: { delivery } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'deliveries/reject': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const realPayload = {
          approved: false,
          approved_date: null,
          approved_user_id: null,
          rejected: true,
          rejected_date: new Date(),
          rejected_user_id: 1
        };

        try {
          const delivery = this.translateEntities(
            this.framework.updateRecord(
              this.dataSet,
              payload.id,
              'id',
              realPayload
            )
          );

          if (payload.comment) {
            const {
              framework: {
                mockNamespaces: { CommentsMockNamespace }
              }
            } = this;
            CommentsMockNamespace.create(
              delivery.id,
              'delivery',
              '',
              payload.comment
            );
          }

          return { code: 200, data: { delivery } };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'deliveries/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'deliveries/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
