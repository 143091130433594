/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconTruck = ({ size, colour, fill, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 100 100"
    fill={fill}
    {...rest}
  >
    <g>
      <g>
        <path
          d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"
          fill={fill}
        />
        <path
          d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z"
          fill={fill}
        />
        <path
          d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z"
          fill={fill}
        />
        <path
          d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z"
          fill={fill}
        />
      </g>
      <path
        d="M94.2,49.5l-8.7-1.8l-4.1-12.3c-1.5-4.4-5.5-7.3-10.1-7.3h-6.9v-6.3c0-2.3-1.8-4.1-4.1-4.1H6.6c-2.3,0-4.1,1.8-4.1,4.1    v44.7c0,2.3,1.8,4.1,4.1,4.1h4.4c0,6.4,5.2,11.7,11.7,11.7c6.4,0,11.7-5.2,11.7-11.7h31.2c0,6.4,5.2,11.7,11.7,11.7    c6.4,0,11.7-5.2,11.7-11.7h4.4c2.3,0,4.1-1.8,4.1-4.1v-13C97.5,51.6,96.1,49.9,94.2,49.5z M22.7,76.2c-3,0-5.5-2.5-5.5-5.5    c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5C28.3,73.7,25.8,76.2,22.7,76.2z M45.6,38.7L33.5,52.4c-0.7,0.8-1.8,1.3-2.9,1.3    s-2.2-0.5-2.9-1.3L21.1,45c-1.4-1.6-1.3-4.1,0.3-5.5c1.6-1.4,4.1-1.3,5.5,0.3l3.5,4l9.1-10.3c1.4-1.6,3.9-1.8,5.5-0.3    C46.8,34.6,47,37.1,45.6,38.7z M64.4,46.7V34.3h6.9c2,0,3.7,1.2,4.3,3.1l3.1,9.3H64.4z M77.3,76.2c-3,0-5.5-2.5-5.5-5.5    c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5C82.8,73.7,80.3,76.2,77.3,76.2z"
        fill={fill}
      />
    </g>
  </svg>
);

IconTruck.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string,
  fill: PropTypes.string
};

IconTruck.defaultProps = {
  size: '100px',
  colour: 'primary',
  fill: 'white'
};

export default memo(IconTruck);
