import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import { formatDistanceToNow, differenceInHours } from 'date-fns';
import PropTypes from 'prop-types';

import styles from './TimeStamp.module.scss';

const TimeStamp = ({ time, indicator, autoUpdate }) => {
  const [timestampText, setTimestampText] = useState();

  const updateTimestamp = () => setTimestampText(formatDistanceToNow(time));
  const isWarning = differenceInHours(Date.now(), time) > 1;

  if (autoUpdate) {
    setInterval(() => {
      updateTimestamp();
    }, 60000);
  }

  useEffect(() => {
    updateTimestamp();
  }, []);

  return (
    <time className={styles.timestamp} dateTime={time}>
      {indicator && (
        <span
          className={classnames(styles.indicator, {
            [styles.warning]: isWarning
          })}
        />
      )}
      {timestampText}
    </time>
  );
};

TimeStamp.propTypes = {
  time: PropTypes.number.isRequired,
  indicator: PropTypes.bool,
  autoUpdate: PropTypes.bool
};

TimeStamp.defaultProps = {
  indicator: false,
  autoUpdate: true
};

export default TimeStamp;
