/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconBuilding = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
      d="m14 16h-14v-16h8.90909v5.33333h5.09091zm-10.18182-3.3333h-1.90909v2.6666h1.90909zm3.18182 0h-1.90909v2.6666h1.90909zm5.0909 0h-1.9091v2.6666h1.9091zm-9.54545-3.33337h-1.27272v1.33337h1.27272zm5.09091 0h-1.27272v1.33337h1.27272zm-2.54545 0h-1.27273v1.33337h1.27273zm5.72729 0h-1.27275v1.33337h1.27275zm1.9091 0h-1.2728v1.33337h1.2728zm-10.18185-2.66666h-1.27272v1.33333h1.27272zm5.09091 0h-1.27272v1.33333h1.27272zm-2.54545 0h-1.27273v1.33333h1.27273zm5.72729 0h-1.27275v1.33333h1.27275zm1.9091 0h-1.2728v1.33333h1.2728zm-10.18185-2.66667h-1.27272v1.33333h1.27272zm5.09091 0h-1.27272v1.33333h1.27272zm-2.54545 0h-1.27273v1.33333h1.27273zm-2.54546-2.66667h-1.27272v1.33334h1.27272zm5.09091 0h-1.27272v1.33334h1.27272zm-2.54545 0h-1.27273v1.33334h1.27273z"
    />
  </svg>
);

IconBuilding.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconBuilding.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconBuilding);
