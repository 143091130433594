import logger from 'services/logger/logger';

import Client from '../../Client';
import Profile from '../../Entities/Profile';
import User from '../../Entities/User';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';
import UserRolesNamespace from './UserRoles';

export default class UsersNamespace extends GenericNamespaceHandler {
  roles = null;

  namespace = 'users';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = User;

  responseKey = 'user';

  requiredFields = [
    {
      param: 'email',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_email',
      validation: { type: 'email', error: 'invalid_email' }
    },
    {
      param: 'name',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_name',
      validation: { type: 'string', error: 'provide_name' }
    }
  ];

  constructor(Client) {
    super(Client);
    this.roles = new UserRolesNamespace(Client);
  }

  /**
   * Method to create a user
   *
   * Example: create(
   *    'james@mahy.com',
   *    '123456',
   *    new Profile({name: 'James Mahy'}),
   *    new Company({company_id: 1, building_id: 1, floor_id:1, role_id: 1}),
   *    error, user => logger.log(error, user)
   * );
   *
   * @param {String} email - must be a valid e-mail address
   * @param {String} password - must meet our minimum password requirements
   * @param {Profile} profile - Required with a valid name for user creation
   * @param {Object} optional
   * @param {SaveCallback} callback - callback for the response
   *
   * @throws Error
   */
  create(
    buildingId,
    email,
    name,
    optional = {
      password: undefined,
      company_id: undefined,
      role_id: undefined,
      floor_id: undefined,
      job_role_id: undefined
    }
  ) {
    let data = { building_id: buildingId, email, name, enabled: true };
    if (Object.keys(optional).length) data = Object.assign(data, optional);

    return super.create(data);
  }

  update(
    id = null,
    optional = {
      email: undefined,
      role_id: undefined,
      company_id: undefined,
      floor_id: undefined,
      job_role_id: undefined,
      name: undefined,
      picture_uri: undefined,
      phone: undefined
    }
  ) {
    return super.update(id, optional);
  }

  searchByBuildingId(
    buildingId,
    criteria = {},
    page = 1,
    orderBy,
    orderDirection
  ) {
    if (buildingId) criteria.building_id = buildingId;
    return this.search(criteria, page, orderBy, orderDirection);
  }

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived({ id, buildingId }, unarchive) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');
      if (!buildingId) throw new Error('provide_building_id');

      this.client.request(
        this.namespace,
        unarchive ? 'unarchive' : 'archive',
        (error, { data }) => resolve(Boolean(data)),
        { id, buildingId },
        Client.methods.PUT
      );
    });
  }

  enable({ id, buildingId }) {
    return this.toggleEnabled({ id, buildingId });
  }

  disable({ id, buildingId }) {
    return this.toggleEnabled({ id, buildingId }, true);
  }

  toggleEnabled({ id, buildingId }, disabled) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');
      if (!buildingId) throw new Error('provide_building_id');

      this.client.request(
        this.namespace,
        disabled ? 'disable' : 'enable',
        (error, { data }) => {
          if (data) resolve(data);
          else reject(error);
        },
        { id, buildingId },
        Client.methods.PUT
      );
    });
  }
}
