import ContactType from '../../Entities/Buildings/Occupiers/ContactType';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class ContactTypesNamespace extends GenericNamespaceHandler {
  namespace = 'buildings/contact_types';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  updateMultipleCall = 'updateMultiple';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = ContactType;

  responseKey = 'type';

  requiredFields = [
    {
      param: 'building_id',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'provide_building_id',
      validation: { type: 'int' }
    },
    {
      param: 'name',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_contact_type_name',
      validation: { type: 'string', min: 5, max: 255 }
    },
    {
      param: 'enabled',
      requiredForCreate: false,
      requiredForUpdate: false,
      validation: { type: 'bool' }
    },
    {
      param: 'seq',
      requiredForCreate: false,
      requiredForUpdate: false,
      validation: { type: 'int', error: 'invalid_seq' }
    }
  ];

  searchByBuildingId(
    buildingId,
    criteria = {},
    page = 1,
    orderBy,
    orderDirection
  ) {
    if (buildingId) criteria.building_id = buildingId;
    return this.search(criteria, page, orderBy, orderDirection);
  }

  create(buildingId = null, name = '', seq = undefined) {
    const data = { building_id: buildingId, name, seq, enabled: true };
    return super.create(data);
  }

  update(id = null, optional = { name: undefined, seq: undefined }) {
    return super.update(id, optional);
  }
}
