import { req } from 'services/api/request';
import { getApiUrl } from 'utils/api';

const endpointPath = `/onboarding`;
const baseURL = getApiUrl({ monolith: true });

const _refreshToken = async () => {
  const { code, session } = await req({
    ...(baseURL && { baseURL }),
    url: `${endpointPath}/refreshToken`,
    method: 'GET'
  });

  return {
    code,
    session
  };
};

const _logout = async () =>
  req({
    ...(baseURL && { baseURL }),
    url: `${endpointPath}/logout`,
    method: 'DELETE'
  });

const refreshToken = (args) => _refreshToken(args);
const logout = (args) => _logout(args);

export { refreshToken, logout };
