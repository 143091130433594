export default class Section {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APICategory - containing the server side object
   */
  constructor(Client, { id, name, created, modified }) {
    if (!Client) throw new Error('Please provide an instance of Client');

    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.name = name || '';

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
