import Occupier from '../Occupier';

export default class Contact {
  constructor(
    Client,
    {
      id,
      type_id,
      name,
      phone,
      email,
      occupier_id,
      occupier,
      archived,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.id = id || 0;
    this.type_id = type_id || 0;
    this.name = name || '';
    this.email = email || '';
    this.phone = phone || '';

    this.occupier_id = occupier_id || null;
    this.occupier = occupier ? new Occupier(Client, occupier) : null;

    this.archived = Boolean(archived);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
