export default class PermitField {
  /** *
   *
   * @param {Object} PermitField - Session passed back by the API
   */
  constructor({
    id,
    section_id,
    label,
    preview_label,
    component,
    required,
    children,
    props,
    visibility,
    enabled,
    created,
    modified
  }) {
    this.id = id || '';
    this.component = component;
    this.label = label;
    this.preview_label = preview_label || label;

    this.children = children || null;
    this.required = Boolean(required);

    if (typeof props === 'string') {
      props = JSON.parse(props);
    }
    this.props = props;
    this.created = created || null;
    this.modified = modified || null;

    this.visibility = visibility || 'both';
    this.enabled = Boolean(enabled);
  }
}
