export default class Priority {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIPriority - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      name,
      colour,
      min_time,
      max_time,
      building_id,
      seq,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.name = name || '';
    this.colour = colour || '';
    this.min_time = min_time || 0;
    this.max_time = max_time | 0;
    this.building_id = building_id || null;
    this.seq = seq || 0;

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
