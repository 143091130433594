/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCamera = ({ size, colour, ...rest }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    {...rest}
    fill="none"
    viewBox="0 0 12 10"
  >
    <path
      d="M2.5 1H1V0.5H2.5V1ZM6.5 4C5.673 4 5 4.673 5 5.5C5 6.327 5.673 7 6.5 7C7.327 7 8 6.327 8 5.5C8 4.673 7.327 4 6.5 4ZM12 1.5V10H0V1.5H2.965C3.2995 1.5 3.6115 1.333 3.797 1.0545L4.5 0H8.5L9.203 1.0545C9.3885 1.333 9.7005 1.5 10.035 1.5H12ZM2.5 3.5C2.5 3.224 2.2765 3 2 3C1.7235 3 1.5 3.224 1.5 3.5C1.5 3.776 1.7235 4 2 4C2.2765 4 2.5 3.776 2.5 3.5ZM9 5.5C9 4.1195 7.8805 3 6.5 3C5.1195 3 4 4.1195 4 5.5C4 6.8805 5.1195 8 6.5 8C7.8805 8 9 6.8805 9 5.5Z"
      fill="currentColor"
    />
  </svg>
);

IconCamera.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconCamera.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconCamera);
