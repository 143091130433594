import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconArrowDropDown = ({ width, height, colour }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L3.5 4L7 0H0Z" fill={colour} />
  </svg>
);

IconArrowDropDown.propTypes = {
  colour: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

IconArrowDropDown.defaultProps = {
  width: 7,
  height: 4,
  colour: '#000'
};

export default memo(IconArrowDropDown);
