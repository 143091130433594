/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconEyeOff = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      d="m16.1478 16.8167c-.2746.2947-.6058.5311-.9738.6951-.368.1639-.7653.2521-1.1681.2592s-.8029-.067-1.1765-.2179c-.3735-.1508-.7128-.3754-.9977-.6603s-.5095-.6242-.6604-.9978c-.1508-.3735-.2249-.7736-.2178-1.1764s.0952-.8001.2592-1.1681.4004-.6992.6951-.9738m-8.87997-8.88004 21.99997 22.00004m-5.06-5.06c-1.7094 1.303-3.7909 2.0248-5.94 2.06-6.99997 0-10.99997-8-10.99997-8 1.24389-2.3181 2.96914-4.3434 5.06-5.94004zm-8.04-13.70004c.6884-.16112 1.3931-.24167 2.1-.24 7 0 11 8.00004 11 8.00004-.607 1.1356-1.3309 2.2047-2.16 3.19z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

IconEyeOff.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconEyeOff.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconEyeOff);
