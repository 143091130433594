/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';

const IconDownload = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 21 19"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33301 13.4583L10.4997 16.6249L13.6663 13.4583"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 9.5V16.625"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5296 14.3212C18.2179 13.8372 18.734 13.1465 19.0031 12.3493C19.2722 11.5521 19.2803 10.6898 19.0262 9.88773C18.772 9.08562 18.2689 8.38535 17.5898 7.88856C16.9107 7.39176 16.091 7.12429 15.2496 7.12495H14.2521C14.014 6.19701 13.5685 5.33518 12.9491 4.60434C12.3297 3.8735 11.5526 3.29269 10.6763 2.90564C9.79999 2.51859 8.84728 2.33539 7.8899 2.36981C6.93253 2.40424 5.99543 2.65541 5.14917 3.10441C4.30291 3.55341 3.56954 4.18854 3.00426 4.96198C2.43898 5.73542 2.05653 6.62703 1.88569 7.56967C1.71486 8.51231 1.76009 9.48142 2.018 10.404C2.2759 11.3267 2.73974 12.1788 3.37461 12.8962"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconDownload.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconDownload.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};
export default IconDownload;
