/**
 * This utility generates a key for use in React iterarators.
 * The key returned _is not_ random so will not cause re-rendering side effects
 * but it will be unique if the index changes but the id doesn't
 *
 * @param {string} id id to include in key
 * @param {number} index number to combine with data to make the value unique (usually the index)
 */
const keyGenerator = (id, index) => btoa(unescape(encodeURIComponent(`${index}-${id}`)));

/**
 * Returns pluralised ordinal string based on provided locale
 *
 * @param {number} number number to convert
 * @param {string} locale locale to convert to (defaults to en-GB)
 */
const ordinal = (number, locale = 'en-GB') => {
  const rules = new Intl.PluralRules(locale, { type: 'ordinal' });
  switch (rules.select(number)) {
    case 'one':
      return `${number}st`;
    case 'two':
      return `${number}nd`;
    case 'few':
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

const formatNumber = (num, locale = 'en-GB') => {
  const numberFormat = new Intl.NumberFormat(locale);
  return !Number.isNaN(num) ? numberFormat.format(num) : num;
};

/**
 * Utility that returns a modified object with an additional timeStamp key.
 *
 * @param {object} object object to modify and return
 * @param {string} key optional key (defaults to 'timeStamp')
 */
const addTimestamp = (object, key = 'timeStamp') => ({
  ...object,
  [key]: Date.now()
});

export { keyGenerator, ordinal, formatNumber, addTimestamp };
