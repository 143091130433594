import React from 'react';

import PropTypes from 'prop-types';

const IconFile = ({ size }) => (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={size} height={size} rx="3" fill="#FE5676" />
        <path
          d="M5.9502 7.6502L1.7002 13.6002H15.3002L10.2002 5.9502L8.0752 9.3502L5.9502 7.6502Z"
          fill="white"
        />
      </svg>
    </div>
  );

IconFile.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

IconFile.defaultProps = {
  size: '100%'
};

export default IconFile;
