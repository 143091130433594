/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useBuilding } from '../../context/building-context';
import SelectInput from '../forms/SelectInput/SelectInput';

import styles from './CompanySelect.module.scss';

const CompanySelect = ({
  label,
  register,
  errors,
  name,
  filter,
  className,
  fullWidth,
  showLabel,
  isRequired,
  onChange,
  // hideRetailers <- Disabled due to weird behaviour. To be reactivated at later point

  buildingId,
  selectedValue,
  ...props
}) => {
  const { buildingData, buildings } = useBuilding();

  let occupiers = [];

  const processOccupiers = (occupiersToProcess) => {
    if (Array.isArray(occupiersToProcess)) {
      occupiers = occupiersToProcess
        .filter(filter)
        .map(
          ({ archived, company: { id, name: optionTextValue }, enabled }) => ({
            id,
            name: optionTextValue,
            disabled: !enabled || archived
          })
        );
    }
  };

  if (+buildingId !== buildingData?.id) {
    const selectedBuilding = buildings
      .filter(({ id }) => id === parseInt(buildingId, 10))
      .pop();
    processOccupiers(selectedBuilding?.occupiers);
  } else {
    processOccupiers(buildingData?.occupiers);
  }

  return (
    <SelectInput
      name={name}
      className={classnames(styles.select, className)}
      errors={errors}
      register={register}
      values={occupiers}
      valueKey="id"
      labelKey="name"
      selectedValue={
        occupiers.length === 1 ? occupiers[0].id : selectedValue || null
      }
      {...{
        label,
        showLabel,
        errors,
        register,
        name,
        fullWidth,
        onChange
      }}
      {...props}
    />
  );
};

CompanySelect.propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  errors: PropTypes.shape([]),
  name: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  showLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  filter: PropTypes.func,
  onChange: PropTypes.func,
  buildingId: PropTypes.number,
  selectedValue: PropTypes.number
};

CompanySelect.defaultProps = {
  label: '',
  register: null,
  errors: null,
  name: '',
  className: null,
  fullWidth: false,
  showLabel: true,
  isRequired: true,
  filter: () => true,
  onChange: null,
  buildingId: null,
  selectedValue: null
};

export default CompanySelect;
