import Client from '../../../Client';
import BuildingEntityMockNamespace from './BuildingEntity';

export default class DepartmentsMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Department 1',
      building_id: 1,
      seq: 1,
      enabled: true,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      name: 'Department 2',
      building_id: 1,
      seq: 2,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      name: 'Department 3',
      building_id: 1,
      seq: 3,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      name: 'Department 1',
      building_id: 2,
      seq: 1,
      enabled: true,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 5,
      name: 'Department 2',
      building_id: 2,
      seq: 2,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 6,
      name: 'Department 3',
      building_id: 2,
      seq: 3,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 7,
      name: 'Department 1',
      building_id: 3,
      seq: 1,
      enabled: true,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 8,
      name: 'Department 2',
      building_id: 3,
      seq: 2,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 9,
      name: 'Department 3',
      building_id: 3,
      seq: 3,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
    this.buildingMocks = new BuildingEntityMockNamespace(Client, Framework);
  }

  getEndpoints() {
    return {
      'buildings/departments': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.search(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/departments/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.create(
          this.dataSet,
          'department',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/departments/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.update(
          this.dataSet,
          'department',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/departments/updateMultiple': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.updateMultiple(
          this.dataSet,
          'department',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/departments/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.delete(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        )
    };
  }
}
