import Client from '../../../../Client';

export default class MobileArticlesMockNamespace {
  dataSet = [
    {
      id: 1,
      building_id: 1,
      title: 'dolor deleniti assumenda',
      category_id: 8,
      section_id: 1,
      publish_date: '2021-04-15T10:38:59.000Z',
      body:
        'Commodi aliquam expedita odit nihil dolorum placeat atque. Ea ab aut. Pariatur aspernatur et ea laudantium assumenda.',
      button_text: 'consequatur est recusandae',
      website_url: 'https://adolfo.net',
      featured: false,
      archived: false,
      draft: false,
      created: '2021-04-15T10:38:59.000Z',
      modified: '2021-04-15T12:03:28.000Z',
      category: {
        id: 8,
        name: 'blanditiis culpa beatae',
        seq: 8,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      section: {
        id: 1,
        name: 'Section 1',
        seq: 1,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: 'f5557e6c-1deb-493f-98d0-7923a66045e0',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/business',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 2,
      building_id: 1,
      title: 'nihil sint voluptates',
      category_id: 7,
      section_id: 3,
      publish_date: '2021-04-15T10:39:30.000Z',
      body:
        'Nemo fugiat incidunt et nisi totam nihil nesciunt. Ea dignissimos dolore perferendis quia eum et quia tenetur voluptatibus. Est nisi et quas et.',
      button_text: 'quasi odio officiis',
      website_url: 'http://gretchen.net',
      featured: false,
      archived: false,
      draft: true,
      created: '2021-04-15T10:39:29.000Z',
      modified: '2021-04-15T10:39:29.000Z',
      category: {
        id: 7,
        name: 'deserunt necessitatibus illum',
        seq: 1,
        enabled: true,
        created: '2021-04-15T10:33:18.000Z',
        modified: '2021-04-15T10:33:18.000Z'
      },
      section: {
        id: 3,
        name: 'Section 3',
        seq: 3,
        enabled: false,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '2d732540-13c1-48d5-822e-1b779cead565',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/animals',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 3,
      building_id: 1,
      title: 'impedit enim qui',
      category_id: 9,
      section_id: 1,
      publish_date: '2021-04-15T10:43:16.000Z',
      body:
        'Quisquam praesentium voluptatum culpa officia animi aut. Et aspernatur sint fugit dolorem non vel qui. Culpa necessitatibus eaque quibusdam enim nobis delectus ut sed possimus. Quibusdam aut tenetur nam nulla ut quo voluptate exercitationem. Qui consequuntur id et laborum et ut.',
      button_text: 'rerum quia non',
      website_url: 'https://ignatius.com',
      featured: false,
      archived: true,
      draft: false,
      created: '2021-04-15T10:43:15.000Z',
      modified: '2021-04-15T10:43:15.000Z',
      category: {
        id: 9,
        name: 'dolores rem quo',
        seq: 1,
        enabled: true,
        created: '2021-04-15T10:48:13.000Z',
        modified: '2021-04-15T10:48:13.000Z'
      },
      section: {
        id: 1,
        name: 'Section 1',
        seq: 1,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: 'd0f87ca0-6d93-4475-83d3-e3782259e451',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/architecture',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 4,
      building_id: 1,
      title: 'dignissimos nulla ad',
      category_id: 6,
      section_id: 1,
      publish_date: '2022-04-15T10:55:00.000Z',
      body:
        'Delectus omnis et officiis modi inventore voluptas labore. Nostrum temporibus eveniet molestias laudantium. Aut sit velit incidunt in in. Alias necessitatibus reprehenderit. Rerum repellat animi aliquam aut consequatur. Sequi illo rem maxime quae et ut totam in vitae.',
      button_text: 'et impedit quia',
      website_url: 'https://alexys.com',
      featured: false,
      archived: true,
      draft: true,
      created: '2021-04-15T10:54:59.000Z',
      modified: '2021-04-15T10:54:59.000Z',
      category: {
        id: 6,
        name: 'eveniet quia qui',
        seq: 1,
        enabled: true,
        created: '2021-04-15T10:31:06.000Z',
        modified: '2021-04-15T10:31:06.000Z'
      },
      section: {
        id: 2,
        name: 'Section 2',
        seq: 2,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '6f48956d-9209-49ce-bd65-5a430829f963',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/nature',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 5,
      building_id: 1,
      title: 'this article is featured and scheduled for publish later',
      category_id: 5,
      section_id: 4,
      publish_date: '2029-08-10T11:07:37.000Z',
      body:
        'Mollitia natus esse et delectus ducimus quod. Est ea voluptatem atque ad aut sit tempore accusamus et. Non et earum accusamus omnis fuga dolor. Magnam eum perspiciatis quo nulla magnam laborum consequatur.',
      button_text: 'nesciunt eius cumque',
      website_url: 'http://jayde.info',
      featured: true,
      archived: false,
      draft: false,
      created: '2021-04-15T11:07:36.000Z',
      modified: '2021-04-15T11:07:36.000Z',
      category: {
        id: 5,
        name: 'incidunt rerum eveniet',
        seq: 8,
        enabled: false,
        created: '2021-04-15T10:30:56.000Z',
        modified: '2021-04-15T10:35:09.000Z'
      },
      section: {
        id: 4,
        name: 'Section 4',
        seq: 4,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '866bdc8a-7ab6-4df4-bdce-67e40b37a5b0',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/people',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 6,
      building_id: 1,
      title: 'quisquam reprehenderit aut',
      category_id: 3,
      section_id: 1,
      publish_date: '2021-04-15T11:14:26.000Z',
      body:
        'Qui rerum et consequuntur. Est cupiditate facilis. Molestias delectus aut quis animi quidem quibusdam repellendus eos. Est perspiciatis repellat et.',
      button_text: 'dolorem recusandae pariatur',
      website_url: 'https://joanne.org',
      featured: true,
      archived: false,
      draft: true,
      created: '2021-04-15T11:14:25.000Z',
      modified: '2021-04-15T11:14:25.000Z',
      category: {
        id: 3,
        name: 'laudantium tenetur nihil',
        seq: 0,
        enabled: true,
        created: '2021-04-15T10:30:37.000Z',
        modified: '2021-04-15T10:30:37.000Z'
      },
      section: {
        id: 1,
        name: 'Section 1',
        seq: 1,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: 'eaa1546b-2cd9-4931-81bc-5bb68cb3c7e6',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/tech',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 7,
      building_id: 1,
      title: 'similique quam officia',
      category_id: 8,
      section_id: 3,
      publish_date: '2024-01-09T11:15:18.000Z',
      body:
        'Eum incidunt in pariatur corporis recusandae. Dolorem necessitatibus saepe voluptatem incidunt sed et labore. Numquam quas consectetur eius optio rem veniam.',
      button_text: 'suscipit hic aperiam',
      website_url: 'http://ella.net',
      featured: true,
      archived: true,
      draft: false,
      created: '2021-04-15T11:15:17.000Z',
      modified: '2021-04-15T11:15:17.000Z',
      category: {
        id: 8,
        name: 'blanditiis culpa beatae',
        seq: 8,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      section: {
        id: 3,
        name: 'Section 3',
        seq: 3,
        enabled: false,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '23193b78-63a9-4b91-89cb-3d28dba6b852',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/business',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 8,
      building_id: 1,
      title: 'est odio laboriosam',
      category_id: 8,
      section_id: 5,
      publish_date: '2021-04-15T11:46:55.000Z',
      body:
        'Dolorem sit dolorem magni velit reprehenderit fugit. Quia ut et doloribus repellat velit natus. Enim quas consequuntur fugit et et harum totam quasi. Quisquam expedita unde commodi ratione cum tenetur non.',
      button_text: 'ut ut beatae',
      website_url: 'https://lenore.biz',
      featured: true,
      archived: true,
      draft: true,
      created: '2021-04-15T11:46:54.000Z',
      modified: '2021-04-15T11:46:54.000Z',
      category: {
        id: 8,
        name: 'blanditiis culpa beatae',
        seq: 8,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      section: {
        id: 5,
        name: 'Section 5',
        seq: 5,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '573fe8a9-8b8d-4daa-b70a-0c51477e9225',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/business',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 9,
      building_id: 1,
      title: 'est odio laboriosam',
      category_id: 8,
      section_id: 5,
      publish_date: '2021-04-15T11:46:55.000Z',
      body:
        'Dolorem sit dolorem magni velit reprehenderit fugit. Quia ut et doloribus repellat velit natus. Enim quas consequuntur fugit et et harum totam quasi. Quisquam expedita unde commodi ratione cum tenetur non.',
      button_text: 'ut ut beatae',
      website_url: 'https://lenore.biz',
      featured: true,
      archived: true,
      draft: true,
      created: '2021-04-15T11:46:54.000Z',
      modified: '2021-04-15T11:46:54.000Z',
      category: {
        id: 8,
        name: 'blanditiis culpa beatae',
        seq: 8,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      section: {
        id: 5,
        name: 'Section 5',
        seq: 5,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '573fe8a9-8b8d-4daa-b70a-0c51477e9225',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/animals',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 10,
      building_id: 1,
      title: 'est odio laboriosam',
      category_id: 8,
      section_id: 5,
      publish_date: '2021-04-15T11:46:55.000Z',
      body:
        'Dolorem sit dolorem magni velit reprehenderit fugit. Quia ut et doloribus repellat velit natus. Enim quas consequuntur fugit et et harum totam quasi. Quisquam expedita unde commodi ratione cum tenetur non.',
      button_text: 'ut ut beatae',
      website_url: 'https://lenore.biz',
      featured: true,
      archived: true,
      draft: true,
      created: '2021-04-15T11:46:54.000Z',
      modified: '2021-04-15T11:46:54.000Z',
      category: {
        id: 8,
        name: 'blanditiis culpa beatae',
        seq: 8,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      section: {
        id: 5,
        name: 'Section 5',
        seq: 5,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '573fe8a9-8b8d-4daa-b70a-0c51477e9225',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/nature',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    },
    {
      id: 11,
      building_id: 1,
      title: 'est odio laboriosam',
      category_id: 8,
      section_id: 5,
      publish_date: '2021-04-15T11:46:55.000Z',
      body:
        'Dolorem sit dolorem magni velit reprehenderit fugit. Quia ut et doloribus repellat velit natus. Enim quas consequuntur fugit et et harum totam quasi. Quisquam expedita unde commodi ratione cum tenetur non.',
      button_text: 'ut ut beatae',
      website_url: 'https://lenore.biz',
      featured: true,
      archived: true,
      draft: true,
      created: '2021-04-15T11:46:54.000Z',
      modified: '2021-04-15T11:46:54.000Z',
      category: {
        id: 8,
        name: 'blanditiis culpa beatae',
        seq: 8,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      section: {
        id: 5,
        name: 'Section 5',
        seq: 5,
        enabled: true,
        created: '2021-04-15T10:34:02.000Z',
        modified: '2021-04-15T10:34:49.000Z'
      },
      images: [
        {
          id: '573fe8a9-8b8d-4daa-b70a-0c51477e9225',
          entity_type: 'news_article',
          user_id: 1,
          uri: 'http://placeimg.com/640/480/architecture',
          filesize: 0,
          type: '',
          verified: false
        }
      ]
    }
  ];

  categoriesDataSet = [
    {
      id: 8,
      name: 'blanditiis culpa beatae',
      seq: 8,
      enabled: true,
      created: '2021-04-15T10:34:02.000Z',
      modified: '2021-04-15T10:34:49.000Z'
    },
    {
      id: 7,
      name: 'deserunt necessitatibus illum',
      seq: 1,
      enabled: true,
      created: '2021-04-15T10:33:18.000Z',
      modified: '2021-04-15T10:33:18.000Z'
    },
    {
      id: 9,
      name: 'dolores rem quo',
      seq: 1,
      enabled: true,
      created: '2021-04-15T10:48:13.000Z',
      modified: '2021-04-15T10:48:13.000Z'
    },
    {
      id: 6,
      name: 'eveniet quia qui',
      seq: 1,
      enabled: true,
      created: '2021-04-15T10:31:06.000Z',
      modified: '2021-04-15T10:31:06.000Z'
    },
    {
      id: 5,
      name: 'incidunt rerum eveniet',
      seq: 8,
      enabled: false,
      created: '2021-04-15T10:30:56.000Z',
      modified: '2021-04-15T10:35:09.000Z'
    },
    {
      id: 3,
      name: 'laudantium tenetur nihil',
      seq: 0,
      enabled: true,
      created: '2021-04-15T10:30:37.000Z',
      modified: '2021-04-15T10:30:37.000Z'
    },
    {
      id: 4,
      name: 'laudantium tenetur nihil',
      seq: 0,
      enabled: true,
      created: '2021-04-15T10:30:39.000Z',
      modified: '2021-04-15T10:30:39.000Z'
    },
    {
      id: 1,
      name: 'laudantium tenetur nihil',
      seq: 1,
      enabled: true,
      created: '2021-04-15T10:30:12.000Z',
      modified: '2021-04-15T10:30:12.000Z'
    },
    {
      id: 2,
      name: 'laudantium tenetur nihil',
      seq: 1,
      enabled: true,
      created: '2021-04-15T10:30:28.000Z',
      modified: '2021-04-15T10:30:28.000Z'
    },
    {
      id: 12,
      name: 'libero quisquam qui',
      seq: 1,
      enabled: true,
      created: '2021-04-15T11:06:41.000Z',
      modified: '2021-04-15T11:06:41.000Z'
    },
    {
      id: 10,
      name: 'modi voluptas amet',
      seq: 8,
      enabled: false,
      created: '2021-04-15T10:50:19.000Z',
      modified: '2021-04-15T10:54:44.000Z'
    },
    {
      id: 11,
      name: 'quibusdam sed facere',
      seq: 1,
      enabled: true,
      created: '2021-04-15T10:52:48.000Z',
      modified: '2021-04-15T10:52:48.000Z'
    },
    {
      id: 13,
      name: 'voluptates dolor aperiam',
      seq: 1,
      enabled: true,
      created: '2021-04-15T12:11:04.000Z',
      modified: '2021-04-15T12:11:04.000Z'
    }
  ];

  sectionsDataSet = [
    {
      id: 1,
      name: 'Section 1',
      seq: 1,
      enabled: true,
      created: '2021-04-15T10:34:02.000Z',
      modified: '2021-04-15T10:34:49.000Z'
    },
    {
      id: 2,
      name: 'Section 2',
      seq: 2,
      enabled: true,
      created: '2021-04-15T10:33:18.000Z',
      modified: '2021-04-15T10:33:18.000Z'
    },
    {
      id: 3,
      name: 'Section 3',
      seq: 3,
      enabled: false,
      created: '2021-04-15T10:48:13.000Z',
      modified: '2021-04-15T10:48:13.000Z'
    },
    {
      id: 4,
      name: 'Section 4',
      seq: 4,
      enabled: true,
      created: '2021-04-15T10:31:06.000Z',
      modified: '2021-04-15T10:31:06.000Z'
    },
    {
      id: 5,
      name: 'Section 5',
      seq: 5,
      enabled: false,
      created: '2021-04-15T10:31:06.000Z',
      modified: '2021-04-15T10:31:06.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (article) => {
    const { category_id, section_id } = article;

    article.category = this.categoriesDataSet.find(
      ({ id }) => id === parseInt(category_id, 10)
    );

    article.section = this.sectionsDataSet.find(
      ({ id }) => id === parseInt(section_id, 10)
    );

    return article;
  };

  getEndpoints() {
    return {
      'mobile/articles': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = ['not_logged_in', 'no_permission'];
        const filters = {
          id: {
            field: 'id',
            expression: 'eq'
          },
          name: {
            field: 'name',
            expression: 'like'
          }
        };
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'mobile/articles/dashboard': () => {
        const dashboard = {
          categories: this.categoriesDataSet,
          sections: this.sectionsDataSet
        };
        return {
          code: 200,
          data: { ...dashboard }
        };
      },
      'mobile/articles/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        templatePermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      },
      'mobile/articles/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        const {
          archived,
          body,
          building_id,
          button_text,
          category_id,
          draft,
          featured,
          images,
          publish_date,
          section_id,
          title,
          website_url
        } = payload;

        const entity = {
          id: this.dataSet.length + 1,
          building_id: parseInt(building_id, 10),
          category_id: parseInt(category_id, 10),
          section_id: parseInt(section_id, 10),
          archived,
          body,
          button_text,
          draft,
          featured,
          images: [
            {
              id: '573fe8a9-8b8d-4daa-b70a-0c51477e9225',
              entity_type: 'news_article',
              user_id: 1,
              uri: 'http://placeimg.com/640/480/architecture',
              filesize: 0,
              type: '',
              verified: false
            }
          ],
          publish_date: publish_date || new Date(publish_date).toISOString(),
          title,
          website_url
        };
        const translatedEntity = this.translateEntities(entity);
        this.dataSet.push(translatedEntity);
        return {
          code: 200,
          data: { article: entity }
        };
      },
      'mobile/articles/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'update_no_permission',
          'provide_article_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const article = this.framework.updateRecord(
            this.dataSet,
            parseInt(payload.id, 10),
            'id',
            this.translateEntities(payload)
          );

          return {
            code: 200,
            data: { article }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'mobile/articles/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const archived = false;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          return {
            code: 200,
            data: { archived }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },

      'mobile/articles/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const archived = true;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          return {
            code: 200,
            data: { archived }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
