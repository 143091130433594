/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconUndo = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 150 108"
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.85749 50.8226C13.7759 46.9042 20.1288 46.9042 24.0472 50.8226L50.7722 77.5476C54.6906 81.466 54.6906 87.8189 50.7722 91.7373C46.8538 95.6557 40.5009 95.6557 36.5825 91.7373L9.85749 65.0123C5.93911 61.0939 5.93911 54.741 9.85749 50.8226Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.0891 51.0943C82.0075 55.0127 82.0075 61.3656 78.0891 65.284L51.3775 91.9956C47.4591 95.914 41.1062 95.914 37.1878 91.9956C33.2694 88.0772 33.2694 81.7243 37.1878 77.8059L63.8994 51.0943C67.8178 47.1759 74.1707 47.1759 78.0891 51.0943Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.6001 20.0673C70.7963 20.0673 53.7112 37.1869 53.7112 58.5785C53.7112 63.4861 54.6096 68.1555 56.2378 72.4424C58.2053 77.6227 55.6008 83.4172 50.4205 85.3848C45.2401 87.3523 39.4456 84.7478 37.4781 79.5674C34.9976 73.0366 33.644 65.9548 33.644 58.5785C33.644 26.349 59.4702 0 91.6001 0C123.73 0 149.556 26.349 149.556 58.5785C149.556 67.6926 147.49 76.3502 143.792 84.0727C141.399 89.0706 135.407 91.182 130.409 88.7886C125.411 86.3953 123.299 80.4035 125.693 75.4055C128.12 70.3368 129.489 64.6358 129.489 58.5785C129.489 37.1869 112.404 20.0673 91.6001 20.0673Z"
      fill="currentColor"
    />
  </svg>
);

IconUndo.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconUndo.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconUndo);
