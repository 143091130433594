import Building from '../Building';
import Floor from '../Buildings/Floor';
import Location from '../Buildings/Location';
import Comment from '../Comment';
import Company from '../Company';
import User from '../User';

export default class Permit {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIPermit - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      activated,
      activated_date,
      activated_user,
      activated_user_id,
      approved,
      approved_date,
      approved_user,
      approved_user_id,
      archived,
      asbestos_register,
      building,
      building_id,
      closed,
      closed_date,
      closed_user,
      closed_user_id,
      comments,
      company,
      company_id,
      company_name,
      contact_name,
      contact_number,
      contractor_company_name,
      data,
      description,
      draft,
      finish,
      floor,
      floor_id,
      hazard_sheet,
      location,
      location_id,
      other_details,
      rejected,
      rejected_date,
      rejected_user,
      rejected_user_id,
      safety_measures,
      sections,
      start,
      user,
      user_id,
      work_types,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC, parseISODateTimeAsLocalTime } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || null;

    this.contact_name = contact_name || '';
    this.contact_number = contact_number || '';
    this.company_name = company_name || '';

    this.start = parseISODateTimeAsLocalTime(start);
    this.finish = parseISODateTimeAsLocalTime(finish);

    this.user_id = user_id || null;
    this.company_id = company_id || null;
    this.building_id = building_id || null;
    this.floor_id = floor_id || null;
    this.location_id = location_id || null;

    this.description = description || '';
    this.safety_measures = safety_measures || '';
    this.other_details = other_details || '';

    this.hazard_sheet = Boolean(hazard_sheet);
    this.asbestos_register = Boolean(asbestos_register);

    this.user = user ? new User(Client, user) : null;
    this.company = company ? new Company(Client, company) : null;
    this.building = building ? new Building(Client, building) : null;

    this.floor = floor ? new Floor(Client, floor) : null;
    this.location = location ? new Location(Client, location) : null;

    this.comments = Array.isArray(comments)
      ? comments.map((comment) => new Comment(Client, comment))
      : [];

    this.activated = activated || false;
    this.activated_date = parseISODateTimeAsUTC(activated_date);
    this.activated_user_id = activated_user_id || null;
    this.activated_user = activated_user
      ? new User(Client, activated_user)
      : null;

    this.closed = closed || false;
    this.closed_date = parseISODateTimeAsUTC(closed_date);
    this.closed_user_id = closed_user_id || null;
    this.closed_user = closed_user ? new User(Client, closed_user) : null;

    this.approved = Boolean(approved);
    this.approved_date = parseISODateTimeAsUTC(approved_date);
    this.approved_user_id = approved_user_id || null;
    this.approved_user =
      typeof approved_user === 'object'
        ? new User(Client, approved_user)
        : null;

    this.rejected = Boolean(rejected);
    this.rejected_date = parseISODateTimeAsUTC(rejected_date);
    this.rejected_user_id = rejected_user_id || null;
    this.rejected_user =
      typeof rejected_user === 'object'
        ? new User(Client, rejected_user)
        : null;
    this.work_types = work_types || [];
    this.contractor_company_name = contractor_company_name || '';

    this.data = data || {};
    this.sections = sections || {};

    this.draft = Boolean(draft);
    this.archived = archived || false;
    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  getStatusLabel() {
    const { draft, archived, rejected, approved, activated, closed } = this;

    let status = 'pending';
    if (draft) status = 'draft';
    else if (archived) status = 'archived';
    else if (closed) status = 'closed';
    else if (activated) status = 'active';
    else if (rejected) status = 'rejected';
    else if (approved) status = 'approved';

    return status;
  }

  processEntities({
    user = false,
    building = false,
    floor = false,
    location = false
  }) {
    return new Promise((resolve) => {
      const promises = [];

      if (user) promises.push(this.getUser());
      if (building) promises.push(this.getBuilding());
      if (location) promises.push(this.getLocation());
      if (floor) promises.push(this.getFloor());

      Promise.all(promises).then(() => resolve(this));
    });
  }

  getUser() {
    return new Promise((resolve) => resolve(this.user));
  }

  getBuilding() {
    return new Promise((resolve) => resolve(this.building));
  }

  getFloor() {
    return new Promise((resolve) => resolve(this.floor));
  }

  getLocation() {
    return new Promise((resolve) => resolve(this.location));
  }
}
