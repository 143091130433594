import logger from 'services/logger/logger';

import Client from '../Client';
import FileUpload from '../Entities/FileUpload';
import Theme from '../Entities/Theme';

export default class GeneralNamespace {
  /** *
   * @param {Client} client - instance of client
   */
  constructor(Client) {
    this.client = Client;
  }

  /**
   * Upload a fileSearch users, you must be logged in to perform this action
   *
   * @param {Object} file - File element file element eg: document.querySelector('#file').files[0];
   * @param {Object} [data] - Data you would like to associate with the file, should be Key Value Pair eg: {location: 'London', title:'The Leadenhall Building'}
   * @param {Boolean} [secure] - Should this file be secured?
   * @param {function(ProgressEvent)} [progressCallback] - callback for when the handler registers progress
   *
   * upload(
   *    {name:'file.png', size: 10204304, type:'image/png', lastModified: 1599147956228, webkitRelativePath: ''},
   *    {location:'london'},
   *    false,
   *    (error, file) => {logger.log(error, file)},
   *    (progressEvent) => {logger.debug(Math.round((progressEvent.loaded * 100) / progressEvent.total))}
   * );
   *
   * @param requestData
   * @param namespace
   * @param call
   * @throws Error
   */
  upload = ({
    file,
    data = null,
    secure = false,
    onProgress,
    requestData = {},
    namespace = '',
    call = 'generateUploadLink',
    method = 'post'
  }) => {
    const {
      client: { mocked, axios }
    } = this;
    if (!mocked) {
      return new Promise((resolve, reject) => {
        const handleUpload = () => {
          const formData = new FormData();

          logger.debug(requestData);
          for (const key in requestData.body) {
            const value = requestData.body[key];
            formData.append(key, value);
          }

          formData.append('file', file);

          const config = {
            onUploadProgress: ({ loaded, total }) => {
              loaded = Math.round((loaded * 100) / total);
              onProgress({ loaded, total });
            }
          };

          resolve(
            axios[method](requestData?.uploadUrl || requestData.url, formData, config).then(
              ({ data }) => {
                const parser = new DOMParser();
                const dom = parser.parseFromString(data, 'application/xml');

                let id = dom.getElementsByTagName('Key');
                if (id.length) id = id[0].innerHTML;
                else {
                  // eslint-disable-next-line no-template-curly-in-string
                  id = requestData.body?.key?.replace ? requestData.body.key.replace('${filename}', file?.name || '') : '';
                }

                return { uri: `${requestData.url}/${id}`, id, requestData };
              }
            )
          );
        };

        if (
          !Object.prototype.hasOwnProperty.call(requestData, 'url') &&
          !Object.prototype.hasOwnProperty.call(requestData, 'body')
        ) {
          this.client.request(
            namespace,
            call,
            (error, response) => {
              if (!response?.data && !error) error = new Error('unknown_error');
              if (error) reject(error);
              else {
                requestData = {
                  url: response?.data?.url,
                  uploadUrl: response?.data?.uploadUrl,
                  body: response?.data?.requestBody
                };
              }
              handleUpload();
            },
            data,
            Client.methods.GET
          );
        } else {
          handleUpload();
        }
      });
    }
    return new Promise((resolve, reject) => {
      if (typeof file !== 'object')
        throw new Error('Please provide a valid file');

      let interval = null;
      let loaded = 0;
      const total = 100;
      interval = setInterval(() => {
        loaded += 10;
        if (typeof onProgress === 'function') {
          try {
            onProgress({ loaded, total });
          } catch (e) {
            logger.debug('Upload Progress Exception', e);
          }
        }
        if (loaded >= total) {
          clearInterval(interval);
          resolve(
            new FileUpload(this.client, {
              id: 1,
              uri:
                'https://qph.fs.quoracdn.net/main-qimg-6291c3a117fc230c82785148baef7eed',
              secure,
              data
            })
          );
        }
      }, 50);
    });
  };

  myProfile = (buildingId) =>
    new Promise((resolve, reject) => {
      this.client.request(
        '',
        'profile',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { buildingId },
        Client.methods.GET
      );
    });

  saveMyProfile = (data) =>
    new Promise((resolve, reject) => {
      this.client.request(
        'profile',
        'update',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        data,
        Client.methods.POST
      );
    });

  changeMyPassword = (password) =>
    new Promise((resolve, reject) => {
      this.client.request(
        'profile',
        'password/update',
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { password },
        Client.methods.POST
      );
    });

  getFileLink = (fileURI, data, namespace, call) =>
    new Promise((resolve, reject) => {
      this.client.request(
        namespace,
        call,
        (error, response) => {
          if (error) reject(error);
          else {
            resolve(response?.data);
          }
        },
        { file_uri: fileURI, ...data },
        Client.methods.GET
      );
    });

  platformSetup = (theme_key) =>
    new Promise((resolve, reject) => {
      this.client.request(
        '',
        'platformSetup',
        (error, response) => {
          if (error) reject(error);
          else {
            const {
              data: { theme, features, apigw_url }
            } = response;
            resolve({
              theme: theme ? new Theme(theme) : null,
              features,
              apigw_url
            });
          }
        },
        { theme_key },
        Client.methods.GET
      );
    });
}
