import BuildingEntityMockNamespace from '../Buildings/BuildingEntity';

export default class VisitorTypesMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Visitor',
      building_id: 1,
      seq: 1,
      enabled: true,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      name: 'Contractor',
      building_id: 1,
      seq: 2,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      name: 'Forgotten Pass',
      building_id: 1,
      seq: 3,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      name: 'Temporary Employee',
      building_id: 1,
      seq: 4,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
    this.buildingMocks = new BuildingEntityMockNamespace(Client, Framework);
  }

  getEndpoints() {
    return {
      'visitors/types': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.search(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'visitors/types/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.create(
          this.dataSet,
          'type',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'visitors/types/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.update(
          this.dataSet,
          'visitor_type',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'visitors/types/updateMultiple': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.updateMultiple(
          this.dataSet,
          'visitor_type',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'visitors/types/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.delete(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        )
    };
  }
}
