/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconNotifications = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="m14.8685 10.4606-.0267-.0313-1.126-1.63771v-2.93302c0-1.96839-1.0213-3.78221-2.737-4.891631h.0041s-.0288-.019567-.0822-.050873c-.0144-.009784-.0288-.019567-.0452-.02935h-.0062c-.6349-.365894-3.49724-1.796207-6.68423 0h-.02466c-.05548.03522-.1089.072396-.16438.107616-.01233.007828-.02466.013698-.03699.021528h.00411c-1.66849 1.11333-2.6589 2.90171-2.6589 4.84271v2.93302l-1.169182 1.66901c-.010273.0333-.0287666.0646-.0513694.09l-.0226027.0411c-.0410959.0822-.0410959.1096-.0410959.1585 0 .0157 0 .0372.00205479.045l.05958901.0567.010274.1311.0595892.0568c.008219.0078.016438.0117.032877.0215.014383.0078.039041.0235.061643.0431l.043151.0215c.084247.0411.113014.0411.164384.0411h4.723968l.03699.1506c.24041.9764 1.21027 1.6827 2.30343 1.6827 1.06643 0 1.9952-.6809 2.30753-1.6944l.04315-.1409h4.71367c.2733 0 .4356-.1546.4356-.4148.0021-.1213-.0328-.1976-.1294-.2896zm-6.09247.9881c-.22603.4305-.73767.7201-1.27603.7201s-1.05-.2896-1.27603-.7201l-.14794-.2837h2.84794z"
    />
  </svg>
);

IconNotifications.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconNotifications.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconNotifications);
