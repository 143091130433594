/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState } from 'react';

const UIContext = createContext();

const UIProvider = (props) => {
  const [activeMenu, setActiveMenu] = useState('primary-menu'); // 'sub-menu' or 'primary-menu'
  const [layoutRefs, setLayoutRefs] = useState();

  const [layoutState, setLayoutState] = useState({
    nav: 'open',
    filter: 'closed'
  });

  const toggleNavMenu = () => {
    setLayoutState(({ nav, ...rest }) => ({
      ...rest,
      nav: nav === 'open' ? 'closed' : 'open'
    }));
  };

  const toggleActiveMenu = () => {
    setActiveMenu((currentValue) =>
      currentValue === 'primary-menu' ? 'sub-menu' : 'primary-menu'
    );
  };

  return (
    <UIContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        toggleNavMenu,
        layoutRefs,
        setLayoutRefs,
        layoutState,
        setLayoutState,
        toggleActiveMenu
      }}
      {...props}
    />
  );
};

const useUI = () => useContext(UIContext);

export { UIProvider, useUI };
