/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState } from 'react';

import shortid from 'shortid';
import { addTimestamp } from 'utils';

const SystemNotificationsContext = createContext();

const SystemNotificationsProvider = (props) => {
  const [systemNotifications, setSystemNotifications] = useState([]);

  const dismissSystemNotification = (idToRemove) => {
    setSystemNotifications((currentNotifications) =>
      currentNotifications.filter(({ id }) => id !== idToRemove)
    );
  };

  const updateSystemNotification = (idToUpdate, update) => {
    setSystemNotifications((currentNotifications) => {
      const updateIndex = currentNotifications.findIndex(
        ({ id }) => id === idToUpdate
      );
      const updatedNotifications = [...currentNotifications];

      updatedNotifications[updateIndex] = {
        ...updatedNotifications[updateIndex],
        ...update
      };

      return updatedNotifications;
    });
  };

  const startTimer = (id, timeout) => {
    setTimeout(() => {
      dismissSystemNotification(id);
    }, timeout);
  };

  const sendSystemNotification = (notification) => {
    const { type = 'success', timeout } = notification;
    const id = shortid();

    if (!timeout) {
      switch (type) {
        case 'success':
          startTimer(id, timeout || 10000);
          break;
        case 'warning':
          startTimer(id, timeout || 30000);
          break;
        default:
          startTimer(id, timeout || 20000);
          break;
      }
    } else if (typeof timeout === 'number') {
      startTimer(id, timeout);
    }

    setSystemNotifications((currentNotifications) => [
      ...currentNotifications,
      { ...addTimestamp(notification), id }
    ]);
    return id;
  };

  return (
    <SystemNotificationsContext.Provider
      value={{
        systemNotifications,
        sendSystemNotification,
        dismissSystemNotification,
        updateSystemNotification
      }}
      {...props}
    />
  );
};

const useSystemNotifications = () => useContext(SystemNotificationsContext);

export { SystemNotificationsProvider, useSystemNotifications };
