/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconWhitelist = ({ size, colour, fill, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill={fill}
    {...rest}
  >
    <path
      d="M56,33a2,2,0,0,0-2-2H32a2,2,0,0,0,0,4H54A2,2,0,0,0,56,33Z"
      fill={fill}
    />
    <path d="M47,41H32a2,2,0,0,0,0,4H47a2,2,0,0,0,0-4Z" fill={fill} />
    <path
      d="M40,23a2,2,0,0,0-2-2H26a2,2,0,0,0,0,4H38A2,2,0,0,0,40,23Z"
      fill={fill}
    />
    <path d="M32,65H47a2,2,0,0,0,0-4H32a2,2,0,0,0,0,4Z" fill={fill} />
    <circle cx="26" cy="33" r="2" fill={fill} />
    <circle cx="26" cy="43" r="2" fill={fill} />
    <circle cx="26" cy="53" r="2" fill={fill} />
    <circle cx="26" cy="63" r="2" fill={fill} />
    <path
      d="M62,39A13,13,0,1,0,75,52,13.015,13.015,0,0,0,62,39Zm0,22a9,9,0,1,1,9-9A9.01,9.01,0,0,1,62,61Z"
      fill={fill}
    />
    <path
      d="M65.587,47.585,61,52.171l-2.586-2.586a2,2,0,0,0-2.828,2.828l4,4a2,2,0,0,0,2.828,0l6-6a2,2,0,0,0-2.828-2.828Z"
      fill={fill}
    />
    <path
      d="M62,67a2,2,0,0,0-2,2v2a2,2,0,0,1-2,2H22a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h4.35A6,6,0,0,0,32,15H48A6,6,0,0,0,48,3H32a6,6,0,0,0-5.65,4H22a6.007,6.007,0,0,0-6,6V71a6.007,6.007,0,0,0,6,6H58a6.007,6.007,0,0,0,6-6V69A2,2,0,0,0,62,67ZM32,7H48a2,2,0,0,1,0,4H32a2,2,0,0,1,0-4Z"
      fill={fill}
    />
    <path
      d="M62,37a2,2,0,0,0,2-2V13a6.007,6.007,0,0,0-6-6,2,2,0,0,0,0,4,2,2,0,0,1,2,2V35A2,2,0,0,0,62,37Z"
      fill={fill}
    />
  </svg>
);

IconWhitelist.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string,
  fill: PropTypes.string
};

IconWhitelist.defaultProps = {
  size: '80px',
  colour: 'primary',
  fill: 'white'
};

export default memo(IconWhitelist);
