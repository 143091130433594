import Client from '../Client';

export default class UserRole {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIUserRole - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      name,
      description,
      building_id,
      company_id,
      permissions,
      permission_groups,
      readonly,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.name = name || '';
    this.description = description || '';
    this.building_id = building_id;
    this.company_id = company_id;

    if (Array.isArray(permissions)) this.permissions = permissions;
    if (Array.isArray(permission_groups))
      this.permission_groups = permission_groups;

    this.readonly = Boolean(readonly);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  update(
    name = null,
    description = null,
    buildingId = null,
    companyId = null,
    permissions = null,
    callback
  ) {
    if (!this.id) throw new Error("This role doesn't have an id attached");
    if (!callback) throw new Error('Please provide a callback');

    this.Client.userRoles.update(
      this.id,
      name,
      description,
      buildingId,
      companyId,
      permissions,
      callback
    );
  }

  updatePermissions(permissions, callback) {
    if (!this.id) throw new Error("This role doesn't have an id attached");
    if (!callback) throw new Error('Please provide a callback');

    this.Client.userRoles.updatePermissions(this.id, permissions, callback);
  }
}
