import Client from '../../../Client';
import BuildingEntityMockNamespace from './BuildingEntity';

export default class PrioritiesMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Critical',
      colour: 'critical',
      building_id: 1,
      min_time: 2,
      max_time: 2,
      seq: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      name: 'High',
      colour: 'high',
      building_id: 1,
      min_time: 4,
      max_time: 4,
      seq: 2,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      name: 'Medium',
      colour: 'medium',
      building_id: 1,
      min_time: 24,
      max_time: 72,
      seq: 3,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      name: 'Low',
      colour: 'low',
      building_id: 1,
      min_time: 120,
      max_time: 120,
      seq: 4,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 5,
      name: 'Low',
      colour: 'verylow',
      building_id: 1,
      min_time: 672,
      max_time: 99999,
      seq: 5,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
    this.buildingMocks = new BuildingEntityMockNamespace(Client, Framework);
  }

  getEndpoints() {
    return {
      'buildings/priorities': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.search(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/priorities/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.create(
          this.dataSet,
          'option',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/priorities/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.update(
          this.dataSet,
          'option',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/priorities/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.delete(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        )
    };
  }
}
