import logger from 'services/logger/logger';

export default (client, framework) => (host) => {
  const onHandlers = {};

  const SocketMock = {
    connected: false,
    authenticated: false,
    connect() {
      this.connected = true;
      this.trigger('connect');
    },
    disconnect() {
      this.connected = false;
      this.trigger('disconnect');
    },
    on(event, callback) {
      if (!onHandlers[event]) onHandlers[event] = [];
      onHandlers[event].push(callback);
    },
    trigger(event, message) {
      logger.debug('Mock Trigger', event, message, onHandlers[event]);

      if (onHandlers[event]) {
        onHandlers[event].forEach((handler) => {
          try {
            handler(message);
          } catch (e) {
            logger.debug('Mock Socket Trigger failed', e);
          }
        });
      }
    },
    emit(path, packet) {
      logger.debug('Emit', path);

      const response = framework.request(
        this,
        path.substring(1),
        packet.requestData,
        {},
        'socket',
        'socket',
        this.authenticated
      );
      const socketResponse = { responseData: response };

      if (packet.requestId) {
        socketResponse.requestId = packet.requestId;
      }

      this.trigger('_response', socketResponse);
    }
  };

  setTimeout(() => SocketMock.connect(), 500);
  return SocketMock;
};
