import poll1Data from './data/poll-1';
import poll2Data from './data/poll-2';
import poll3Data from './data/poll-3';
import poll4Data from './data/poll-4';
import poll5Data from './data/poll-5';
import pollsData from './data/poll-list';
import topicDataSet from './data/topics-data.json';
import users from './data/users.json';
import generateProportion from './generateProportion';

export default class PollsMockNamespace {
  dataSet = pollsData.data.records;

  topicDataSet = topicDataSet.data;

  pollDetailsDataSet = [poll1Data, poll2Data, poll3Data, poll4Data, poll5Data];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (poll) => {
    const { topic_id } = poll;

    poll.topic = this.topicDataSet.records.find(
      ({ id }) => id === parseInt(topic_id, 10)
    );

    return poll;
  };

  getEndpoints() {
    return {
      'mobile/polls': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = ['not_logged_in', 'no_permission'];
        const filters = {
          id: {
            field: 'id',
            expression: 'eq'
          },
          name: {
            field: 'name',
            expression: 'like'
          }
        };

        if (payload.id) {
          return {
            code: 200,
            data: {
              poll: this.pollDetailsDataSet.find(({ id }) => id === payload.id)
            }
          };
        }

        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'mobile/polls/topics': () => ({
        code: 200,
        data: this.topicDataSet
      }),
      'mobile/polls/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        const {
          title,
          building_id,
          topic_id,
          user_id,
          answers,
          publish_date_time,
          expires_date_time,
          optional
        } = payload;

        const proportionOfVotes = generateProportion(answers.length);

        const entity = {
          id: String(this.dataSet.length + 1),
          title,
          expires_date_time,
          topic_id: parseInt(topic_id, 10),
          building_id: parseInt(building_id, 10),
          user_id: parseInt(user_id, 10),
          answers: answers.map((answer, index) => ({
            id: this.client.generateUUIDV4(),
            percentage: proportionOfVotes[index],
            ...answer
          })),
          archived: false,
          participants: users.users,
          participant_count: users.users.length,
          publish_date_time,
          created_by: 'Bob Jones'
        };
        const translatedEntity = this.translateEntities(entity);
        this.dataSet.push(translatedEntity);
        this.pollDetailsDataSet.push(translatedEntity);
        return {
          code: 200,
          data: { poll: entity }
        };
      },
      'mobile/polls/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'update_no_permission',
          'provide_poll_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          this.framework.updateRecord(
            this.dataSet,
            String(payload.id, 10),
            'id',
            {
              ...this.dataSet.find(({ id }) => id === payload.id),
              ...payload
            }
          );

          const fullRecord = this.pollDetailsDataSet.find(
            ({ id }) => id === payload.id
          );

          const poll = this.framework.updateRecord(
            this.pollDetailsDataSet,
            String(payload.id, 10),
            'id',
            {
              ...fullRecord,
              ...payload,
              ...(payload.answers && {
                answers: payload.answers.map((newAnswer, index) => ({
                  ...fullRecord.answers[index],
                  ...newAnswer
                }))
              })
            }
          );

          return {
            code: 200,
            data: { poll }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'mobile/polls/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const archived = false;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          this.framework.updateRecord(
            this.pollDetailsDataSet,
            payload.id,
            'id',
            {
              archived
            }
          );
          return {
            code: 200,
            data: {
              poll: this.pollDetailsDataSet.find(({ id }) => id === payload.id)
            }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },

      'mobile/polls/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }

        if (!isLoggedIn) {
          return this.framework.createErrorResponse('not_logged_in');
        }

        try {
          const archived = true;
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived
          });
          this.framework.updateRecord(
            this.pollDetailsDataSet,
            payload.id,
            'id',
            {
              archived
            }
          );
          return {
            code: 200,
            data: {
              poll: this.pollDetailsDataSet.find(({ id }) => id === payload.id)
            }
          };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
