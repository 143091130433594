/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React, { memo, useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import VisuallyHidden from 'components/accessibility/VisuallyHidden';
import * as Icons from 'components/Icons';
import PropTypes from 'prop-types';
import {
  iconPropValidator,
  themeColourPropValidator
} from 'utils/propValidators';

import ChevronDown from '../Icons/ChevronDown';

import styles from './Button.module.scss';

const Button = ({
  appearance,
  autoFocus,
  className,
  dataTestId,
  describedBy,
  disabled,
  dropdownItems,
  form,
  hidden,
  iconColour,
  iconName,
  iconSize,
  onClick,
  size,
  text,
  type,
  width,
  withMargin
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const classes = classNames(className, styles[size], styles[appearance], {
    [styles.disabled]: disabled,
    [styles['icon-padding']]: iconName && text,
    [styles.margin]: withMargin,
    [styles.hidden]: hidden,
    [styles.dropdown]: dropdownItems.length
  });

  const renderIcon = () => {
    const Icon = Icons[`Icon${iconName}`];
    const iconColours = {
      'icon-only': 'secondary-1',
      primary: 'neutral-7',
      secondary: 'neutral-7',
      tertiary: 'neutral-7',
      quarternary: 'primary',
      text: 'neutral-7'
    };

    if (!Icon) return null;

    return (
      <span className={text ? styles.icon : styles['icon-no-text']}>
        <Icon colour={iconColour || iconColours[appearance]} size={iconSize} />
      </span>
    );
  };

  const renderText = () => {
    if (appearance === 'icon-only') {
      return <VisuallyHidden>{text}</VisuallyHidden>;
    }

    if (dropdownItems.length) {
      return (
        <>
          {text}
          <ChevronDown size={20} className={styles.chevron} />
          <div
            className={classNames(styles.dropdownMenu, {
              [styles.show]: menuOpen
            })}
          >
            {dropdownItems.map(
              ({ name, onItemClick, dropdownItemDataTestId }) => (
                <button
                  className={styles.item}
                  onClick={onItemClick}
                  data-testid={dropdownItemDataTestId}
                >
                  {name}
                </button>
              )
            )}
          </div>
        </>
      );
    }

    return text;
  };

  const handleMenuClose = () => setMenuOpen(false);
  const handleMenuOpen = () => setMenuOpen(true);

  const handleOnClick = (e) => {
    if (dropdownItems) {
      e.stopPropagation();
      setMenuOpen(true);
    }
    onClick(e);
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleMenuClose);

    return () => {
      window.removeEventListener('mouseup', handleMenuOpen);
    };
  }, [menuOpen]);

  return (
    <button
      {...{ disabled }}
      ref={buttonRef}
      onClick={handleOnClick}
      className={classes}
      type={type}
      form={form}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      style={{
        ...((width !== 'auto' || size !== 'full-width') && { width }),
        ...(appearance === 'icon-only' && { height: width })
      }}
      aria-describedby={describedBy}
      title={text}
      data-testid={`${dataTestId}_button`}
    >
      {iconName && renderIcon()}
      {renderText()}
    </button>
  );
};

const ButtonPropTypes = {
  appearance: [
    'archive',
    'blue',
    'danger',
    'dropdown',
    'green',
    'icon-only',
    'outline-blue',
    'outline-purple',
    'outline-red',
    'outline-gray',
    'primary',
    'quarternary',
    'secondary',
    'shaded',
    'tertiary',
    'text'
  ],
  size: ['inline-small', 'small', 'large', 'full-width', 'half-width']
};

Button.propTypes = {
  appearance: PropTypes.oneOf(ButtonPropTypes.appearance),
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  describedBy: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onItemClick: PropTypes.func
    })
  ),
  form: PropTypes.string,
  hidden: PropTypes.bool,
  iconColour: themeColourPropValidator,
  iconName: iconPropValidator,
  iconSize: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(ButtonPropTypes.size),
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  width: PropTypes.string,
  withMargin: PropTypes.bool
};

Button.defaultProps = {
  appearance: 'primary',
  autoFocus: false,
  className: null,
  dataTestId: '',
  describedBy: null,
  disabled: false,
  dropdownItems: [],
  iconColour: null,
  iconName: null,
  iconSize: '100%',
  form: null,
  hidden: false,
  onClick: () => {},
  size: 'large',
  type: 'button',
  width: 'auto',
  withMargin: false
};

export { ButtonPropTypes };

export default memo(Button);
