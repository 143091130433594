import { tomorrow, yesterday } from './dates';

export default {
  data: {
    records: [
      {
        id: '10f4e5ee-36fd-4533-acb4-444dcf6d5c9d',
        title: 'What facilities would you like to see added to the building?',
        topic_id: 1,
        expires_date_time: tomorrow,
        user_id: 1,
        archived: false,
        created: yesterday,
        publish_date_time: yesterday,
        modified: yesterday,
        topic: {
          id: 1,
          name: 'Improvements',
          seq: 1,
          enabled: true,
          created: '2021-05-04T08:53:43.000Z',
          modified: '2021-05-04T08:53:43.000Z'
        }
      },
      {
        id: '3970afc3-4019-4d63-be2f-1a70193e1f30',
        title: 'Have you met your neighbours?',
        topic_id: 2,
        expires_date_time: yesterday,
        user_id: 1,
        archived: false,
        created: yesterday,
        publish_date_time: yesterday,
        modified: yesterday,
        topic: {
          id: 2,
          name: 'Social',
          seq: 1,
          enabled: true,
          created: '2021-05-04T08:53:43.000Z',
          modified: '2021-05-04T08:53:43.000Z'
        }
      },
      {
        id: '16c2d8fd-e7f3-4a8c-9833-b7dda4f021db',
        title: 'How do you commute to work?',
        topic_id: 3,
        expires_date_time: tomorrow,
        user_id: 1,
        archived: false,
        created: yesterday,
        publish_date_time: tomorrow,
        modified: yesterday,
        topic: {
          id: 3,
          name: 'Travel',
          seq: 1,
          enabled: true,
          created: '2021-05-04T08:53:43.000Z',
          modified: '2021-05-04T08:53:43.000Z'
        }
      },
      {
        id: 'd0339d31-1d1b-4832-a396-7545783dee6c',
        title: 'Do your colleagues share Minions memes?',
        topic_id: 4,
        expires_date_time: tomorrow,
        user_id: 1,
        archived: true,
        created: yesterday,
        publish_date_time: yesterday,
        modified: yesterday,
        topic: {
          id: 4,
          name: 'Fun',
          seq: 1,
          enabled: true,
          created: '2021-05-04T08:53:43.000Z',
          modified: '2021-05-04T08:53:43.000Z'
        }
      },
      {
        id: '3eb194f8-e803-4484-b4dd-40ab7749052e',
        title: 'Do you leave things in the communal kitchen sink (you animal)?',
        topic_id: 4,
        expires_date_time: tomorrow,
        user_id: 1,
        archived: false,
        created: yesterday,
        publish_date_time: yesterday,
        modified: yesterday,
        topic: {
          id: 4,
          name: 'Fun',
          seq: 1,
          enabled: true,
          created: '2021-05-04T08:53:43.000Z',
          modified: '2021-05-04T08:53:43.000Z'
        }
      }
    ]
  }
};
