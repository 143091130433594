/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconBriefcase = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 20 13"
    fill="none"
    {...rest}
  >
    <path
      d="M8.15333 8.775C3.61933 8.775 1.242 7.98135 0 7.08695V13H16V7.18315C13.8547 8.6268 9.71867 8.775 8.15333 8.775ZM9.33333 10.4208C9.33333 11.1273 8.736 11.7 8 11.7C7.264 11.7 6.66667 11.1273 6.66667 10.4208V9.75H9.33333V10.4208ZM0 4.86915V3.25H16V4.94325C16 8.3811 0 8.2797 0 4.86915ZM6 0C5.264 0 4.66667 0.5824 4.66667 1.3V2.6H6V1.625C6 1.4456 6.14933 1.3 6.33333 1.3H9.66667C9.85067 1.3 10 1.4456 10 1.625V2.6H11.3333V1.3C11.3333 0.5824 10.736 0 10 0H6Z"
      fill="currentColor"
    />
  </svg>
);

IconBriefcase.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconBriefcase.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconBriefcase);
