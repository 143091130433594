import logger from 'services/logger/logger';

import Client from '../../../../Client';
import OccupierDomainMocks from './Occupier-Domains.json';
import OccupierHoursMocks from './Occupier-Hours.json';
import OccupierIndustriesMocks from './Occupier-Industries.json';
import OccupierFeedMocks from './Occupier-Feed.json';

export default class OccupiersMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Carwyn Witt',
      email: 'cwitt@cureoscity.com',
      phone: '+4412345678',
      contact_job_role: 'Owner',
      retailer: true,
      neighbour: true,
      description:
        'Aut non quia consectetur. Praesentium explicabo modi. Corporis reprehenderit nihil.',
      industry_id: 1,
      public_website: 'https://www.google.com',
      public_phone: '12345678',
      ignore_email_validation: false,
      company_id: 1,
      building_id: 1,
      floor_ids: [1],
      archived: false,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 2,
      name: 'Caspian Carpenter',
      email: 'caspian@cureoscity.com',
      phone: '+4412345678',
      contact_job_role: 'Sales Clerk',
      retailer: false,
      neighbour: true,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur augue orci, faucibus ac tempor in, accumsan sit amet urna. Quisque eu consectetur lacus, lacinia vestibulum elit. Curabitur pulvinar sodales laoreet',
      industry_id: 1,
      public_website: 'https://hotmail.com',
      public_phone: '0117658456',
      ignore_email_validation: false,
      company_id: 2,
      building_id: 2,
      floor_ids: [4, 5],
      archived: false,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      name: 'Israel May',
      email: 'may@cureoscity.com',
      phone: '+4412345678',
      contact_job_role: '',
      retailer: true,
      neighbour: false,
      description: '',
      industry_id: 1,
      public_website: '',
      public_phone: '+44845 112 2245',
      ignore_email_validation: false,
      company_id: 3,
      building_id: 3,
      floor_ids: [6],
      archived: true,
      enabled: false,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      name: 'Rodney Rowland',
      email: 'caspian@cureoscity.com',
      phone: '+4412345678',
      contact_job_role: '',
      retailer: false,
      neighbour: false,
      description:
        'Ut semper urna in mauris suscipit rhoncus. Ut pretium mi odio, id pellentesque metus porta et. Aliquam venenatis ut sapien eu rutrum. Sed elementum mauris at lacinia rutrum',
      industry_id: 1,
      public_website: 'https://yahoo.com',
      public_phone: '',
      ignore_email_validation: false,
      company_id: 4,
      building_id: 1,
      floor_ids: [2],
      archived: false,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 5,
      name: 'Solomon Potts',
      email: 'solomonpotts@cureoscity.com',
      phone: '+4412345678',
      contact_job_role: '',
      retailer: false,
      neighbour: true,
      description:
        'Proin pretium elit vitae sem pretium, vitae imperdiet tortor tincidunt. Aenean gravida sagittis ex nec efficitur. Cras eget leo felis. Integer at mi eget felis viverra fringilla a sit amet nisi.',
      industry_id: 1,
      public_website: 'http://diy.com',
      public_phone: '+44789548641',
      ignore_email_validation: false,
      company_id: 5,
      building_id: 2,
      floor_ids: [5],
      archived: true,
      enabled: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 6,
      name: 'Khushi Pugh',
      email: 'khushi@cureoscity.com',
      phone: '+4412345678',
      contact_job_role: '',
      retailer: false,
      neighbour: true,
      description: '',
      industry_id: 1,
      public_website: '',
      public_phone: '',
      ignore_email_validation: false,
      company_id: 6,
      building_id: 1,
      floor_ids: [1, 2],
      archived: false,
      enabled: false,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  feedMocks = OccupierFeedMocks.data;

  industryMocks = OccupierIndustriesMocks.data.records;

  hourMocks = OccupierHoursMocks.data.records;

  domainMocks = OccupierDomainMocks.data.records;

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
    this.dataSet.forEach(this.translateEntities);
  }

  translateEntities = (occupier, ignoreContacts) => {
    const { company_id, building_id, industry_id } = occupier;
    let { floor_ids } = occupier;

    floor_ids = floor_ids.map((id) => parseInt(id, 10));

    const {
      industryMocks,
      hourMocks,
      domainMocks,
      framework: {
        mockNamespaces: {
          CompaniesMockNamespace: { dataSet: companies },
          BuildingsMockNamespace: { dataSet: buildings },
          FloorsMockNamespace: { dataSet: floors },
          ContactsMockNamespace: { dataSet: contacts }
        }
      }
    } = this;

    if (company_id)
      occupier.company = companies?.filter(({ id }) => id == company_id).pop();
    if (building_id) {
      occupier.building = buildings.filter(({ id }) => id == building_id).pop();
      occupier.floors = floors.filter(({ id }) => floor_ids.includes(id));
    }

    if (!ignoreContacts) {
      occupier.contacts = contacts.filter(({ id }) => id == occupier?.id);
    }
    if (industry_id)
      occupier.industry = industryMocks
        .filter(({ id }) => id == industry_id)
        .pop();

    if (!Array.isArray(occupier.contacts)) occupier.contacts = [];

    occupier.hours = hourMocks
      .filter(({ occupier_id }) => occupier_id == occupier?.id)
      .pop();
    occupier.domains = domainMocks.filter(
      ({ occupier_id }) => occupier_id == occupier?.id
    );

    return occupier;
  };

  parseHours = (id, hours) => {
    if (hours && Object.keys(hours).length) {
      const hoursObj = this.hourMocks.find(
        ({ occupier_id }) => occupier_id == id
      ) || {
        occupier_id: id,
        mon_open: '',
        mon_close: '',
        tue_open: '',
        tue_close: '',
        wed_open: '',
        wed_close: '',
        thur_open: '',
        thur_close: '',
        fri_open: '',
        fri_close: '',
        sat_open: '',
        sat_close: '',
        sun_open: '',
        sun_close: '',
        created: new Date().toISOString(),
        modified: new Date().toISOString()
      };

      const keys = [
        'mon_open',
        'mon_close',
        'tue_open',
        'tue_close',
        'wed_open',
        'wed_close',
        'thur_open',
        'thur_close',
        'fri_open',
        'fri_close',
        'sat_open',
        'sat_close',
        'sun_open',
        'sun_close'
      ];
      keys.forEach((key) => {
        if (Object.hasOwnProperty.call(hours, key)) {
          let value = hours[key];
          if (
            typeof value !== 'string' ||
            value.length < 5 ||
            value.split(':').length !== 2
          )
            value = '';

          hoursObj[key] = value;
        }
      });
      this.hourMocks.push(hoursObj);
    }
  };

  parseDomains = (id, domains) => {
    this.domainMocks = this.domainMocks.filter(
      ({ occupier_id }) => id != occupier_id
    );

    if (Array.isArray(domains)) {
      domains.forEach(({ type, data }) => {
        this.domainMocks.push({
          occupier_id: id,
          type,
          data,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });
      });
    }
  };

  parseContacts = (id, contacts) => {
    const {
      framework: {
        mockNamespaces: {
          ContactsMockNamespace: { dataSet: contactDataset }
        }
      }
    } = this;

    this.framework.mockNamespaces.ContactsMockNamespace.dataSet = contactDataset.filter(
      ({ occupier_id }) => id != occupier_id
    );

    if (Array.isArray(contacts)) {
      contacts.forEach(({ type_id, name, email, phone }) => {
        contactDataset.push({
          occupier_id: id,
          type_id,
          name,
          email,
          phone,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });
      });
    }
  };

  getEndpoints() {
    const { generateUUIDV4 } = this.framework;
    return {
      occupiers: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          building_id: { field: 'building_id', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const occupiers = [...this.dataSet];

        return this.framework.defaultSearchMock(
          payload,
          occupiers,
          filters,
          permittedErrors,
          error
        );
      },
      'occupiers/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const {
          name,
          email,
          phone,
          company_id,
          building_id,
          floor_ids,
          contact_job_role,
          retailer,
          neighbour,
          description,
          industry_id,
          public_website,
          public_phone,
          hours,
          domains,
          contacts,
          images
        } = payload;

        const id = this.dataSet.length + 1;

        this.parseDomains(id, domains);
        this.parseHours(id, hours);
        this.parseContacts(id, contacts);

        const occupier = this.translateEntities({
          id,
          name,
          email,
          phone,
          company_id,
          building_id,
          floor_ids,
          contact_job_role,
          retailer: Boolean(retailer),
          neighbour: Boolean(neighbour),
          images: images || [],
          description,
          industry_id,
          public_website,
          public_phone,
          ignore_email_validation: false,
          archived: false,
          enabled: true,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });

        this.dataSet.push(occupier);
        return { code: 200, data: { occupier } };
      },
      'occupiers/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const { hours, domains, id, contacts } = payload;

          this.parseDomains(id, domains);
          this.parseHours(id, hours);
          this.parseContacts(id, contacts);

          const occupier = this.translateEntities(
            this.framework.updateRecord(this.dataSet, id, 'id', payload),
            true
          );
          return { code: 200, data: { occupier } };
        } catch (e) {
          logger.debug(e);
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'occupiers/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      },
      'occupiers/industries': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return {
          code: 200,
          data: { records: this.industryMocks }
        };
      },
      'occupiers/feed': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return {
          code: 200,
          data: this.feedMocks
        };
      },
      'occupiers/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'occupiers/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'occupiers/enable': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            enabled: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'occupiers/disable': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            enabled: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
