import Company from './Company';

export default class Visitor {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIVisitor - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      parent_id,
      type,
      building_id,
      user_id,
      company_id,
      company,
      host_name,
      host_email,
      pass_no,
      visitor_type_id,
      visitor_name,
      visitor_email,
      visitor_company,
      checked_in,
      checked_in_date,
      checked_in_user_id,
      checked_in_user,
      checked_out,
      checked_out_date,
      checked_out_user_id,
      checked_out_user,
      arrival,
      departure,
      arrival_instructions,
      special_instructions,
      comments,
      guests,
      additional_visitors,
      archived,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC, parseISODateTimeAsLocalTime } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || 0;
    this.parent_id = parent_id || 0;
    this.type = type || 'prebooked';
    this.user_id = user_id || 0;

    this.building_id = building_id || 0;

    this.company_id = company_id || null;
    this.company = company ? new Company(Client, company) : null;

    this.host_name = host_name || '';
    this.host_email = host_email || '';

    this.pass_no = pass_no || '';

    this.visitor_type_id = visitor_type_id || 0;
    this.visitor_name = visitor_name || '';
    this.visitor_email = visitor_email || '';
    this.visitor_company = visitor_company || '';

    this.checked_in = checked_in || false;
    this.checked_in_date = parseISODateTimeAsUTC(checked_in_date);
    this.checked_in_user_id = checked_in_user_id || null;
    this.checked_in_user = checked_in_user || null;

    this.checked_out = checked_out || false;
    this.checked_out_date = parseISODateTimeAsUTC(checked_out_date);
    this.checked_out_user_id = checked_out_user_id || null;
    this.checked_out_user = checked_out_user || null;

    this.arrival = parseISODateTimeAsLocalTime(arrival);
    this.departure = parseISODateTimeAsLocalTime(departure);

    this.arrival_instructions = arrival_instructions || '';
    this.special_instructions = special_instructions || '';
    this.comments = comments || '';

    this.guests = guests || [];
    this.additional_visitors = Array.isArray(additional_visitors)
      ? additional_visitors.map((visitor) => new Visitor(Client, visitor))
      : [];

    this.archived = Boolean(archived);

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  getStatusLabel() {
    const { archived, checked_in, checked_out } = this;

    let status = 'previous';
    if (archived) status = 'archived';
    else if (this.isToday() && !checked_out) status = 'today';
    else if (this.isFuture()) status = 'upcoming';
    else if (this.isPast() && !checked_in) status = 'no show';

    return status;
  }

  processEntities({ company = false, guests = false } = {}) {
    return new Promise((resolve) => {
      const promises = [];

      if (company) promises.push(this.getCompany());
      if (guests) promises.push(this.getGuests());
      Promise.all(promises).then(() => resolve(this));
    });
  }

  getCompany() {
    return new Promise((resolve) => resolve(this.company));
  }

  getGuests() {
    return new Promise((resolve) => resolve(this.guests));
  }

  isToday() {
    const { arrival } = this;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 59);

    return (
      arrival.getTime() >= today.getTime() &&
      arrival.getTime() <= endOfToday.getTime()
    );
  }

  isFuture() {
    const { arrival } = this;

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 59);

    return arrival.getTime() > endOfToday.getTime();
  }

  isPast() {
    const { arrival } = this;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return arrival.getTime() < today.getTime();
  }
}
