/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useReducer, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import logger from 'services/logger/logger';
import Permit from 'middleware/Client/Entities/Permits/Permit';
import { act } from 'react-dom/test-utils';
import Input from '../components/forms/Input/Input';
import AnimatedInput from '../components/forms/Input/AnimatedInput';
import ReRegisterableInput from '../components/forms/Input/ReRegisterableInput';
import PhoneInput from '../components/forms/PhoneInput/PhoneInput';
import NameInput from '../components/forms/NameInput/NameInput';
import DateInput from '../components/forms/DateInput/DateInput';
import CheckboxInput from '../components/forms/CheckboxInput/CheckboxInput';
import BuildingSelect from '../components/BuildingSelect/BuildingSelect';
import FloorSelect from '../components/FloorSelect/FloorSelect';
import LocationSelect from '../components/LocationSelect/LocationSelect';
import CompanySelect from '../components/CompanySelect/CompanySelect';
import TimeInput from '../components/TimeInput/TimeInput';
import TwoOptionRadioInput from '../components/forms/TwoOptionRadioInput/TwoOptionRadioInput';
import HideableGroup from '../components/HideableGroup/HideableGroup';
import FileUpload from '../components/FileUpload/FileUpload';

import styles from './form-builder-context.module.scss';

import apiInstance from '../services/api/apiInstance/apiInstance';

const APIService = apiInstance();

const FormBuilderContext = createContext();

const PermitReducer = (state, action) => {
  switch (action.type) {
    case 'add':
      console.log(state);
      const sectionId = Object.keys(action.payload)[0];
      const newPermitState = { ...state.permit };
      newPermitState[sectionId] = Object.values(action.payload)[0];
      return {
        permit: newPermitState
      };
    case 'addAll':
      console.log(state);

      return {
        permit: action.payload
      };

    default:
      console.log('No action performed in reducer');
      return state;
  }
};

const initialPermitState = {
  permit: {}
};
export const PermitFormContext = createContext(initialPermitState);

export const PermitProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PermitReducer, initialPermitState);

  // Actions

  const addOrUpdateSection = (section) => {
    dispatch({
      type: 'add',
      payload: section
    });
  };

  const setWholePermit = (permit) => {
    dispatch({
      type: 'addAll',
      payload: permit
    });
  };

  return (
    <PermitFormContext.Provider
      value={{
        permit: state.permit,
        addOrUpdateSection,
        setWholePermit
      }}
    >
      {children}
    </PermitFormContext.Provider>
  );
};

const FormBuilderProvider = (contextProps) => {
  const {
    namespaces: { general }
  } = APIService;
  const { uploadNamespace, uploadCall, uploadGetCall, entityId } = contextProps;

  let { uploadHandler, uploadGetHandler } = contextProps;

  if (!uploadGetHandler && entityId && uploadNamespace && uploadGetCall) {
    uploadGetHandler = (fileUri) =>
      general.getFileLink(
        fileUri,
        { id: entityId },
        uploadNamespace,
        uploadGetCall
      );
  }

  const [uploadRequestData, setUploadRequestData] = useState({});

  if (!uploadHandler) {
    uploadHandler = ({ file, data, onProgress }) => {
      const blob = file.slice(0, file.size, file.type);

      const newFile = new File(
        [blob],
        `${uuidv4()}-${file.name.replace(
          /[^a-zA-Z0-9().!'()&$@=;,_\-]/g,
          '_'
        )}`,
        { type: file.type }
      );
      return general
        .upload({
          file: newFile,
          data,
          secure: true,
          onProgress,
          requestData: uploadRequestData,
          namespace: uploadNamespace,
          call: uploadCall
        })
        .then((response) => {
          const { requestData } = response;
          if (!Object.values(uploadRequestData).length) {
            setUploadRequestData(requestData);
          }
          logger.debug(response);
          return response;
        });
    };
  }

  const buildComponent = (field, form, currentValue, readOnly, extraProps) => {
    if (!field) return <></>;

    if (Array.isArray(field)) {
      return (
        <div className={styles.groupedContainer}>
          {' '}
          {field.map((actualField) => buildComponent(actualField, form))}{' '}
        </div>
      );
    }

    const {
      formState,
      register,
      control,
      watch,
      setValue,
      unregister,
      getValues
    } = form;

    const { isValid, errors, dirtyFields } = formState;

    const watchFields = watch();

    const { id, component, required, props, max, min } = field;

    const label = field.label
      ? `${field.label}${required && !field.label?.endsWith('*') ? '*' : ''}`
      : '';

    if (component === 'Group') {
      return (
        <div>
          <label>{label}</label>
          {field.children.map((child) => {
            let value = '';
            if (
              currentValue &&
              child &&
              Object.hasOwnProperty.call(currentValue, child?.id)
            ) {
              value = currentValue[child?.id];
            }
            return buildComponent(child, form, value, readOnly);
          })}
        </div>
      );
    }

    if (component === 'HideableGroup') {
      return (
        <HideableGroup label={label}>
          {field.children.map((child) => {
            let value = '';

            if (
              currentValue &&
              child &&
              Object.hasOwnProperty.call(currentValue, child?.id)
            ) {
              value = currentValue[child?.id];
            }
            return buildComponent(child, form, value, readOnly);
          })}
        </HideableGroup>
      );
    }

    if (component === 'Text') {
      return (
        <Input
          type="text"
          appearance={props?.row ? 'search' : 'default'}
          name={id}
          inputRef={register(
            required ? { required: 'This field is required' } : null
          )}
          defaultValue={currentValue}
          error={errors[id]}
          isDirty={dirtyFields[id]}
          isRequired={required}
          {...(max && { max })}
          placeholder={`Add ${field.label.toLowerCase()}`}
          label={label}
          fullWidth
          {...props}
          readOnly={readOnly}
        />
      );
    }

    if (component === 'AnimatedInput') {
      return (
        <>
          <AnimatedInput
            type="text"
            appearance={props?.row ? 'search' : 'default'}
            name={id}
            ref={register(
              required ? { required: 'This field is required' } : null
            )}
            defaultValue={currentValue}
            error={errors[id]}
            isDirty={dirtyFields[id]}
            isRequired={required}
            label={label}
            fullWidth
            {...props}
            readOnly={readOnly}
            variants={{
              required: {
                borderColor: ['rgba(0,0,0,0)', '#00c48c', 'rgba(0,0,0,0)']
              },
              unrequired: { borderColor: 'rgba(0,0,0,0)' }
            }}
            animate={required ? 'required' : 'unrequired'}
            initial={{ borderColor: 'rgba(0,0,0,0)' }}
            transition={{ duration: 1 }}
          />
        </>
      );
    }

    if (component === 'Name') {
      return (
        <NameInput
          appearance="text"
          {...{ errors, dirtyFields, register }}
          name={id}
          defaultValue={currentValue}
          isRequired={!!required}
          label={label}
          fullWidth
          {...props}
          readOnly={readOnly}
        />
      );
    }

    if (component === 'Phone') {
      return (
        <PhoneInput
          appearance="text"
          {...{ errors, dirtyFields, register }}
          name={id}
          defaultValue={currentValue}
          isRequired={!!required}
          label={label}
          fullWidth
          {...props}
          readOnly={readOnly}
        />
      );
    }

    if (component === 'BuildingSelect') {
      return (
        <BuildingSelect
          name={id}
          fullWidth
          isRequired={!!required}
          {...{
            label,
            errors,
            dirtyFields,
            register
          }}
          {...props}
          readOnly={readOnly}
          selectedValue={currentValue}
        />
      );
    }

    if (component === 'FloorSelect') {
      return (
        <FloorSelect
          name={id}
          label="Floor *"
          isRequired={!!required}
          buildingId={watchFields?.building_id}
          showLabel
          fullWidth
          {...{
            label,
            errors,
            register,
            setValue
          }}
          {...props}
          readOnly={readOnly}
          selectedValue={currentValue}
        />
      );
    }

    if (component === 'LocationSelect') {
      return (
        <LocationSelect
          name={id}
          label="Location *"
          isRequired={!!required}
          buildingId={watchFields?.building_id}
          showLabel
          fullWidth
          {...{ label, errors, register }}
          {...props}
          readOnly={readOnly}
          selectedValue={currentValue}
        />
      );
    }

    if (component === 'CompanySelect') {
      return (
        <CompanySelect
          name={id}
          label="Occupier *"
          isRequired={!!required}
          buildingId={watchFields?.building_id}
          showLabel
          fullWidth
          {...{ label, errors, register }}
          {...props}
          readOnly={readOnly}
          selectedValue={currentValue}
        />
      );
    }

    if (component === 'Label') {
      return <p className={styles.label}>{props?.text}</p>;
    }

    if (component === 'Date') {
      return (
        <DateInput
          fullWidth
          {...{
            errors,
            register,
            dirtyFields,
            control
          }}
          name={id}
          defaultValue={currentValue}
          label={label}
          {...props}
          readOnly={readOnly}
        />
      );
    }

    if (component === 'Time') {
      return (
        <TimeInput
          name={id}
          isRequired={!!required}
          showLabel
          fullWidth
          {...{ label, errors, register }}
          {...props}
          readOnly={readOnly}
          selectedValue={currentValue}
          availableTimes={extraProps?.timeValues}
        />
      );
    }

    if (component === 'Checkbox') {
      return (
        <CheckboxInput
          name={id}
          control={control}
          defaultValue={currentValue}
          defaultChecked={currentValue}
          label={label}
          {...props}
          readOnly={readOnly}
        />
      );
    }

    if (component === 'TwoOptionRadio') {
      return (
        <>
          <TwoOptionRadioInput
            name={id}
            control={control}
            defaultValue={currentValue}
            label={label}
            {...props}
            readOnly={readOnly}
          />
          {field.children &&
            field.children.map((child) => {
              if (child.component === 'Text') {
                const childRequired =
                  watch(id) === child.props?.requiredWhenParentValue;

                return (
                  <ReRegisterableInput
                    id={child.id}
                    required={childRequired}
                    value={watch(child.id)}
                    unregister={unregister}
                  >
                    {(cachedValue) =>
                      buildComponent(
                        {
                          ...child,
                          component: 'AnimatedInput',
                          required: childRequired
                        },
                        form,
                        watch(child.id) || cachedValue,
                        readOnly
                      )
                    }
                  </ReRegisterableInput>
                );
              }

              return null;
            })}
        </>
      );
    }

    if (component === 'File') {
      return (
        <FileUpload
          name={id}
          label="Upload File"
          isRequired={!!required}
          showLabel
          fullWidth
          existingFile={currentValue}
          {...{
            label,
            errors,
            register,
            getValues,
            setValue
          }}
          {...props}
          value={watchFields[id]}
          readOnly={readOnly}
          uploadHandler={uploadHandler}
          getHandler={uploadGetHandler}
        />
      );
    }

    return <></>;
  };

  return (
    <FormBuilderContext.Provider
      value={{
        buildComponent
      }}
      {...contextProps}
    />
  );
};

const useFormBuilder = () => useContext(FormBuilderContext);

export { FormBuilderProvider, useFormBuilder };
