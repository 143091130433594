/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCircleTick = ({ size }) => (
  <svg
    width={size}
    height="107"
    viewBox="0 0 107 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.5 0C24.0047 0 0 24.0047 0 53.5C0 82.9953 24.0047 107 53.5 107C82.9953 107 107 82.9953 107 53.5C107 24.0047 82.9953 0 53.5 0ZM53.5 104.082C25.6043 104.082 2.91818 81.3957 2.91818 53.5C2.91818 25.6043 25.6152 2.91818 53.5 2.91818C81.3848 2.91818 104.082 25.6043 104.082 53.5C104.082 81.3957 81.3957 104.082 53.5 104.082Z"
      fill="#00C48C"
    />
    <path
      d="M76.6833 37.0178C76.4023 36.6936 76.0456 36.5315 75.6565 36.5099C75.2674 36.4882 74.9 36.6396 74.6406 36.9098L44.6373 66.913L32.2621 54.5269C32.0027 54.2675 31.6352 54.1162 31.2353 54.1162C30.8462 54.1162 30.468 54.2675 30.2086 54.5269C29.9492 54.7863 29.7979 55.1538 29.7979 55.5537C29.7979 55.9428 29.9492 56.3211 30.2086 56.5805L44.6373 71.1065L76.6833 39.0606C77.2237 38.5202 77.2237 37.569 76.6833 37.0178Z"
      fill="#00C48C"
    />
  </svg>
);

IconCircleTick.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

IconCircleTick.defaultProps = {
  size: '100%'
};

export default memo(IconCircleTick);
