/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import classnames from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import styles from './Loader.module.scss';

const Loader = ({ isShowing, className, animateWithCss }) => {
  const classes = classnames(styles['loading-overlay'], className);

  if (isShowing) {
    if (!animateWithCss) {
      return (
        <div className={classes}>
          <motion.svg
            animate={{
              rotate: 360
            }}
            transition={{
              repeat: Infinity,
              repeatType: 'loop',
              ease: 'linear',
              duration: 3
            }}
            style={{ color: 'var(--secondary-1)' }}
            ie-style="var(--secondary-1)"
            width="107"
            height="108"
            viewBox="0 0 107 108"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M53.2666 0.764709C54.095 0.764709 54.7666 1.43628 54.7666 2.26471V22.9522C54.7666 23.7806 54.095 24.4522 53.2666 24.4522C52.4382 24.4522 51.7666 23.7806 51.7666 22.9522V2.26471C51.7666 1.43628 52.4382 0.764709 53.2666 0.764709ZM15.6408 16.3576C16.2266 15.7719 17.1763 15.7719 17.7621 16.3576L32.3985 30.9941C32.9843 31.5798 32.9843 32.5296 32.3985 33.1154C31.8127 33.7012 30.863 33.7012 30.2772 33.1154L15.6408 18.479C15.055 17.8932 15.055 16.9434 15.6408 16.3576ZM90.8924 16.3576C91.4782 16.9434 91.4782 17.8932 90.8924 18.479L76.256 33.1154C75.6702 33.7012 74.7205 33.7012 74.1347 33.1154C73.5489 32.5296 73.5489 31.5798 74.1347 30.9941L88.7711 16.3576C89.3569 15.7719 90.3066 15.7719 90.8924 16.3576ZM0.0478516 53.9835C0.0478516 53.155 0.719424 52.4835 1.54785 52.4835H22.2354C23.0638 52.4835 23.7354 53.155 23.7354 53.9835C23.7354 54.8119 23.0638 55.4835 22.2354 55.4835H1.54785C0.719424 55.4835 0.0478516 54.8119 0.0478516 53.9835ZM82.7979 53.9835C82.7979 53.155 83.4694 52.4835 84.2979 52.4835H104.985C105.814 52.4835 106.485 53.155 106.485 53.9835C106.485 54.8119 105.814 55.4835 104.985 55.4835H84.2979C83.4694 55.4835 82.7979 54.8119 82.7979 53.9835ZM32.3985 74.8515C32.9843 75.4373 32.9843 76.3871 32.3985 76.9729L17.7621 91.6093C17.1763 92.1951 16.2266 92.1951 15.6408 91.6093C15.055 91.0235 15.055 90.0737 15.6408 89.488L30.2772 74.8515C30.863 74.2658 31.8127 74.2658 32.3985 74.8515ZM74.1347 74.8515C74.7205 74.2658 75.6702 74.2658 76.256 74.8515L90.8924 89.488C91.4782 90.0737 91.4782 91.0235 90.8924 91.6093C90.3066 92.1951 89.3569 92.1951 88.7711 91.6093L74.1347 76.9729C73.5489 76.3871 73.5489 75.4373 74.1347 74.8515ZM53.2666 83.5147C54.095 83.5147 54.7666 84.1863 54.7666 85.0147V105.702C54.7666 106.531 54.095 107.202 53.2666 107.202C52.4382 107.202 51.7666 106.531 51.7666 105.702V85.0147C51.7666 84.1863 52.4382 83.5147 53.2666 83.5147Z"
              fill="currentColor"
            />
          </motion.svg>
        </div>
      );
    }
    if (animateWithCss) {
      return (
        <div className={classes}>
          <svg
            className={styles['css-loader']}
            style={{ color: 'var(--secondary-1)' }}
            ie-style="var(--secondary-1)"
            width="107"
            height="108"
            viewBox="0 0 107 108"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M53.2666 0.764709C54.095 0.764709 54.7666 1.43628 54.7666 2.26471V22.9522C54.7666 23.7806 54.095 24.4522 53.2666 24.4522C52.4382 24.4522 51.7666 23.7806 51.7666 22.9522V2.26471C51.7666 1.43628 52.4382 0.764709 53.2666 0.764709ZM15.6408 16.3576C16.2266 15.7719 17.1763 15.7719 17.7621 16.3576L32.3985 30.9941C32.9843 31.5798 32.9843 32.5296 32.3985 33.1154C31.8127 33.7012 30.863 33.7012 30.2772 33.1154L15.6408 18.479C15.055 17.8932 15.055 16.9434 15.6408 16.3576ZM90.8924 16.3576C91.4782 16.9434 91.4782 17.8932 90.8924 18.479L76.256 33.1154C75.6702 33.7012 74.7205 33.7012 74.1347 33.1154C73.5489 32.5296 73.5489 31.5798 74.1347 30.9941L88.7711 16.3576C89.3569 15.7719 90.3066 15.7719 90.8924 16.3576ZM0.0478516 53.9835C0.0478516 53.155 0.719424 52.4835 1.54785 52.4835H22.2354C23.0638 52.4835 23.7354 53.155 23.7354 53.9835C23.7354 54.8119 23.0638 55.4835 22.2354 55.4835H1.54785C0.719424 55.4835 0.0478516 54.8119 0.0478516 53.9835ZM82.7979 53.9835C82.7979 53.155 83.4694 52.4835 84.2979 52.4835H104.985C105.814 52.4835 106.485 53.155 106.485 53.9835C106.485 54.8119 105.814 55.4835 104.985 55.4835H84.2979C83.4694 55.4835 82.7979 54.8119 82.7979 53.9835ZM32.3985 74.8515C32.9843 75.4373 32.9843 76.3871 32.3985 76.9729L17.7621 91.6093C17.1763 92.1951 16.2266 92.1951 15.6408 91.6093C15.055 91.0235 15.055 90.0737 15.6408 89.488L30.2772 74.8515C30.863 74.2658 31.8127 74.2658 32.3985 74.8515ZM74.1347 74.8515C74.7205 74.2658 75.6702 74.2658 76.256 74.8515L90.8924 89.488C91.4782 90.0737 91.4782 91.0235 90.8924 91.6093C90.3066 92.1951 89.3569 92.1951 88.7711 91.6093L74.1347 76.9729C73.5489 76.3871 73.5489 75.4373 74.1347 74.8515ZM53.2666 83.5147C54.095 83.5147 54.7666 84.1863 54.7666 85.0147V105.702C54.7666 106.531 54.095 107.202 53.2666 107.202C52.4382 107.202 51.7666 106.531 51.7666 105.702V85.0147C51.7666 84.1863 52.4382 83.5147 53.2666 83.5147Z"
              fill="currentColor"
            />
          </svg>
        </div>
      );
    }
  }
  return <></>;
};

Loader.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  className: PropTypes.string,
  animateWithCss: PropTypes.bool
};

Loader.defaultProps = {
  isShowing: false,
  className: '',
  animateWithCss: false
};

export default Loader;
