import Client from '../../../Client';
import BuildingEntityMockNamespace from './BuildingEntity';

export default class TravelOptionsMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Train',
      icon: 'Train',
      seq: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 2,
      name: 'Bus',
      icon: 'Bus',
      seq: 2,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      name: 'Cycle',
      icon: 'Bike',
      seq: 3,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      name: 'Vehicle',
      icon: 'Vehicle',
      seq: 4,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 5,
      name: 'Underground',
      icon: 'Underground',
      seq: 5,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 6,
      name: 'Walk',
      icon: 'Walk',
      seq: 6,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
    this.buildingMocks = new BuildingEntityMockNamespace(Client, Framework);
  }

  getEndpoints() {
    return {
      'buildings/travel_options': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.search(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/travel_options/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.create(
          this.dataSet,
          'option',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/travel_options/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.update(
          this.dataSet,
          'option',
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        ),
      'buildings/travel_options/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) =>
        this.buildingMocks.delete(
          this.dataSet,
          payload,
          headers,
          method,
          transmitType,
          isLoggedIn,
          userPermissions,
          error
        )
    };
  }
}
