/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCalendar = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 20 22"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={`var(--${colour})`}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5989 1.20081V5.20081M5.59888 1.20081V5.20081M0.598877 9.20081H18.5989M2.59888 3.20081H16.5989C17.7034 3.20081 18.5989 4.09624 18.5989 5.20081V19.2008C18.5989 20.3054 17.7034 21.2008 16.5989 21.2008H2.59888C1.49431 21.2008 0.598877 20.3054 0.598877 19.2008V5.20081C0.598877 4.09624 1.49431 3.20081 2.59888 3.20081Z"
    />
  </svg>
);

IconCalendar.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconCalendar.defaultProps = {
  size: '100%',
  colour: 'primary'
};

export default memo(IconCalendar);
