/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconBookmark = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      d="m21.5989 23.2008-7-5-7.00002 5v-15.99999c0-.53044.21071-1.03915.58578-1.41422.37508-.37507.88378-.58578 1.41422-.58578h10.00002c.5304 0 1.0391.21071 1.4142.58578s.5858.88378.5858 1.41422z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconBookmark.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconBookmark.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconBookmark);
