import Client from '../../../Client';

export default class VisitorGuestsMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Elle Hensley',
      email: 'elle@cureoscity.com',
      visitor_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 2,
      name: 'Lana Carson',
      email: 'lanacarson@cureoscity.com',
      visitor_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      name: 'Tudor Mata',
      email: 'tmata@cureoscity.com',
      visitor_id: 2,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      name: 'Leighton Garza',
      email: 'lgarza@cureoscity.com',
      visitor_id: 3,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 5,
      name: 'Barry White',
      email: 'bwhite@cureoscity.com',
      visitor_id: 5,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 6,
      name: 'Lord Sugar',
      email: 'lsugar@cureoscity.com',
      visitor_id: 5,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (contact) => contact;

  getEndpoints() {
    this.dataSet.forEach(this.translateEntities);

    const { generateUUIDV4 } = this.framework;
    return {};
  }
}
