import Client from '../Client';
import MockFramework from './Framework';

export default class MockRest {
  /**
   *
   * @param {Client} client
   * @param {MockFramework} framework
   */
  constructor(client, framework) {
    this.client = client;
    this.framework = framework;
  }

  create({ baseURL, timeout, headers }) {
    const { framework } = this;

    const Request = class Request {
      get = (path, payload) => {
        if (!payload) payload = {};

        if (path.includes('?')) {
          const url = new URL(`https://cureoscity.com/${path}`);
          if (url) {
            path = url.pathname.substr(1);
            const entries = url?.searchParams.entries();

            for (const [key, value] of entries) payload[key] = value;
          }
        }
        return this.request(path, payload, Client.methods.GET);
      };

      post = (path, payload) =>
        this.request(path, payload, Client.methods.POST);

      put = (path, payload) => this.request(path, payload, Client.methods.PUT);

      delete = (path, payload) =>
        this.request(path, payload, Client.methods.DELETE);

      request = (path, payload, method) =>
        new Promise((resolve, reject) => {
          let isLoggedIn = false;
          if (
            typeof headers['device-id'] === 'string' &&
            headers['device-id'].length > 0 &&
            typeof headers['session-id'] === 'string' &&
            headers['session-id'].length > 0
          ) {
            isLoggedIn = true;
          }

          const response = framework.request(
            this,
            path,
            payload,
            headers,
            method,
            'rest',
            isLoggedIn
          );
          if (response.error) {
            const error = new Error(response.error.code);
            error.response = response;
            throw error;
          } else {
            resolve({ status: response.code, data: response });
          }
        });
    };

    return new Request();
  }
}
