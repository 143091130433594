import logger from 'services/logger/logger';

import Client from '../../Client';
import HelpdeskTicketMockNamespace from './Helpdesk/Tickets';
import PermitsMockNamespace from './Permits';

export default class CommentsMockNamespace {
  dataSet = [
    {
      id: '2da0b4ee-184f-4cc7-bc69-a6b3b41eda5a',
      type: 'ticket',
      sub_type: '',
      entity_id: 1,
      user_id: 1,
      comment: 'Hello this is a helpdesk comment',
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 'cf56ccb7-03ae-4ee2-a5a1-ce7b540fb323',
      type: 'ticket',
      sub_type: '',
      entity_id: 1,
      user_id: 2,
      comment: 'Hello this is another comment',
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 'bf96204d-8746-47ee-ac75-d27828d8caf6',
      type: 'delivery',
      sub_type: '',
      entity_id: 1,
      user_id: 1,
      comment: 'Hello this is a delivery comment',
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 'f16bb637-73b4-428b-b2f9-89b4a987df36',
      type: 'delivery',
      sub_type: '',
      entity_id: 1,
      user_id: 2,
      comment: 'Hello this is another comment',
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  pushEntities = (comment) => {
    const {
      framework: {
        mockNamespaces: {
          UsersMockNamespace: { dataSet: users }
        }
      }
    } = this;
    const { user_id } = comment;

    comment.user = users
      .filter(({ id }) => id === user_id)
      .map(({ id, profile }) => ({ id, profile }))
      .pop();

    return comment;
  };

  create(entity_id, type, sub_type, comment) {
    const { generateUUIDV4 } = this.framework;
    const {
      framework: {
        mockNamespaces: {
          DeliveriesMockNamespace,
          HelpdeskTicketMockNamespace,
          PermitsMockNamespace
        }
      }
    } = this;

    const entity = this.pushEntities({
      id: generateUUIDV4(),
      user_id: 1,
      entity_id,
      type,
      sub_type,
      comment,
      created: new Date().toISOString(),
      modified: new Date().toISOString()
    });

    logger.log(entity);

    this.dataSet.push(entity);

    if (type === 'ticket') {
      HelpdeskTicketMockNamespace.refreshDataSet();
    } else if (type === 'delivery') {
      DeliveriesMockNamespace.refreshDataSet();
    } else if (type === 'permit') {
      PermitsMockNamespace.refreshDataSet();
    }

    return entity;
  }

  getEndpoints() {
    this.dataSet.forEach(this.pushEntities);

    return {
      comments: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          entity_id: { field: 'entity_id', expression: 'eq' },
          entity_type: { field: 'entity_type', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'comments/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const { entity_id, type, sub_type, comment } = payload;

        if (!entity_id) {
          return this.framework.createErrorResponse('comments_invalid_entity');
        }
        if (!['ticket', 'delivery', 'permit'].includes(type)) {
          return this.framework.createErrorResponse(
            'comments_invalid_entity_type'
          );
        }
        return {
          code: 200,
          data: { comment: this.create(entity_id, type, sub_type, comment) }
        };
      }
    };
  }
}
