import Client from '../Client';
import Building from './Building';
import Floor from './Buildings/Floor';
import JobRole from './Buildings/JobRole';
import Company from './Company';
import Profile from './Profile';
import UserRole from './UserRole';

export default class User {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APIUser - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      email,
      name,
      created,
      modified,
      last_accessed,
      enabled,
      archived,
      profile,
      companies,
      buildings,
      job_roles,
      floors,
      roles
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.buildings = [];
    this.job_roles = [];
    this.floors = [];
    this.roles = [];

    this.id = id || 0;
    this.email = email || '';
    this.name = name || '';
    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
    this.last_accessed = parseISODateTimeAsUTC(last_accessed);
    this.enabled = enabled;
    this.archived = archived;

    this.profile = typeof profile === 'object' ? new Profile(profile) : null;

    if (Array.isArray(companies)) {
      this.companies = companies.map((company) => new Company(Client, company));
    }

    if (Array.isArray(buildings)) {
      this.buildings = buildings.map(
        (building) => new Building(Client, building)
      );
    }

    if (Array.isArray(job_roles)) {
      this.job_roles = job_roles.map((role) => new JobRole(Client, role));
    }

    if (Array.isArray(floors)) {
      this.floors = floors.map((floor) => new Floor(Client, floor));
    }

    if (Array.isArray(roles)) {
      this.roles = roles.map((role) => new UserRole(Client, role));
    }
  }

  update(email, password, callback) {
    if (!callback) throw new Error('Please provide a callback');
    this.Client.users.update(
      this.id,
      email,
      password,
      this.profile,
      null,
      callback
    );
  }

  entityByBuildingId(entityArray, idKey, buildingId) {
    let entity = null;

    if (Array.isArray(entityArray) && entityArray.length) {
      const entities = entityArray.filter((entity) => {
        if (entity[idKey] === buildingId) return entity;
      });
      if (entities.length) entity = entities[0];
    }
    return entity;
  }

  companyForBuildingId() {
    if (this.companies?.length) return this.companies[0];
    return null;
  }

  buildingForBuildingId(buildingId) {
    const { buildings } = this;
    return this.entityByBuildingId(buildings, 'id', buildingId);
  }

  jobRoleForBuildingId(buildingId) {
    const { job_roles } = this;
    return this.entityByBuildingId(job_roles, 'building_id', buildingId);
  }

  floorForBuildingId(buildingId) {
    const { floors } = this;
    return this.entityByBuildingId(floors, 'building_id', buildingId);
  }

  roleForBuildingId(buildingId) {
    const { roles } = this;
    return this.entityByBuildingId(roles, 'building_id', buildingId);
  }

  getStatusLabel() {
    const { archived, enabled } = this;

    let status = 'live';
    if (archived) status = 'archived';
    else if (!enabled) status = 'disabled';
    return status;
  }
}
