import EventCategory from './Category';

export default class Event {
  /**
   * @param {Client} Client - Instance of Client
   * @param {Object} APICategory - containing the server side object
   */
  constructor(
    Client,
    {
      id,
      building_id,
      category_id,
      archived,
      attendees,
      attendee_limit,
      cancelled,
      cancelled_reason,
      category,
      description,
      end_date_time,
      images,
      location,
      name,
      online,
      price,
      start_date_time,
      terms_and_conditions,
      url,
      created,
      host,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');

    const { parseISODateTimeAsUTC } = Client;

    this.Client = Client;
    this.ISODateTimeToUTCDate = parseISODateTimeAsUTC;
    Object.defineProperty(this, 'Client', { enumerable: false });

    this.id = id || null;
    this.name = name || '';
    this.images = images || '';
    this.host = host || '';

    this.category = category || '';
    this.category =
      typeof category === 'object' ? new EventCategory(Client, category) : null;

    this.cancelled = cancelled || false;
    this.archived = archived || false;
    this.online = online || false;
    this.archived = archived || false;

    this.building_id = building_id;
    this.category_id = category_id;
    this.price = price;
    this.description = description;
    this.terms_and_conditions = terms_and_conditions;
    this.url = url;
    this.location = location;
    this.attendees = attendees;
    this.attendee_limit = attendee_limit;
    this.cancelled_reason = cancelled_reason;

    this.start_date_time = parseISODateTimeAsUTC(start_date_time);
    this.end_date_time = parseISODateTimeAsUTC(end_date_time);
    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }

  getStatusLabel() {
    const { end_date_time, cancelled, archived } = this;

    const now = new Date();

    let status = 'live';
    if (end_date_time < now) status = 'previous';
    if (cancelled) status = 'cancelled';
    if (archived) status = 'archived';
    return status;
  }
}
