import logger from 'services/logger/logger';

import Client from '../../../../Client';

export default class ContactsMockNamespace {
  dataSet = [
    {
      id: 1,
      type_id: 1,
      name: 'Elle Hensley',
      email: 'elle@cureoscity.com',
      phone: '+4412345678',
      occupier_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 2,
      type_id: 2,
      name: 'Lana Carson',
      email: 'lanacarson@cureoscity.com',
      phone: '+4412345678',
      occupier_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 3,
      type_id: 1,
      name: 'Tudor Mata',
      email: 'tmata@cureoscity.com',
      phone: '+4412345678',
      occupier_id: 2,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 4,
      type_id: 3,
      name: 'Leighton Garza',
      email: 'lgarza@cureoscity.com',
      phone: '+4412345678',
      occupier_id: 3,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  translateEntities = (contact) => {
    return contact;
    const { type_id, occupier_id } = contact;
    const {
      framework: {
        mockNamespaces: {
          ContactTypesMockNamespace: { dataSet: types },
          OccupiersMockNamespace: { dataSet: occupiers }
        }
      }
    } = this;

    if (type_id) contact.type = types.filter(({ id }) => id == type_id).pop();
    if (occupier_id)
      contact.occupier = occupiers.filter(({ id }) => id == occupier_id).pop();
    return contact;
  };

  getEndpoints() {
    this.dataSet.forEach(this.translateEntities);

    const { generateUUIDV4 } = this.framework;
    return {
      'occupiers/contacts': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      },
      'occupiers/contacts/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const { type_id, name, email, phone, occupier_id } = payload;

        const occupier = this.translateEntities({
          id: this.dataSet.length + 1,
          type_id,
          name,
          email,
          phone,
          occupier_id,
          archived: false,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        });

        this.dataSet.push(occupier);
        return { code: 200, data: { occupier } };
      },
      'occupiers/contacts/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id',
          'provide_building_id',
          'duplicate_name'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          const occupier = this.framework.updateRecord(
            this.dataSet,
            payload.id,
            'id',
            payload
          );
          return { code: 200, data: { occupier } };
        } catch (e) {
          logger.debug(e);
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'occupiers/contacts/delete': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'provide_id',
          'no_records',
          'delete_no_record'
        ];
        return this.framework.defaultDeleteMock(
          this.dataSet,
          payload.id,
          'id',
          permittedErrors,
          error,
          'delete_no_record',
          isLoggedIn
        );
      },
      'occupiers/contacts/archive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: true
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      },
      'occupiers/contacts/unarchive': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'system_error',
          'no_permission',
          'provide_id'
        ];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        try {
          this.framework.updateRecord(this.dataSet, payload.id, 'id', {
            archived: false
          });
          return { code: 200 };
        } catch (e) {
          return this.framework.createErrorResponse('no_permission');
        }
      }
    };
  }
}
