/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconUpload = ({ size, colour, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.5" cy="15.5" r="15.5" fill="#C7C7CC" />
    <rect width="17" height="17" transform="translate(7 7)" fill="#C7C7CC" />
    <path
      stroke="black"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.6426 15.4999V20.357C10.6426 20.6791 10.7705 20.9879 10.9982 21.2157C11.226 21.4434 11.5348 21.5713 11.8569 21.5713H19.1426C19.4646 21.5713 19.7735 21.4434 20.0012 21.2157C20.2289 20.9879 20.3569 20.6791 20.3569 20.357V15.4999M17.9283 11.857L15.4997 9.42847M15.4997 9.42847L13.0711 11.857M15.4997 9.42847V17.3213"
    />
  </svg>
);

IconUpload.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconUpload.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconUpload);
