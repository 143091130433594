/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const UploadFile = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 13 16"
    fill="none"
    {...rest}
  >
    <path
      d="M1.31055 7.6123V12.8123C1.31055 13.1571 1.44224 13.4877 1.67666 13.7315C1.91108 13.9753 2.22903 14.1123 2.56055 14.1123H10.0605C10.3921 14.1123 10.71 13.9753 10.9444 13.7315C11.1789 13.4877 11.3105 13.1571 11.3105 12.8123V7.6123M8.81055 3.7123L6.31055 1.1123M6.31055 1.1123L3.81055 3.7123M6.31055 1.1123V9.56231"
      stroke={`var(--${colour})`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

UploadFile.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

UploadFile.defaultProps = {
  size: '40px',
  colour: 'neutral-3'
};

export default memo(UploadFile);
