/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconAnalytics = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="currentColor">
      <path d="m6.37788 16h3.24424c.14746 0 .27649-.1258.27649-.2697v-15.460637c0-.14382-.12903-.269663-.27649-.269663h-3.24424c-.14747 0-.2765.125843-.2765.269663v15.460637c0 .1439.12903.2697.2765.2697z" />
      <path d="m12.4793 16h3.2442c.1475 0 .2765-.1258.2765-.2697v-9.11457c0-.14382-.129-.26966-.2765-.26966h-3.2442c-.1475 0-.2765.12584-.2765.26966v9.11457c0 .1439.1106.2697.2765.2697z" />
      <path d="m.276498 16h3.244242c.14746 0 .27649-.1258.27649-.2697v-10.6966c0-.14382-.12903-.26966-.27649-.26966h-3.244242c-.147466 0-.276498.12584-.276498.26966v10.6966c0 .1439.129032.2697.276498.2697z" />
    </g>
  </svg>
);

IconAnalytics.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconAnalytics.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconAnalytics);
