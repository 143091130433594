/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState, useEffect } from 'react';

import { apiInstance } from 'services';
import { logout as userLogout } from 'services/api/auth/auth';

const APIService = apiInstance();
const AuthContext = createContext();
let currentUser = null;

const AuthProvider = (props) => {
  const userKey = 'cureoscity_user';
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkedLoggedIn, setCheckedLoggedIn] = useState(false);
  const [authError, setAuthError] = useState();
  const {
    namespaces: {
      sessions,
      onboarding: { login }
    }
  } = APIService;

  const storeUser = (user) => {
    localStorage.setItem(userKey, JSON.stringify(user));
    currentUser = user;
  };

  const getUser = () =>
    new Promise((resolve, reject) => {
      if (currentUser) return resolve(currentUser);

      return reject();
    });

  const logIn = (email, password) => {
    const device = APIService.getDevice();
    APIService.resetSession();

    login(email, password, device)
      .then(({ user, session: loggedInSession, device: loggedInDevice }) => {
        storeUser(user);
        APIService.updateDevice(loggedInDevice);
        APIService.updateSession(loggedInSession);
        setLoggedIn(true);
      })
      .catch(setAuthError);
  };

  const logout = () => {
    /*
       .finally instead of .then as we still want set the user as logged out even if userLogout()
        was unsuccessful (to avoid and endless failed logout loop)
    */
    userLogout().finally(() => {
      setLoggedIn(false);
      APIService.resetSession();
      storeUser(null);
    });
  };

  useEffect(() => {
    // Check if already logged in
    APIService.isLoggedIn()
      .then((user) => {
        if (!user.id) logout();
        else {
          storeUser(user);
          setLoggedIn(true);
        }
      })
      .catch(() => {
        logout();
      })
      .finally(() => {
        setCheckedLoggedIn(true);
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authError,
        setAuthError,
        loggedIn,
        logIn,
        logout,
        checkedLoggedIn,
        hasPermission: (
          permission,
          buildingId = null,
          companyId = null,
          userId = null
        ) => sessions.hasPermission(permission, userId, companyId, buildingId),
        storeUser,
        getUser
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
