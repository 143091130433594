/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const Clock = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00084 4.14284V7.99998L10.5723 9.2857M14.4294 7.99998C14.4294 11.5504 11.5512 14.4286 8.00084 14.4286C4.45044 14.4286 1.57227 11.5504 1.57227 7.99998C1.57227 4.44958 4.45044 1.57141 8.00084 1.57141C11.5512 1.57141 14.4294 4.44958 14.4294 7.99998Z"
      stroke={`var(--${colour})`}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Clock.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

Clock.defaultProps = {
  size: '100%',
  colour: 'primary'
};

export default memo(Clock);
