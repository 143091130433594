import Building from './Building';

export default class Company {
  constructor(
    Client,
    {
      id,
      name,
      address1,
      address2,
      address3,
      city,
      postcode,
      domains,
      ignore_email_validation,
      picture_uri,
      building,
      buildings,
      latitude,
      longitude,
      created,
      modified
    }
  ) {
    if (!Client) throw new Error('Please provide an instance of Client');
    const { parseISODateTimeAsUTC } = Client;

    this.id = id || 0;
    this.name = name || '';
    this.address1 = address1 || '';
    this.address2 = address2 || '';
    this.address3 = address3 || '';
    this.city = city || '';
    this.postcode = postcode || '';
    this.domains = Array.isArray(domains) ? domains : [];
    this.ignore_email_validation = ignore_email_validation || false;
    this.picture_uri = picture_uri || '';

    if (Array.isArray(buildings)) {
      this.buildings = buildings.map(
        (building) => new Building(Client, building)
      );
    } else if (building) {
      this.buildings = [building];
      this.building_id = building;
    }
    this.latitude = latitude || 0.0;
    this.longitude = longitude || 0.0;

    this.created = parseISODateTimeAsUTC(created);
    this.modified = parseISODateTimeAsUTC(modified);
  }
}
