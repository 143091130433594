export default class PermitData {
  /** *
   *
   * @param {Object} PermitData - Session passed back by the API
   */
  constructor({
    id,
    permit_id,
    section_id,
    data_structure,
    data_key,
    data_value,
    created,
    modified
  }) {
    this.id = id || '';
    this.permit_id = permit_id || 0;
    this.section_id = section_id || '';

    this.data_structure =
      data_structure && typeof data_structure === 'object'
        ? { ...data_structure }
        : null;
    this.data_key = data_key || null;

    if (data_value) {
      try {
        const parsedValue = JSON.parse(data_value);
        data_value = parsedValue;
      } catch (e) {}
    }

    this.data_value = data_value || null;
    this.created = created || null;
    this.modified = modified || null;
  }
}
