/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconThumbsUp = ({ size, colour, ...rest }) => (
  <svg
    width={size}
    height={size}
    ie-style={`var(--${colour})`}
    viewBox="0 0 139 137"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ color: `var(--${colour})` }}
    {...rest}
  >
    <path
      d="M57.5256 56.8464H30.133C21.5841 56.8464 16.0239 65.7126 19.8471 73.2481L39.9721 112.915C41.9201 116.754 45.9021 119.18 50.258 119.18H73.3599C74.3002 119.18 75.2369 119.066 76.1491 118.841L97.7756 113.513M57.5256 56.8464V28.513C57.5256 22.2538 62.6743 17.1797 69.0256 17.1797H69.5746C72.447 17.1797 74.7756 19.4745 74.7756 22.3053C74.7756 26.3531 75.9914 30.3102 78.2696 33.6781L97.7756 62.513V113.513M57.5256 56.8464H69.0256M97.7756 113.513H109.276C115.627 113.513 120.776 108.439 120.776 102.18V68.1797C120.776 61.9205 115.627 56.8464 109.276 56.8464H94.9006"
      stroke="#6B7280"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconThumbsUp.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconThumbsUp.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconThumbsUp);
