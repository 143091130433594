import logger from 'services/logger/logger';

import Client from '../../../Client';

export default class UserRolesMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Asset admin',
      description: '',
      building_id: 1,
      company_id: 0,
      priority: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z',
      readonly: true
    },
    {
      id: 2,
      name: 'Occupier FM',
      description: '',
      building_id: 1,
      company_id: 0,
      priority: 2,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 3,
      name: 'Occupier reception',
      description: '',
      building_id: 1,
      company_id: 0,
      priority: 3,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 4,
      name: 'Security manager',
      description: '',
      building_id: 1,
      company_id: 0,
      priority: 4,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 5,
      name: 'Security officer',
      description: '',
      building_id: 1,
      company_id: 0,
      priority: 5,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 6,
      name: 'Front of house manager',
      description: '',
      building_id: 1,
      company_id: 0,
      priority: 6,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 7,
      name: 'Cleaning manager',
      description: '',
      building_id: 1,
      company_id: 0,
      priority: 7,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 8,
      name: 'Asset admin',
      description: '',
      building_id: 2,
      company_id: 0,
      priority: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 9,
      name: 'Occupier FM',
      description: '',
      building_id: 2,
      company_id: 0,
      priority: 2,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 10,
      name: 'Occupier reception',
      description: '',
      building_id: 2,
      company_id: 0,
      priority: 3,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 11,
      name: 'Security manager',
      description: '',
      building_id: 2,
      company_id: 0,
      priority: 4,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 12,
      name: 'Security officer',
      description: '',
      building_id: 2,
      company_id: 0,
      priority: 5,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 13,
      name: 'Front of house manager',
      description: '',
      building_id: 2,
      company_id: 0,
      priority: 6,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 14,
      name: 'Cleaning manager',
      description: '',
      building_id: 2,
      company_id: 0,
      priority: 7,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 15,
      name: 'Asset admin',
      description: '',
      building_id: 3,
      company_id: 0,
      priority: 1,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 16,
      name: 'Occupier FM',
      description: '',
      building_id: 3,
      company_id: 0,
      priority: 2,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 17,
      name: 'Occupier reception',
      description: '',
      building_id: 3,
      company_id: 0,
      priority: 3,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 18,
      name: 'Security manager',
      description: '',
      building_id: 3,
      company_id: 0,
      priority: 4,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 19,
      name: 'Security officer',
      description: '',
      building_id: 3,
      company_id: 0,
      priority: 5,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 20,
      name: 'Front of house manager',
      description: '',
      building_id: 3,
      company_id: 0,
      priority: 6,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    },
    {
      id: 21,
      name: 'Cleaning manager',
      description: '',
      building_id: 3,
      company_id: 0,
      priority: 7,
      created: '2020-08-28T05:12:13.000Z',
      modified: '2020-08-28T05:12:13.000Z'
    }
  ];

  rolePermissionsLinkDataSet = [
    {
      role_id: 1,
      permission_id: 'visitors/search'
    },
    {
      role_id: 1,
      permission_id: 'visitors/view'
    },
    {
      role_id: 1,
      permission_id: 'visitors/export'
    },
    {
      role_id: 1,
      permission_id: 'visitors/walkin'
    },
    {
      role_id: 1,
      permission_id: 'visitors/update'
    },
    {
      role_id: 1,
      permission_id: 'visitors/checkin'
    },
    {
      role_id: 1,
      permission_id: 'visitors/checkout'
    },
    {
      role_id: 1,
      permission_id: 'helpdesk/search'
    },
    {
      role_id: 1,
      permission_id: 'helpdesk/view'
    },
    {
      role_id: 1,
      permission_id: 'helpdesk/create'
    },
    {
      role_id: 2,
      permission_id: 'deliveries/create'
    },
    {
      role_id: 2,
      permission_id: 'deliveries/unscheduled'
    },
    {
      role_id: 2,
      permission_id: 'deliveries/approve'
    },
    {
      role_id: 2,
      permission_id: 'events/view'
    },
    {
      role_id: 2,
      permission_id: 'events/app/attend'
    },
    {
      role_id: 2,
      permission_id: 'events/app/comment'
    }
  ];

  areaDataSet = [
    {
      id: '985e7b8e-ad60-4969-ac07-709f44e91f8a',
      name: 'Building Management',
      description: '',
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: '95d416e0-50e8-11eb-9b3e-ef51de8b847a',
      name: 'Mobile App',
      description: '',
      building_id: 1,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  groupsDataSet = [
    {
      id: 'f428fd14-5cc3-11eb-b173-9f71894a2152',
      area_id: '985e7b8e-ad60-4969-ac07-709f44e91f8a',
      building_id: null,
      parent_id: null,
      name: 'Can Access',
      permissions: ['building/manage'],
      visible: true,
      readonly: false,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: '3ca386a3-2d18-47e8-a16e-f0fc68359345',
      parent_id: null,
      area_id: '985e7b8e-ad60-4969-ac07-709f44e91f8a',
      building_id: null,
      name: 'Visitor Management',
      permissions: ['visitors/search', 'visitors/view'],
      visible: true,
      readonly: false,
      children: [
        {
          id: 'aab6116b-252d-4a7c-a9bb-f883e6ccc585',
          area_id: null,
          building_id: null,
          parent_id: '3ca386a3-2d18-47e8-a16e-f0fc68359345',
          name: 'Pre-book visitor',
          permissions: ['visitors/create'],
          visible: true,
          readonly: true,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: 'a933e13e-f7a9-4982-a14e-f04295227a55',
          area_id: null,
          building_id: null,
          parent_id: '3ca386a3-2d18-47e8-a16e-f0fc68359345',
          name: 'Download report',
          permissions: ['visitors/export'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '5696ed52-50e5-11eb-a452-17e65c4aab0b',
          area_id: null,
          building_id: null,
          parent_id: '3ca386a3-2d18-47e8-a16e-f0fc68359345',
          name: 'Add a walk-in',
          permissions: ['visitors/walkin'],
          visible: true,
          readonly: true,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '5b54a906-50e5-11eb-af8f-27c6f69eac23',
          area_id: null,
          building_id: null,
          parent_id: '3ca386a3-2d18-47e8-a16e-f0fc68359345',
          name: 'Edit booking',
          permissions: ['visitors/update'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '5e6b75fc-50e5-11eb-9354-5b4205aa9ddc',
          area_id: null,
          building_id: null,
          parent_id: '3ca386a3-2d18-47e8-a16e-f0fc68359345',
          name: 'Check-in visitor',
          permissions: ['visitors/checkin'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '6179fab6-50e5-11eb-b626-8b9a86c36ca1',
          area_id: null,
          building_id: null,
          parent_id: '3ca386a3-2d18-47e8-a16e-f0fc68359345',
          name: 'Check-out visitor',
          permissions: ['visitors/checkout'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      ],
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 'a8892124-50e6-11eb-b5de-1bb88225b56a',
      area_id: '985e7b8e-ad60-4969-ac07-709f44e91f8a',
      parent_id: null,
      building_id: null,
      name: 'Helpdesk',
      permissions: ['helpdesk/search'],
      children: [
        {
          id: 'ba73d618-50e6-11eb-9578-f7f9de7b2b25',
          parent_id: 'a8892124-50e6-11eb-b5de-1bb88225b56a',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'View a ticket',
          permissions: ['helpdesk/view'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '242e1459-271e-44fa-81ca-76d9c81c167c',
          parent_id: 'a8892124-50e6-11eb-b5de-1bb88225b56a',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'Create a ticket',
          permissions: ['helpdesk/create'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: 'bdaa96f0-50e6-11eb-872d-b7541a53c7d4',
          parent_id: 'a8892124-50e6-11eb-b5de-1bb88225b56a',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'Update ticket',
          permissions: ['helpdesk/update'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: 'c15b6ff4-50e6-11eb-b213-9305f6607001',
          parent_id: 'a8892124-50e6-11eb-b5de-1bb88225b56a',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'Assign Tickets',
          permissions: ['helpdesk/assign'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      ],
      visible: true,
      readonly: true,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 'f816e266-50e7-11eb-8df8-bb77e5547e66',
      area_id: '985e7b8e-ad60-4969-ac07-709f44e91f8a',
      parent_id: null,
      building_id: null,
      name: 'Deliveries',
      permissions: ['deliveries/search'],
      visible: true,
      readonly: false,
      children: [
        {
          id: 'fb7e90e8-50e7-11eb-9a90-f76d5f71a853',
          parent_id: 'f816e266-50e7-11eb-8df8-bb77e5547e66',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'View a delivery',
          permissions: ['delivery/view'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '0009b5e8-50e8-11eb-9b79-27ccd6c135c0',
          parent_id: 'f816e266-50e7-11eb-8df8-bb77e5547e66',
          area_id: null,
          building_id: null,
          visible: false,
          readonly: false,
          name: 'Book a delivery',
          permissions: ['deliveries/create'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '02c77cac-50e8-11eb-bac7-ff7562dff6d0',
          parent_id: 'f816e266-50e7-11eb-8df8-bb77e5547e66',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'Book an unscheduled delivery',
          permissions: ['deliveries/unscheduled'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '060491ac-50e8-11eb-a45c-5bcca3764ee0',
          parent_id: 'f816e266-50e7-11eb-8df8-bb77e5547e66',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'Approve a delivery',
          permissions: ['deliveries/approve'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '08ed1704-50e8-11eb-81ec-33e886ccfdbf',
          parent_id: 'f816e266-50e7-11eb-8df8-bb77e5547e66',
          area_id: null,
          building_id: null,
          visible: true,
          readonly: false,
          name: 'Check in / out deliveries',
          permissions: ['deliveries/checkin', 'deliveries/checkout'],
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      ],
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 'd4110bf2-50e8-11eb-906c-377bfa6217a9',
      area_id: '95d416e0-50e8-11eb-9b3e-ef51de8b847a',
      parent_id: null,
      building_id: null,
      name: 'Events',
      permissions: ['events/search', 'events/view'],
      visible: true,
      readonly: false,
      children: [
        {
          id: 'cef6b9fa-50e8-11eb-b24a-bbeb1f921e51',
          parent_id: 'd4110bf2-50e8-11eb-906c-377bfa6217a9',
          area_id: null,
          building_id: null,
          name: 'Attend an event',
          permissions: ['events/app/attend'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: 'e92e736c-50e8-11eb-9f2c-03e3247873a3',
          parent_id: 'd4110bf2-50e8-11eb-906c-377bfa6217a9',
          area_id: null,
          building_id: null,
          name: 'Comment on an event',
          permissions: ['events/app/comment'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        },
        {
          id: '532df458-50e5-11eb-83fb-8ff811932f3d',
          parent_id: 'd4110bf2-50e8-11eb-906c-377bfa6217a9',
          area_id: null,
          building_id: null,
          name: 'Share an event',
          permissions: ['events/app/share'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      ],
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    },
    {
      id: 'a2dd2d92-50f5-11eb-8c8e-a3bf98a40b26',
      area_id: '95d416e0-50e8-11eb-9b3e-ef51de8b847a',
      parent_id: null,
      building_id: null,
      name: 'Meetings',
      permissions: ['meetings/app/search'],
      children: [
        {
          id: 'aa5c229e-50f5-11eb-bfd9-fb0ecfa4b90c',
          parent_id: 'a2dd2d92-50f5-11eb-8c8e-a3bf98a40b26',
          name: 'Book a meeting room',
          permissions: ['meeting/app/book'],
          visible: true,
          readonly: false,
          created: '2020-08-28T11:53:56.000Z',
          modified: '2020-08-28T11:53:56.000Z'
        }
      ],
      visible: true,
      readonly: false,
      created: '2020-08-28T11:53:56.000Z',
      modified: '2020-08-28T11:53:56.000Z'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  getEndpoints() {
    const { generateUUIDV4 } = this.framework;

    return {
      'users/roles': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const { id } = payload;
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          building_id: { field: 'building_id', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const response = this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
        return response;
      },
      'users/roles/getForEdit': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const { id } = payload;
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' },
          building_id: { field: 'building_id', expression: 'eq' }
        };
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');
        const response = this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
        const role = response.data.records[0];

        if (id && response.data && response.data.records) {
          const rolePermissions = {};
          const groups = [];

          const flattened = [];
          this.groupsDataSet.forEach((group) => {
            flattened.push(group);
            if (group.children) {
              group.children.forEach((child) => flattened.push(child));
            }
          });

          this.rolePermissionsLinkDataSet.forEach((link, index) => {
            const { permission_id, role_id } = link;
            if (role_id === id) {
              rolePermissions[permission_id] = index;
            }
          });

          flattened.forEach((group) => {
            const { permissions } = group;
            const confirmedPermissions = permissions.filter((permission) =>
              rolePermissions.hasOwnProperty(permission)
            );

            if (confirmedPermissions.length === permissions.length) {
              groups.push(group.id);
            }
          });

          role.permissions = Object.keys(rolePermissions);
          role.permission_groups = groups;
          response.data = { role };
        }
        return response;
      },
      'users/roles/dashboard': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const { building_id } = payload;

        const areas = [...this.areaDataSet];
        const groups = [...this.groupsDataSet];

        const ret = [];
        const roles = this.dataSet.filter(
          ({ building_id: buildingId }) => buildingId == building_id
        );

        areas.forEach((area) => {
          const { id: areaId } = area;
          area.groups = [];
          groups.forEach((section) => {
            const { area_id } = section;
            if (area_id === areaId) {
              area.groups = groups.filter(({ area_id }) => area_id === areaId);
            }
          });
          ret.push(area);
        });

        return { code: 200, data: { areas: ret, roles } };
      },
      'users/roles/create': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = ['not_logged_in', 'system_error', 'provide_id'];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const { name, building_id, company_id } = payload;
        let lowestPriority = 0;

        this.dataSet.forEach(({ building_id: buildingId, priority }) => {
          if (building_id === buildingId && priority > lowestPriority)
            lowestPriority = priority;
        });

        const role = {
          id: this.dataSet.length + 1,
          description: '',
          name,
          building_id,
          company_id: company_id || 0,
          priority: lowestPriority + 1,
          created: new Date().toISOString(),
          modified: new Date().toISOString()
        };

        this.dataSet.push(role);

        return { code: 200, data: { role } };
      },
      'users/roles/update': (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = ['not_logged_in', 'system_error', 'provide_id'];

        if (error !== false && permittedErrors.includes(error)) {
          return this.framework.createErrorResponse(error);
        }
        if (!isLoggedIn)
          return this.framework.createErrorResponse('not_logged_in');

        const { id, name, groups } = payload;
        let existingIndex = 0;
        let existing = null;
        this.dataSet.forEach((role, index) => {
          const { id: roleId } = role;
          if (roleId === parseInt(id, 10)) {
            existingIndex = index;
            existing = role;
          }
        });
        if (existing) {
          const role = {
            ...existing,
            modified: new Date().toISOString()
          };
          this.dataSet[existingIndex] = role;

          if (Array.isArray(groups)) {
            role.permissions = [];
            role.permission_groups = [];

            const parsedGroups = groups
              .filter(({ enabled }) => enabled)
              .map(({ id }) => id);
            logger.debug(parsedGroups);

            let newPermissionLinks = this.rolePermissionsLinkDataSet.filter(
              ({ role_id }) => role_id != id
            );

            const flattened = [];
            this.groupsDataSet.forEach((group) => {
              if (parsedGroups.includes(group.id)) flattened.push(group);

              if (group.children) {
                group.children.forEach((child) => {
                  if (parsedGroups.includes(child.id)) {
                    flattened.push(child);
                  }
                });
              }
            });

            flattened.forEach((group) => {
              const { permissions } = group;

              role.permissions = [...role.permissions, ...permissions];
              role.permission_groups.push(group.id);

              newPermissionLinks = [
                ...newPermissionLinks,
                ...permissions.map((permission) => ({
                  role_id: parseInt(id, 10),
                  permission_id: permission
                }))
              ];
            });
            this.rolePermissionsLinkDataSet = newPermissionLinks;
          }

          return { code: 200, data: { role } };
        }
        return this.framework.createErrorResponse('provide_id');
      }
    };
  }
}
