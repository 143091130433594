import logger from 'services/logger/logger';

import EmailTemplate from '../Entities/EmailTemplate';
import GenericNamespaceHandler from '../GenericNamespaceHandler';

export default class EmailTemplatesNamespace extends GenericNamespaceHandler {
  namespace = 'email_templates';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = EmailTemplate;

  responseKey = 'template';

  requiredFields = [
    {
      param: 'building_id',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'provide_building_id',
      validation: { type: 'int' }
    },
    {
      param: 'name',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_name',
      validation: { type: 'string', min: 5 }
    },
    {
      param: 'subject',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_subject',
      validation: { type: 'string', min: 5 }
    },
    {
      param: 'content',
      requiredForCreate: true,
      requiredForUpdate: false,
      error: 'invalid_content',
      validation: { type: 'string', min: 5 }
    }
  ];

  searchByBuildingId(
    buildingId,
    criteria = {},
    page = 1,
    orderBy,
    orderDirection
  ) {
    if (buildingId) criteria.building_id = buildingId;
    return this.search(criteria, page, orderBy, orderDirection);
  }

  /**
   * Method to create a template
   *
   * Example: create(
   *    'BOOKASPACE',
   *    'Form submit from book a space',
   *    '<html></html>',
   *    1,
   *    (error, template) => logger.log(error, template)
   * );
   *
   * @param {int} buildingId - must be a valid building_id
   * @param {String} name - must be a valid template name
   * @param {String} subject - must be a valid subject line
   * @param {String} content - Usually html / similar
   *
   * @throws Error
   */
  create(buildingId = null, name = '', subject = '', content = '') {
    return super.create({ building_id: buildingId, name, subject, content });
  }

  /**
   * Method to update a template
   * Please provide at-least one field to update!
   *
   * Example: update(
   *    1,
   *    'BOOKASPACE',
   *    'Form submit from book a space',
   *    '<html></html>',
   *    1,
   *    (error, template) => logger.log(error, template)
   * );
   * @param {int} id - EmailTemplateId of the template to update
   * @param optional
   */
  update(
    id,
    optional = { name: undefined, subject: undefined, content: undefined }
  ) {
    return super.update(id, optional);
  }
}
