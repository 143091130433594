import React from 'react';

import Button from 'components/Button/Button';
import SolidCircleTick from 'components/Icons/SolidCircleTick';
import IconUploadCircle from 'components/Icons/UploadCircle';
import TimeStamp from 'components/TimeStamp/TimeStamp';
import { useSystemNotifications } from 'context/systemNotifications-context';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import styles from './ProgressNotification.module.scss';

const ProgressNotification = ({
  name,
  cancelUpload,
  message,
  id,
  type,
  timeStamp,
  size,
  progress
}) => {
  const renderIcon = () => {
    if (type === 'in_progress') {
      return <IconUploadCircle percents={progress} />;
    }
    return (
      <div className={styles.icon}>
        <SolidCircleTick colour="neutral-6" />
      </div>
    );
  };

  const { dismissSystemNotification } = useSystemNotifications();

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0.8, x: 300 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        transition={{
          type: 'spring',
          stiffness: 500,
          damping: 40
        }}
        layout
        className={styles.notification}
      >
        <div className={styles.message} id={`${id}-description`}>
          {/* Change later to file array length (how many files) */}
          <div className={styles.uploadNotificationContainer}>
            <div className={styles.uploadNotificationTitleContainer}>
              <div className={styles.icons}>{renderIcon()}</div>
              <p className={styles.uploadNotificationTitle}>{message}</p>
            </div>
            {/* <hr /> */}
            {type === 'uploading' && (
              <div className={styles.cancelContainer}>
                <p className={styles.startUploading}>Starting upload...</p>
                {/* Add onClick from props as needs to interact with file uploader */}
                <Button
                  className={styles.cancelButton}
                  text="CANCEL"
                  appearance="text"
                  size={12}
                  onClick={() => cancelUpload(id)}
                />
              </div>
            )}
          </div>
          {name || size ? (
            <div className={styles.fileDetailsContainer}>
              {/* Icon */}
              <div className={styles.fileDetails}>
                <p className={styles.fileName}>{name && name}</p>
                <p className={styles.fileSize}>
                  {size && `${+`${Math.round(`${size / 1000000}e+3`)}e-3`} MB`}
                </p>
              </div>
              <div className={styles.timeStamp}>
                {type === 'completed' && timeStamp && (
                  <TimeStamp time={timeStamp} indicator />
                )}
              </div>
            </div>
          ) : null}
        </div>
        <Button
          className={styles.close}
          onClick={() => dismissSystemNotification(id)}
          appearance="icon-only"
          iconName="Cross"
          iconColour="neutral-1"
          width="30px"
          height="30px"
          describedBy={`${id}-description`}
          text="Dismiss notification"
          dataTestId="system_notification"
        />
      </motion.div>
    </div>
  );
};

ProgressNotification.propTypes = {
  name: PropTypes.string.isRequired,
  cancelUpload: PropTypes.func,
  message: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  timeStamp: PropTypes.instanceOf(Date),
  size: PropTypes.number
};

ProgressNotification.defaultProps = {
  cancelUpload: () => {},
  message: '',
  type: 'in_progress',
  timeStamp: new Date(),
  size: 0
};

export default ProgressNotification;
