import Client from '../../Client';

export default class ClientsMockNamespace {
  dataSet = [
    {
      id: 1,
      name: 'Savills'
    },
    {
      id: 2,
      name: 'Cureoscity'
    },
    {
      id: 3,
      name: 'Knight Frank'
    }
  ];

  /** *
   * @param {Client} Client - instance of client
   * @param {MockFramework} Framework - instance of framework
   */
  constructor(Client, Framework) {
    this.client = Client;
    this.framework = Framework;
  }

  pushEntities = (client) => {
    const { id } = client;

    return client;
  };

  getEndpoints() {
    this.dataSet.forEach(this.pushEntities);

    return {
      clients: (
        payload,
        headers,
        method,
        transmitType,
        isLoggedIn,
        userPermissions,
        error
      ) => {
        const permittedErrors = [
          'not_logged_in',
          'no_permission',
          'no_records',
          'provide_client_id'
        ];
        const filters = {
          id: { field: 'id', expression: 'eq' },
          name: { field: 'name', expression: 'like' }
        };
        return this.framework.defaultSearchMock(
          payload,
          this.dataSet,
          filters,
          permittedErrors,
          error
        );
      }
    };
  }
}
