/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconHelpDesk = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m8 0c-4.22533 0-8 2.68927-8 6.36809 0 1.30455.492 2.58555 1.36467 3.57955.03666 1.16456-.682003 2.83566-1.32867 4.05236 1.73467-.2991 4.20067-.9596 5.31867-1.6138 6.15733 1.4299 10.64533-2.1668 10.64533-6.01811 0-3.69854-3.8007-6.36809-8-6.36809zm0 9.54545c-.37667 0-.68267-.29209-.68267-.65227 0-.35954.306-.65163.68267-.65163.37733 0 .68267.29209.68267.65163 0 .36018-.30534.65227-.68267.65227zm1.07067-3.09145c-.49334.50845-.51667.78973-.51067 1.13591h-1.09533c-.004-.76873.01066-1.10855.782-1.80855.31266-.28381.56-.50845.52533-.95009-.03133-.42-.39933-.63891-.74467-.63891-.38733 0-.84066.27491-.84066 1.04937h-1.09734c0-1.25109.77-2.05991 1.96067-2.05991.566 0 1.06133.17691 1.39333.49827.312.301.47267.71591.46667 1.19827-.00867.72164-.46933 1.19509-.83933 1.57564z"
      fill="currentColor"
    />
  </svg>
);

IconHelpDesk.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconHelpDesk.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconHelpDesk);
