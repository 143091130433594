/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

import Checkbox from '../../Checkbox/Checkbox';

const CheckboxInput = ({
  additionalClasses,
  afterChange,
  control,
  dataTestId,
  defaultValue,
  deleteSelector,
  disabled,
  inline,
  label,
  name,
  onChange,
  onClick,
  readOnly,
  required
}) => (
  <Controller
    defaultValue={defaultValue}
    name={name}
    control={control}
    rules={{
      required
    }}
    render={({ onChange: rhfOnChange, ref, value }) => (
      <Checkbox
        additionalClasses={additionalClasses}
        afterChange={afterChange}
        dataTestId={dataTestId || `${name}_checkbox`}
        deleteSelector={deleteSelector}
        disabled={disabled}
        inline={inline}
        inputRef={ref}
        label={label}
        name={name}
        onChange={(e) => {
          rhfOnChange(e);
          onChange();
        }}
        onClick={onClick}
        readOnly={readOnly}
        required={required}
        value={value}
      />
    )}
  />
);

CheckboxInput.propTypes = {
  additionalClasses: PropTypes.string,
  afterChange: PropTypes.func,
  control: PropTypes.object.isRequired,
  dataTestId: PropTypes.string,
  defaultValue: PropTypes.bool,
  deleteSelector: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
};

CheckboxInput.defaultProps = {
  additionalClasses: '',
  afterChange: () => {},
  dataTestId: '',
  defaultValue: false,
  deleteSelector: '',
  disabled: false,
  inline: false,
  onChange: () => {},
  onClick: () => {},
  readOnly: false,
  required: false
};

export default CheckboxInput;
