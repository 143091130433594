import Client from '../Client';
import Permit from '../Entities/Permits/Permit';
import PermitData from '../Entities/Permits/PermitData';
import PermitSection from '../Entities/Permits/PermitSection';
import PermitSession from '../Entities/Permits/PermitSession';
import PermitSessionData from '../Entities/Permits/PermitSessionData';
import GenericNamespaceHandler from '../GenericNamespaceHandler';

export default class PermitsNamespace extends GenericNamespaceHandler {
  namespace = 'permits';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = Permit;

  responseKey = 'permit';

  requiredFields = [];

  processPermitEntities = (permit) => {
    const { data } = permit;
    Object.keys(data).forEach((sectionId) => {
      data[sectionId] = data[sectionId].map(
        (dataItem) => new PermitData(dataItem)
      );
    });
  };

  searchByBuildingId = async (buildingId, query = {}) => {
    const {
      skip = 0,
      take = 10,
      orderBy = [],
      where = {},
      include = {
        location: true
      }
    } = query;
    const response = await this.queryFeed({
      building_id: buildingId,
      skip: skip || 0,
      take: take || 10,
      ...(Object.keys(include).length && { include }),
      ...(Object.keys(where).length && { where }),
      ...(orderBy.length && { orderBy })
    });
    const records = response.data.records.map(
      (entity) => new this.responseEntity(this.client, entity)
    );
    return {
      ...response.data,
      records
    };
  };

  get(id = 0, translateValues = false) {
    return new Promise((resolve, reject) => {
      if (isNaN(id) || id <= 0)
        throw this.client.translateResponseError(new Error('provide_id'));
      id = +id;

      this.search({ id, translate_values: translateValues })
        .then((entity) => {
          this.processPermitEntities(entity);
          resolve(entity);
        })
        .catch((error) => reject(error));
    });
  }

  sections = () =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'sections',
        (error, response) => {
          if (!response?.data?.sections && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const sections = response?.data?.sections.map(
              (section) => new PermitSection(section)
            );
            resolve(sections);
          }
        },
        null,
        Client.methods.GET
      );
    });

  report = (criteria = {}, page = 1, orderBy, orderDirection) => {
    if (
      this.searchRequiresBuildingId &&
      !criteria.hasOwnProperty('building_id') &&
      !criteria.hasOwnProperty('id')
    ) {
      throw this.client.translateResponseError(
        new Error('provide_building_id')
      );
    }

    return new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'report',
        (error, response) => {
          if (!response?.data?.records && !error)
            error = new Error('unknown_error');
          if (error) return reject(error);

          const permits = response?.data?.records.map(
            (permit) => new Permit(this.client, permit)
          );
          return resolve(permits);
        },
        { page, orderBy, orderDirection, ...criteria },
        Client.methods.GET
      );
    });
  };

  /**
   * Callback triggered when a response is received from the API for a save request
   *
   * @callback StartOnboardingCallback
   * @param {Error} error - any error that's been passed back
   * @param {PermitSession} permitSession - The onboarding session if successful
   */

  /** *
   * Starts the onboarding process
   * @param {StartOnboardingCallback} callback - Callback once a response is received.
   */
  startSession = (buildingId = null, sections = []) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'session/start',
        (error, response) => {
          if (!response?.data?.session && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const session = new PermitSession(response?.data?.session);
            resolve(session);
          }
        },
        { building_id: buildingId, sections }
      );
    });

  /**
   * Callback triggered when a response is received from the API for a save request
   *
   * @callback SaveOnboardingCallback
   * @param {Error} error - any error that's been passed back
   * @param {PermitSession} permitSession - The onboarding session if successful
   * @param {Object} permitSessionData - data returned if successful
   */

  /** *
   * Saves data to your onboarding session the onboarding process
   * @param {PermitSession} permitSession - Session object to save data against
   * @param {SaveOnboardingCallback} callback - Callback once a response is received.
   */
  saveSessionData = (
    session,
    sectionId = null,
    dataKey = '',
    dataValue = {}
  ) => {
    if (
      !Array.isArray(dataKey) &&
      !['string', 'number', 'bigint', 'symbol', 'function'].includes(
        typeof dataValue
      )
    ) {
      dataValue = JSON.stringify(dataValue);
    }

    return new Promise((resolve, reject) => {
      if (
        !session ||
        typeof session !== 'object' ||
        session.constructor.name !== 'PermitSession'
      )
        throw new Error('Please provide an permitSession');

      if (dataValue === undefined || dataValue === null)
        throw new Error('Please provide a valid value');

      session.setDataForKey(sectionId, dataKey, dataValue);
      this.client.request(
        this.namespace,
        'session/save',
        (error, response) => {
          if (!response?.data && !error) error = new Error('unknown_error');

          if (error) reject(error);
          else {
            resolve({ data: response?.data, session });
          }
        },
        {
          session_id: session.id,
          section_id: sectionId,
          data_key: dataKey,
          data_value: dataValue
        }
      );
    });
  };

  /** *
   *
   * @param {PermitSession} session - Session object to save data against
   * @param {String} dataKey - key to retrieve data against
   */
  getSessionData = (session, dataKey = '') =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session.constructor.name !== 'PermitSession'
      )
        throw new Error('Please provide an permitSession');

      this.client.request(
        this.namespace,
        'session/get',
        (error, response) => {
          if (!response?.data && !error) error = new Error('unknown_error');

          if (error) reject(error);
          else {
            resolve(new PermitSessionData(response?.data));
          }
        },
        { session_id: session.id, data_key: dataKey },
        Client.methods.GET
      );
    });

  /** *
   *
   * @param {PermitSession} session - Session object to save data against
   */
  getAllSessionData = (session) =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session?.constructor?.name !== 'PermitSession'
      )
        throw new Error('Please provide an permitSession');

      this.client.request(
        this.namespace,
        'session/getAll',
        (error, response) => {
          if (!Array.isArray(response?.data) && !error)
            error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const data = response?.data.map(
              (sessionData) => new PermitSessionData(sessionData)
            );
            resolve(data);
          }
        },
        { session_id: session.id },
        Client.methods.GET
      );
    });

  /** *
   * Triggers a registration call
   * @param {PermitSession} session - Onboarding session to complete
   */
  create = (session) =>
    new Promise((resolve, reject) => {
      if (
        typeof session !== 'object' ||
        session?.constructor?.name !== 'PermitSession'
      )
        throw new Error('Please provide an permitSession');

      this.client.request(
        this.namespace,
        'create',
        (error, { data }) => {
          if (!data?.permit?.id && !error) error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const permit = data?.permit
              ? new this.responseEntity(this.client, data[this.responseKey])
              : null;
            resolve(permit);
          }
        },
        { session_id: session.id }
      );
    });

  save = (id, sectionId, data) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'save',
        (error, { data }) => {
          if (!data?.permit?.id && !error) error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const permit = data?.permit
              ? new this.responseEntity(this.client, data[this.responseKey])
              : null;
            this.processPermitEntities(permit);
            resolve(permit);
          }
        },
        { id, section_id: sectionId, data }
      );
    });

  resubmit = (id, sectionId, data) =>
    new Promise((resolve, reject) => {
      this.client.request(
        this.namespace,
        'resubmit',
        (error, { data }) => {
          if (!data?.permit?.id && !error) error = new Error('unknown_error');
          if (error) reject(error);
          else {
            const permit = data?.permit
              ? new this.responseEntity(this.client, data[this.responseKey])
              : null;
            this.processPermitEntities(permit);
            resolve(permit);
          }
        },
        { id, section_id: sectionId, data }
      );
    });

  approve(id, comment) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'approve',
        (error, { data }) => {
          if (error) reject(error);
          const permit = new this.responseEntity(
            this.client,
            data[this.responseKey]
          );
          this.processPermitEntities(permit);
          resolve(permit);
        },
        { id, comment },
        Client.methods.PUT
      );
    });
  }

  reject(id, comment) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'reject',
        (error, { data }) => {
          if (error) reject(error);
          const permit = new this.responseEntity(
            this.client,
            data[this.responseKey]
          );
          this.processPermitEntities(permit);
          resolve(permit);
        },
        { id, comment },
        Client.methods.PUT
      );
    });
  }

  close(id, comment) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        'close',
        (error, { data }) => {
          if (error) reject(error);
          const permit = new this.responseEntity(
            this.client,
            data[this.responseKey]
          );
          this.processPermitEntities(permit);
          resolve(permit);
        },
        { id, comment },
        Client.methods.PUT
      );
    });
  }

  activate(id) {
    return this.toggleActivated(id);
  }

  deactivate(id) {
    return this.toggleActivated(id, true);
  }

  toggleActivated(id, deactivate) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        deactivate ? 'deactivate' : 'activate',
        (error, { data }) => {
          if (error) reject(error);
          const permit = new this.responseEntity(
            this.client,
            data[this.responseKey]
          );
          this.processPermitEntities(permit);
          resolve(permit);
        },
        { id },
        Client.methods.PUT
      );
    });
  }

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(id, unarchive) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        unarchive ? 'unarchive' : 'archive',
        (error, { data }) => resolve(Boolean(data)),
        { id },
        Client.methods.PUT
      );
    });
  }
}
