/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';

export const OpenFolder = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      d="M26.0117 3.85133H16.5089C16.416 3.85116 16.3269 3.82007 16.2611 3.76484L13.2846 1.26005C13.0078 1.02632 12.6786 0.84103 12.3162 0.714856C11.9537 0.588682 11.5652 0.524125 11.1729 0.524909H2.98832C2.19609 0.52579 1.43661 0.790904 0.876414 1.26212C0.316222 1.73333 0.00104631 2.37218 0 3.03858V21.0123C0.00104699 21.6786 0.316265 22.3174 0.876494 22.7884C1.43672 23.2594 2.19621 23.5243 2.98832 23.5249H26.0117C26.8038 23.5243 27.5633 23.2594 28.1235 22.7884C28.6837 22.3174 28.999 21.6786 29 21.0123V6.365C28.999 5.6986 28.6838 5.05975 28.1236 4.58854C27.5634 4.11733 26.8039 3.85221 26.0117 3.85133Z"
      fill="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

OpenFolder.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

OpenFolder.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default OpenFolder;
