/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconBell = ({ size, colour, ...rest }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    {...rest}
    fill="none"
    viewBox="0 0 29 29"
  >
    <path
      d="M14.984 5.2a7 7 0 00-7 7v5a3 3 0 01-3 3h20a3 3 0 01-3-3v-5a7 7 0 00-7-7zm0 0v-3m1.73 22a2 2 0 01-3.46 0"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconBell.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconBell.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconBell);
