/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const ListView = ({ size, colour, ...rest }) => (
  <svg
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    width={size}
    height={size}
    viewBox="0 0 21 15"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H20.1613C20.5755 0.25 20.9113 0.585786 20.9113 1C20.9113 1.41421 20.5755 1.75 20.1613 1.75H1C0.585786 1.75 0.25 1.41421 0.25 1ZM0.25 7.3871C0.25 6.97288 0.585786 6.6371 1 6.6371H20.1613C20.5755 6.6371 20.9113 6.97288 20.9113 7.3871C20.9113 7.80131 20.5755 8.1371 20.1613 8.1371H1C0.585786 8.1371 0.25 7.80131 0.25 7.3871ZM0.25 13.7742C0.25 13.36 0.585786 13.0242 1 13.0242H20.1613C20.5755 13.0242 20.9113 13.36 20.9113 13.7742C20.9113 14.1884 20.5755 14.5242 20.1613 14.5242H1C0.585786 14.5242 0.25 14.1884 0.25 13.7742Z"
      fill={colour}
    />
  </svg>
);

ListView.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

ListView.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(ListView);
