const gaScript = (gaId) => gaId ? `https://www.googletagmanager.com/gtag/js?id=${gaId}` : '';

const gaInit = (gaId) => gaId ? `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${gaId}');
  gtag('config', '${gaId}', {
    send_page_view: false
  });
` : '';

export { gaScript, gaInit }
