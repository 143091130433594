import ArticlesNamespace from './Mobile/Articles';
import EventsNamespace from './Mobile/Events';
import MyBuildingNamespace from './Mobile/MyBuilding';
import OffersNamespace from './Mobile/Offers';
import PollsNamespace from './Mobile/Polls';

export default class MobileNamespace {
  /** *
   * @param {Client} Client - instance of client
   */

  constructor(Client) {
    this.articles = new ArticlesNamespace(Client);
    this.events = new EventsNamespace(Client);
    this.mybuilding = new MyBuildingNamespace(Client);
    this.offers = new OffersNamespace(Client);
    this.polls = new PollsNamespace(Client);
  }
}
