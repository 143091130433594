/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconNavigation = ({ size, colour, ...rest }) => (
  <svg
    width={size}
    height={size}
    style={{ color: `var(--${colour})` }}
    ie-style={`var(--${colour})`}
    viewBox="0 0 29 29"
    fill="none"
    {...rest}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m4.59888 13.6967 19.00002-9.00004-9 19.00004-2-8z"
    />
  </svg>
);

IconNavigation.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconNavigation.defaultProps = {
  size: '100%',
  colour: 'theme-primary'
};

export default memo(IconNavigation);
