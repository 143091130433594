/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconCiclePlus = ({ size, colour, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 22 22" fill="none" {...rest}>
    <path
      d="M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke={colour}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCiclePlus.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colour: PropTypes.string
};

IconCiclePlus.defaultProps = {
  size: '100%',
  colour: '#999999'
};

export default memo(IconCiclePlus);
