import Client from '../../Client';
import MyBuildingArticle from '../../Entities/Mobile/MyBuildingArticle';
import GenericNamespaceHandler from '../../GenericNamespaceHandler';

export default class MyBuildingNamespace extends GenericNamespaceHandler {
  namespace = 'mobile/my-building';

  searchCall = '';

  createCall = 'create';

  updateCall = 'update';

  deleteCall = 'delete';

  searchRequiresBuildingId = true;

  responseEntity = MyBuildingArticle;

  responseKey = 'article';

  requiredFields = [];

  searchByBuildingId(
    buildingId,
    criteria = {},
    page = 1,
    orderBy,
    orderDirection
  ) {
    if (buildingId) criteria.building_id = buildingId;
    criteria.getChildEntities = true;
    return this.search(criteria, page, orderBy, orderDirection);
  }

  create(buildingId, title, body, coverImage, publishDate) {
    const data = {
      title,
      body,
      cover_image_uri: coverImage,
      publish_date: publishDate,
      building_id: buildingId
    };

    return super.create(data);
  }

  update(
    id = null,
    optional = {
      title: undefined,
      body: undefined,
      cover_image_uri: undefined,
      publish_date: undefined
    }
  ) {
    return super.update(id, optional);
  }

  archive(id) {
    return this.toggleArchived(id);
  }

  unarchive(id) {
    return this.toggleArchived(id, true);
  }

  toggleArchived(id, unarchive) {
    return new Promise((resolve, reject) => {
      if (!id) throw new Error('provide_id');

      this.client.request(
        this.namespace,
        unarchive ? 'unarchive' : 'archive',
        (error, { data }) => resolve(Boolean(data)),
        { id },
        Client.methods.PUT
      );
    });
  }
}
